import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
} from "../../shared/components/TableElements";
import DataReactTable from "../DataTable/components/DataReactTable";
import { Link } from "react-router-dom";
import getUnixTime from "date-fns/getUnixTime";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import ExplorePopup from "../../shared/components/ExplorePopup";
import { clearAllAdvancedFilters } from "../../features/advancedFilter/advancedFilterSlice";
import { LinkSpan } from "../../shared/components/form/FormElements";
import {
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedTransactionType,
} from "../../shared/helpers";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const ExpensesTable = () => {
  const { t } = useTranslation("common");
  const columnWidth = 130;
  const { rowData, values, status } = useSelector((state) => state.filter);
  const filterData = useSelector((state) => state.filter.data);
  const expensesColumns = useSelector((state) => state.advancedFilter.expenses);
  const jsonData = {
    ...filterData,
    ...expensesColumns,
    //querystring: "getallexpenses",
  };

  // const { reload, setReload, isLoggedIn, setLoading } = useAuth();

  // const {
  //   draw,
  //   length,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   start,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   querystring,
  // } = filterData;

  // const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const { minDate } = useSelector((state) => state.columns);

  const { currency, distance } = useSelector((state) => state.units);
  const [showExplorePopup, setShowExplorePopup] = useState(false);

  // const { orgDate } = useSelector((state) => state.user);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "getallexpenses",
    sortIndex: 2,
  });

  //Initial Effect
  // useEffect(() => {
  //   if (
  //     location.pathname.includes("expenses/expenses") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //     //&&
  //     //colOrderQuery === "getfillupsorder"
  //   ) {
  //     (async () => {
  //       setLoading(true);

  //       if (expensesColumns) {
  //         dispatch(
  //           clearAllAdvancedFilters({
  //             columns: Object.keys(expensesColumns),
  //             screen: "services",
  //           })
  //         );
  //       }
  //       const startDate = 0;
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "expense",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         const { col_order, min_date, default_rows } = data;
  //         if (col_order) {
  //           colOrder = col_order;
  //         }
  //         minDate = min_date;
  //         defaultRows = default_rows;
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
  //         index = colOrderUI.findIndex((col) => col === 2);
  //       }

  //       dispatch(
  //         updateInitialData({
  //           query: "getallexpenses",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "getallexpenses",
  //       };

  //       const initialAdvancedFilterCols = {
  //         odo: {},
  //         vendor: {},
  //         expenseTask: {},
  //         totalCost: {},
  //         transactionType: {},
  //         cardNumber: {},
  //         expenseBy: {},
  //         comments: {},
  //       };

  //       dispatch(fetchData({ ...initialData, ...initialAdvancedFilterCols }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // //On initial load, send update initial data
  // // useEffect(() => {
  // //   (async () => {
  // //     if (
  // //       isLoggedIn &&
  // //       location?.pathname.includes("expenses/expenses") &&
  // //       firstTimeLoad
  // //     ) {
  // //       console.log("Initial Effect called - expenses ");
  // //       const startDate = 0; //getUnixTime(subDays(new Date(), 6));
  // //       const endDate = getUnixTime(new Date());
  // //       dispatch(
  // //         updateInitialData({
  // //           query: "getallexpenses",
  // //           startDate,
  // //           endDate,
  // //           length: 10,
  // //         })
  // //       );
  // //       const initialData = {
  // //         draw: 1,
  // //         length: 10,
  // //         search: {
  // //           value: "",
  // //           regex: false,
  // //         },
  // //         order: [
  // //           {
  // //             column: 1,
  // //             dir: "desc",
  // //           },
  // //         ],
  // //         start_date: 0,
  // //         end_date: getUnixTime(new Date()),
  // //         start: 0,
  // //         filter_group_id: "",
  // //         filter_vehicle_id: "",
  // //         querystring: "getallexpenses",
  // //       };
  // //       if (querystring === "getallexpenses") {
  // //         //setLoadingToTrue();
  // //         dispatch(fetchData({ ...initialData, ...expensesColumns }));
  // //         setTimeout(() => {
  // //           setFirstTimeLoad(false);
  // //         }, 2000);
  // //       }
  // //     }
  // //   })();

  // //   //Fetch all vehicles initially to capture updation of vehicle secondary status to use in issue form and right panel
  // //   // (async () => {
  // //   //   const vehiclesRes = await getVehicles("get_all_vehicle");
  // //   //   dispatch(updateVehiclesList(vehiclesRes));
  // //   // })();
  // // }, [querystring]);

  // useEffect(() => {
  //   if (isLoggedIn && querystring === "getallexpenses" && !firstTimeLoad) {
  //     dispatch(updateStart(0));
  //     //setLoadingToTrue();
  //     dispatch(fetchData({ ...filterData, ...expensesColumns }));
  //   }
  // }, [
  //   draw,
  //   length,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   //start,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   expensesColumns,
  //   //reload,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (
  //     isLoggedIn &&
  //     location?.pathname.includes("expenses/expenses") &&
  //     !firstTimeLoad
  //   ) {
  //     //if (token) {
  //     //setLoadingToTrue();
  //     dispatch(fetchData({ ...filterData, ...expensesColumns })); //Destructure and add columns similar to date
  //     //}
  //   }
  // }, [start]);

  // //Reload Effect
  // useEffect(() => {
  //   if (
  //     location?.pathname.includes("expenses/expenses") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getallexpenses" &&
  //     reload === "expense"
  //   ) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...filterData, ...expensesColumns, start: 0 }));
  //     //setLoading(true);
  //     setReload("");
  //   }
  // }, [reload]);

  const valuesArray = [
    {
      id: 0,
      name: t("cost"),
      value: values.footer_total_cost
        ? `${values.footer_total_cost?.toFixed(2)} ${currency}`
        : NATEXT,
      //units: values.footer_total_cost ? "INR" : "",
      //icon: "currency",
    },
  ];

  const { defaultContainsData, defaultRangeData, defaultTransactionTypeData } =
    getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      date: {
        column: "date",
        name: t("date_tv"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      odo: {
        column: "odo",
        name: t("odo"),
        ...defaultRangeData,
      },
      vendor: {
        column: "vendor",
        name: t("vendor"),
        ...defaultContainsData,
      },
      expenseTask: {
        column: "expenseTask",
        name: t("expense_task"),
        ...defaultContainsData,
      },
      totalCost: {
        column: "totalCost",
        name: t("tc_tv"),
        ...defaultRangeData,
      },
      transactionType: {
        column: "transactionType",
        name: t("transaction_type"),
        ...defaultTransactionTypeData,
      },
      cardNumber: {
        column: "cardNumber",
        name: t("card_number"),
        ...defaultContainsData,
      },
      expenseBy: {
        column: "expenseBy",
        name: t("expense_by"),
        ...defaultContainsData,
      },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );
  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("date_tv"),
        accessor: "expense_date",
        disableCol: true,
        width: columnWidth,
        Cell: ({ value }) => (value ? getListingDisplayDate(value) : NATEXT),
      },
      {
        id: 3,
        Header: t("odo"),
        accessor: "odo",
        width: columnWidth,
        Cell: ({ row }) => {
          const { odo, primary_meter, required } = row?.original;
          const odoUnit =
            primary_meter === "Hours"
              ? getDistanceUnit(primary_meter)
              : getDistanceUnit(distance);
          return odo && required ? `${odo} ${odoUnit}` : NATEXT;
        },
      },
      {
        id: 4,
        Header: t("vendor"),
        accessor: "vendor",
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 5,
        Header: t("expense_task"),
        accessor: "expense_task_name",
        width: columnWidth,
        accessorId: "task_name",
      },
      {
        id: 6,
        Header: t("tc_tv"),
        accessor: "total_cost",
        disableSortBy: false,
        width: columnWidth,
        // Cell: ({ value }) =>
        //   value && value !== "0" ? Number(value.toFixed(2)) : NATEXT,
        Cell: ({ row }) => {
          const { total_cost, tax, discount } = row.original;
          let total = 0;
          if (+total_cost) {
            total = +total_cost + +tax - +discount;
          }
          return total ? `${Number(total).toFixed(2)} ${currency}` : NATEXT;

          // if (+value && value !== "0") {
          //   return Number(value).toFixed(2);
          // } else {
          //   return NATEXT;
          // }
        },
      },
      {
        id: 7,
        Header: t("transaction_type"),
        accessor: "transaction_type",
        width: columnWidth,
        Cell: ({ value }) =>
          value ? getTranslatedTransactionType(value, t) : NATEXT,
      },
      {
        id: 8,
        Header: t("card_number"),
        accessor: "card_number",
        width: columnWidth,
        Cell: ({ value }) => (value && +value ? value : NATEXT),
      },
      {
        id: 9,
        Header: t("expense_by"),
        accessor: "uname",
        width: columnWidth,
        //Cell: ({ value }) => users.find((u) => u.user_id === value)?.name,
      },
      {
        id: 10,
        Header: t("notes_tv"),
        accessor: "comments",
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
      // {
      //   id: 12,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
    ],
    [currency]
  );

  let count = 0;
  let defaultColumns = {};
  columnHeaders.forEach((column) => {
    defaultColumns[count] = {
      accessor: column.accessor,
      header: column.Header,
    };
    count++;
  });

  // const rows = [];
  // for (let i = 0; i < rowData?.length; i++) {
  //   rows.push({
  //     name: rowData[i].name ? rowData[i].name.slice(0, 20) : "N/A",
  //     expense_date: rowData[i].expense_date ? rowData[i].expense_date : "N/A",
  //     odo: rowData[i].odo ? `${rowData[i].odo}` : "N/A",
  //     vendor: rowData[i].vendor ? `${rowData[i].vendor}` : "N/A",
  //     expense_task_name: rowData[i].expense_task_name
  //       ? `${rowData[i].expense_task_name.slice(0, 70)}`
  //       : "N/A",
  //     total_cost: rowData[i].total_cost
  //       ? `${rowData[i].total_cost} INR`
  //       : "N/A",
  //     transaction_type: rowData[i].transaction_type
  //       ? rowData[i].transaction_type
  //       : "N/A",
  //     card_number: rowData[i].card_number ? rowData[i].card_number : "N/A",
  //     expense_by: rowData[i].expense_by ? rowData[i].expense_by : "N/A",
  //     comments: rowData[i].comments ? rowData[i].comments.slice(0, 70) : "N/A",
  //     row_id: rowData[i].expense_id ? rowData[i].expense_id : "N/A",
  //     group_name: rowData[i].group_name
  //       ? rowData[i].group_name.slice(0, 20)
  //       : "N/A",
  //     veh_id: rowData[i].veh_id,
  //     veh_img: rowData[i].veh_img,
  //     org_id: rowData[i].org_id,
  //     primary_meter: rowData[i].primary_meter,
  //   });
  // }
  //console.log('Data inside rows', data);
  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IEL1GTQE"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          {/* <Col sm={10}>
            <ColumnHeading>{t("expenses")}</ColumnHeading>
          </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("expenses")}</ColumnHeading>
            {/* <FilterButton onClick={() => setShowExplorePopup(true)}>
              <i
                className="fi fi-rr-globe"
                style={{ margin: "4px 4px 0 0" }}
              ></i>
              <span>{t("explore")}</span>
            </FilterButton> */}
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col sm={2} className="d-flex justify-content-end">
            {/* {roleId !== 4 && ( */}
            <Link to={"/expenses/expenses/addnew"}>
              <AddFormButton>{t("add_expense")}</AddFormButton>
            </Link>
            {/* )} */}
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={valuesArray}
            initialData={initialData}
            defaultColumns={defaultColumns}
          />
        </Row>
      </Container>
    </>
  );
};

export default ExpensesTable;
