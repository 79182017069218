import React from "react";
import { colorBlue, colorLightBlue } from "../../../../../../utils/palette";
import { useTranslation } from "react-i18next";
import defaultPartIcon from "@/shared/img/default/default_parts.png";
import { useSelector } from "react-redux";
import { s3Url } from "../../../../../constants";

const PartInfoComponent = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { orgId } = useSelector((state) => state.user);
  const {
    part_name,
    qty,
    isParent,
    warehouse_loc,
    part_notes,
    part_img,
    part_id,
    barcode,
  } = rowData;
  const partImg = part_img
    ? `${s3Url}/org_${orgId}/parts/${part_id}/${part_img}`
    : "";
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2 align-items-center py-2">
          <div
            style={{
              border: `1px solid ${colorBlue}`,
              borderRadius: "20px",
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={partImg || defaultPartIcon}
              alt="part-img"
              style={{ width: "40px", height: "40px" }}
              className="rounded-circle"
            />
          </div>
          <div style={{ overflow: "auto" }}>
            <p style={{ fontSize: "14px", fontWeight: 500 }}>{part_name}</p>
            {!isParent && <p style={{ fontSize: "12px" }}>{warehouse_loc}</p>}
            {barcode && (
              <p style={{ fontSize: "12px" }}>{`${t(
                "upc_barcode"
              )}: ${barcode}`}</p>
            )}
            <p style={{ fontSize: "12px" }}>{`${t("available_qty")}: ${
              qty || 0
            }`}</p>
          </div>
        </div>
      </div>
      <p className="mb-1" style={{ fontSize: "12px" }}>
        {part_notes}
      </p>
    </>
  );
};

export default PartInfoComponent;
