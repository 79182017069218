import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colorGray, colorLightGray } from "../../../utils/palette";
import { Icon } from "../TableElements";

const AddButton = ({ handleClick }) => {
  const { t } = useTranslation("common");
  return (
    <AddIconContainer onClick={handleClick} type="button" id="add-task-btn">
      <Icon className="fi fi-rr-plus"></Icon>
      <span>{t("add")}</span>
    </AddIconContainer>
  );
};

export default AddButton;

export const AddIconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  color: black;
  border: 1px solid ${colorLightGray};
  background: white;
  font-weight: 500;
  color: ${colorGray};
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  &:hover {
    background: ${colorLightGray};
  }
`;
