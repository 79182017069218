import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DataReactTable from "../DataTable/components/DataReactTable";
import { useSelector } from "react-redux";
import {
  initialContainsData,
  initialRangeData,
  initialTransactionTypeData,
} from "../../shared/constants/filterData";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  AddFormButton,
  ColumnHeading,
  FilterButton,
} from "../../shared/components/TableElements";
import { Link, useLocation } from "react-router-dom";
import {
  getDisplayDate,
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedTransactionType,
} from "../../shared/helpers";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import { useAuth } from "../../contexts/AuthContext";
import { putApi } from "../../api/getUser";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import ExplorePopup from "../../shared/components/ExplorePopup";
import { clearAllAdvancedFilters } from "../../features/advancedFilter/advancedFilterSlice";
import { LinkSpan } from "../../shared/components/form/FormElements";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import { dhm } from "../WorkOrders";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const ServicesTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const columnWidth = 130;
  const location = useLocation();
  const { pathname } = location;
  //const fromCancel = location.state?.fromCancel;

  const { status } = useSelector((state) => state.filter);
  const rowData = useSelector((state) => state.filter.rowData);
  const servicesValues = useSelector((state) => state.filter.values);
  const filterData = useSelector((state) => state.filter.data);
  const columns = useSelector((state) => state.advancedFilter.services);
  const jsonData = {
    ...filterData,
    ...columns,
    //querystring: "getallservices",
  };

  // const {
  //   draw,
  //   length,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   start,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   querystring,
  // } = filterData;

  const { distance, currency } = useSelector((state) => state.units);

  // const users = useSelector((state) => state.users.list);

  // const { isLoggedIn, laoding, setLoading, reload, setReload } = useAuth();
  const { minDate, maxDate, noOfRecords } = useSelector(
    (state) => state.columns
  );

  // const { orgDate } = useSelector((state) => state.user);

  const [showExplorePopup, setShowExplorePopup] = useState(false);

  const { defaultContainsData, defaultRangeData, defaultTransactionTypeData } =
    getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      date: {
        column: "date",
        name: t("date_tv"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      odo: {
        column: "odo",
        name: t("odo"),
        ...defaultRangeData,
      },
      serviceStation: {
        column: "serviceStation",
        name: t("service_station"),
        ...defaultContainsData,
      },
      serviceTask: {
        column: "serviceTask",
        name: t("service_task"),
        ...defaultContainsData,
      },
      partNames: {
        column: "partNames",
        name: t("parts_consumed"),
        ...defaultContainsData,
      },
      totalCost: {
        column: "totalCost",
        name: t("tc_tv"),
        ...defaultRangeData,
      },
      totalLaborCost: {
        column: "totalLaborCost",
        name: t("labor_cost"),
        ...defaultRangeData,
      },
      totalPartsCost: {
        column: "totalPartsCost",
        name: t("parts_cost"),
        ...defaultRangeData,
      },
      transactionType: {
        column: "transactionType",
        name: t("transaction_type"),
        ...defaultTransactionTypeData,
      },
      cardNumber: {
        column: "cardNumber",
        name: t("card_number"),
        ...defaultContainsData,
      },
      servicedBy: {
        column: "servicedBy",
        name: t("serviced_by"),
        ...defaultContainsData,
      },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );

  // const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  // const colOrder = useSelector((state) => state.columns.order);
  // const colOrderQuery = useSelector((state) => state.columns.query);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "getallservices",
    sortIndex: 2,
  });

  // //Initial Effect
  // useEffect(() => {
  //   if (
  //     pathname.includes("services/services") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //     //&&
  //     //colOrderQuery === "getfillupsorder"
  //   ) {
  //     (async () => {
  //       if (columns) {
  //         dispatch(
  //           clearAllAdvancedFilters({
  //             columns: Object.keys(columns),
  //             screen: "services",
  //           })
  //         );
  //       }
  //       setLoading(true);
  //       console.log("Initial Effect called - services ");
  //       const startDate = 0;
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "service",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       console.log("userSettingsRes", userSettingsRes);
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         console.log("data", data);
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
  //         index = colOrderUI.findIndex((col) => col === 2);
  //       }

  //       dispatch(
  //         updateInitialData({
  //           query: "getallservices",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "getallservices",
  //       };

  //       const initialAdvancedFilterCols = {
  //         odo: {},
  //         serviceStation: {},
  //         serviceTask: {},
  //         totalCost: {},
  //         totalLaborCost: {},
  //         totalPartsCost: {},
  //         transactionType: {},
  //         cardNumber: {},
  //         servicedBy: {},
  //         comments: {},
  //       };

  //       dispatch(fetchData({ ...initialData, ...initialAdvancedFilterCols }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, initialLoadDelay);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  //On initial load, send update initial data
  // useEffect(() => {
  //   (async () => {
  //     if (
  //       pathname.includes("services/services") &&
  //       firstTimeLoad &&
  //       colOrderQuery === "getserviceorder"
  //     ) {
  //       console.log("Initial Effect called - Services ");
  //       const startDate = 0; //getUnixTime(subDays(new Date(), 6));
  //       const endDate = getUnixTime(new Date());
  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         index = colOrder.findIndex((col) => col === 2);
  //       }
  //       dispatch(
  //         updateInitialData({
  //           query: "getallservices",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: noOfRecords ? noOfRecords : 25,
  //         })
  //       );

  //       //setLoadingToTrue();
  //       //setLoading(true);

  //       const initialData = {
  //         draw: 1,
  //         length: noOfRecords ? noOfRecords : 25,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "getallservices",
  //       };
  //       dispatch(fetchData({ ...initialData, ...columns }));

  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, 1000);
  //     }
  //   })();

  //   //Fetch all vehicles initially to capture updation of vehicle secondary status to use in issue form and right panel
  //   // (async () => {
  //   //   const vehiclesRes = await getVehicles("get_all_vehicle");
  //   //   dispatch(updateVehiclesList(vehiclesRes));
  //   // })();
  // }, [isLoggedIn, colOrder, colOrderQuery]);

  // //Effect to call fetch api without start
  // useEffect(() => {
  //   if (querystring === "getallservices" && !firstTimeLoad && !reload) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData(jsonData));
  //   }
  // }, [
  //   draw,
  //   length,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   //start,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   columns,
  // ]);

  // //Effect to fetch data with change in start
  // useEffect(() => {
  //   if (querystring === "getallservices" && !firstTimeLoad && !reload) {
  //     dispatch(fetchData(jsonData));
  //   }
  // }, [start]);

  // //Fetch data on reload
  // useEffect(() => {
  //   if (
  //     pathname.includes("services/services") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getallservices" &&
  //     reload === "service"
  //   ) {
  //     console.log("Reload Effect");
  //     dispatch(updateStart(0));
  //     dispatch(fetchData(jsonData));
  //     //setLoading(true);
  //     setReload("");
  //   }
  // }, [reload]);

  //dispatch(fetchData('getallservices'));
  const servicesValuesArray = [
    {
      id: 0,
      name: t("cost"),
      value: servicesValues.footer_total_cost
        ? `${servicesValues.footer_total_cost?.toFixed(2)} ${currency}`
        : NATEXT,
    },
    {
      id: 1,
      name: t("labor_cost"),
      value: servicesValues.footer_total_labor_cost
        ? `${servicesValues.footer_total_labor_cost?.toFixed(2)} ${currency}`
        : NATEXT,
    },
    {
      id: 2,
      name: t("parts_cost"),
      value: servicesValues.footer_total_parts_cost
        ? `${servicesValues.footer_total_parts_cost?.toFixed(2)} ${currency}`
        : NATEXT,
    },
    {
      id: 3,
      name: t("total_duration"),
      value: servicesValues.footer_total_time
        ? dhm(servicesValues.footer_total_time * 1000)
        : NATEXT,
    },
  ];

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("date_tv"),
        accessor: "service_date",
        //disableFilters: true,
        width: columnWidth,
        disableCol: true,
        //minWidth: 50,
        //Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
        Cell: ({ value }) => (value ? getListingDisplayDate(value) : NATEXT),
      },
      {
        id: 3,
        Header: t("odo"),
        accessor: "odo",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ row }) => {
          const { odo, primary_meter } = row?.original;
          const odoUnit =
            primary_meter === "Hours"
              ? getDistanceUnit(primary_meter)
              : getDistanceUnit(distance);
          return odo ? `${odo} ${odoUnit}` : NATEXT;
        },
      },
      {
        id: 4,
        Header: t("service_station"),
        accessor: "service_station",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
        // disableGlobalFilter: false,
      },
      {
        id: 5,
        Header: t("service_task"),
        accessor: "service_task_name",
        //disableFilters: true,
        width: columnWidth,
        accessorId: "task_name",
        Cell: ({ value }) => (value ? value : NATEXT),
        // disableGlobalFilter: false,
      },
      {
        id: 6,
        Header: t("tc_tv"),
        accessor: "total",
        // disableGlobalFilter: false,
        disableSortBy: false,
        //disableFilters: true,
        width: columnWidth,
        //Cell: ({ value }) => (value ? value : NATEXT),
        Cell: ({ row }) => {
          const { discount, tax, total } = row?.original;
          if (+total && +total > 0) {
            const grandTotal = +total - +discount + +tax;
            return `${grandTotal?.toFixed(2)} ${currency}`;
          } else {
            return NATEXT;
          }
        },
      },
      {
        id: 7,
        Header: t("labor_cost"),
        accessor: "labor_total",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) =>
          value ? `${value?.toFixed(2)} ${currency}` : NATEXT,
        // Cell: ({ row }) => {
        //   if (row?.original?.total_cost) {
        //     const costObj = JSON.parse(row.original.total_cost);
        //     const laborCostTotal = +costObj?.labor_cost_total;
        //     //console.log(laborCostTotal);
        //     if (laborCostTotal && laborCostTotal > 0) {
        //       return `${laborCostTotal?.toFixed(2)} ${currency}`;
        //     } else {
        //       return NATEXT;
        //     }
        //   }
        // },
        // disableGlobalFilter: false,
      },
      {
        id: 8,
        Header: t("parts_cost"),
        accessor: "parts_total",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) =>
          value ? `${value?.toFixed(2)} ${currency}` : NATEXT,
        // Cell: ({ row }) => {
        //   if (row?.original?.total_cost) {
        //     const costObj = JSON.parse(row.original.total_cost);
        //     const partsCostTotal = +costObj?.parts_total;
        //     if (partsCostTotal && partsCostTotal > 0) {
        //       return `${+partsCostTotal?.toFixed(2)} ${currency}`;
        //     } else {
        //       return NATEXT;
        //     }
        //     //return `${costObj.parts_total?.toFixed(2)} ${currency}`;
        //   } else {
        //     return NATEXT;
        //   }
        // },
        // disableGlobalFilter: false,
      },
      {
        id: 9,
        Header: t("transaction_type"),
        accessor: "transaction_type",
        //disableFilters: true,
        Cell: ({ value }) =>
          value ? getTranslatedTransactionType(value, t) : NATEXT,
        width: columnWidth,
      },
      {
        id: 10,
        Header: t("card_number"),
        accessor: "card_number",
        //disableFilters: true,
        Cell: ({ value }) => (+value ? +value : NATEXT),
        width: columnWidth,
      },
      {
        id: 11,
        Header: t("serviced_by"),
        accessor: "uname",
        //disableFilters: true,
        //Cell: ({ value }) => users?.find((u) => u.user_id === value)?.label,
        width: columnWidth,
      },
      {
        id: 12,
        Header: t("notes_tv"),
        accessor: "comments",
        //disableFilters: true,
        width: columnWidth,
        accessorId: "comments",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 13,
        Header: t("total_duration"),
        accessor: "total_time",
        width: columnWidth,
        Cell: ({ value }) => (value ? dhm(value * 1000) : NATEXT),
      },
      {
        id: 14,
        Header: t("parts_consumed"),
        accessor: "part_names",
        accessorId: "task_name",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      // {
      //   id: 12,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    [rowData, currency, distance]
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IAFSOVME"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          {/* <Col sm={10}>
          <ColumnHeading>{t("services")}</ColumnHeading>
        </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("services")}</ColumnHeading>
            {/* <FilterButton onClick={() => setShowExplorePopup(true)}>
              <i
                className="fi fi-rr-globe"
                style={{ margin: "4px 4px 0 0" }}
              ></i>
              <span>{t("explore")}</span>
            </FilterButton> */}
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col sm={2} className="d-flex justify-content-end align-items-center">
            <Link to={"/services/services/addnew"}>
              <AddFormButton id="add-service-btn">
                {t("add_service")}
              </AddFormButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={servicesValuesArray}
            initialData={initialData}
          />
        </Row>
      </Container>
    </>
  );
  //return <div>Hello World</div>;
};

export default ServicesTable;
