import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ActionPopup from "../../shared/components/ActionPopup";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { colorRowHover, scrollbarStyles } from "../../utils/palette";
import { useTranslation } from "react-i18next";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import getUnixTime from "date-fns/getUnixTime";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../api/firebase";
import { logErrors, post } from "../../api/getUser";
import { getDistanceUnit } from "../../shared/helpers";
import axios from "axios";
import { fetchServicesTasks } from "../../features/servicesTasks/servicesTasksSlice";
import { useDispatch } from "react-redux";
import { TableSpinner } from "../../shared/components/table/components/ReactTableConstructor";
import CheckboxInputComponent from "../../shared/components/form/CheckboxInputComponent";

const MaintenanceSchedulePopup = ({
  showPopup,
  setShowPopup,
  //maintSchedule,
  //setMaintSchedule,
  newVehicle,
  setNewVehicle,
  handleSkip,
  toggleSnackbar,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { tag } = useAuth();
  const { userId, orgId, roleId } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);
  const serviceTasksList = useSelector((state) => state.servicesTasks.list);

  const [maintSchedule, setMaintSchedule] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  const [createBtnLoading, setCreateBtnLoading] = useState(false);
  const distanceUnit = getDistanceUnit(
    newVehicle?.primary_meter === "Hours" ? "Hours" : distance
  );

  async function getMaintenanceSchedule(
    apiKey,
    prompt,
    model,
    availableTasks,
    controller
  ) {
    const endpoint = "https://api.openai.com/v1/chat/completions";

    const data = {
      model, // Specify GPT-4 model
      messages: [
        // {
        //   role: "system",
        //   content:
        //     "You are a helpful assistant that returns car maintenance schedules.",
        // },
        {
          role: "user",
          content: prompt,
        },
      ],
      response_format: {
        type: "json_object",
      },
      max_tokens: 4096,
      //temperature: 0.7,
    };

    try {
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          //"Content-Type": "application/json",
        },
        signal: controller?.signal,
      });

      let res = "";
      let recSchedule = [];

      if (response.data.choices[0].message.content) {
        res = JSON.parse(response.data.choices[0].message.content);
        //console.log("gpt res", res);
        if (res) {
          const { matched, unmatched } = res;

          if (matched?.length > 0) {
            let availableTaskIds = [];
            if (availableTasks?.length > 0) {
              availableTaskIds = availableTasks?.map((t) => t?.id);
            }
            matched.forEach((task) => {
              //Check if matched task id in actually present in available_tasks (Since sometimes gpt is giving random task ids in matched arr)
              //Check if odo_interval and time_interval are greater than 0
              if (
                availableTaskIds?.includes(task?.id) &&
                (+task?.odo_interval > 0 || +task.time_interval > 0)
              ) {
                recSchedule.push({ ...task, matched: true, isChecked: true });
              }
            });
          }

          if (unmatched?.length > 0) {
            let availableTaskNames = [];
            if (availableTasks?.length > 0) {
              availableTaskNames = availableTasks?.map((t) => t?.name);
            }
            unmatched.forEach((task, i) => {
              //Check if odo_interval and time_interval are greater than 0
              if (+task?.odo_interval > 0 || +task.time_interval > 0) {
                //Check if unmatched has mistakenly given tasks that are already present
                if (availableTaskNames?.includes(task.task_name)) {
                  const existingTask = availableTasks.find(
                    (existing) =>
                      existing.name?.toLocaleLowerCase() ===
                      task.task_name?.toLocaleLowerCase()
                  );
                  if (existingTask) {
                    recSchedule.push({
                      ...task,
                      matched: true,
                      isChecked: true,
                      id: existingTask?.id,
                    });
                  }
                } else {
                  recSchedule.push({
                    ...task,
                    matched: false,
                    id: `${userId}_${Date.now() + i}`,
                    isChecked: true,
                  });
                }
              }
            });
          }

          if (recSchedule.length > 0) {
            return recSchedule;
          }
          return null;
        }
      }
    } catch (error) {
      console.error(error.response ? error.response.data : error.message);
      return null;
    }
  }

  useEffect(() => {
    if (distance && newVehicle) {
      //not including service tasks here since it gets updated later causing re-render of this effect
      const controller = new AbortController();
      (async () => {
        const aiConfigApiData = {
          querystring: "get_ai_config",
          module: "reminders",
        };
        const aiConfigRes = await post("aiConfig", aiConfigApiData);
        if (aiConfigRes?.success) {
          const { api_key, data, model } = aiConfigRes;
          const { make, year, primary_meter, fuel_type } = newVehicle;
          const distanceUnit = primary_meter === "Hours" ? "Hours" : distance;
          const availableTasks = serviceTasksList
            .filter((task) => task.veh_id === "All")
            .map((t) => ({
              id: t.service_task_id,
              name: t.service_task_name,
            }));
          //console.log("availableTasks", availableTasks);
          const serviceTasksJson = {
            vehicle_make: make,
            vehicle_model: newVehicle.model,
            unit: distanceUnit,
            year,
            fuel_type,
            available_tasks: availableTasks,
          };
          const stringifiedJson = JSON.stringify(serviceTasksJson);
          const promptData = data.replace(
            "<service_task_json>",
            stringifiedJson
          );
          const recSchedule = await getMaintenanceSchedule(
            api_key,
            promptData,
            model,
            availableTasks,
            controller
          );
          //console.log("recSchedule", recSchedule);

          if (!recSchedule) {
            setNewVehicle(null);
            toggleSnackbar(t("gpt_rec_schedule_res_err"), 2);
            setShowPopup(false);
          }
          if (recSchedule) {
            setMaintSchedule(recSchedule);
            //setShowMaintSchedulePopup(true);
          }
        }
      })();

      return () => {
        controller.abort();
      };
    }
  }, [newVehicle, distance]);

  useEffect(() => {
    if (maintSchedule.length > 0) {
      const selectedServiceTasks = maintSchedule.filter((t) => t.isChecked);
      if (selectedServiceTasks.length === maintSchedule.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
  }, [maintSchedule]);

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setMaintSchedule((prev) => prev.map((t) => ({ ...t, isChecked: checked })));
  };

  function getFormatedStringFromDays(numberOfDays) {
    const years = Math.floor(numberOfDays / 365);
    const months = Math.floor((numberOfDays % 365) / 30);
    const days = Math.floor((numberOfDays % 365) % 30);

    const yearsDisplay =
      years > 0 ? `${years} ${t("years_sm", { count: years })}` : "";
    const monthsDisplay =
      months > 0 ? `${months} ${t("months_sm", { count: months })}` : "";
    const daysDisplay =
      days > 0 ? `${days} ${t("days_sm", { count: days })}` : "";
    if (yearsDisplay) {
      return `${yearsDisplay} ${monthsDisplay}`;
    } else {
      return `${monthsDisplay} ${daysDisplay}`;
    }
  }

  const handleCheckboxChange = (taskId) => {
    setMaintSchedule((prev) => {
      let temp = [...prev];
      temp = temp.map((t) => {
        if (t.id === taskId) {
          return { ...t, isChecked: !t.isChecked };
        } else {
          return t;
        }
      });
      return temp;
    });
  };

  const handleCreateSchedule = async () => {
    const errLogData = {
      url: "AI schedule saved",
      method_type: newVehicle?.veh_id,
      error: "",
      request: "",
      placeholder: "",
    };
    logErrors(errLogData);
    const selectedServiceTasks = maintSchedule.filter((t) => t.isChecked);

    if (selectedServiceTasks?.length > 0) {
      setCreateBtnLoading(true);
      let service_tasks = [];
      let reminders = [];
      selectedServiceTasks.forEach((task, i) => {
        const { id, task_name, odo_interval, time_interval } = task;
        if (!task.matched) {
          service_tasks.push({
            service_task_id: id,
            service_task_name: task_name,
            recurring: 1,
            servicetask_veh_id: "All",
            comments: "",
            source: "web",
            sync_version: "v2",
            action: 1,
          });
        }
        const { starting_odo, veh_id } = newVehicle;
        const reminderId = `${veh_id}_${Date.now() + i}`;
        let dueOnOdo = starting_odo
          ? +starting_odo + +odo_interval
          : +odo_interval;
        let dueOnDate = 0;
        if (+time_interval) {
          const timeIntervalMilliSecs = time_interval * 86400000;
          dueOnDate = getUnixTime(Date.now() + timeIntervalMilliSecs);
        }
        const emails = tag === "free-user" ? "" : auth.currentUser.email;
        let odoThreshold = 250;
        let daysThreshold = 15;
        if (tag === "free-user") {
          odoThreshold = 0;
          daysThreshold = 0;
        } else {
          if (!odo_interval || +odo_interval <= 0) {
            odoThreshold = 0;
          }
          if (!time_interval || +time_interval <= 0) {
            daysThreshold = 0;
          }
        }

        reminders.push({
          veh_id: newVehicle.veh_id,
          due_on_odo: dueOnOdo,
          due_on_date: dueOnDate,
          //querystring: "add_reminder",
          emails,
          emails_sent: 0,
          reminder_id: reminderId,
          service_task_id: id,
          expense_task_id: null,
          last_date_of_service: getUnixTime(new Date()),
          last_odo_of_service: starting_odo || 0,
          due_days: time_interval,
          due_odo: odo_interval,
          days_threshold: daysThreshold,
          odo_threshold: odoThreshold,
          desc: "",
          type: 0,
          sync_version: "v3",
          create_wo: 0,
          user_id: userId,
          assigned_technician: "",
          wo_priority: 0,
          wo_instructions: "",
          action: 1,
        });
      });
      const serviceTasksApi = {
        sync_version: "v2",
        querystring: "add_service_task_v2",
        source: "web",
        service_tasks,
      };

      const remindersApi = {
        sync_version: "v3",
        source: "web",
        querystring: "add_reminder_v2",
        operation: "create",
        reminders,
      };

      //Create Service Tasks
      if (service_tasks.length > 0) {
        const serviceTasksRes = await post("commonNew", serviceTasksApi);
        if (!serviceTasksRes?.success) {
          setNewVehicle(null);
          toggleSnackbar(t("new_tasks_rem_insert_err"), 2);
          setCreateBtnLoading(false);
          setShowPopup(false);
          return;
        }

        if (serviceTasksRes?.success) {
          const apiData = {
            querystring: "getallservicetask",
            service_veh_id: "All",
          };
          dispatch(fetchServicesTasks(apiData));
          const failedRecords = serviceTasksRes?.data?.failed_records_count;
          if (failedRecords > 0) {
            setNewVehicle(null);
            toggleSnackbar(t("new_tasks_rem_insert_err"), 2);
            setCreateBtnLoading(false);
            setShowPopup(false);
            return;
          }
        }
      }
      if (reminders.length > 0) {
        //Create Reminders
        const remindersRes = await post("commonNew", remindersApi);
        if (!remindersRes?.success) {
          setNewVehicle(null);
          toggleSnackbar(t("new_tasks_rem_insert_err"), 2);
          setCreateBtnLoading(false);
          setShowPopup(false);
          return;
        }

        if (remindersRes?.success) {
          setNewVehicle(null);
          toggleSnackbar(t("maint_schedule_created_msg"), 1);
          setCreateBtnLoading(false);
          setShowPopup(false);
        }
      }
    }
  };

  const handleClose = () => setShowPopup(false);
  return (
    <>
      <Modal
        show={showPopup}
        dialogClassName="modal-80w"
        size="lg"
        //onHide={() => setShowPopup(false)}
      >
        <Modal.Header>
          <h4>{t("asset_added")}</h4>
          <div className="d-flex justify-content-end">
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>

        <ModalBody className="m-0 px-2 py-0">
          <div className="d-flex flex-column gap-1">
            <h5 className="my-2 mx-3" style={{ fontWeight: 500 }}>
              {t("asset_added_succes_msg")}
            </h5>
            {maintSchedule?.length > 0 && (
              <h5 className="my-2 mx-3" style={{ fontWeight: 500 }}>
                {t("rec_maint_schedule_msg", {
                  vehicleName: newVehicle?.name,
                })}
              </h5>
            )}
            <ScrollableDiv $height={`${window.innerHeight - 300}px`}>
              {maintSchedule?.length === 0 && (
                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                  <TableSpinner animation="border" />
                  {t("fetching_maint_schedule")}
                </div>
              )}
              {maintSchedule.length > 0 && (
                <div className="mx-3 my-1" style={{ fontWeight: 600 }}>
                  <CheckboxInputComponent
                    checked={selectAll}
                    setChecked={setSelectAll}
                    id={"select-all-checkbox"}
                    label={t("select_all_tasks")}
                    onChange={handleSelectAllChange}
                    //disabled={!status || search}
                  />
                </div>
              )}
              {maintSchedule?.length > 0 &&
                maintSchedule.map((task) => (
                  <ScheduleDiv
                    key={task.id}
                    onClick={() => handleCheckboxChange(task.id)}
                  >
                    <div>
                      <input
                        type="checkbox"
                        id={task.id}
                        name={task.id}
                        //value={id}
                        checked={task.isChecked}
                        readOnly
                        //onChange={() => handleCheckboxChange(task.id)}
                        style={{
                          margin: "0 8px 0 0",
                          position: "relative",
                          top: "1px",
                        }}
                      />
                      <span style={{ fontWeight: 400, margin: 0 }}>
                        {task.task_name}
                      </span>
                    </div>
                    <div>
                      <span>
                        {/* {t("schedule_duration_msg", {
                        odo: `${task.odo_interval} ${distanceUnit}`,
                        time: t("days_sm", { count: task?.time_interval }),
                      })} */}
                        {`${t("every")} ${
                          +task.odo_interval > 0
                            ? `${task.odo_interval} ${distanceUnit}`
                            : ""
                        } ${
                          +task.odo_interval > 0 && +task.time_interval > 0
                            ? t("or")
                            : ""
                        } ${
                          +task.time_interval > 0
                            ? getFormatedStringFromDays(+task.time_interval)
                            : ""
                        }`}
                      </span>
                    </div>
                  </ScheduleDiv>
                ))}
            </ScrollableDiv>
          </div>
        </ModalBody>
        <Modal.Footer className="d-flex justify-content-around">
          <FilterButton
            id="skip-btn"
            type="button"
            onClick={handleSkip}
            style={{ minWidth: 250 }}
          >
            {t("skip")}
          </FilterButton>
          <AddFormButton
            id="create-schedule-btn"
            type="button"
            onClick={handleCreateSchedule}
            disabled={maintSchedule?.filter((t) => t.isChecked)?.length === 0}
            style={{ minWidth: 250 }}
          >
            {createBtnLoading ? <CustomLoader /> : t("create_maint_reminders")}
          </AddFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MaintenanceSchedulePopup;

//styles
export const ScrollableDiv = styled.div`
  overflow: auto;
  ${(p) => p.$height && `height: ${p.$height}`};
  ${scrollbarStyles}
`;

export const ScheduleDiv = styled.div`
  cursor: pointer;
  padding: 0.5rem 1rem;

  &:hover {
    background: ${colorRowHover};
  }
`;
