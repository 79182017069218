import React, { useState, useEffect } from "react";
import {
  getDateWithTime,
  getDistanceFactorforOdo,
  getDistanceUnit,
  getListingDisplayDate,
  getOdoUnit,
} from "../helpers";
import { post, postApi } from "../../api/getUser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useFetchOdoHint = ({ vehicle, date, time, filterDateEnd }) => {
  const { t } = useTranslation("common");
  const [odoHint, setOdoHint] = useState("");
  const [odoUnit, setOdoUnit] = useState("");
  const [lastOdoValue, setLastOdoValue] = useState(0);
  const [nextOdoValue, setNextOdoValue] = useState(0);

  const { distance } = useSelector((state) => state.units);

  //Effect to change odo hint data on change of vehicle, date and time
  useEffect(() => {
    (async () => {
      if (vehicle) {
        let filterEndDate = filterDateEnd
          ? filterDateEnd
          : getDateWithTime(date, time);

        const res = await post("commonNew", {
          querystring: "getOdometerFromDate",
          veh_id: vehicle?.vehicleId,
          date_to_check: filterEndDate,
        });
        const response = res[0];
        if (!response?.success) {
          setLastOdoValue(0);
          setNextOdoValue(0);
          setOdoHint("");
        }
        if (response.success === 1) {
          const {
            data: {
              odo,
              odo_date,
              odo_module,
              next_odo,
              next_odo_date,
              next_odo_module,
            },
          } = response;

          let lastOdo = +odo;
          let nextOdo = +next_odo;
          const distanceFactor = getDistanceFactorforOdo(
            vehicle?.primary_meter,
            distance
          );
          if (lastOdo) {
            lastOdo = lastOdo / distanceFactor;
            setLastOdoValue(lastOdo);
            lastOdo = lastOdo.toFixed(2);
          } else {
            setLastOdoValue(0);
          }
          if (nextOdo) {
            nextOdo = nextOdo / distanceFactor;
            setNextOdoValue(nextOdo);
            nextOdo = nextOdo.toFixed(2);
          } else {
            setNextOdoValue(0);
          }

          if (lastOdo && nextOdo) {
            // setOdoHint(
            //   t("last_odo") + lastOdo + ", " + t("next_odo") + nextOdo
            // );
            const odoHintMsg = `${t("last_odo")}: ${lastOdo} ${getDistanceUnit(
              vehicle?.primary_meter
            )} ${
              odo_date ? `(Dt: ${getListingDisplayDate(odo_date)})` : ""
            } - ${t("next_odo")}: ${nextOdo} ${getDistanceUnit(
              vehicle?.primary_meter
            )} ${
              next_odo_date
                ? `(Dt: ${getListingDisplayDate(next_odo_date)})`
                : ""
            }`;
            setOdoHint(odoHintMsg);
          } else if (lastOdo && !nextOdo) {
            let odoHintMsg = `${t("last_odo")}: ${lastOdo} ${getDistanceUnit(
              vehicle?.primary_meter
            )}`;
            if (odo_date) {
              odoHintMsg += ` (Dt: ${getListingDisplayDate(odo_date)})`;
            }
            // setOdoHint(
            //   `${t("last_odo")}: ${lastOdo} ${getDistanceUnit(
            //     vehicle?.primary_meter
            //   )} (Dt: ${getListingDisplayDate(odo_date)})`
            // );
            setOdoHint(odoHintMsg);
          } else if (!lastOdo && nextOdo) {
            let odoHintMsg = `${t("next_odo")}: ${nextOdo} ${getDistanceUnit(
              vehicle?.primary_meter
            )}`;
            if (next_odo_date) {
              odoHintMsg += ` (Dt: ${getListingDisplayDate(next_odo_date)})`;
            }

            // setOdoHint(
            //   `${t("next_odo")}: ${nextOdo} ${getDistanceUnit(
            //     vehicle?.primary_meter
            //   )} (Dt: ${getListingDisplayDate(next_odo_date)})`
            // );
            setOdoHint(odoHintMsg);
          } else {
            setOdoHint("");
          }
        }
      }
    })();
  }, [vehicle, date, time, filterDateEnd]);

  //Effect to update Odo Unit on vehicle change
  useEffect(() => {
    if (vehicle) {
      setOdoUnit(getOdoUnit(vehicle?.primary_meter));
    }
  }, [vehicle]);
  return { odoHint, odoUnit, lastOdoValue, nextOdoValue };
};

export default useFetchOdoHint;
