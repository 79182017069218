import React, { useMemo, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataReactTable from "../DataTable/components/DataReactTable";
import {
  AddFormButton,
  ColumnHeading,
  StatusContainer,
} from "../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import { useDispatch } from "react-redux";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import { useState } from "react";
import { useEffect } from "react";
import { NATEXT, initialLoadDelay } from "../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import {
  getDisplayDate,
  getDistanceUnit,
  getScreenName,
} from "../../shared/helpers";
import { getIssuePriorityText } from "../Issues/IssuesTable";
import TaskPillComponent from "../../shared/components/table/components/TaskPillComponent";
import { dhm, getTimeSinceAcceptance } from ".";
import { putApi } from "../../api/getUser";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import { useAuth } from "../../contexts/AuthContext";
import ExplorePopup from "../../shared/components/ExplorePopup";
import { LinkSpan } from "../../shared/components/form/FormElements";
import SubscribePopup from "../../shared/components/SubscribePopup";
import { useHistory, useLocation } from "react-router";
import { addSnackbarData } from "../../features/snackbar/snackbarSlice";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";
import { colorStatusActive } from "../../utils/palette";

const WorkOrdersTable = () => {
  const { roleId, orgDate } = useSelector((state) => state.user);

  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const fromCancel = location.state?.fromCancel;
  const rowData = useSelector((state) => state.filter.rowData);

  const { data, values, status } = useSelector((state) => state.filter);
  const { pause_data, completeWorkOrder, inWorkOrder, openWorkOrder } = values;

  const columns = useSelector((state) => state.advancedFilter.workorders);

  const storedFilters = useSelector((state) => state.storedFilters);

  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = data;
  const { pathname } = location;
  const { currency, distance } = useSelector((state) => state.units);

  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const { minDate } = useSelector((state) => state.columns);

  const [showExplorePopup, setShowExplorePopup] = useState(false);
  const screenName = getScreenName(pathname);

  const workOrderKPIs = [
    {
      id: 0,
      key: "footertotalcost",
      name: t("open_work_orders"),
      value: openWorkOrder ? openWorkOrder : NATEXT,
      //units: "INR",
      icon: "currency",
    },
    {
      id: 1,
      key: "footertotalqty",
      name: t("in_progress_work_orders"),
      value: inWorkOrder ? inWorkOrder : NATEXT,
      //units: "Ltr",
      icon: "gasStation",
    },
    {
      id: 2,
      key: "footertotaleff",
      name: t("completed_work_orders"),
      value: completeWorkOrder ? completeWorkOrder : NATEXT,
      //units: "km/L",
      icon: "gauge",
    },
  ];

  //On initial load, send update initial data
  // useEffect(() => {
  //   if (pathname.includes("workorders") && firstTimeLoad) {
  //     console.log("Initial Effect called - fillups ");
  //     const startDate = 0;
  //     const endDate = getUnixTime(new Date());
  //     dispatch(
  //       updateInitialData({
  //         query: "get_all_workorder_data",
  //         startDate,
  //         endDate,
  //       })
  //     );
  //     const initialData = {
  //       draw: 1,
  //       length: 10,
  //       search: {
  //         value: "",
  //         regex: false,
  //       },
  //       order: [
  //         {
  //           column: 1,
  //           dir: "desc",
  //         },
  //       ],
  //       start_date: 0,
  //       end_date: getUnixTime(new Date()),
  //       start: 0,
  //       filter_group_id: "",
  //       filter_vehicle_id: "",

  //       querystring: "get_all_workorder_data",
  //     };

  //     setLoadingToTrue();
  //     dispatch(fetchData({ ...initialData })); //Add ...columns

  //     setTimeout(() => {
  //       setFirstTimeLoad(false);
  //     }, 2000);

  //     let interval = setInterval(() => {
  //       console.log("Running");
  //       //if (querystring === "get_all_workorder_data") {
  //       dispatch(fetchData({ ...initialData })); //Add ...columns
  //       //}
  //     }, 5000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [reload]);

  //Fetch data on every filter change expect for start and set start to 0
  // useEffect(() => {
  //   if (pathname.includes("workorders") && !firstTimeLoad) {
  //     console.log("Effect without start change - workorders ");
  //     setLoadingToTrue();
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...data })); //Add ...columns

  //     let interval = setInterval(() => {
  //       console.log("Running");
  //       if (querystring === "get_all_workorder_data") {
  //         dispatch(fetchData({ ...data })); //Add ...columns
  //       }
  //     }, 5000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   //querystring,
  //   //columns,
  //   reload,
  // ]);

  const { isLoggedIn, reload, setReload, setLoading, tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  //If coming from cancel, set first time load to true after timeout
  //to allow filtering in listing
  useEffect(() => {
    if (isLoggedIn && fromCancel) {
      setTimeout(() => {
        setFirstTimeLoad(false);
      }, initialLoadDelay);
    }
  }, [isLoggedIn]);

  //Effect for first time load
  useEffect(() => {
    if (
      pathname.includes("workorders") &&
      isLoggedIn &&
      orgDate &&
      (!fromCancel || status === "initial")
    ) {
      (async () => {
        setLoading(true);
        const startDate = 0;
        const endDate = getUnixTime(new Date());

        let colOrder = "[0,1,2,3,4,5,6]";
        let minDate = orgDate;
        let defaultRows = 10;

        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "work_order",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");

        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { col_order, min_date, default_rows } = data;
            if (col_order) {
              colOrder = col_order;
            }
            if (min_date) {
              minDate = min_date;
            }
            defaultRows = default_rows;
          }
          console.log("data", data);
        }
        dispatch(
          updateUserSettingsData({
            col_order: colOrder,
            min_date: minDate,
            default_rows: defaultRows,
          })
        );

        let index = 3;
        if (colOrder && colOrder.length > 0) {
          const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
          index = colOrderUI.findIndex((col) => col === 4);
        }

        let startTime = startDate;
        let endTime = endDate;
        // const currentScreenFilters = filters[screenName];
        const currentScreenFilters = storedFilters[screenName];
        const dateFilter = currentScreenFilters?.find(
          (col) => col?.column === "date"
        );

        if (dateFilter) {
          const { from, to } = dateFilter;
          startTime = from;
          endTime = to;
        }

        const searchFilter = currentScreenFilters?.find(
          (col) => col?.filterName === "search"
        );

        dispatch(
          updateInitialData({
            length: defaultRows ? defaultRows : 10,
            query: "get_all_workorder_data",
            startDate: startTime,
            endDate: endTime,
            order: index,
            search: searchFilter ? searchFilter.value : "",
          })
        );

        //setFirstTimeLoad(false);
        setTimeout(() => {
          setFirstTimeLoad(false);
        }, initialLoadDelay);
      })();
    }
  }, [isLoggedIn, orgDate]);

  useEffect(() => {
    if (pathname.includes("workorders") && !firstTimeLoad && isLoggedIn) {
      setLoading(true);
      console.log("Effect without start change - workorders ");
      dispatch(updateStart(0));

      if (
        querystring === "get_all_workorder_data" &&
        length > 0 &&
        order[0]?.column >= 0
      ) {
        dispatch(fetchData({ ...data, ...columns }));
      }

      let interval = setInterval(() => {
        if (
          querystring === "get_all_workorder_data" &&
          length > 0 &&
          order[0]?.column >= 0
        ) {
          dispatch(fetchData({ ...data, ...columns }));
        }
      }, 300000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    filter_group_id,
    filter_vehicle_id,
    firstTimeLoad,
    columns,

    //start,
    //querystring,
    //columns,
    //reload,
  ]);

  //Fetch data on change of start
  useEffect(() => {
    if (
      pathname.includes("workorders") &&
      querystring === "get_all_workorder_data" &&
      !firstTimeLoad &&
      isLoggedIn
    ) {
      console.log("Effect with start change - workorders ");
      //setLoadingToTrue();
      dispatch(fetchData({ ...data, ...columns }));
    }
  }, [start]);

  //Relaod Effect
  useEffect(() => {
    if (
      pathname.includes("workorders") &&
      querystring === "get_all_workorder_data" &&
      !firstTimeLoad &&
      isLoggedIn &&
      reload === "work_order"
    ) {
      setReload("");
      dispatch(updateStart(0));
      dispatch(fetchData({ ...data, ...columns, start: 0 }));
    }
  }, [reload]);

  //If status is failed, then show failed snackbar
  useEffect(() => {
    if (status === "failed") {
      dispatch(addSnackbarData({ msg: t("listing_err_msg"), type: 2 }));
    }
  }, [status]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        //width: getColumnWidth("name", "Vehicle"),
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("type"),
        accessor: "type",
        Cell: ({ value }) => (value ? t("unscheduled") : t("scheduled")),
        //disableFilters: true,
        //width: getColumnWidth("distance", "Distance"),
        // disableGlobalFilter: false,
      },
      {
        id: 3,
        Header: `${t("issue")}/${t("reminder")}`, //("issue"), //Issue/Reminder(Confirm column name)
        accessor: "all_issues",
        accessorId: "task_name",
        Cell: ({ row }) => (
          <TaskPillComponent
            stringValue={
              row?.original?.reminder_name
                ? row?.original?.reminder_name
                : row?.original?.all_issues
            }
            //issuesListRef={issuesListRef}
          />
        ),
        disableSortBy: true,
        //Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 4,
        Header: t("created_date"), //Issue/Reminder(Confirm column name)
        accessor: "create_date",
        disableCol: true,
        Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
      },
      {
        id: 5,
        Header: t("assigned_to"),
        accessor: "assigned_to",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 6,
        Header: t("status"),
        accessor: "status",
        Cell: ({ value }) => <WorkOrderStatus status={value} />,
      },
      {
        id: 7,
        Header: t("duration"),
        accessor: "create_date",
        Cell: ({ row }) => (
          <DurationComponent rowData={row.original} pauses={pause_data} />
        ),
      },
      {
        id: 8,
        Header: t("odometer"),
        accessor: "odo",
        Cell: ({ row }) =>
          row?.original?.odo != -1000
            ? `${row?.original?.odo} ${getDistanceUnit(
                row?.original?.primary_meter === "Hours" ? "Hours" : distance
              )}`
            : NATEXT,
        //Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
      },
      {
        id: 9,
        Header: t("wo_number"), //t("odometer"),
        accessor: "wo_number",
        Cell: ({ value }) => value,
      },
      {
        id: 10,
        Header: t("work_order_priority"),
        accessor: "priority",
        Cell: ({ value }) => getIssuePriorityText(value),
      },
      {
        id: 11,
        Header: t("created_by"),
        accessor: "created_by_name",
        // Cell: ({ value }) =>
        //   value ? usersList.find((u) => u.user_id === value)?.label : NATEXT,
      },
      {
        id: 12,
        Header: t("due_on"),
        accessor: "due_date",
        Cell: ({ value }) => (value ? getDisplayDate(value) : NATEXT),
      },
      {
        id: 13,
        Header: t("po_number"), //t("odometer"),
        accessor: "po_number",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 14,
        Header: t("invoice_number"), //t("odometer"),
        accessor: "invoice_number",
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      {
        id: 15,
        Header: t("tc_tv"),
        accessor: "total_cost",
        // disableGlobalFilter: false,
        disableSortBy: false,
        //disableFilters: true,
        //width: columnWidth,
        Cell: ({ row }) => {
          const { discount, tax, total } = row?.original;
          if (+total && +total > 0) {
            const grandTotal = +total - +discount + +tax;
            return `${grandTotal?.toFixed(2)} ${currency}`;
          } else {
            return NATEXT;
          }
        },
      },
      // {
      //   id: 16,
      //   Header: t("services"),
      //   accessor: "service_id",
      //   Cell: ({ value }) => (value ? value : NATEXT),
      // },

      {
        id: 16,
        Header: t("notes_tv"),
        accessor: (row) => (row.comments ? row.comments : NATEXT),
        accessorId: "comments",
        //disableFilters: true,
        //width: columnWidth,
      },
    ],
    [distance, currency, pause_data]
  );

  const { defaultWOTypeData, defaultContainsData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      woType: {
        column: "woType",
        name: t("type"),
        ...defaultWOTypeData,
      },
      date: {
        column: "date",
        name: t("created_date"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      assignedTo: {
        column: "assignedTo",
        name: t("assigned_to"),
        ...defaultContainsData,
      },
      woNumber: {
        column: "woNumber",
        name: t("wo_number"),
        ...defaultContainsData,
      },
      dueOn: {
        column: "dueOn",
        name: t("due_on"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
        mode: "custom",
      },
      poNumber: {
        column: "poNumber",
        name: t("po_number"),
        ...defaultContainsData,
      },
      invoiceNumber: {
        column: "invoiceNumber",
        name: t("invoice_number"),
        ...defaultContainsData,
      },
      // totalCost: {
      //   column: "totalCost",
      //   name: t("tc_tv"),
      //   ...initialRangeData,
      // },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );

  const handleAddWO = () => {
    if (tag === "essential-user") {
      setShowSubscribePopup(true);
      return;
    }
    history.push("workorders/addnew");
  };

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/ISZ5SB6S"}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around p-0 m-0">
          {/* <Col>
            <ColumnHeading>{t("work_orders")}</ColumnHeading>
          </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("work_orders")}</ColumnHeading>
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>
          <Col className="d-flex justify-content-end mb-1">
            {roleId !== 4 && (
              // <Link to={"/workorders/addnew"}>
              <AddFormButton
                id="add-work-order-btn"
                onClick={handleAddWO}
                type="button"
              >
                {t("add_work_order")}
              </AddFormButton>
              // </Link>
            )}
          </Col>
        </Row>

        <div style={{ height: "100%" }}>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={workOrderKPIs}
            initialData={initialData}
            pauses={pause_data}
          />
        </div>
      </Container>
    </>
    // <div style={{ height: '90%', background: 'lightblue' }}>Hi</div>
  );
};

export default WorkOrdersTable;

export const WorkOrderStatus = ({ status }) => {
  const { t } = useTranslation("common");
  let issueData = { text: "", bg: "" };

  if (status === "new") {
    issueData.text = t("new");
    issueData.bg = "#9FC5F8";
  } else if (status == 0) {
    issueData.text = t("drawer_open");
    issueData.bg = "#9FC5F8";
  } else if (status == 1) {
    issueData.text = t("in_progress");
    issueData.bg = "#FFE599";
  } else if (status == 2) {
    issueData.text = t("paused");
    issueData.bg = "#FFE599";
  } else if (status == 3) {
    issueData.text = t("closed");
    issueData.bg = colorStatusActive;
  }

  return (
    <StatusContainer background={issueData.bg}>
      <p>{issueData.text}</p>
    </StatusContainer>
  );
};

export const DurationComponent = ({ rowData, pauses }) => {
  const [timeSinceAcc, setTimeSinceAcc] = useState("");
  //console.log(rowData);

  const usersList = useSelector((state) => state.users.list);

  const {
    status,
    technician_id,
    start_date,
    end_date,
    starting_time,
    ending_time,
    working_days,
    wo_id,
    total_time,
  } = rowData;

  useEffect(() => {
    let interval = null;
    if ((status === 1 || status === 2) && start_date) {
      let pausesForRecord = pauses?.filter((p) => p.wo_id === wo_id);
      const incompletePause = pausesForRecord?.find((p) => !p.end_time);
      let completedPauses = [];
      if (pausesForRecord?.length > 0) {
        completedPauses = pausesForRecord?.filter((p) => p.end_time);
      }

      let technician = "";
      if (technician_id) {
        technician = usersList?.find((u) => u.user_id === technician_id);
      }

      let startTime = new Date(start_date * 1000);

      let endTime = new Date();
      if (incompletePause) {
        endTime = new Date(incompletePause.start_time * 1000);
      }

      let schedule = working_days ? working_days : "1:2:3:4:5:6:7";
      let scheduleStartTime = starting_time
        ? starting_time.slice(0, 5)
        : "00:00";
      let scheduleEndTime = ending_time ? ending_time.slice(0, 5) : "23:59";
      // if (technician) {
      //   //const { working_days, starting_time, ending_time } = technician
      //   schedule = technician.working_days;
      //   scheduleStartTime = technician.starting_time.slice(0, 5);
      //   scheduleEndTime = technician.ending_time.slice(0, 5);
      // }

      let timeSinceAcceptance = getTimeSinceAcceptance(
        startTime,
        endTime,
        scheduleStartTime,
        scheduleEndTime,
        schedule,
        completedPauses
      );

      //console.log(timeSinceAcceptance);
      setTimeSinceAcc(timeSinceAcceptance.display);
      if (status === 1) {
        interval = setInterval(() => {
          timeSinceAcceptance = getTimeSinceAcceptance(
            startTime,
            new Date(),
            scheduleStartTime,
            scheduleEndTime,
            schedule,
            completedPauses
          );
          setTimeSinceAcc(timeSinceAcceptance.display);
        }, 60000);
      }
    } else {
      setTimeSinceAcc("");
    }

    return () => {
      clearInterval(interval);
    };
  }, [pauses, rowData, usersList]);

  // useEffect(() => {
  //   console.log("Time Since Acc", timeSinceAcc);
  // }, [timeSinceAcc]);

  return (
    <div>
      {status === 3
        ? dhm(total_time * 1000)
        : status === 0
        ? NATEXT
        : timeSinceAcc}
    </div>
  );
};
