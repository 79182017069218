import React from "react";
import {
  ActionIconContainer,
  ColumnHeaderRow,
  DataRow,
  FilterButton,
  TableContainer,
} from "../../../shared/components/TableElements";
import { Col, Container, Row } from "react-bootstrap";
import FillupsFormPopup from "./FillupsFormPopup";
import { useState } from "react";
import ExpensesFormPopup from "./ExpensesFormPopup";
import IncomeFormPopup from "./IncomeFormPopup";
import { useFormContext } from "react-hook-form";
import ActionPopup from "../../../shared/components/ActionPopup";
import { postApi } from "../../../api/getUser";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SecondaryDataTable = ({
  heading,
  rows,
  setRows,
  columns,
  onAdd,
  screenName,
  newRecordData,
  setNewRecordData,
  vehicle,
  driver,
  userOptions,
}) => {
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { distance, volume, consumption } = useSelector((state) => state.units);

  const startingOdo = watch("startingOdo");
  const depDate = watch("depDate");

  const [showFillupsFormPopup, setShowFillupsFormPopup] = useState(false);
  const [showExpensesFormPopup, setShowExpensesFormPopup] = useState(false);
  const [showIncomeFormPopup, setShowIncomeFormPopup] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  //Yes button loading state for delete popup
  const [yesBtnLoading, setYesBtnLoading] = useState(false);

  const rowIdToDelete = useRef(null);

  //console.log("newRecordData", newRecordData);
  const rowDisplayData = rows.map((row) => row.displayData);
  //console.log(rows, rowDisplayData);

  const handleAdd = () => {
    if (screenName === "fillups") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "fillups",
        vehicleId: vehicle?.vehicleId,
        userId: driver?.user_id,
        type: "add",
        newRecordId: "",
      }));
      //setNewRecordData({ screenName: "fillups" });
      setShowFillupsFormPopup(true);
    } else if (screenName === "expenses") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "expenses",
        vehicleId: vehicle?.vehicleId,
        userId: driver?.user_id,
        type: "add",
        newRecordId: "",
      }));
      //setNewRecordData({ screenName: "fillups" });
      setShowExpensesFormPopup(true);
    } else if (screenName === "income") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "income",
        vehicleId: vehicle?.vehicleId,
        userId: driver?.user_id,
        type: "add",
        newRecordId: "",
      }));
      //setNewRecordData({ screenName: "fillups" });
      setShowIncomeFormPopup(true);
    }
  };

  const handleEdit = (rowId) => {
    const currentRow = rows.find((row) => row.rowId === rowId);
    const { rowData } = currentRow;

    if (screenName === "fillups") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "fillups",
        type: "edit",
        vehicleId: rowData?.veh_id,
        userId: rowData?.filled_by,
        newRecordId: "",
        rowData,
      }));
      setShowFillupsFormPopup(true);
    }

    if (screenName === "expenses") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "expenses",
        type: "edit",
        vehicleId: rowData?.veh_id,
        userId: rowData?.expense_by,
        newRecordId: "",
        expenseTaskIds: "",
        rowData,
      }));
      setShowExpensesFormPopup(true);
    }

    if (screenName === "income") {
      setNewRecordData((prev) => ({
        ...prev,
        screenName: "income",
        type: "edit",
        vehicleId: rowData?.veh_id,
        userId: rowData?.user_id,
        newRecordId: "",
        rowData,
      }));
      setShowIncomeFormPopup(true);
    }
  };

  const handleDelete = (rowId) => {
    rowIdToDelete.current = rowId;
    setShowDeletePopup(true);
  };

  const deleteRecord = async (rowId) => {
    setYesBtnLoading(true);
    let apiData = "";
    let apiMethod = "";
    const rowData = rows.find(
      (row) => row.rowId === rowIdToDelete.current
    )?.rowData;

    if (screenName === "fillups") {
      apiData = {
        delete_ids: rowId,
        querystring: "multiple_delete",
        sub_query_string: "FILLUPS",
      };
      apiMethod = "fillups";
    }

    if (screenName === "expenses") {
      // apiData = {
      //   expense_id: rowId,
      //   querystring: "delete_expense",
      // };
      apiData = {
        delete_ids: rowId,
        querystring: "multiple_delete",
        sub_query_string: "EXPENSES",
      };
      apiMethod = "commonNew";
    }

    if (screenName === "income") {
      // apiData = {
      //   InvoiceNumber: rowData.invoice_number,
      //   billedTo: rowData.billed_to,
      //   incomeId: rowData.income_id,
      //   insertIncomeAmount: rowData.amount,
      //   insertIncomeDate: rowData.income_date,
      //   insertIncomeDesc: rowData.income_desc,
      //   insertIncomeVehicle: rowData.veh_id,
      //   PaidUnpaid: rowData.paid,
      //   querystring: "insert_income",
      //   action: 3,
      //   //sync_version: "v2",
      //   source: "web",
      // };
      apiData = {
        delete_ids: rowId,
        querystring: "multiple_delete",
        sub_query_string: "INCOME",
      };
      apiMethod = "commonNew";
    }

    let res = null;
    res = await postApi(apiData, "deleteData");
    if (res?.success) {
      setRows((prev) => prev.filter((row) => row.rowId !== rowId));
      rowIdToDelete.current = null;
      setYesBtnLoading(false);
      setShowDeletePopup(false);
    }
  };

  const handleCancelDelete = () => {
    rowIdToDelete.current = null;
    setShowDeletePopup(false);
  };

  return (
    <>
      {showFillupsFormPopup && (
        <FillupsFormPopup
          showPopup={showFillupsFormPopup}
          setShowPopup={setShowFillupsFormPopup}
          newRecordData={newRecordData}
          setNewRecordData={setNewRecordData}
          startingOdo={startingOdo}
          depDate={depDate}
          userOptions={userOptions}
        />
      )}
      {showExpensesFormPopup && (
        <ExpensesFormPopup
          showPopup={showExpensesFormPopup}
          setShowPopup={setShowExpensesFormPopup}
          newRecordData={newRecordData}
          setNewRecordData={setNewRecordData}
          startingOdo={startingOdo}
          depDate={depDate}
          userOptions={userOptions}
        />
      )}
      {showIncomeFormPopup && (
        <IncomeFormPopup
          showPopup={showIncomeFormPopup}
          setShowPopup={setShowIncomeFormPopup}
          newRecordData={newRecordData}
          setNewRecordData={setNewRecordData}
          startingOdo={startingOdo}
          depDate={depDate}
          userOptions={userOptions}
        />
      )}
      {showDeletePopup && (
        <ActionPopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          message={t("delete_record", { count: 1 })}
          handleYes={async () => deleteRecord(rowIdToDelete.current)}
          handleNo={handleCancelDelete}
          yesBtnLoading={yesBtnLoading}
        />
      )}
      <Container className="p-4">
        <Row className="pb-2">
          <Col>
            <TableHeader heading={heading} onAdd={handleAdd} />
          </Col>
        </Row>

        {rowDisplayData.length > 0 && (
          <TableContainer>
            <ColumnHeaderRow>
              {columns?.map((col, id) => {
                return !col.header ? (
                  <Col key={id} md={1}>
                    <span>{col.header}</span>
                  </Col>
                ) : (
                  <Col key={id}>
                    <span>{col.header}</span>
                  </Col>
                );
              })}
            </ColumnHeaderRow>

            {rowDisplayData.map((row) => (
              <DataRow key={row.rowId}>
                {Object.keys(row).map((key) => {
                  return (
                    key !== "rowId" && (
                      <Col key={key}>
                        <span>{row[key]}</span>
                      </Col>
                    )
                  );
                })}
                <Col md={1}>
                  <span className="d-flex gap-2">
                    <ActionIconContainer onClick={() => handleEdit(row.rowId)}>
                      <i className="fi fi-rr-edit" />
                    </ActionIconContainer>

                    <ActionIconContainer
                      onClick={() => handleDelete(row.rowId)}
                    >
                      <i className="fi fi-rr-trash" />
                    </ActionIconContainer>
                  </span>
                </Col>
                {/* <Col md={1}>
                  <span>
                    <ActionIconContainer onClick={() => handleDelete(row.rowId)}>
                      <i className="fi fi-rr-trash" />
                    </ActionIconContainer>
                  </span>
                </Col> */}
              </DataRow>
            ))}
          </TableContainer>
        )}
      </Container>
    </>
  );
};

export default SecondaryDataTable;

const TableHeader = ({ heading, onAdd }) => {
  const { t } = useTranslation("common");

  return (
    <div className="d-flex justify-content-between align-items-center">
      <p>{heading}</p>
      <FilterButton type="button" id="add-row-btn" onClick={onAdd}>
        {t("add")}
      </FilterButton>
    </div>
  );
};
