import { lighten } from "polished";
import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { putApi, url } from "../../../api/getUser";
import RowOptions from "../../../shared/components/table/components/RowOptions";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import { colorLightBlue } from "../../../utils/palette";
import DataReactTable from "../../DataTable/components/DataReactTable";
import { useSelector } from "react-redux";
import { useAuth } from "../../../contexts/AuthContext";
import { updateNoOfRecords } from "../../../features/columns/columnsSlice";
import { useDispatch } from "react-redux";
import { sortItems } from "../../../shared/helpers";
import { fetchInspectionTasks } from "../../../features/inspectionTasks/inspectionTasksSlice";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const InspectionTasks = () => {
  const { t } = useTranslation("common");
  const rowData = useSelector((state) => state.inspectionTasks.list);

  const location = useLocation();
  const fromCancel = location.state?.fromCancel;
  const {
    reload,
    setReload,
    setLoading,
    isLoggedIn,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && (!isClientApiCalled || !fromCancel)) {
      //Effect to set initial default rows
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "inspectiontasks",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          const { default_rows } = data;
          dispatch(updateNoOfRecords({ defaultRows: default_rows }));

          // const apiData = {
          //   querystring: "get_all_inspection_tasks",
          // };
          // dispatch(fetchInspectionTasks(apiData));

          setLoading(false);
          // const response = await postApi(
          //   { querystring: "get_all_inspection_tasks" },
          //   "commonNew"
          // );
          // const rowData = response?.user_data;
          // dispatch(addRowData(rowData));
          // setRowData(rowData);
        }
      })();

      // (async () => {
      //   const response = await postApi(
      //     { querystring: "get_all_inspection_tasks" },
      //     "commonNew"
      //   );
      //   const data = response?.user_data;
      //   console.log(data);
      //   setRowData(data);
      // })();
    }
  }, []);

  //Reload Effect
  useEffect(() => {
    (async () => {
      if (reload === "inspectiontasks") {
        setReload("");
        // const response = await postApi(
        //   { querystring: "get_all_inspection_tasks" },
        //   "commonNew"
        // );

        // const data = response?.user_data;
        // dispatch(addRowData(data));
        // setRowData(data);
        const apiData = {
          //email: "testgroup@12.in",
          querystring: "get_all_inspection_tasks",
        };
        dispatch(fetchInspectionTasks(apiData));
      }
    })();
  }, [reload]);

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("inspection_task"),
        accessor: "task_name",
        accessorId: "task_name",
        isCenterAligned: false,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, "task_name");
        },
        // isBorderReqd: true
        //disableGlobalFilter: true,
        //columns: [{ id: 1, accessor: "task_name" }],
      },
      {
        id: 2,
        Header: t("task_attr"),
        isParent: true,
        isBorderReqd: true,
        isCenterAligned: true,
        //accessor: "task_name",
        //disableGlobalFilter: true,
        columns: [
          {
            id: 3,
            Header: t("inspection_passfail"),
            accessor: "task_name",
            disableSortBy: true,
            isBorderReqd: true,
            isCenterAligned: true,
            //disableGlobalFilter: true,
            Cell: ({ row }) => (
              <AttrComponent isRequired={row.original.pass_required} />
            ),
          },
          {
            id: 4,
            Header: t("info"),
            accessor: "task_name",
            disableSortBy: true,
            isCenterAligned: true,
            Cell: ({ row }) => (
              <AttrComponent isRequired={row.original.value_required} />
            ),
            //disableGlobalFilter: true,
          },
          {
            id: 5,
            Header: t("photo"),
            accessor: "task_name",
            disableSortBy: true,
            isCenterAligned: true,
            Cell: ({ row }) => (
              <AttrComponent isRequired={row.original.image_required} />
            ),
            //disableGlobalFilter: true,
          },
        ],
      },
      {
        id: 6,
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row?.original} />,
        disableSortBy: true,
      },
      // {
      //   id: 2,
      //   Header: "Pass/Fail",
      //   accessor: "task_name",
      //   disableSortBy: true,
      //   //disableGlobalFilter: true,
      //   Cell: ({ row }) => (
      //     <AttrComponent isRequired={row.original.pass_required} />
      //   ),
      // },
      // {
      //   id: 3,
      //   Header: "Info",
      //   accessor: "task_name",
      //   disableSortBy: true,
      //   Cell: ({ row }) => (
      //     <AttrComponent isRequired={row.original.value_required} />
      //   ),
      //   //disableGlobalFilter: true,
      // },
      // {
      //   id: 4,
      //   Header: "Photo",
      //   accessor: "task_name",
      //   disableSortBy: true,
      //   Cell: ({ row }) => (
      //     <AttrComponent isRequired={row.original.image_required} />
      //   ),
      //   //disableGlobalFilter: true,
      // },
      // {
      //   id: 2,
      //   Header: "Task Attributes",
      //   accessor: "task_attributes",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   Cell: ({ row }) => (
      //     <AttributesComponent
      //       passRequired={row.original.pass_required}
      //       valueRequired={row.original.value_required}
      //       imageRequired={row.original.image_required}
      //     />
      //   ),
      // },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };
  return (
    <Container>
      <Row className="mx-1 d-flex align-items-center justify-content-around">
        <Col
          sm={10}
          //className="d-flex justify-content-start align-items-center"
        >
          <ColumnHeading>{t("inspection_tasks")}</ColumnHeading>
        </Col>
        <Col
          sm={2}
          className="d-flex justify-content-end align-items-center mb-1"
        >
          <Link to={"/inspection/inspectiontasks/addnew"}>
            <AddFormButton id="add-inspection-task-btn">
              {t("add_inspection_task")}
            </AddFormButton>
          </Link>
        </Col>
      </Row>
      <Row>
        <DataReactTable
          reactTableData={reactTableData}
          //valuesArray={servicesValuesArray}
          //initialData={initialData}
          //filterToggleColumns={filterToggleColumns}
          //defaultColumns={defaultColumns}
          //columnSizeData={columnSizeData}
        />
      </Row>
    </Container>
  );
};

export default InspectionTasks;

export const AttributesComponent = ({
  passRequired,
  valueRequired,
  imageRequired,
}) => {
  //const { pass_required, value_required, image_required } = row.original;
  const styles = {
    //border: '1px solid lightgray',
    borderRadius: "5px",
    padding: "1px 3px 1px 3px",
    letterSpacing: 0.5,
    //fontSize: 14,
    //fontWeight: 600,
    //color: 'darkgreen',
    //background: 'lightgreen'
  };
  return (
    <div className="d-flex gap-2">
      {passRequired ? (
        <div
          style={{
            ...styles,
            color: "darkblue",
            background: lighten(0.22, colorLightBlue),
          }}
        >
          Pass/Fail
        </div>
      ) : (
        ""
      )}
      {valueRequired ? (
        <div
          style={{
            ...styles,
            color: lighten(0.1, "green"),
            background: lighten(0.22, "lightgreen"),
          }}
        >
          Info
        </div>
      ) : (
        ""
      )}
      {imageRequired ? (
        <div
          style={{
            ...styles,
            color: "#a74b22",
            background: "#FFEECC",
          }}
        >
          Photo
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const AttrComponent = ({ isRequired }) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginRight: "12px" }}
    >
      {isRequired ? (
        <i
          className="fi fi-br-check"
          style={{ color: "#888888", position: "relative", top: 4 }}
        ></i>
      ) : (
        <p style={{ color: "#c5c5c5" }}>-</p>
      )}
    </div>
  );
};
