import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
} from "../../../shared/components/form/FormElements";
import { Card, CardBody } from "../../../shared/components/Card";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import { PhotoUploadComponent } from "../../UserManagement/UserDetailsForm";
import { NATEXT, s3Url } from "../../../shared/constants";
import ErrorPopup from "../../../shared/components/ErrorPopup";
import { postApi } from "../../../api/getUser";
import { useAuth } from "../../../contexts/AuthContext";
import getUnixTime from "date-fns/getUnixTime";
import defaultPartIcon from "@/shared/img/default/default_parts.png";
import { NumberInputWithStep } from "../components/AddPartsPopup";

const EditQuantityPopup = ({
  showPopup,
  setShowPopup,
  rowData,
  setSnackbarMsg,
}) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);
  const { setReload } = useAuth();
  const { userId, orgId } = useSelector((state) => state.user);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const reasonOptions = [
    { label: t("consumed"), value: 1 },
    { label: t("damaged"), value: 2 },
    { label: t("stolen"), value: 3 },
    { label: t("returned"), value: 4 },
    { label: t("sold"), value: 5 },
    { label: t("stock_recon"), value: 6 },
  ];

  const quantityChange = watch("quantityChange");
  const reason = watch("reason");
  const notes = watch("notes");

  const [partPhoto, setPartPhoto] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [newQuantity, setNewQuantity] = useState("");

  const [showErrPopup, setShowErrPopup] = useState(false);

  //Effect on change of rowData(edit)
  useEffect(() => {
    if (rowData && orgId) {
      const { part_name, part_notes, part_img, part_id, available_quantity } =
        rowData;
      setValue("partName", part_name);
      setValue("partNotes", part_notes);

      //Logic to set Part Image
      if (part_img) {
        const imageUrl = `${s3Url}/org_${orgId}/parts/${part_id}/${part_img}`;

        setPartPhoto({ url: imageUrl, imgName: part_img });
      } else {
        setPartPhoto({ url: defaultPartIcon });
      }

      setAvailableQuantity(+available_quantity || "");
      setNewQuantity(+available_quantity || "");
    }
  }, [rowData, orgId]);

  //Effect to change new qty on change of qty change
  useEffect(() => {
    setNewQuantity(+availableQuantity + +quantityChange);
  }, [quantityChange]);

  const handleClose = () => {
    setShowPopup(false);
  };

  const onSubmit = async () => {
    if (+newQuantity < 0) {
      setError(
        "quantityChange",
        {
          type: "custom",
          message: t("new_qty_less_than_zero_err"),
        },
        { shouldFocus: true }
      );
      return;
    }

    setSaveBtnLoading(true);

    const {
      part_id,
      parts_batch_id,
      vendor,
      store_room,
      part_name,
      part_notes,
      location,
      part_img,
      available_quantity,
      purchase_quantity,
      purchase_total_cost,
      purchase_date,
      tax,
      discount,
      custom_fields,
      images,
      rack_number,
      //notes,
      purchase_cost_per_unit,
    } = rowData;

    //Batches API
    const batchApiJson = {
      querystring: "add_edit_parts_batches",
      parts_batch_id: parts_batch_id,
      part_id: part_id,
      location: location,
      purchase_date: purchase_date,
      vendor: vendor,
      store_room: store_room,
      rack_number: rack_number,
      available_quantity: +newQuantity || 0,
      purchase_quantity: purchase_quantity,
      purchase_cost_per_unit: purchase_cost_per_unit,
      purchase_total_cost: purchase_total_cost,
      discount: discount,
      tax: tax,
      custom_fields: custom_fields,
      images: images,
      notes: rowData?.notes,
      action: 2,
    };
    const batchApiRes = await postApi(batchApiJson, "inventory");

    if (!batchApiRes.success) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }

    if (batchApiRes.success) {
      const transactionId = `${userId}_${Date.now()}`;
      const batchTransactionsApiJson = {
        transaction_id: transactionId,
        parts_batch_id: parts_batch_id,
        transaction_date: getUnixTime(new Date()),
        querystring: "add_edit_batch_transactions",
        old_qty: +availableQuantity || 0,
        new_qty: +newQuantity || 0,
        reason: reason.value,
        notes: notes || "",
        action: 1,
      };
      const batchTransactionsApiRes = await postApi(
        batchTransactionsApiJson,
        "inventory"
      );
      if (!batchTransactionsApiRes?.success) {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }

      setReload("parts");
      setSaveBtnLoading(false);
      setShowPopup(false);
      setSnackbarMsg(t("record_edited_msg", { recordName: t("batch") }));
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <Modal show={showPopup} size="md">
        <FormContainer
          className="m-0 p-0 d-block"
          onSubmit={handleSubmit(onSubmit)}
          $showNumberStep={true}
        >
          <Modal.Header>
            <h4>{t("update_qty")}</h4>
            <div
              className="d-flex justify-content-end"
              //style={{ width: "-webkit-fill-available" }}
            >
              <ActionIconContainer onClick={handleClose}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container className="m-0 p-0">
              <Card className="m-0 p-0">
                <CardBody
                  //className="m-0 p-0"
                  style={{
                    height: `${window.innerHeight - 220}px`,
                    overflow: "auto",
                  }}
                >
                  <Row className="d-flex align-items-center">
                    <Col>
                      <PhotoUploadComponent
                        photo={partPhoto}
                        setPhoto={setPartPhoto}
                        isDisabled={true}
                        module={"parts"}
                        //rowData={rowData}
                      />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel className="m-0 p-0">
                            {t("part_name")}
                          </FormGroupLabel>
                          <FormValueContainer>
                            {rowData?.part_name}
                          </FormValueContainer>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel>{t("upc_barcode")}</FormGroupLabel>
                          <FormValueContainer>
                            {rowData?.barcode || NATEXT}
                          </FormValueContainer>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroupLabel>{t("notes")}</FormGroupLabel>
                          <FormValueContainer>
                            {rowData?.part_notes ? rowData?.part_notes : NATEXT}
                          </FormValueContainer>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroupLabel>{t("available_qty")}</FormGroupLabel>
                      <FormValueContainer>
                        {availableQuantity}
                      </FormValueContainer>
                    </Col>
                    <Col>
                      <FormGroupLabel>{t("qty_change")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="quantityChange"
                          control={control}
                          component={"input"}
                          type="number"
                          step={1}
                          defaultValue=""
                          placeholder={t("qty_change")}
                          style={{
                            ...formInputStyles,
                            paddingRight: 0,
                            paddingLeft: "0.3rem",
                          }}
                          disabled={false}
                          rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {/* <Controller
                        name="quantityChange"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <FormItem id="quantity-change-input">
                            <NumberInputWithStep
                              type="number"
                              step={1}
                              value={value}
                              onChange={onChange}
                              style={formInputStyles}
                            />
                          </FormItem>
                        )}
                        rules={{ required: t("required_err") }}
                      /> */}

                      {errors?.quantityChange && (
                        <Error error={errors.quantityChange.message} />
                      )}
                    </Col>
                    <Col>
                      <FormGroupLabel>{t("new_qty")}</FormGroupLabel>
                      <FormValueContainer>{newQuantity}</FormValueContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroupLabel>
                        {t("reason_for_qty_change")}
                      </FormGroupLabel>
                      <Controller
                        name="reason"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="warehouse-location-select"
                            options={reasonOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            placeholder={t("reason_for_qty_change")}
                            styles={formStyles}
                            //isDisabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.reason && (
                        <Error error={errors.reason.message} />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroupLabel>{t("notes")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="notes"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("notes")}
                          style={formInputStyles}
                          disabled={false}
                        />
                      </FormItem>

                      {errors?.notes && <Error error={errors.notes.message} />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <AddFormButton
              id="save-form-button"
              type="submit"
              //onClick={addServiceTask}
            >
              {saveBtnLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          </Modal.Footer>
        </FormContainer>
      </Modal>
    </>
  );
};

export default EditQuantityPopup;
