import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  colorBackground,
  colorHover,
  colorText,
  colorBorder,
} from "@/utils/palette";
import { marginLeft, right, left } from "@/utils/directions";
import { TopbarBack, TopbarDownIcon } from "./BasicTopbarComponents";
import TopbarMenuLink, { TopbarLink } from "./TopbarMenuLink";
import {
  colorBlue,
  colorGrayNew,
  sidenavLinkHoverColor,
} from "../../../../utils/palette";
import orgImgDefault from "../../../../shared/img/org.png";
import { s3Url } from "../../../../shared/constants";

const TopbarOrganization = () => {
  const { orgName, orgImg, orgId } = useSelector((state) => state.user);
  const [orgImgUrl, setOrgImgUrl] = useState(
    `${s3Url}/org_${orgId}/logo/${orgImg}`
  );

  useEffect(() => {
    if (orgImg && orgId) {
      setOrgImgUrl(`${s3Url}/org_${orgId}/logo/${orgImg}`);
    } else {
      setOrgImgUrl(orgImgDefault);
    }
    // if (orgImg && orgId) {
    //   const extension = orgImg.split(".").pop();
    //   if (extension) {
    //     setOrgImgUrl(
    //       `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/logo/${orgImg}`
    //     );
    //   } else {
    //     setOrgImgUrl(orgImgDefault);
    //   }
    // } else {
    //   setOrgImgUrl(orgImgDefault);
    // }

    // if (orgImg || orgImg === "logo.") {
    //   setOrgImgUrl(orgImgDefault);
    // } else {
    //   setOrgImgUrl(
    //     `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/logo/${orgImg}`
    //   );
    // }
  }, [orgImg, orgId]);

  return (
    <TopbarProfileWrap>
      <TopbarAvatarButton>
        <TopbarAvatarImage
          src={orgImgUrl}
          key={Date.now()}
          alt="avatar"
          className="img-circle"
        />
        <TopbarAvatarName>{orgName}</TopbarAvatarName>
      </TopbarAvatarButton>
    </TopbarProfileWrap>
  );
};

export default TopbarOrganization;

// region STYLES

export const TopbarProfileWrap = styled.div`
  position: relative;
  margin-bottom: 0;
  ${marginLeft}: 0;

  @media screen and (max-width: 576px) {
    margin: inherit;
  }

  @media screen and (max-width: 320px) {
    margin: auto 0;
  }
`;

const TopbarAvatarButton = styled.button`
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: ${sidenavLinkHoverColor};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`;

const TopbarAvatarImage = styled.img`
  margin: auto 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  max-width: 40px;
  object-fit: cover;
`;

export const TopbarAvatarName = styled.p`
  margin: auto 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 350;
  display: none;
  ${marginLeft}: 10px;
  color: ${colorGrayNew};

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const TopbarMenuWrap = styled.div`
  z-index: 101;
  position: absolute;
  width: 100%;
  min-width: 210px;
  ${right}: 0;

  @media screen and (max-width: 320px) {
    ${right}: -50px;
  }
`;

const TopbarMenu = styled.div`
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: ${colorBackground};

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }

    &${TopbarLink} {
      background-color: transparent;
      border: none;
      padding: 9px 20px;
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`;

const TopbarMenuDivider = styled.div`
  margin: 15px 0;
  border-top: 1px solid ${colorBorder};
`;

// endregion
