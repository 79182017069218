import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ZoomComponent from "./ZoomComponent";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import styled from "styled-components";
import { Carousel } from "primereact/carousel";
import { ActionIconContainer, FilterButton } from "./TableElements";
import { downloadImage } from "../helpers";

const ZoomModal = ({ showModal, setShowModal, doc, docs, modalHeading }) => {
  const docTemplate = (doc) => {
    const [rotateDeg, setRotateDeg] = useState(0);

    const getFileType = (ext) => {
      if (ext.includes("pdf")) {
        return "pdf";
      }
      if (
        ext.includes("image") ||
        ext.includes("img") ||
        ext.includes("png") ||
        ext.includes("jpeg") ||
        ext.includes("jpg") ||
        ext.includes("webp") ||
        ext.includes("jfif")
      ) {
        return "img";
      }

      if (ext.includes("csv")) {
        return "csv";
      }
    };

    let fileType = "";
    if (doc?.file) {
      fileType = getFileType(doc?.file?.type);
    } else if (doc?.extension) {
      if (doc.extension === "txt") {
        fileType = getFileType(doc?.type[0]);
      } else {
        fileType = getFileType(doc.extension);
      }
    } else {
      fileType = getFileType(doc?.url?.split(".").pop());
    }

    const [docType, setDocType] = useState("");

    const handleRotate = () => {
      setRotateDeg((prev) => (prev === 270 ? 0 : prev + 90));
    };

    const handleReset = (reset) => {
      setRotateDeg(0);
      reset();
    };

    const Controls = () => {
      const { zoomIn, zoomOut, resetTransform } = useControls();
      return (
        <div className="d-flex justify-content-end gap-1 mb-3 mx-3">
          <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={() => zoomIn()}
          >
            <i className="fi fi-rr-zoom-in m-0"></i>
          </FilterButton>
          <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={() => zoomOut()}
          >
            <i className="fi fi-rr-zoom-out m-0"></i>
          </FilterButton>
          <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={() => handleReset(resetTransform)}
          >
            <i className="fi fi-rr-refresh m-0"></i>
          </FilterButton>
          <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={handleRotate}
          >
            <i className="fi fi-rr-rotate-right m-0"></i>
          </FilterButton>
          <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={() => downloadImage(doc?.url, doc?.extension)}
          >
            <i className="fi fi-rr-cloud-download-alt m-0"></i>
          </FilterButton>
        </div>
      );
    };

    return (
      // <Modal show={showPopup} onHide={() => setShowPopup(false)} size="md">
      <>
        {fileType === "img" && (
          <div
          //style={{ width: 600, height: 420 }}
          >
            <TransformWrapper>
              <Controls />
              <TransformComponent>
                <Img
                  rotateDeg={rotateDeg}
                  src={doc.url}
                  alt="zoomed-img"
                  //width="100%"
                  width={600}
                  height={window.innerHeight - 250}
                  //className={className}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        )}

        {fileType === "pdf" && (
          <embed src={doc.url} width={600} height={window.innerHeight - 220} />
        )}
      </>
    );
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      size="lg"
      //className="d-flex align-items-center justify-content-center"
    >
      <Modal.Header>
        <h4>{modalHeading ? modalHeading : ""}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={() => setShowModal(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body
        //style={{ width: 500, height: 500 }}
        className="d-flex align-items-center justify-content-center"
      >
        {doc && <ZoomComponent doc={doc} />}
        {docs && (
          <Carousel
            value={docs}
            numScroll={1}
            numVisible={1}
            itemTemplate={docTemplate}
            style={{ height: window.innerHeight - 150 }}
          />
          // <Carousel>
          //   {docs.map((doc) => (
          //     <Carousel.Item>
          //       <ZoomComponent doc={doc} />
          //     </Carousel.Item>
          //   ))}
          // </Carousel>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ZoomModal;

const Img = styled.img`
  transform: rotate(${(p) => p.rotateDeg}deg);
  object-fit: contain;
`;
