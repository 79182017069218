import React, { useEffect, useRef } from "react";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  Label,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import FormHeader from "../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { post, postApi } from "../../api/getUser";
import { useDispatch } from "react-redux";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { fetchServicesTasks } from "../../features/servicesTasks/servicesTasksSlice";
import ErrorPopup from "../../shared/components/ErrorPopup";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const ServiceTasksForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.slice(1, vehiclesList.length);

  const { userId } = useSelector((state) => state.user);

  const type = location?.state?.type;
  const rowData = location?.state?.rowData;

  const serviceTaskOptions = useSelector((state) => state.servicesTasks.list);
  const allVehicles = useSelector((state) => state.vehicles.list);

  //Coming from Add Service
  const mode = location?.state?.mode;
  const fromType = location?.state?.fromType;
  const route = location?.state?.route;
  const redirect = location?.state?.redirect;
  const routeData = location?.state?.routeData;
  const serviceData = location?.state?.routeData?.serviceData;
  //console.log(mode, route, redirect);

  const [recurringTask, setRecurringTask] = useState(false);
  const [applyToAllVeh, setApplyToAllVeh] = useState(true);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const serviceTaskName = watch("serviceTaskName");
  const vehicle = watch("vehicle");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  //Effect for populating Edit Data
  useEffect(() => {
    if (type === "edit") {
      const { service_task_name, comments, recurring, veh_id } = rowData;

      setValue("serviceTaskName", service_task_name);
      setValue("comments", comments);
      setRecurringTask(recurring);
      setApplyToAllVeh(veh_id === "All");

      if (veh_id !== "All") {
        setValue(
          "vehicle",
          allVehicles.find((v) => v.veh_id === veh_id)
        );
      }
    }

    if (redirect) {
      setValue("serviceTaskName", routeData?.serviceTaskName);
    }
  }, []);

  const onSubmit = async () => {
    try {
      if (type !== "edit") {
        for (let serviceTask of serviceTaskOptions) {
          if (applyToAllVeh) {
            if (
              serviceTask.service_task_name.toLocaleLowerCase() ===
              serviceTaskName?.trim().toLocaleLowerCase()
            ) {
              setError(
                "serviceTaskName",
                {
                  type: "custom",
                  message: t("dup_service_task_name_error"),
                },
                { shouldFocus: true }
              );
              return;
            }
          } else {
            if (
              serviceTask.service_task_name.toLocaleLowerCase() ===
                serviceTaskName?.trim().toLocaleLowerCase() &&
              (serviceTask.veh_id === vehicle.vehicleId ||
                serviceTask.veh_id === "All")
            ) {
              setError(
                "serviceTaskName",
                {
                  type: "custom",
                  message: t("dup_service_task_name_error"),
                },
                { shouldFocus: true }
              );
              return;
            }
          }
        }
      }

      const serviceTaskId =
        type === "edit" ? rowData?.service_task_id : `${userId}_${Date.now()}`;

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("service_task") })
          : t("record_added_msg", { recordName: t("service_task") });

      // const apiData = {
      //   querystring:
      //     type === "edit" ? "update_servicetask" : "add_service_task",
      //   service_task_id: serviceTaskId,
      //   service_task_name: serviceTaskName,
      //   recurring: recurringTask ? 1 : 0,
      //   servicetask_veh_id: applyToAllVeh ? "All" : vehicle.vehicleId,
      //   comments: comments,
      //   source: "web",
      //   sync_version: "v2",
      //   action: type === "edit" ? 2 : 1,
      // };

      const apiData = {
        sync_version: "v2",
        querystring: "add_service_task_v2",
        source: "web",
        service_tasks: [
          {
            service_task_id: serviceTaskId,
            service_task_name: serviceTaskName,
            recurring: recurringTask ? 1 : 0,
            servicetask_veh_id: applyToAllVeh ? "All" : vehicle.vehicleId,
            comments: comments,
            source: "web",
            sync_version: "v2",
            action: type === "edit" ? 2 : 1,
          },
        ],
      };

      setSaveBtnLoading(true);
      const response = await post("commonNew", apiData);

      if (!response?.success) {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }

      if (response.success === 1) {
        setSaveBtnLoading(false);
        const apiData = {
          querystring: "getallservicetask",
          service_veh_id: "All",
        };
        dispatch(fetchServicesTasks(apiData));

        if (mode === "from-add-service") {
          history.push("/services/services/addnew", {
            mode: "from-add-service-task",
            routeData: { serviceTaskId, serviceData },
            fromType: fromType,
          });
        } else if (redirect) {
          history.push(route, {
            mode: "from-add-service-task",
            routeData: { serviceTaskId },
            fromType: fromType,
          });
        } else {
          dispatch(addMsg(snackbarMsg));
          history.push("/services/servicetasks", { fromCancel: false });
        }
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //title={t("add_service")}
          handleCancel={() =>
            history.push("/services/servicetasks", { fromCancel: true })
          }
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={
            type === "edit" ? t("edit_service_task") : t("add_custom_task")
          }
          //handleCancel={handleCancel}
        />
        <Container>
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
              }}
            >
              <Row>
                <Col md={6}>
                  <FormGroupLabel>{t("service_task_name")}</FormGroupLabel>
                  <FormItem disabled={false}>
                    <FormField
                      name="serviceTaskName"
                      control={control}
                      component="input"
                      defaultValue=""
                      placeholder={t("service_task_name")}
                      style={formInputStyles}
                      disabled={false}
                      rules={{ required: t("required_err") }}
                    />
                  </FormItem>

                  {errors?.serviceTaskName && (
                    <Error error={errors.serviceTaskName.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                  {/* <FormItem disabled={false}>
                    <FormField
                      name="comments"
                      control={control}
                      component="input"
                      defaultValue=""
                      placeholder={t("notes_tv")}
                      style={formInputStyles}
                      disabled={false}
                    />
                  </FormItem> */}

                  <Controller
                    name="comments"
                    control={control}
                    //defaultValue={users?.length > 0 ? users[0] : ""}
                    //errors={errors}
                    render={({ field: { onChange, value, ref } }) => (
                      <AutoResizeTextArea
                        ref={textAreaRef}
                        onChange={onChange}
                        value={value}
                        placeholder={t("notes_tv")}
                        rows={1}
                        //isDisabled={status == 1 || status == 2}
                      />
                    )}
                    //rules={{ required: t("required_err") }}
                  />

                  {errors?.comments && (
                    <Error error={errors.comments.message} />
                  )}
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md={12}>
                  <CheckBoxInput
                    type="checkbox"
                    id="recurringTask"
                    name="recurringTask"
                    value="recurringTask"
                    checked={recurringTask}
                    onChange={() => setRecurringTask((prev) => !prev)}
                    style={{ margin: "0 8px 0 0" }}
                  />
                  <Label htmlFor="recurringTask">{t("recurring_task")}</Label>
                </Col>
                <Desc style={{ paddingLeft: "2rem" }}>
                  {t("no_reminder_for_nonrecurring")}
                </Desc>
              </Row>
              <Row className="pt-3">
                <Col md={2} className="pt-2">
                  <CheckBoxInput
                    type="checkbox"
                    id="applyToAllVeh"
                    name="applyToAllVeh"
                    value="applyToAllVeh"
                    checked={applyToAllVeh}
                    onChange={() => setApplyToAllVeh((prev) => !prev)}
                    style={{ margin: "0 8px 0 0" }}
                  />
                  <Label htmlFor="applyToAllVeh">{t("apply_all_vehs")}</Label>
                </Col>
                {!applyToAllVeh && (
                  <Col md={4}>
                    {/* <FormGroupLabel className="m-0">{t("vehicle")}</FormGroupLabel> */}
                    <Controller
                      name="vehicle"
                      control={control}
                      //errors={errors}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={vehicles}
                          //value={vehicles.find((c) => c.value === value)}
                          value={value}
                          onChange={onChange}
                          //onCreateOption={handleCreate}
                          placeholder={t("vehicle_placeholder")}
                          styles={formStyles}
                          isDisabled={false}
                        />
                      )}
                      rules={{ required: t("required_err") }}
                    />

                    {errors?.vehicle && (
                      <Error error={errors.vehicle.message} />
                    )}
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </FormContainer>
    </>
  );
};

export default ServiceTasksForm;
