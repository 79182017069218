import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { postApi, putApi, url } from "../../../api/getUser";
import RowOptions from "../../../shared/components/table/components/RowOptions";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import DataReactTable from "../../DataTable/components/DataReactTable";
import { NATEXT } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/AuthContext";
import { updateNoOfRecords } from "../../../features/columns/columnsSlice";
import { useDispatch } from "react-redux";
import { sortItems } from "../../../shared/helpers";
import { useLocation } from "react-router";
import { addRowData } from "../../../features/row/rowSlice";
import { useSelector } from "react-redux";

const InspectionFormsTable = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const fromCancel = location.state?.fromCancel;
  const [rowData, setRowData] = useState([]);
  const rows = useSelector((state) => state.row.data);

  const {
    reload,
    setReload,
    setLoading,
    isLoggedIn,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && (!isClientApiCalled || !fromCancel)) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "inspectionforms",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          const { default_rows } = data;
          dispatch(updateNoOfRecords({ defaultRows: default_rows }));
          const response = await postApi(
            { querystring: "get_all_inspection_form" },
            "commonNew"
          );

          const rowData = response?.user_data;
          console.log(rowData);
          setRowData(rowData);
          dispatch(addRowData(rowData));

          setLoading(false);
        }
      })();
    }
  }, [isLoggedIn, isClientApiCalled]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        if (reload === "inspectionforms") {
          const response = await postApi(
            { querystring: "get_all_inspection_form" },
            "commonNew"
          );
          const data = response?.user_data;
          //console.log(data);
          setRowData(data);
          dispatch(addRowData(data));
          setReload("");
        }
      })();
    }
  }, [reload]);

  //const rows = [];
  // rowData.forEach((row) => {
  //   rows.push({
  //     form_id: row.form_id ? row.form_id : NATEXT,
  //     row_id: row.form_id ? row.form_id : NATEXT,
  //     form_name: row.form_name ? row.form_name : NATEXT,
  //     dt: row.dt,
  //     cnt: row.cnt ? row.cnt : NATEXT,
  //     description: row.description ? row.description : NATEXT,
  //   });
  // });

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("inspection_form_label"),
        accessor: "form_name",
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, "form_name");
        },
        accessorId: "task_name",
        //disableGlobalFilter: true,
      },
      {
        id: 2,
        Header: t("short_desc"),
        accessor: "description",
        accessorId: "task_name",
        Cell: ({ value }) => (value ? value : NATEXT),
        //disableGlobalFilter: true,
      },
      // {
      //   id: 3,
      //   Header: t('total_submissions'),
      //   accessor: "cnt",
      //   disableGlobalFilter: true,
      //   //disableSortBy: true,
      // },
      // {
      //   id: 4,
      //   Header: t('last_submission'),
      //   accessor: "dt",
      //   disableGlobalFilter: true,
      //   //disableSortBy: true,
      //   Cell: ({ value }) =>
      //     value ? new Date(value * 1000).toLocaleDateString("en-GB") : NATEXT,
      // },
      {
        id: 3,
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row.original} />,
        disableSortBy: true,
      },
    ],
    []
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rows,
  };
  return (
    <Container>
      <Row className="mx-1 d-flex align-items-center justify-content-around">
        <Col
          sm={10}
          //className="d-flex justify-content-start align-items-center"
        >
          <ColumnHeading>{t("inspection_forms")}</ColumnHeading>
        </Col>
        <Col
          sm={2}
          className="d-flex justify-content-end align-items-center mb-1"
        >
          <Link to={"/inspection/inspectionforms/addnew"}>
            <AddFormButton id="add-inspection-form-btn">{t("add_inspection_form")}</AddFormButton>
          </Link>
        </Col>
      </Row>
      <Row>
        <DataReactTable
          reactTableData={reactTableData}
          //valuesArray={servicesValuesArray}
          //initialData={initialData}
          //filterToggleColumns={filterToggleColumns}
          //defaultColumns={defaultColumns}
          //columnSizeData={columnSizeData}
        />
      </Row>
    </Container>
  );
};

export default InspectionFormsTable;
