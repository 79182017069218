import { Modal } from "react-bootstrap";
import {
  AddFormButton,
  CustomLoader,
  FilterButton,
  ActionIconContainer,
} from "../../TableElements";
import { postApi } from "../../../../api/getUser";
import { useDispatch } from "react-redux";
import { updateDeletedData } from "../../../../features/filter/filterSlice";
import { useState } from "react";
import { addMsg } from "../../../../features/snackbar/snackbarSlice";
import { getScreenName } from "../../../helpers";
import { colorLightBlueStatus } from "../../../../utils/palette";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";

export const DeletePopup = ({
  showPopup,
  setShowPopup,
  deleteApiData,
  screen,
  setShowInfoPopup,
  setInfoData,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  console.log("deleteApiData", deleteApiData);

  const { setReload } = useAuth();

  let deleteConfirmationMessage = t("delete_record", { count: 1 });

  if (screen.includes("vehicles")) {
    deleteConfirmationMessage = t("delete_veh_msg");
  } else if (screen?.includes("groups")) {
    deleteConfirmationMessage = t("delete_group_msg");
  } else if (screen?.includes("users")) {
    deleteConfirmationMessage = t("delete_record", { count: 1 });
  } else if (screen?.includes("servicetasks")) {
    deleteConfirmationMessage = t("delete_service_task_err_msg", {
      count: deleteApiData?.delete_ids?.split(":::")?.length,
    });
  } else if (screen?.includes("expensetasks")) {
    deleteConfirmationMessage = t("delete_expense_task_err_msg", {
      count: deleteApiData?.delete_ids?.split(":::")?.length,
    });
  } else if (screen?.includes("inspectionforms")) {
    deleteConfirmationMessage = t("delete_insp_form_err_msg", {
      count: deleteApiData?.delete_ids?.split(":::")?.length,
    });
  } else if (screen?.includes("services") || screen?.includes("expenses")) {
    deleteConfirmationMessage = t(
      screen?.includes("services")
        ? t("delete_service_err_msg")
        : t("delete_expense_err_msg"),
      {
        count: deleteApiData?.delete_ids?.split(":::")?.length,
      }
    );
  } else if (screen?.includes("parts")) {
    deleteConfirmationMessage = t("delete_part_err", {
      count: deleteApiData?.delete_ids?.split(":::")?.length,
    });
  } else if (screen?.includes("batches")) {
    deleteConfirmationMessage = t("delete_batch_err");
  } else {
    deleteConfirmationMessage = t("delete_record", {
      count: deleteApiData?.delete_ids?.split(":::")?.length,
    });
  }

  const handleDelete = async () => {
    setDeleteBtnLoading(true);
    const response = await postApi(deleteApiData, "deleteData");
    if (screen?.includes("users")) {
      if (response.common_result === "user delted sucessful") {
        setShowPopup(false);
        //toggleReload();
        setReload("users");
        dispatch(addMsg(t("rec_del_suc")));
      } else if (response.common_result === "under_manager") {
        setInfoData({
          icon: { className: "fi fi-rr-triangle-warning", color: "red" },
          msg: t("user_del_under_manager_err_msg"),
        });
        setShowPopup(false);
        setShowInfoPopup(true);
      } else if (response.common_result === "number_of_admin") {
        setInfoData({
          icon: { className: "fi fi-rr-triangle-warning", color: "red" },
          msg: t("user_del_number_of_admin_err_msg"),
        });
        setShowPopup(false);
        setShowInfoPopup(true);
      } else if (response.common_result === "selfdelete") {
        setInfoData({
          icon: { className: "fi fi-rr-triangle-warning", color: "red" },
          msg: t("user_del_self_delete_err_msg"),
        });
        setShowPopup(false);
        setShowInfoPopup(true);
      } else if (response.common_result === "email not found") {
        setInfoData({
          icon: { className: "fi fi-rr-triangle-warning", color: "red" },
          msg: t("email_not_found"),
        });
        setShowPopup(false);
        setShowInfoPopup(true);
      }
    } else if (screen?.includes("vehicles")) {
      if (response.success) {
        setShowPopup(false);
        setReload("vehicles");
        dispatch(addMsg(t("rec_del_suc")));
      }
    } else if (screen?.includes("groups")) {
      if (response.success) {
        setShowPopup(false);
        setReload("groups");
        dispatch(addMsg(t("rec_del_suc")));
      }
    } else {
      if (response.success === 1) {
        if (screen.includes("inspectiontasks")) {
          const { delete_result } = response;

          //Multiple Tasks Delete
          if (deleteApiData?.delete_ids.includes(":::")) {
            if (delete_result?.length > 0) {
              setInfoData({
                icon: { className: "fi fi-rr-triangle-warning", color: "red" },
                msg: t("delete_insp_task_err_msg"),
                listMsg: delete_result,
              });
              setShowPopup(false);
              setShowInfoPopup(true);
              //setReload(getScreenName(screen));
            } else {
              setShowPopup(false);
              setReload(getScreenName(screen));
              dispatch(addMsg(t("recs_del_suc")));
            }
          } else {
            //Single Task Delete
            if (delete_result?.length > 0) {
              let formNames = [];
              if (response?.form_names) {
                const { form_names } = response;
                if (form_names?.length > 0) {
                  if (form_names[0]?.length > 0) {
                    formNames = form_names[0].map((obj) => obj.form_name);
                  }
                }
              }
              setInfoData({
                icon: { className: "fi fi-rr-triangle-warning", color: "red" },
                msg: t("delete_single_insp_task_err_msg"),
                listMsg: formNames,
              });
              setShowPopup(false);
              setShowInfoPopup(true);
            } else {
              setShowPopup(false);
              setReload(getScreenName(screen));
              dispatch(addMsg(t("rec_del_suc")));
            }
          }
        } else if (screen.includes("issues")) {
          const { delete_result } = response;

          if (delete_result?.length > 0) {
            const issueNames = delete_result.map((obj) => obj.issue);
            setInfoData({
              icon: { className: "fi fi-rr-triangle-warning", color: "red" },
              msg: t("delete_issue_err_msg"),
              listMsg: issueNames,
            });
            setShowPopup(false);
            setShowInfoPopup(true);
          } else {
            setShowPopup(false);
            setReload(getScreenName(screen));
            const deleteMessage =
              deleteApiData?.delete_ids &&
              deleteApiData?.delete_ids.includes(":::")
                ? t("recs_del_suc")
                : t("rec_del_suc");
            dispatch(addMsg(deleteMessage));
          }
        } else {
          dispatch(updateDeletedData({ query: deleteApiData.query }));
          let reloadScreen = getScreenName(screen);
          //If screen is batches, then reload parts
          if (screen?.includes("batches")) {
            reloadScreen = "parts";
          }
          setReload(reloadScreen);
          setShowPopup(false);
          setDeleteBtnLoading(false);
          const deleteMessage =
            deleteApiData?.delete_ids &&
            deleteApiData?.delete_ids.includes(":::")
              ? t("recs_del_suc")
              : t("rec_del_suc");
          dispatch(addMsg(deleteMessage));
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        centered
        size="sm"
      >
        <Modal.Body>
          <div className="m-0 p-0 d-flex align-items-center justify-content-center flex-column">
            <div
              className="d-flex justify-content-end"
              style={{ width: "-webkit-fill-available" }}
            >
              <ActionIconContainer onClick={() => setShowPopup(false)}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
            <div className="d-flex gap-2">
              <i
                className="fi fi-rr-interrogation"
                style={{
                  color: colorLightBlueStatus,
                  fontSize: 28,
                  width: 28,
                  height: 28,
                }}
              />
              <p className="mb-2">{deleteConfirmationMessage}</p>
            </div>
            <div className="d-flex justify-content-end gap-2 p-2">
              <FilterButton
                id="cancel-delete-btn"
                type="button"
                disabled={deleteBtnLoading}
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                {t("no")}
              </FilterButton>
              <AddFormButton
                id="delete-btn"
                onClick={handleDelete}
                disabled={deleteBtnLoading}
                type="button"
              >
                {deleteBtnLoading ? <CustomLoader /> : t("yes")}
              </AddFormButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
