import axios from "axios";
import { auth } from "./firebase";

const testApiUrl =
  "https://e0r3ic7ndl.execute-api.ap-south-1.amazonaws.com/test/";
const prodApiUrl =
  "https://mn7wnec4c6.execute-api.us-east-1.amazonaws.com/prod/";
const apiUrl = process.env.REACT_APP_ENV === "test" ? testApiUrl : prodApiUrl;

const testApiUrlNew =
  "https://hpdn322zuc.execute-api.ap-south-1.amazonaws.com/test/";

const prodApiUrlNew =
  "https://m6r95v3ydd.execute-api.us-east-1.amazonaws.com/prod/";
const apiUrlNew =
  process.env.REACT_APP_ENV === "test" ? testApiUrlNew : prodApiUrlNew;

export const urlConfig = {
  common: `${apiUrl}common`,
  user: `${apiUrlNew}user`,
  dashboard: `${apiUrlNew}dash`,
  vehicle: `${apiUrlNew}vehicle`,
  fillups: `${apiUrlNew}fillups`,
  organization: `${apiUrlNew}organization`,
  search: `${apiUrl}search`,
  subscription: `${apiUrlNew}subscription`,
  inspection: `${apiUrlNew}inspection`,
  issue: `${apiUrlNew}issues`,
  commonNew: `${apiUrlNew}common`,
  workOrder: `${apiUrlNew}work-order`,
  getInfo: `${apiUrl}get_info`,
  import: `${apiUrl}import`,
  integrations: `${apiUrlNew}integrations`,
  inventory: `${apiUrlNew}inventory`,
  listing: `${apiUrlNew}listing`,
  commonNewServer: `${apiUrlNew}common`,
  deleteData: `${apiUrlNew}delete_data`,
  commonOld: `${apiUrl}common`, //for put common requests (temp)
  getInfoV2: `${apiUrlNew}get_info_v2`,
  aiConfig: `${apiUrlNew}ai_config`,
};

export const newUrl =
  process.env.REACT_APP_ENV === "test"
    ? "https://e0r3ic7ndl.execute-api.ap-south-1.amazonaws.com/test/common"
    : "https://mn7wnec4c6.execute-api.us-east-1.amazonaws.com/prod/common";

export const getToken = async () => {
  let token = "";
  const user = auth?.currentUser;
  if (user) {
    token = await user.getIdToken();
  }

  return token;
};

export const getVehicles = async (query, t) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const queryData = {
      email: email,
      querystring: query,
    };
    const { data } = await axios.post(
      urlConfig["commonNew"],
      queryData,
      config
    );
    const res = data[0]?.value;

    let vehicles = [
      {
        id: 0,
        label: t("all_vehicles"),
        value: "",
        vehicleId: "",
      },
    ];
    if (res.success) {
      const result = data[0]?.value?.user_data;
      result?.forEach((item) => {
        //const currentGroup = groupsData.find((g) => g.group_id === item.group_id);
        vehicles.push({
          ...item,
          id: item.id,
          vehicleId: item.veh_id,
          label: item.name,
          value: item.name,
          groupId: item.group_id,
          orgId: item.org_id,
          secondaryStatus: item.secondary_status,
          vehActive: item.veh_active,
          primaryMeter: item.primary_meter,
          veh_img: item.veh_img,
          //group_name: currentGroup ? currentGroup?.group_name : "",
        });
      });
    } else {
      const errLogData = {
        url: urlConfig.commonNew,
        method_type: "POST",
        error: JSON.stringify(data),
        request: JSON.stringify(queryData),
        placeholder: "",
      };
      await logErrors(errLogData);
      vehicles = [];
    }
    return vehicles;
  } catch (e) {
    const errLogData = {
      url: urlConfig.commonNew,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

const getGroupNodes = (groups, current, groupNodes = []) => {
  groups.forEach((group) => {
    if (current.group_id === group.parent_group) {
      groupNodes.push(group.group_id);
      getGroupNodes(groups, group, groupNodes);
    }
  });

  return groupNodes;
};

export const getGroups = async (query, t) => {
  try {
    const groups = [
      {
        groupId: 0,
        label: t("all_groups"),
        value: "",
        data: 0,
        group_id: "",
        parent_group: null,
        key: "all-groups",
      },
    ];
    const token = await getToken(); //sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const queryData = {
      email: email,
      querystring: query,
    };
    const { data } = await axios.post(
      urlConfig["commonNew"],
      queryData,
      config
    );
    const result = data[0]?.value?.user_data;

    if (!data[0]?.value?.success) {
      const errLogData = {
        url: urlConfig.commonNew,
        method_type: "POST",
        error: JSON.stringify(data),
        request: JSON.stringify(queryData),
        placeholder: "",
      };
      await logErrors(errLogData);
    }

    result.forEach((item) =>
      groups.push({
        ...item,
        label: item.group_name,
        value: item.group_name,
        data: item,
        groupId: item.group_id,
        orgId: item.org_id,
        key: item.group_id,
        parentGroup: item.parent_group,
        groupNodes: getGroupNodes(result, item),
      })
    );
    return groups;
  } catch (e) {
    const errLogData = {
      url: urlConfig.commonNew,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const getUsers = async () => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const response = await axios.post(
      urlConfig.commonNew,
      {
        email: email,
        querystring: "getallorgusers",
      },
      config
    );
    //console.log(response);
    const userData = response?.data[0]?.value.user_data;
    if (!response?.data[0]?.value?.success) {
      const errLogData = {
        url: urlConfig.commonNew,
        method_type: "POST",
        error: JSON.stringify(response.data),
        request: JSON.stringify({
          email: email,
          querystring: "getallorgusers",
        }),
        placeholder: "",
      };
      await logErrors(errLogData);
    }
    if (userData.length > 0) {
      const responseData = [];
      userData.forEach((item) => {
        responseData.push({
          ...item,
          label: item.name,
          value: item.user_id,
          //userId: item.user_id,
          id: item.user_id,
        });
      });
      return responseData;
    }
  } catch (e) {
    const errLogData = {
      url: urlConfig.commonNew,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const getCsvData = async (data, screen) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const { csvQuery: query } = getQuery(screen);
    const queryData = {
      email,
      querystring: query,
      ...data,
    };

    const res = await axios.post(urlConfig.listing, queryData, config);
    return { url: res.data, error: false };
  } catch (e) {
    const errLogData = {
      url: urlConfig.listing,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
    return { url: "", error: true };
  }
};

export const getPdfData = async (data, screen) => {
  try {
    const token = await getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const { pdfQuery: query } = getQuery(screen);
    const queryData = {
      email: email,
      querystring: query,
      ...data,
    };
    const res = await axios.post(urlConfig.listing, queryData, config);
    if (res?.data[0]?.value?.success) {
      const result = res?.data[0]?.value;
      const rowData = getPDFRows(result, screen);
      return { rows: rowData, order: result?.roworder, error: false };
    } else {
      const errLogData = {
        url: urlConfig.listing,
        method_type: "POST",
        error: JSON.stringify(res.data),
        request: JSON.stringify(queryData),
        placeholder: "",
      };
      await logErrors(errLogData);
      return { rows: [], order: 0, error: true };
    }
  } catch (e) {
    const errLogData = {
      url: urlConfig.listing,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
    return { rows: [], order: 0, error: true };
  }
};

export const getQuery = (screen) => {
  let csvQuery,
    pdfQuery = "";
  if (screen === "fillups") {
    csvQuery = "fillupcsv";
    pdfQuery = "getfilluppdf";
  } else if (screen.includes("services/services")) {
    csvQuery = "getservicecsv";
    pdfQuery = "getservicepdf";
  } else if (screen.includes("expenses/expenses")) {
    csvQuery = "getcsvexpenses";
    pdfQuery = "getexpensepdf";
  } else if (screen.includes("trips")) {
    csvQuery = "alltripcsv";
    pdfQuery = "alltrippdf";
  } else if (screen.includes("dailymileage")) {
    csvQuery = "getmileagecsv";
    pdfQuery = "getmileagepdf";
  } else if (screen.includes("maintenance")) {
    csvQuery = "get_all_service_reminder_csv_v2";
    pdfQuery = "get_all_service_reminder_pdf_v2";
  } else if (screen.includes("renewals")) {
    csvQuery = "get_all_expense_reminder_csv_v2";
    pdfQuery = "get_all_expense_reminder_pdf_v2";
  } else if (screen.includes("inspectionsubmissions")) {
    csvQuery = "get_inspection_submission_csv";
    pdfQuery = "get_inspection_submission_pdf";
  } else if (screen.includes("issues")) {
    csvQuery = "issuescsv";
    pdfQuery = "issuespdf";
  } else if (screen.includes("parts")) {
    csvQuery = "get_all_inventory_csv_data";
    pdfQuery = "get_all_inventory_pdf_data";
  }
  return { csvQuery, pdfQuery };
};

export const getPDFRows = (data, screen) => {
  let rows = [];
  if (screen === "fillups") {
    rows = data.fillippdfdata;
  } else if (screen.includes("services/services")) {
    rows = data.servicepdfdata;
  } else if (screen.includes("expenses/expenses")) {
    rows = data.servicepdfdata;
  } else if (screen.includes("trips")) {
    rows = data.trippdfdata;
  } else if (screen.includes("dailymileage")) {
    rows = data.mileagepdfdata;
  } else if (screen.includes("reminders")) {
    rows = data.reminderpdfdata;
  } else if (screen.includes("inspectionsubmissions")) {
    rows = data.inspection_submission_pdf_data;
  } else if (screen.includes("issues")) {
    rows = data.fillippdfdata;
  } else if (screen.includes("parts")) {
    rows = data.parts_data;
  }
  return rows;
};

export const getInspectionScheduleFormInfo = async (
  query,
  vehicleId,
  serviceTaskId
) => {
  try {
    const token = await getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const res = await axios.post(
      urlConfig.commonNew,
      {
        email: email,
        querystring: query,
        selected_veh_ids: JSON.stringify([vehicleId]),
        servicetaskid: serviceTaskId,
      },
      config
    );
    const data = res?.data[0]?.value?.lastservicedata[0];
    let lastInspectionDate = "";
    let lastInspectionOdo = "";

    const [, dateVal, , odoVal] = data;

    if (dateVal && +dateVal > 0) {
      if (odoVal && odoVal > 0) {
        return {
          type: "last-inspected",
          lastInspectionOdo: odoVal,
          lastInspectionDate: new Date(dateVal * 1000),
        };
      } else {
        const getOdoRes = await axios.post(
          urlConfig.commonNew,
          {
            email: email,
            //odo: 0,
            veh_id: vehicleId, //"20675_5",
            date_to_check: dateVal,
            querystring: "get_odo_from_date_v2",
          },
          config
        );
        const res = getOdoRes?.data[0]?.value?.user_data[0];
        const odoRes = res?.vehicle_max_odo;
        const maxOdoVal = Number(odoRes.split(",")[0]);
        return {
          type: "max-odo",
          lastInspectionOdo: maxOdoVal,
          lastInspectionDate: new Date(dateVal * 1000),
        };
      }
    } else {
      if (odoVal && +odoVal > 0) {
        return {
          type: "max-odo",
          lastInspectionOdo: odoVal,
          lastInspectionDate: new Date(),
        };
      } else {
        return {
          type: "no-value",
          lastInspectionOdo: odoVal,
          lastInspectionDate: new Date(),
        };
      }
    }
  } catch (e) {
    const errLogData = {
      url: urlConfig.commonNew,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const postApi = async (
  data,
  screen,
  withEmail = true,
  orgData = false
) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let apiData = { ...data };
    const email = auth?.currentUser?.email;
    if (withEmail) {
      apiData = { email: email, ...data };
    }

    const res = await axios.post(urlConfig[screen], apiData, config);
    if (res.data) {
      const response = res?.data[0]?.value;

      if (
        !response?.success &&
        apiData?.querystring !== "updatefeedbackrating" &&
        apiData?.querystring !== "savefeedbackrating"
      ) {
        const errLogData = {
          url: urlConfig[screen],
          method_type: "POST",
          error: JSON.stringify(res.data),
          request: JSON.stringify(apiData),
          placeholder: "",
        };
        await logErrors(errLogData);
      }

      if (orgData) {
        return res?.data[1]?.value;
      }

      return response;
    }
  } catch (e) {
    console.log("catch err", e);
    const errLogData = {
      url: urlConfig[screen],
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const putApi = async (data, screen) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    //console.log("token in postApi", token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const res = await axios.put(
      urlConfig[screen],
      { email: email, ...data },
      config
    );
    const response = res?.data[0]?.value;
    if (!response?.success) {
      const errLogData = {
        url: urlConfig[screen],
        method_type: "PUT",
        error: JSON.stringify(res.data),
        request: JSON.stringify({ email: email, ...data }),
        placeholder: "",
      };
      await logErrors(errLogData);
    }
    return response;
  } catch (e) {
    console.log("catch err", e);
    const errLogData = {
      url: urlConfig[screen],
      method_type: "PUT",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const getUnits = async () => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await axios.post(
      urlConfig.getInfoV2,
      { querystring: "get_units" },
      config
    );
    //console.log(res);
    const response = res?.data[0];
    if (!response?.success) {
      const errLogData = {
        url: urlConfig.getInfoV2,
        method_type: "POST",
        error: JSON.stringify(res.data),
        request: JSON.stringify({ querystring: "get_units" }),
        placeholder: "",
      };
      await logErrors(errLogData);
    }
    return response;
  } catch (e) {
    const errLogData = {
      url: urlConfig.getInfoV2,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const addOrganizationData = async (data, withEmail = true) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const email = auth?.currentUser?.email;
    let apiData = { ...data };
    if (withEmail) {
      apiData = { email: email, ...data };
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await axios.post(urlConfig.organization, apiData, config);
    const isSuccess = res.data[1]?.value?.success;
    if (!isSuccess) {
      const errLogData = {
        url: urlConfig.organization,
        method_type: "POST",
        error: JSON.stringify(res.data),
        request: JSON.stringify(apiData),
        placeholder: "",
      };
      await logErrors(errLogData);
    }

    return res;
  } catch (e) {
    const errLogData = {
      url: urlConfig.organization,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const post = async (screen, data) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    const email = auth?.currentUser?.email;
    let apiData = { email, ...data };
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const res = await axios.post(urlConfig[screen], apiData, config);
    if (res.data) {
      const { data } = res;
      const queryStringsToSkip = [
        "create_customer_session",
        "get_integrations_data",
        "getOdometerFromDate",
        "check_user_email_v2",
        "get_all_inventory_data",
      ];
      if (
        !data?.success &&
        !queryStringsToSkip.includes(apiData?.querystring)
      ) {
        const errLogData = {
          url: urlConfig[screen],
          method_type: "POST",
          error: JSON.stringify(data),
          request: JSON.stringify(apiData),
          placeholder: "",
        };
        await logErrors(errLogData);
      }
      //Handle log errors for get_all_inventory_data separately since response structure is different
      if (
        apiData?.querystring === "get_all_inventory_data" &&
        data?.errorMessage
      ) {
        const errLogData = {
          url: urlConfig[screen],
          method_type: "POST",
          error: JSON.stringify(data),
          request: JSON.stringify(apiData),
          placeholder: "",
        };
        await logErrors(errLogData);
      }
      return res.data;
    }
  } catch (e) {
    console.log("catch err", e);
    const errLogData = {
      url: urlConfig[screen],
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const getWODataForPdf = async (data) => {
  try {
    const token = await getToken(); //sessionStorage.getItem("token");
    //console.log("token in postApi", token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const email = auth?.currentUser?.email;
    const res = await axios.post(
      urlConfig.listing,
      { email: email, ...data },
      config
    );
    const response = res?.data;

    return response;
  } catch (e) {
    const errLogData = {
      url: urlConfig.listing,
      method_type: "POST",
      error: e?.message,
      request: e?.config?.data,
      placeholder: "",
    };
    await logErrors(errLogData);
  }
};

export const getFormattedOffset = () => {
  const offsetInMinutes = new Date().getTimezoneOffset();
  // Calculate the absolute value of the offset in hours and minutes
  const absOffsetInMinutes = Math.abs(offsetInMinutes);
  const hours = Math.floor(absOffsetInMinutes / 60);
  const minutes = absOffsetInMinutes % 60;
  // Determine the sign of the offset
  const sign = offsetInMinutes <= 0 ? "+" : "-";
  // Format the offset as '+HH:MM' or '-HH:MM'
  const formattedOffset = `${sign}${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  return formattedOffset;
};

export const logErrors = async (errObj) => {
  const email = auth?.currentUser?.email;
  const apiData = {
    querystring: "log_api_errors",
    errors: [{ ...errObj, user_id: email, org_id: "", source: "web" }],
  };
  await axios.post(urlConfig.getInfoV2, apiData);
};
