import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DataReactTable from "../DataTable/components/DataReactTable";
import {
  fetchData,
  updateInitialData,
  updateStart,
} from "../../features/filter/filterSlice";
import VehicleComponent from "../../shared/components/table/components/VehicleComponent";
import {
  initialRangeData,
  initialContainsData,
} from "../../shared/constants/filterData";

import {
  AddFormButton,
  ColumnHeading,
  FilterButton,
} from "../../shared/components/TableElements";
import getUnixTime from "date-fns/getUnixTime";
import { useState } from "react";
import {
  daysHoursMinutes,
  getDisplayDateWithTime,
  getDisplayDateWithTimeNew,
  getDistanceUnit,
} from "../../shared/helpers";
import { NATEXT } from "../../shared/constants";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router";
import { putApi } from "../../api/getUser";
import { updateUserSettingsData } from "../../features/columns/columnsSlice";
import ExplorePopup from "../../shared/components/ExplorePopup";
import { clearAllAdvancedFilters } from "../../features/advancedFilter/advancedFilterSlice";
import { LinkSpan } from "../../shared/components/form/FormElements";
import SubscribePopup from "../../shared/components/SubscribePopup";
import useFetchData from "../../shared/custom-hooks/useFetchData";
import getDefaultFilterData from "../../shared/constants/getDefaultFilterData";

const TripsTable = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const columnWidth = 130;
  const { rowData, values, status } = useSelector((state) => state.filter);
  const filterData = useSelector((state) => state.filter.data);
  const {
    draw,
    length,
    search,
    order,
    start_date,
    end_date,
    start,
    filter_group_id,
    filter_vehicle_id,
    querystring,
  } = filterData;
  const columns = useSelector((state) => state.advancedFilter.trips);
  //const { reload } = useContext(DeletedContext);
  const { reload, setReload, isLoggedIn, setLoading, tag } = useAuth();
  const newData = {
    ...filterData,
    ...columns,
    //querystring: "getalltrips",
  };
  const { minDate } = useSelector((state) => state.columns);
  const { orgDate } = useSelector((state) => state.user);

  const { distance, currency } = useSelector((state) => state.units);

  const users = useSelector((state) => state.users.list);

  const location = useLocation();
  //const fromCancel = location.state?.fromCancel;

  const valuesArray = [
    {
      id: 0,
      name: t("trp_distance"),
      value: values.footertotaldistance
        ? `${values.footertotaldistance?.toFixed(2)} ${getDistanceUnit(
            distance
          )}`
        : NATEXT,
      //units: values.footertotaldistance ? "Km" : "",
      //icon: "currency",
    },
    {
      id: 1,
      name: t("trip_duration"),
      value: values.footertotaltime
        ? daysHoursMinutes(values.footertotaltime).display
        : NATEXT,
    },
    {
      id: 2,
      name: t("fillup_cost"),
      value: values.footertotalfillupcost
        ? `${values.footertotalfillupcost?.toFixed(2)} ${currency}`
        : NATEXT,
    },
    {
      id: 3,
      name: t("expense_cost"),
      value: values.footertotalexpense
        ? `${values.footertotalexpense?.toFixed(2)} ${currency}`
        : NATEXT,
    },
    {
      id: 4,
      name: t("trip_income"),
      value: values.footertotalincome
        ? `${values.footertotalincome?.toFixed(2)} ${currency}`
        : NATEXT,
    },
  ];

  //Logic for first time load(Reload start_date and end_date)
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [showExplorePopup, setShowExplorePopup] = useState(false);
  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  useFetchData({
    startDate: 0,
    endDate: getUnixTime(new Date()),
    query: "getalltrips",
    sortIndex: 2,
  });

  // //Initial Effect
  // useEffect(() => {
  //   if (
  //     pathname.includes("trips") &&
  //     firstTimeLoad &&
  //     isLoggedIn &&
  //     orgDate
  //     //&& (!fromCancel || status === "initial")
  //     //&&
  //     //colOrderQuery === "getfillupsorder"
  //   ) {
  //     (async () => {
  //       setLoading(true);
  //       if (columns) {
  //         dispatch(
  //           clearAllAdvancedFilters({
  //             columns: Object.keys(columns),
  //             screen: "trips",
  //           })
  //         );
  //       }
  //       console.log("Initial Effect called - services ");
  //       const startDate = 0;
  //       const endDate = getUnixTime(new Date());

  //       let colOrder = "[0,1,2,3,4,5,6]";
  //       let minDate = orgDate;
  //       let defaultRows = 10;

  //       const apiData = {
  //         querystring: "get_user_screen_settings",
  //         screen_name: "trips",
  //       };

  //       const userSettingsRes = await putApi(apiData, "dashboard");
  //       console.log("userSettingsRes", userSettingsRes);
  //       if (userSettingsRes.success) {
  //         const { data } = userSettingsRes;
  //         if (data) {
  //           const { col_order, min_date, default_rows } = data;
  //           if (col_order) {
  //             colOrder = col_order;
  //           }
  //           if (min_date) {
  //             minDate = min_date;
  //           }
  //           defaultRows = default_rows;
  //         }
  //         console.log("data", data);
  //       }
  //       dispatch(
  //         updateUserSettingsData({
  //           col_order: colOrder,
  //           min_date: minDate,
  //           default_rows: defaultRows,
  //         })
  //       );

  //       let index = 1;
  //       if (colOrder && colOrder.length > 0) {
  //         const colOrderUI = JSON.parse(colOrder).map((item) => +item + 1);
  //         index = colOrderUI.findIndex((col) => col === 2);
  //       }

  //       dispatch(
  //         updateInitialData({
  //           query: "getalltrips",
  //           startDate,
  //           endDate,
  //           order: index,
  //           length: defaultRows ? defaultRows : 10,
  //         })
  //       );

  //       const initialData = {
  //         draw: 1,
  //         length: defaultRows ? defaultRows : 10,
  //         search: {
  //           value: "",
  //           regex: false,
  //         },
  //         order: [
  //           {
  //             column: index,
  //             dir: "desc",
  //           },
  //         ],
  //         start_date: 0,
  //         end_date: getUnixTime(new Date()),
  //         start: 0,
  //         filter_group_id: "",
  //         filter_vehicle_id: "",
  //         querystring: "getalltrips",
  //       };

  //       const initialAdvancedFilterCols = {
  //         depOdo: {},
  //         departure: {},
  //         arrOdo: {},
  //         arrival: {},
  //         distance: {},
  //         time: {},
  //         tripBy: {},
  //         comments: {},
  //       };

  //       dispatch(fetchData({ ...initialData, ...initialAdvancedFilterCols }));
  //       setTimeout(() => {
  //         setFirstTimeLoad(false);
  //       }, 2000);
  //     })();
  //   }
  // }, [isLoggedIn, orgDate]);

  // // useEffect(() => {
  // //   //console.log(firstTimeLoad);
  // //   if (
  // //     firstTimeLoad &&
  // //     isLoggedIn &&
  // //     pathname.includes("trips") &&
  // //     colOrderQuery === "gettripsorder"
  // //   ) {
  // //     let startDate = 0;
  // //     let endDate = getUnixTime(new Date());
  // //     dispatch(
  // //       updateInitialData({
  // //         query: "getalltrips",
  // //         startDate,
  // //         endDate,
  // //         length: 25,
  // //       })
  // //     );

  // //     let index = 1;
  // //     if (colOrder && colOrder.length > 0) {
  // //       index = colOrder.findIndex((col) => col === 2);
  // //     }
  // //     const initialData = {
  // //       draw: 1,
  // //       length: 10,
  // //       search: {
  // //         value: "",
  // //         regex: false,
  // //       },
  // //       order: [
  // //         {
  // //           column: index,
  // //           dir: "desc",
  // //         },
  // //       ],
  // //       start_date: 0,
  // //       end_date: getUnixTime(new Date()),
  // //       start: 0,
  // //       filter_group_id: "",
  // //       filter_vehicle_id: "",
  // //       querystring: "getalltrips",
  // //     };
  // //     dispatch(fetchData({ ...initialData, ...columns }));

  // //     setFirstTimeLoad(false);
  // //   }
  // // }, [isLoggedIn, colOrderQuery]);

  // useEffect(() => {
  //   if (!firstTimeLoad) {
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...newData, start: 0 }));
  //   }
  // }, [
  //   draw,
  //   length,
  //   //start,
  //   search,
  //   order,
  //   start_date,
  //   end_date,
  //   filter_group_id,
  //   filter_vehicle_id,
  //   columns,
  // ]);

  // //Fetch data on change of start
  // useEffect(() => {
  //   if (
  //     pathname.includes("trips") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getalltrips" &&
  //     !reload
  //   ) {
  //     console.log("Effect with start change - trips ");
  //     dispatch(fetchData({ ...newData, ...columns }));
  //   }
  // }, [start]);

  // //Fetch data on reload
  // useEffect(() => {
  //   if (
  //     pathname.includes("trips") &&
  //     !firstTimeLoad &&
  //     isLoggedIn &&
  //     querystring === "getalltrips" &&
  //     reload === "trips"
  //   ) {
  //     console.log("Reload Effect");
  //     dispatch(updateStart(0));
  //     dispatch(fetchData({ ...newData, ...columns, start: 0 }));
  //     //setLoading(true);
  //     setReload("");
  //   }
  // }, [reload]);

  //Data for Filters
  const filterToggleColumns = {
    depDate: false,
    depOdo: false,
    departure: false,
    arrDate: false,
    arrOdo: false,
    arrival: false,
    distance: false,
    time: false,
    tripBy: false,
    comments: false,
  };

  const { defaultContainsData, defaultRangeData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      date: {
        column: "date",
        name: t("date_tv"),
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      // depDate: {
      //   column: "depDate",
      //   name: "Dep. Date",
      //   ...initialDateData,
      // },
      depOdo: {
        column: "depOdo",
        name: t("dep_odo"),
        ...defaultRangeData,
      },
      departure: {
        column: "departure",
        name: t("departure"),
        ...defaultContainsData,
      },
      // arrDate: {
      //   column: "arrDate",
      //   name: "Arr. Date",
      //   ...initialDateData,
      // },
      arrOdo: {
        column: "arrOdo",
        name: t("arr_odo"),
        ...defaultRangeData,
      },
      arrival: {
        column: "arrival",
        name: t("arrival"),
        ...defaultContainsData,
      },
      distance: {
        column: "distance",
        name: t("distance"),
        ...defaultRangeData,
      },
      time: {
        column: "time",
        name: t("trip_duration"),
        ...defaultRangeData,
      },
      tripBy: {
        column: "tripBy",
        name: t("trip_by"),
        ...defaultContainsData,
      },
      comments: {
        column: "comments",
        name: t("notes_tv"),
        ...defaultContainsData,
      },
    }),
    [minDate]
  );

  //Columns
  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        // Footer: 'Middle age:',
        // disableGlobalFilter: false,
        width: 130,
        disableFilters: true,
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName={row.original.group_name}
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("dep_date_and_time"),
        accessor: "dep_date",
        //disableFilters: true,
        width: columnWidth,
        //minWidth: 50,
        Cell: ({ value }) =>
          value ? getDisplayDateWithTimeNew(value) : NATEXT,
      },
      {
        id: 3,
        Header: t("dep_loc"),
        accessor: "dep_loc",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
        // disableGlobalFilter: false,
      },
      {
        id: 4,
        Header: t("dep_odo"),
        accessor: "dep_odo",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ row }) =>
          +row.original.dep_odo
            ? `${row.original.dep_odo} ${getDistanceUnit(
                row.original.odo_unit
              )}`
            : NATEXT,
        // disableGlobalFilter: false,
      },
      {
        id: 5,
        Header: t("arr_date_and_time"),
        accessor: "arr_date",
        //disableFilters: true,
        width: columnWidth,
        // disableGlobalFilter: false,
        Cell: ({ value }) =>
          value ? getDisplayDateWithTimeNew(value) : NATEXT,
      },

      {
        id: 6,
        Header: t("arr_loc"),
        accessor: "arr_loc",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
        // disableGlobalFilter: false,
      },
      {
        id: 7,
        Header: t("arr_odo"),
        accessor: "arr_odo",
        // disableGlobalFilter: false,
        disableSortBy: false,
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ row }) =>
          row.original.arr_odo
            ? `${row.original.arr_odo} ${getDistanceUnit(
                row.original.odo_unit
              )}`
            : NATEXT,
      },
      {
        id: 8,
        Header: t("trp_distance"),
        accessor: "trip_distance",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ row }) =>
          row.original.trip_distance
            ? `${row.original.trip_distance} ${getDistanceUnit(
                row.original.odo_unit
              )}`
            : NATEXT,
        // disableGlobalFilter: false,
      },
      {
        id: 9,
        Header: t("trip_duration"),
        accessor: "trip_time",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) =>
          value ? daysHoursMinutes(value)?.display : NATEXT,
      },
      {
        id: 10,
        Header: t("trip_by"),
        accessor: "uname",
        //disableFilters: true,
        width: columnWidth,
        // Cell: ({ value }) =>
        //   value ? users.find((u) => u.user_id === value)?.name : NATEXT,
      },
      {
        id: 11,
        Header: t("fillup_cost"),
        accessor: "total_fillup_cost", //"trip_fillups_cost",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (+value ? `${value} ${currency}` : NATEXT),
      },
      {
        id: 12,
        Header: t("expense_cost"),
        accessor: "total_expense_cost", //"trip_expenses_cost",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (+value ? `${value} ${currency}` : NATEXT),
      },
      {
        id: 13,
        Header: t("income"),
        accessor: "total_income_cost", //"trip_income_cost",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (+value ? `${value} ${currency}` : NATEXT),
      },
      {
        id: 14,
        Header: t("notes_tv"),
        accessor: "comments",
        //disableFilters: true,
        width: columnWidth,
        Cell: ({ value }) => (value ? value : NATEXT),
      },
      // {
      //   id: 'edit',
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
      // {
      //   id: 12,
      //   Header: "Group Name",
      //   accessor: "group_name",
      //   //disableFilters: true,
      //   width: columnWidth,
      // },
    ],
    [distance, rowData, currency]
  );

  let count = 0;
  let defaultColumns = {};
  columnHeaders.forEach((column) => {
    defaultColumns[count] = {
      accessor: column.accessor,
      header: column.Header,
    };
    count++;
  });

  const handleAddTrip = () => {
    // if (tag === "essential-user") {
    //   setShowSubscribePopup(true);
    //   return;
    // }
    history.push("trips/addnew");
  };

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rowData,
  };

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/IOI7KURH"}
        />
      )}
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_to_advanced_msg")}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          {/* <Col
          sm={10}
          //className="d-flex justify-content-start align-items-center"
        >
          <ColumnHeading>{t("trips")}</ColumnHeading>
        </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("trips")}</ColumnHeading>
            {/* <FilterButton onClick={() => setShowExplorePopup(true)}>
              <i
                className="fi fi-rr-globe"
                style={{ margin: "4px 4px 0 0" }}
              ></i>
              <span>{t("explore")}</span>
            </FilterButton> */}
            <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan>
          </Col>

          <Col
            sm={2}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            {/* <Link to={"/trips/addnew"}> */}
            <AddFormButton
              id="add-trip-button"
              type="button"
              onClick={handleAddTrip}
            >
              {t("add_trips")}
            </AddFormButton>
            {/* </Link> */}
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            valuesArray={valuesArray}
            filterToggleColumns={filterToggleColumns}
            initialData={initialData}
            defaultColumns={defaultColumns}
          />
        </Row>
      </Container>
    </>
  );
};

export default TripsTable;
