import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { getUnits, post, postApi } from "../../../api/getUser";
import { Col, Container, Row } from "react-bootstrap";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { countries, currencies } from "country-data";
import { PhotoUploadComponent } from "../../UserManagement/UserDetailsForm";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import FormHeader from "../../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import {
  getTranslatedPMUnit,
  getTranslatedVolumeUnit,
  handleNegative,
} from "../../../shared/helpers";
import Switch from "../../../shared/components/form/Switch/Switch";
import { colorStatusActive, colorSwitchActive } from "../../../utils/palette";
import { useSelector } from "react-redux";
import {
  consumptionCurrencyMapping,
  distanceCurrencyMapping,
  hourlyConsumptionCurrencyMapping,
  volumeCurrencyMapping,
} from "../../../shared/constants/loginMappingDetails";
import getUnixTime from "date-fns/getUnixTime";
import { uploadImgToS3 } from "../../Issues/IssuesForm";
import { updateUnits } from "../../../features/units/unitsSlice";
import InfoPopup from "../../../shared/components/InfoPopup";
import { updateUser } from "../../../features/user/userSlice";
import { countryCurrencyMapping, s3Url } from "../../../shared/constants";

const OrganizationSettings = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const colWidth = 8;

  const { roleId } = useSelector((state) => state.user);

  const currencyUnit = useSelector((state) => state.units.currency);
  const consumptionUnit = useSelector((state) => state.units.consumption);
  const hourlyConsumptionUnit = useSelector(
    (state) => state.units.hourlyConsumption
  );
  const distanceUnit = useSelector((state) => state.units.distance);
  const volumeUnit = useSelector((state) => state.units.volume);

  const { orgId, userId, orgImg } = useSelector((state) => state.user);

  const countriesList = countries.all.filter((c) => c.currencies.length > 0);
  const currenciesList = currencies.all;

  // const countriesOptions = countriesList.map((c) => ({
  //   ...c,
  //   label: c.name,
  //   value: c.alpha3,
  //   currency: c.currencies[0],
  // }));

  // const currenciesOptions = currenciesList.map((c) => ({
  //   ...c,
  //   label: c.name,
  //   value: c.code,
  // }));

  const countriesOptions = countryCurrencyMapping.map((c) => ({
    label: c[0],
    value: c[0],
    currency: c[1],
    countryCode: countriesList.find((country) => country.name === c[0])?.alpha2,
  }));

  const currenciesOptions = countryCurrencyMapping.map((c) => ({
    value: c[1],
    code: c[1],
    label: currenciesList.find((cur) => cur.code === c[1])?.name,
  }));

  let distanceOptions = useRef([]);
  let volumeOptions = useRef([]);
  let consumptionOptions = useRef([]);
  let hourlyConsumptionOptions = useRef([]);

  const rowData = useRef(null);

  const fleetSizeOptions = [
    {
      label: "1-10",
      value: "1-10",
    },
    {
      label: "10-50",
      value: "10-50",
    },
    {
      label: "50-100",
      value: "50-100",
    },
    {
      label: "100+",
      value: "100+",
    },
  ];

  //Hook Form Values
  const country = watch("country");
  const currency = watch("currency");
  const distance = watch("distance");
  const volume = watch("volume");
  const consumption = watch("consumption");
  const hourlyConsumption = watch("hourlyConsumption");
  const orgName = watch("orgName");
  const address = watch("address");
  const phone = watch("phone");
  const fleetSize = watch("fleetSize");

  const [orgPhoto, setOrgPhoto] = useState(null);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  //State for Switch Component
  const [vehicleConsumptionInHours, setVehicleConsumptionInHours] =
    useState(false);

  const [firstTimeEdit, setFirstTimeEdit] = useState(true);

  //Initial Effect
  useEffect(() => {
    (async () => {
      const unitsRes = await getUnits();
      if (unitsRes.success) {
        const {
          meta_data: { units },
        } = unitsRes;

        distanceOptions.current = units
          .filter((u) => u.unit_type === "distance")
          .map((u) => ({
            ...u,
            label: getTranslatedPMUnit(u.unit_name, t),
            value: u.unit_name,
          }));

        volumeOptions.current = units
          .filter((u) => u.unit_type === "volume")
          .map((u) => ({
            ...u,
            label: getTranslatedVolumeUnit(u.unit_name, t),
            value: u.unit_name,
          }));

        consumptionOptions.current = units
          .filter((u) => u.unit_type === "consumption")
          .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));

        hourlyConsumptionOptions.current = units
          .filter((u) => u.unit_type === "hour_consumption")
          .map((u) => ({ ...u, label: u.unit_name, value: u.unit_name }));
      }

      const fetchOrgDetailsRes = await postApi(
        {
          querystring: "get_org_data",
        },
        "commonNew"
      );
      if (fetchOrgDetailsRes.success) {
        const { user_data } = fetchOrgDetailsRes;
        const orgData = user_data[0];
        rowData.current = orgData;
        const {
          name,
          address_1,
          address_2,
          org_img,
          country,
          currency,
          distance,
          volume,
          consumption,
          hourly_consumption,
          hourly_machines_present,
          starting_ending_mileage_required,
          starting_mileage_reminder_time,
          //fleet_size,
        } = orgData;
        setValue("orgName", name);
        setValue("address", address_1 + address_2);
        setValue("phone", orgData?.phone);
        // setValue(
        //   "fleetSize",
        //   fleetSizeOptions.find((o) => o.value === fleet_size)
        // );

        setVehicleConsumptionInHours(hourly_machines_present === 1);

        setValue(
          "country",
          countriesOptions.find((c) => c.value === country)
        );

        setValue(
          "currency",
          currenciesOptions.find((c) => c.value === currency)
        );

        setValue(
          "distance",
          distanceOptions.current.find((d) => d.value === distance)
        );
        setValue(
          "volume",
          volumeOptions.current.find((d) => d.value === volume)
        );

        setValue(
          "consumption",
          consumptionOptions.current.find((d) => d.value === consumption)
        );
        setValue(
          "hourlyConsumption",
          hourlyConsumptionOptions.current.find(
            (d) => d.value === hourly_consumption
          )
        );

        setFirstTimeEdit(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (orgImg && orgId) {
      //const extension = orgImg.split(".").pop();
      // if (extension) {
      setOrgPhoto({
        id: Date.now(),
        url: `${s3Url}/org_${orgId}/logo/${orgImg}`,
        imgName: orgImg,
        existing: true,
      });
      // }
    }

    // if (orgImg && orgImg !== "logo.") {
    //   setOrgPhoto({
    //     id: Date.now(),
    //     url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${orgId}/logo/${orgImg}`,
    //     imgName: orgImg,
    //     existing: true,
    //   });
    // }
  }, [orgId, orgImg]);

  const handleCountryChange = (selected, onChange) => {
    onChange(selected);

    setValue(
      "currency",
      currenciesOptions.find((c) => c.code === selected.currency)
    );

    //Set Distance
    if (Object.keys(distanceCurrencyMapping).includes(selected.currency)) {
      setValue(
        "distance",
        distanceOptions.current.find(
          (d) => d.value === distanceCurrencyMapping[selected.currency]
        )
      );
    } else {
      setValue(
        "distance",
        distanceOptions.current.find((d) => d.value === "Kilometers")
      );
    }

    //Set Volume
    if (Object.keys(volumeCurrencyMapping).includes(selected.currency)) {
      setValue(
        "volume",
        volumeOptions.current.find(
          (d) => d.value === volumeCurrencyMapping[selected.currency]
        )
      );
    } else {
      setValue(
        "volume",
        volumeOptions.current.find((d) => d.value === "Liters")
      );
    }

    //Set Consumption
    if (Object.keys(consumptionCurrencyMapping).includes(selected.currency)) {
      setValue(
        "consumption",
        consumptionOptions.current.find(
          (d) => d.value === consumptionCurrencyMapping[selected.currency]
        )
      );
    } else {
      setValue(
        "consumption",
        consumptionOptions.current.find((d) => d.value === "km/L")
      );
    }

    //Set Hourly Consumption
    if (
      Object.keys(hourlyConsumptionCurrencyMapping).includes(selected.currency)
    ) {
      setValue(
        "hourlyConsumption",
        hourlyConsumptionOptions.current.find(
          (d) => d.value === hourlyConsumptionCurrencyMapping[selected.currency]
        )
      );
    } else {
      setValue(
        "hourlyConsumption",
        hourlyConsumptionOptions.current.find((d) => d.value === "L/Hr")
      );
    }
  };

  const onSubmit = async () => {
    setSaveBtnLoading(true);
    const {
      daily_timesheet_reminder_time,
      starting_mileage_reminder_time,
      ending_mileage_reminder_time,
      daily_timesheet_required,
      starting_ending_mileage_required,
    } = rowData.current;

    const apiData = {
      querystring: "update_org_settings",
      //action: 2,
      //operation: "update",
      org_name: orgName,
      org_country: country.value,
      org_distance: distance.value,
      org_volume: volume.value,
      org_consumption: consumption.value,
      org_currency: currency.value,
      org_addressone: address,
      org_addresstwo: "",
      orgimagename: orgPhoto ? orgPhoto.imgName : "",
      login_user_daily_timesheet_required: daily_timesheet_required,
      login_user_daily_timesheet_reminder_time: daily_timesheet_reminder_time
        ? daily_timesheet_reminder_time
        : "00:00:00",
      hourly_machines_present: vehicleConsumptionInHours ? 1 : 0,
      hourly_consumption: hourlyConsumption.value,
      login_user_starting_ending_mileage_required:
        starting_ending_mileage_required,
      login_user_starting_mileage_reminder_time: starting_mileage_reminder_time
        ? starting_mileage_reminder_time
        : "00:00:00",
      login_user_ending_mileage_reminder_time: ending_mileage_reminder_time
        ? ending_mileage_reminder_time
        : "00:00:00",
      timestamp: getUnixTime(new Date()),
      phone: phone ? phone : 0,
    };

    const res = await postApi(apiData, "commonNew");
    if (res.success) {
      //Call get_org_units
      const apiData = {
        querystring: "get_org_units",
      };
      const response = await postApi(apiData, "commonNew");
      const unitsData = response?.user_data[0];
      dispatch(updateUnits(unitsData));

      if (orgPhoto && orgPhoto.file) {
        const orgImg = {
          file: orgPhoto.file,
          key: `image_data/org_${orgId}/logo/${orgPhoto.imgName}`,
        };
        const uploadImgToS3Res = await uploadImgToS3(orgImg);
        if (uploadImgToS3Res === 200) {
          //Get User Profile
          const apiData = {
            querystring: "get_user_profile",
          };
          const response = await postApi(apiData, "commonNew");
          const userData = response.user_data[0];
          dispatch(updateUser(userData));

          setShowSuccessPopup(true);
          setSaveBtnLoading(false);
        }
      } else {
        //Get User Profile
        const apiData = {
          querystring: "get_user_profile",
        };
        const response = await postApi(apiData, "commonNew");
        const userData = response.user_data[0];
        dispatch(updateUser(userData));

        setShowSuccessPopup(true);
        setSaveBtnLoading(false);
      }
    }
  };

  return (
    <>
      {showSuccessPopup && (
        <InfoPopup
          showPopup={showSuccessPopup}
          setShowPopup={setShowSuccessPopup}
          message={t("org_settings_save_success_msg")}
          icon={{
            className: "fi fi-rs-check-circle",
            color: colorStatusActive,
          }}
          //onClose={() => location.reload()}
        />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //handleCancel={() => history.goBack()}
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={t("org_settings")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Card>
            <CardBody formcard="true">
              <Row>
                <Col md={2} className="pt-2">
                  <PhotoUploadComponent
                    photo={orgPhoto}
                    setPhoto={setOrgPhoto}
                    isDisabled={roleId === 4 || roleId === 2}
                    //rowData={rowData}
                  />
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="org-name-label">
                        {t("org_name")}
                      </FormGroupLabel>
                      <FormItem
                        disabled={roleId === 4 || roleId === 2}
                        id="org-name-div"
                      >
                        <FormField
                          name="orgName"
                          id="org-name"
                          control={control}
                          component="input"
                          type="text"
                          defaultValue=""
                          placeholder={t("org_name")}
                          style={formInputStyles}
                          disabled={roleId === 4 || roleId === 2}
                          rules={{ required: t("required_err") }}
                          //onKeyUp={handleSIOdoChange}
                        />
                        {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                      </FormItem>

                      {errors?.orgName && (
                        <Error error={errors.orgName.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="address-label">
                        {t("address")}
                      </FormGroupLabel>
                      <FormItem
                        disabled={roleId === 4 || roleId === 2}
                        id="address-div"
                      >
                        <FormField
                          name="address"
                          id="address"
                          control={control}
                          component="input"
                          type="text"
                          defaultValue=""
                          placeholder={t("address")}
                          style={formInputStyles}
                          disabled={roleId === 4 || roleId === 2}
                          rules={{ required: t("required_err") }}
                          //onKeyUp={handleSIOdoChange}
                        />
                      </FormItem>

                      {errors?.address && (
                        <Error error={errors.address.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="country-label">
                        {t("country")}
                      </FormGroupLabel>
                      <Controller
                        name="country"
                        //id="country"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            options={countriesOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={(e) => handleCountryChange(e, onChange)}
                            //onCreateOption={handleCreate}
                            placeholder={t("country")}
                            styles={formStyles}
                            isDisabled={roleId === 4 || roleId === 2}
                            id="country-select"
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.country && (
                        <Error error={errors.country.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="phone-label">
                        {t("phone")}
                      </FormGroupLabel>
                      <FormItem
                        disabled={roleId === 4 || roleId === 2}
                        id="phone-div"
                      >
                        <FormField
                          name="phone"
                          id="phone"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={handleNegative}
                          //defaultValue=""
                          placeholder={t("phone")}
                          style={formInputStyles}
                          disabled={roleId === 4 || roleId === 2}
                          //rules={{ required: t("required_err") }}
                          //onKeyUp={handleSIOdoChange}
                        />
                      </FormItem>

                      {errors?.phone && <Error error={errors.phone.message} />}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="currency-label">
                        {t("currency")}
                      </FormGroupLabel>
                      <Controller
                        name="currency"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="currency-select"
                            options={currenciesOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("currency")}
                            styles={formStyles}
                            isDisabled={roleId === 4 || roleId === 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.currency && (
                        <Error error={errors.currency.message} />
                      )}
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="fleet-size-label">
                        {t("fleet_size")}
                      </FormGroupLabel>
                      <Controller
                        name="fleetSize"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="fleet-size-select"
                            options={fleetSizeOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("fleet_size")}
                            styles={formStyles}
                            isDisabled={false}
                            isSearchable={false}
                          />
                        )}
                        //rules={{ required: t("required_err") }}
                      />

                      {errors?.fleetSize && (
                        <Error error={errors.fleetSize.message} />
                      )}
                    </Col>
                  </Row> */}

                  <Row>
                    <Col md={colWidth}>
                      <hr className="mt-4 mb-0" />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="distance-label">
                        {t("distance")}
                      </FormGroupLabel>
                      <Controller
                        name="distance"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="distance-select"
                            options={distanceOptions.current}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("distance")}
                            styles={formStyles}
                            isDisabled={roleId === 4 || roleId === 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.distance && (
                        <Error error={errors.distance.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="volume-label">
                        {t("volume")}
                      </FormGroupLabel>
                      <Controller
                        name="volume"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="volume-select"
                            options={volumeOptions.current}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("volume")}
                            styles={formStyles}
                            isDisabled={roleId === 4 || roleId === 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.volume && (
                        <Error error={errors.volume.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth}>
                      <FormGroupLabel id="consumption-label">
                        {t("consumption")}
                      </FormGroupLabel>
                      <Controller
                        name="consumption"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="consumption-select"
                            options={consumptionOptions.current}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onCreateOption={handleCreate}
                            placeholder={t("consumption")}
                            styles={formStyles}
                            isDisabled={roleId === 4 || roleId === 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.consumption && (
                        <Error error={errors.consumption.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={colWidth} className="pt-4 pb-2">
                      <div
                        //style={{ height: "34px" }}
                        className="d-flex align-items-center gap-2"
                      >
                        <Switch
                          id={"vehicle-consumption-in-hours-switch"}
                          isDisabled={roleId === 4 || roleId === 2}
                          isOn={vehicleConsumptionInHours}
                          onColor={colorSwitchActive}
                          handleToggle={() =>
                            setVehicleConsumptionInHours((prev) => !prev)
                          }
                        />
                        <span style={{ fontSize: "12px" }} id="span-msg">
                          {t("hr_machine_desc")}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  {vehicleConsumptionInHours && (
                    <Row className="py-2">
                      <Col md={8}>
                        <FormGroupLabel id="hourly-consumption-label">
                          {t("hourly_consumption")}
                        </FormGroupLabel>
                        <Controller
                          name="hourlyConsumption"
                          control={control}
                          //errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              id="hourly-consumption-select"
                              options={hourlyConsumptionOptions.current}
                              //value={vehicles.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              //onCreateOption={handleCreate}
                              placeholder={t("hourly_consumption")}
                              styles={formStyles}
                              isDisabled={roleId === 4 || roleId === 2}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />

                        {errors?.hourlyConsumption && (
                          <Error error={errors.hourlyConsumption.message} />
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </FormContainer>
    </>
  );
};

export default OrganizationSettings;
