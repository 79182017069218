import React, { useEffect, useState } from "react";
import { colorBackground, colorHover } from "@/utils/palette";
import { marginRight, right, left } from "@/utils/directions";
import styled from "styled-components";
import { colorBlue, colorTextNew } from "../../../../../utils/palette";
import { TopbarBack } from "../../../../../containers/Layout/components/topbar/BasicTopbarComponents";
import { Button } from "../../../Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getCsvData,
  getFormattedOffset,
  getPdfData,
  getWODataForPdf,
  putApi,
} from "../../../../../api/getUser";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useLocation } from "react-router";
import { getCsvPdfData } from "../../../../constants/defaultColumns";
import { ColumnReOrderButtonContainer } from "../ColumnReorder";
import { Icon } from "../../../TableElements";
import { CSVLink } from "react-csv";
import { NATEXT } from "../../../../constants";
import {
  getDisplayDate,
  getDistanceUnit,
  getListingDisplayDate,
  getScreenName,
} from "../../../../helpers";
import { getTimeSinceAcceptance } from "../../../../../components/WorkOrders";
import SubscribePopup from "../../../SubscribePopup";
import { useAuth } from "../../../../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../../SubscribeTooltipIcon";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import { addSnackbarData } from "../../../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import getUnixTime from "date-fns/getUnixTime";
//import Loading, { LoadIcon } from "../../../Loading";

const MoreOptions = ({ screen, columnOrder, rows, setShowImportPopup }) => {
  let screenName = getScreenName(screen);
  // if (screenName === "renewals") {
  //   screenName = "maintenancereminders";
  // }
  // if (screenName === "service" || screenName === "expense") {
  //   screenName += "s";
  // }
  if (screenName === "work_order") {
    screenName = "workorders";
  }

  const columns = useSelector((state) => state.advancedFilter[screenName]);

  const [toggleOptions, setToggleOptions] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setToggleOptions(!toggleOptions);
  };

  return (
    <div>
      <ColumnReOrderButtonContainer
        id={`more-options-btn-${screen}`}
        variant="outline-secondary"
        size="sm"
        onClick={handleClick}
      >
        {/* <MoreVertIcon /> */}
        {/* <MenuIcon fill='35353B' /> */}
        <Icon className="fi fi-rr-menu-dots-vertical"></Icon>
      </ColumnReOrderButtonContainer>
      {toggleOptions && (
        <TopbarBack
          type="button"
          aria-label="profile button"
          onClick={() => setToggleOptions(!toggleOptions)}
        />
      )}
      {toggleOptions && (
        <OptionsMenus
          columns={columns}
          screen={screen}
          columnOrder={columnOrder}
          rows={rows}
          setShowImportPopup={setShowImportPopup}
          setToggleOptions={setToggleOptions}
        />
      )}
    </div>
  );
};

export default MoreOptions;

const OptionsMenus = ({
  columns,
  screen,
  columnOrder,
  rows,
  setShowImportPopup,
  setToggleOptions,
}) => {
  const dispatch = useDispatch();

  const groupsList = useSelector((state) => state.groups.list);
  const usersList = useSelector((state) => state.users.list);
  const { distance, currency } = useSelector((state) => state.units);
  const myColumns = { ...columns };
  let colOrder = useSelector((state) => state.columns.order);
  const location = useLocation();
  const path = location.pathname;
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const { t } = useTranslation("common");
  const { userName, roleId } = useSelector((state) => state.user);
  const { tag } = useAuth();
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribeMsg, setSubscribeMsg] = useState(t("subscribe_msg"));

  //For pdf and csv generated on the client side (Screens - User Management, Vehicles)
  const [csvData, setCsvData] = useState("");
  const [pdfData, setPdfData] = useState("");

  const moduleName = getScreenName(path);
  const modulesForImport = [
    "users",
    "vehicles",
    "service",
    "expense",
    "servicetasks",
    "expensetasks",
    "inspectiontasks",
    "inspectionforms",
    "dailymileage",
    "fillups",
    "maintenancereminders",
    "renewals",
    "trips",
    "parts",
  ];

  const modulesForCsvPdf = [
    "dailytimesheet",
    "users",
    "vehicles",
    "dailymileage",
    "fillups",
    "service",
    "expense",
    "trips",
    "issues",
    "work_order",
    "maintenancereminders",
    "renewals",
    "inspectionschedules",
    "inspectionsubmissions",
    "parts",
  ];

  const { pdfHeader, defaultColumns, pdfFileName } = getCsvPdfData();

  //Effect to set CSV and PDF Data for Client Side Fetching Screens
  useEffect(() => {
    if (
      screen.includes("users") ||
      screen.includes("vehicles") ||
      screen.includes("dailytimesheet") ||
      screen.includes("inspectionscheduling")
    ) {
      if (columnOrder?.length > 0) {
        const orderLimit = Number(Object.keys(defaultColumns).slice(-1));
        const colOrderLimit = screen.includes("vehicles")
          ? +orderLimit
          : +orderLimit + 1;

        let selectedColumns = colOrder
          .map((c) => +c - 1)
          .filter((col) => +col <= colOrderLimit);

        //Columns in listing is 5 but in pdf, csv is 6 - Hence setting manually
        if (screen?.includes("inspectionscheduling")) {
          selectedColumns = [0, 1, 2, 3, 4, 5];
        }

        let tempCsvArr = [];
        let tempPdfArr = [];
        rows?.forEach((row) => {
          let tempCsvObj = {};
          let tempPdfObj = {};

          //Logic to set csv and pdf objs for users
          if (screen.includes("users")) {
            selectedColumns.forEach((no) => {
              const currentCol = defaultColumns[no];
              if (currentCol) {
                const { accessor, header } = currentCol;
                if (accessor === "user_active") {
                  tempCsvObj[header] =
                    row.original[accessor] === 1 ? t("active") : t("inactive");

                  tempPdfObj[accessor] =
                    row.original[accessor] === 1 ? t("active") : t("inactive");
                } else if (accessor === "joining_date") {
                  let joiningDate = row.original[accessor]
                    ? getListingDisplayDate(row.original[accessor])
                    : NATEXT;

                  tempCsvObj[header] = joiningDate;
                  tempPdfObj[accessor] = joiningDate;
                } else {
                  tempCsvObj[header] = row.original[accessor]
                    ? row.original[accessor]
                    : NATEXT;

                  tempPdfObj[accessor] = row.original[accessor]
                    ? row.original[accessor]
                    : NATEXT;
                }
              }
            });
          }

          //Logic to set csv and pdf objs for vehicles
          if (screen.includes("vehicles")) {
            selectedColumns.forEach((no) => {
              const currentCol = defaultColumns[no];
              const { accessor, header } = currentCol;
              if (accessor === "veh_active") {
                let vehicleStatus = NATEXT;
                if (row.original[accessor] == 1) {
                  vehicleStatus = t("active");
                } else {
                  vehicleStatus = t("inactive");
                }

                tempCsvObj[header] = vehicleStatus;
                tempPdfObj[accessor] = vehicleStatus;
              } else if (accessor === "operational_status") {
                const { veh_active, secondary_status } = row.original;
                let secondaryStatus = t("in_service");

                if (veh_active === 0 && secondary_status === 1) {
                  secondaryStatus = t("out_of_service");
                }

                if (
                  (veh_active === 0 && secondary_status === 0) ||
                  (veh_active === 2 && secondary_status === 0)
                ) {
                  secondaryStatus = NATEXT;
                }

                if (veh_active === 0 && secondary_status === 2) {
                  secondaryStatus = t("unconfirmed");
                }

                tempCsvObj[header] = secondaryStatus;
                tempPdfObj[accessor] = secondaryStatus;
              } else if (accessor === "group_id") {
                let selectedGroup = NATEXT;
                if (row.original[accessor]) {
                  selectedGroup = groupsList.find(
                    (g) => g.group_id === row.original[accessor]
                  )?.group_name;
                }
                tempCsvObj[header] = selectedGroup;
                tempPdfObj[accessor] = selectedGroup;
              } else if (accessor === "odo") {
                let currentOdo = NATEXT;
                //No odo unit in csv
                let currentOdoVal = NATEXT;
                if (row.original[accessor]) {
                  let distanceUnit = getDistanceUnit(distance);
                  currentOdo = `${row.original[accessor]} ${distanceUnit}`;
                  currentOdoVal = row.original[accessor];
                }
                tempCsvObj[header] = currentOdoVal;
                tempPdfObj[accessor] = currentOdo;
              } else {
                tempCsvObj[header] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;

                tempPdfObj[accessor] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;
              }
            });
          }

          //Logic to set csv and pdf objs for daily time sheet
          if (screen.includes("dailytimesheet")) {
            selectedColumns.forEach((no) => {
              const currentCol = defaultColumns[no];
              const { accessor, header } = currentCol;
              if (accessor === "timesheet_date") {
                let displayDate = getDisplayDate(row.original[accessor]);
                tempCsvObj[header] = displayDate;
                tempPdfObj[accessor] = displayDate;
              } else {
                tempCsvObj[header] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;

                tempPdfObj[accessor] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;
              }
            });
          }

          //Logic to set csv and pdf objs for inspection schedule
          if (screen.includes("inspectionscheduling")) {
            const distanceUnit =
              row.original.primary_meter === "Hours"
                ? getDistanceUnit("Hours")
                : getDistanceUnit(distance);
            selectedColumns.forEach((no) => {
              const currentCol = defaultColumns[no];
              const { accessor, header } = currentCol;
              //Status
              if (accessor === "status") {
                const { percentage } = row.original;
                let status = t("scheduled_caps");
                let color = "#5CB85C";
                if (percentage <= 90) {
                  status = t("scheduled_caps");
                  color = "#5CB85C";
                } else if (percentage > 90 && percentage < 100) {
                  status = t("due_soon_caps");
                  color = "#F0AD4E";
                } else if (percentage >= 100) {
                  status = t("overdue_caps");
                  color = "#D9534F";
                }
                tempCsvObj[header] = status;
                tempPdfObj[accessor] = status;
              } else if (accessor === "due_in") {
                //dueInDays
                let dueInDays = 0;
                const { due_days, last_inspection_date } = row.original;
                if (due_days) {
                  let due = last_inspection_date + Number(due_days) * 86400;
                  const currentDate = getUnixTime(new Date());
                  dueInDays =
                    ((due - currentDate) / (60 * 60 * 24)).toFixed(0) || 0;
                }

                let maxOdo = row.original.maxOdo || 0;
                //dueInOdo
                const dueInOdo =
                  +row.original.last_inspection_odo && +row.original.due_odo
                    ? (
                        +row.original.last_inspection_odo +
                        +row.original.due_odo -
                        +maxOdo
                      )?.toFixed(2)
                    : 0;

                //dueInText
                let dueIn = NATEXT;
                if (+row.original.percentage < 100) {
                  if (dueInDays && +dueInOdo > 0) {
                    dueIn = `${dueInOdo} ${distanceUnit} ${t(
                      "or"
                    )} ${dueInDays} ${t("days_sm_other")}`;
                  } else if (!dueInDays && +dueInOdo > 0) {
                    dueIn = `${dueInOdo} ${distanceUnit}`;
                  } else if (dueInDays && +dueInOdo <= 0) {
                    dueIn = `${dueInDays} ${t("days_sm_other")}`;
                  }
                }
                tempCsvObj[header] = dueIn;
                tempPdfObj[accessor] = dueIn;
              } else if (accessor === "due_on") {
                //dueOnDate
                let dueOnDate = 0;
                const { last_inspection_date, due_days } = row.original;
                if (due_days) {
                  let lastInspectionDate = new Date(
                    last_inspection_date * 1000
                  );
                  dueOnDate = new Date(last_inspection_date * 1000);
                  dueOnDate.setDate(lastInspectionDate.getDate() + +due_days);
                }

                //dueOnOdo
                let dueOnOdo = 0;
                const { last_inspection_odo, due_odo } = row.original;
                if (+due_odo) {
                  dueOnOdo = +last_inspection_odo + +due_odo;
                }

                //dueOnText
                let dueOn = NATEXT;
                if (dueOnDate && dueOnOdo) {
                  dueOn = `${dueOnOdo} ${distanceUnit} ${t("or")} ${format(
                    dueOnDate,
                    "yyyy-MM-dd hh:mm:ss"
                  )}`;
                } else if (!dueOnDate && dueOnOdo) {
                  dueOn = `${dueOnOdo} ${distanceUnit}`;
                } else if (dueOnDate && !dueOnOdo) {
                  dueOn = `${format(dueOnDate, "yyyy-MM-dd hh:mm:ss")}`;
                }
                tempCsvObj[header] = dueOn;
                tempPdfObj[accessor] = dueOn;
              } else if (accessor === "last_inspected") {
                const lastInspectedOdo = +row.original.last_inspection_odo
                  ? `${row.original.last_inspection_odo} ${distanceUnit}`
                  : 0;
                const lastInspectedDate = row.original.last_inspection_date
                  ? format(
                      new Date(row.original.last_inspection_date * 1000),
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : 0;
                let lastInspected = `${lastInspectedOdo} / ${lastInspectedDate}`;
                if (lastInspectedOdo && !lastInspectedDate) {
                  lastInspected = lastInspectedOdo;
                }

                if (!lastInspectedOdo && lastInspectedDate) {
                  lastInspected = lastInspectedDate;
                }

                tempCsvObj[header] = lastInspected;
                tempPdfObj[accessor] = lastInspected;
              } else {
                tempCsvObj[header] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;

                tempPdfObj[accessor] = row.original[accessor]
                  ? row.original[accessor]
                  : NATEXT;
              }
            });
          }

          tempCsvArr.push(tempCsvObj);
          tempPdfArr.push(tempPdfObj);
        });
        setCsvData(tempCsvArr);
        setPdfData(tempPdfArr);
      }
    }
  }, [columnOrder, rows]);

  const {
    start_date,
    end_date,
    search,
    order,
    filter_vehicle_id,
    filter_group_id,
    start,
  } = useSelector((state) => state.filter.data);
  const searchValue = search.value;
  const orderArray = [order[0].column, order[0].dir];

  const csvApiData = {
    start_date,
    end_date,
    search: searchValue,
    order: [orderArray],
    filter_vehicle_id,
    filter_group_id,
    utc_offset: getFormattedOffset(),
  };

  if (screen.includes("dailymileage")) {
    colOrder = [1, 2, 3, 4, 5, 6, 7, 8];
  }
  if (screen.includes("maintenancereminders") || screen.includes("renewals")) {
    colOrder = [1, 2, 3, 4];
  }
  if (screen.includes("inspectionsubmissions")) {
    colOrder = [1, 2, 3, 4, 5];
  }
  if (
    screen.includes("users") ||
    screen.includes("vehicles") ||
    screen.includes("dailytimesheet")
  ) {
    colOrder = columnOrder;
  }

  const generateWOData = async () => {
    const apiJson = {
      draw: 1,
      length: 100,
      search: search,
      order: order,
      start_date: start_date,
      end_date: end_date,
      start: start,
      filter_group_id: filter_group_id,
      filter_vehicle_id: filter_vehicle_id,
      querystring: "get_all_workorder_data",
      ...columns,
    };
    const res = await getWODataForPdf(apiJson);
    const { data, pause_data } = res;
    const selectedColumns = colOrder
      .filter((no) => no <= 16)
      .map((c) => +c - 1);

    let tempCsvArr = [];
    let tempPdfArr = [];
    data?.forEach((row) => {
      let tempCsvObj = {};
      let tempPdfObj = {};
      selectedColumns.forEach((no) => {
        const currentCol = defaultColumns[no];
        const { accessor, header } = currentCol;
        if (
          accessor === "created_by"
          //|| accessor === "assigned_to"
        ) {
          let selectedUser = NATEXT;
          if (row[accessor]) {
            selectedUser = usersList.find(
              (g) => g.user_id === row[accessor]
            )?.name;
          }
          tempCsvObj[header] = selectedUser;
          tempPdfObj[accessor] = selectedUser;
        } else if (accessor === "type") {
          let type = row[accessor] == 1 ? t("unscheduled") : t("scheduled");
          tempCsvObj[header] = type;
          tempPdfObj[accessor] = type;
        } else if (accessor === "status") {
          let status = NATEXT;
          if (row[accessor] === "new") {
            status = t("new");
          } else if (row[accessor] == 0) {
            status = t("drawer_open");
          } else if (row[accessor] == 1) {
            status = t("in_progress");
          } else if (row[accessor] == 2) {
            status = t("paused");
          } else if (row[accessor] == 3) {
            status = t("closed");
          }
          tempCsvObj[header] = status;
          tempPdfObj[accessor] = status;
        } else if (accessor === "odo") {
          let odo =
            row[accessor] && row[accessor] != -1000
              ? `${row[accessor]} ${getDistanceUnit(distance)}`
              : NATEXT;
          //No odo unit in csv
          let odoVal = row[accessor];
          tempCsvObj[header] = odoVal && odoVal !== -1000 ? odoVal : NATEXT;
          tempPdfObj[accessor] = odo;
        } else if (accessor === "priority") {
          let priority = t("not_set");

          if (row[accessor] == 0) {
            priority = t("not_set");
          }
          if (row[accessor] == 1) {
            priority = t("low");
          }
          if (row[accessor] == 2) {
            priority = t("med");
          }
          if (row[accessor] == 3) {
            priority = t("high");
          }

          tempCsvObj[header] = priority;
          tempPdfObj[accessor] = priority;
        } else if (accessor === "due_date" || accessor === "create_date") {
          let dateVal = row[accessor] ? getDisplayDate(row[accessor]) : NATEXT;
          tempCsvObj[header] = dateVal;
          tempPdfObj[accessor] = dateVal;
        } else if (accessor === "total_cost") {
          let totalCost = NATEXT;
          const { discount, tax, total } = row;
          //const costObj = JSON.parse(row.total_cost);
          totalCost = +total - +discount + +tax;
          totalCost = totalCost ? `${totalCost} ${currency}` : NATEXT;
          tempCsvObj[header] = totalCost;
          tempPdfObj[accessor] = totalCost;
        } else if (accessor === "duration") {
          let duration = NATEXT;
          const {
            status,
            technician_id,
            start_date,
            end_date,
            starting_time,
            ending_time,
            working_days,
            wo_id,
            total_time,
          } = row;

          if ((status === 1 || status === 2) && start_date) {
            let pausesForRecord = pause_data?.filter((p) => p.wo_id === wo_id);
            const incompletePause = pausesForRecord?.find((p) => !p.end_time);
            let completedPauses = [];
            if (pausesForRecord?.length > 0) {
              completedPauses = pausesForRecord?.filter((p) => p.end_time);
            }

            // let technician = "";
            // if (technician_id) {
            //   technician = usersList?.find(
            //     (u) => u.user_id === technician_id
            //   );
            // }

            let startTime = new Date(start_date * 1000);

            let endTime = new Date();
            if (incompletePause) {
              endTime = new Date(incompletePause.start_time * 1000);
            }

            let schedule = working_days ? working_days : "1:2:3:4:5:6:7";
            let scheduleStartTime = starting_time
              ? starting_time.slice(0, 5)
              : "00:00";
            let scheduleEndTime = ending_time
              ? ending_time.slice(0, 5)
              : "23:59";

            let timeSinceAcceptance = getTimeSinceAcceptance(
              startTime,
              endTime,
              scheduleStartTime,
              scheduleEndTime,
              schedule,
              completedPauses
            );

            duration = timeSinceAcceptance.display;
          } else {
            duration = NATEXT;
          }
          tempCsvObj[header] = duration ? duration : NATEXT;
          tempPdfObj[accessor] = duration ? duration : NATEXT;
        } else {
          tempCsvObj[header] = row[accessor] ? row[accessor] : NATEXT;

          tempPdfObj[accessor] = row[accessor] ? row[accessor] : NATEXT;
        }
      });

      tempCsvArr.push(tempCsvObj);
      tempPdfArr.push(tempPdfObj);
    });

    //setCsvData(tempCsvArr);
    //setPdfData(tempPdfArr);
    return { csvArr: tempCsvArr, pdfArr: tempPdfArr };
  };

  //Effect to set CSV and PDF Data for work orders
  useEffect(() => {
    if (screen.includes("workorders") && colOrder?.length > 0) {
      (async () => {
        const apiJson = {
          draw: 1,
          length: 100,
          search: search,
          order: order,
          start_date: start_date,
          end_date: end_date,
          start: start,
          filter_group_id: filter_group_id,
          filter_vehicle_id: filter_vehicle_id,
          querystring: "get_all_workorder_data",
          ...columns,
        };
        const res = await getWODataForPdf(apiJson);
        const { data, pause_data } = res;
        const selectedColumns = colOrder
          .filter((no) => no <= 16)
          .map((c) => +c - 1);

        let tempCsvArr = [];
        let tempPdfArr = [];
        data?.forEach((row) => {
          let tempCsvObj = {};
          let tempPdfObj = {};
          selectedColumns.forEach((no) => {
            const currentCol = defaultColumns[no];
            const { accessor, header } = currentCol;
            if (
              accessor === "created_by"
              //|| accessor === "assigned_to"
            ) {
              let selectedUser = NATEXT;
              if (row[accessor]) {
                selectedUser = usersList.find(
                  (g) => g.user_id === row[accessor]
                )?.name;
              }
              tempCsvObj[header] = selectedUser;
              tempPdfObj[accessor] = selectedUser;
            } else if (accessor === "type") {
              let type = row[accessor] == 1 ? t("unscheduled") : t("scheduled");
              tempCsvObj[header] = type;
              tempPdfObj[accessor] = type;
            } else if (accessor === "status") {
              let status = NATEXT;
              if (row[accessor] === "new") {
                status = t("new");
              } else if (row[accessor] == 0) {
                status = t("drawer_open");
              } else if (row[accessor] == 1) {
                status = t("in_progress");
              } else if (row[accessor] == 2) {
                status = t("paused");
              } else if (row[accessor] == 3) {
                status = t("closed");
              }
              tempCsvObj[header] = status;
              tempPdfObj[accessor] = status;
            } else if (accessor === "odo") {
              let odo =
                row[accessor] && row[accessor] != -1000
                  ? `${row[accessor]} ${getDistanceUnit(distance)}`
                  : NATEXT;
              //No odo unit in csv
              let odoVal = row[accessor];
              tempCsvObj[header] = odoVal && odoVal !== -1000 ? odoVal : NATEXT;
              tempPdfObj[accessor] = odo;
            } else if (accessor === "priority") {
              let priority = t("not_set");
              // switch (row[accessor]) {
              //   case 0:
              //     priority = t("not_set");
              //   case 1:
              //     priority = t("low");
              //   case 2:
              //     priority = t("med");
              //   case 3:
              //     priority = t("high");
              //   default:
              //     priority = t("not_set");
              // }

              if (row[accessor] == 0) {
                priority = t("not_set");
              }
              if (row[accessor] == 1) {
                priority = t("low");
              }
              if (row[accessor] == 2) {
                priority = t("med");
              }
              if (row[accessor] == 3) {
                priority = t("high");
              }

              tempCsvObj[header] = priority;
              tempPdfObj[accessor] = priority;
            } else if (accessor === "due_date" || accessor === "create_date") {
              let dateVal = row[accessor]
                ? getDisplayDate(row[accessor])
                : NATEXT;
              tempCsvObj[header] = dateVal;
              tempPdfObj[accessor] = dateVal;
            } else if (accessor === "total_cost") {
              let totalCost = NATEXT;
              const { discount, tax, total } = row;
              //const costObj = JSON.parse(row.total_cost);
              totalCost = +total - +discount + +tax;
              totalCost = totalCost ? `${totalCost} ${currency}` : NATEXT;
              tempCsvObj[header] = totalCost;
              tempPdfObj[accessor] = totalCost;
            } else if (accessor === "duration") {
              let duration = NATEXT;
              const {
                status,
                technician_id,
                start_date,
                end_date,
                starting_time,
                ending_time,
                working_days,
                wo_id,
                total_time,
              } = row;

              if ((status === 1 || status === 2) && start_date) {
                let pausesForRecord = pause_data?.filter(
                  (p) => p.wo_id === wo_id
                );
                const incompletePause = pausesForRecord?.find(
                  (p) => !p.end_time
                );
                let completedPauses = [];
                if (pausesForRecord?.length > 0) {
                  completedPauses = pausesForRecord?.filter((p) => p.end_time);
                }

                // let technician = "";
                // if (technician_id) {
                //   technician = usersList?.find(
                //     (u) => u.user_id === technician_id
                //   );
                // }

                let startTime = new Date(start_date * 1000);

                let endTime = new Date();
                if (incompletePause) {
                  endTime = new Date(incompletePause.start_time * 1000);
                }

                let schedule = working_days ? working_days : "1:2:3:4:5:6:7";
                let scheduleStartTime = starting_time
                  ? starting_time.slice(0, 5)
                  : "00:00";
                let scheduleEndTime = ending_time
                  ? ending_time.slice(0, 5)
                  : "23:59";

                let timeSinceAcceptance = getTimeSinceAcceptance(
                  startTime,
                  endTime,
                  scheduleStartTime,
                  scheduleEndTime,
                  schedule,
                  completedPauses
                );

                duration = timeSinceAcceptance.display;
              } else {
                duration = NATEXT;
              }
              tempCsvObj[header] = duration;
              tempPdfObj[accessor] = duration;
            } else {
              tempCsvObj[header] = row[accessor] ? row[accessor] : NATEXT;
              tempPdfObj[accessor] = row[accessor] ? row[accessor] : NATEXT;
            }
          });

          tempCsvArr.push(tempCsvObj);
          tempPdfArr.push(tempPdfObj);
        });

        setCsvData(tempCsvArr);
        setPdfData(tempPdfArr);
      })();
    }
  }, [colOrder]);

  const handleExportCsv = async () => {
    if (process.env.REACT_APP_ENV === "prod") {
      // window.gtag("send", "event", `${getScreenName(screen)}_csv`, "Clicked");
      window.gtag("event", `${getScreenName(screen)}_csv`);
    }
    if (tag === "free-user") {
      setSubscribeMsg(t("subscribe_msg"));
      setShowSubscribePopup(true);
      return;
    }

    setLoading(true);
    const data = { ...csvApiData, ...myColumns };
    const csvRes = await getCsvData(data, screen);

    if (csvRes.error) {
      setLoading(false);
      dispatch(addSnackbarData({ msg: t("listing_err_msg"), type: 2 }));
      return;
    }

    setLoading(false);

    window.open(csvRes?.url);
  };

  const handleExportPdf = async () => {
    if (process.env.REACT_APP_ENV === "prod") {
      //window.gtag("send", "event", `${getScreenName(screen)}_pdf`, "Clicked");
      window.gtag("event", `${getScreenName(screen)}_pdf`);
    }
    if (tag === "free-user") {
      setSubscribeMsg(t("subscribe_msg"));
      setShowSubscribePopup(true);
      return;
    }
    setPdfLoading(true);
    if (
      screen.includes("users") ||
      screen.includes("vehicles") ||
      screen.includes("workorders") ||
      screen.includes("dailytimesheet") ||
      screen.includes("inspectionscheduling")
    ) {
      const columns = [];
      const orderLimit = Number(Object.keys(defaultColumns).slice(-1));

      let order = [...colOrder].filter((no) => no <= +orderLimit + 1);

      //Columns in listing is 5 but in pdf, csv is 6 - Hence setting manually
      if (screen?.includes("inspectionscheduling")) {
        order = [1, 2, 3, 4, 5, 6];
      }

      order.forEach((value) => {
        columns.push({
          header: defaultColumns[value - 1].header,
          dataKey: defaultColumns[value - 1].accessor,
        });
      });
      if (screen.includes("workorders")) {
        (async () => {
          if (pdfData && pdfData?.length > 0) {
            generatePdfNew(pdfData, columns);
          } else {
            const { pdfArr } = await generateWOData();
            generatePdfNew(pdfArr, columns);
          }
        })();
      } else {
        generatePdfNew(pdfData, columns);
      }
    } else {
      const data = { ...csvApiData, ...myColumns };
      const { rows, error } = await getPdfData(data, screen);

      if (error) {
        setPdfLoading(false);
        dispatch(addSnackbarData({ msg: t("listing_err_msg"), type: 2 }));
        return;
      }
      const columns = [];
      const orderLimit = Number(Object.keys(defaultColumns).slice(-1));
      if (
        screen.includes("maintenancereminders") ||
        screen.includes("renewals")
      ) {
        const remindersCols = [
          { header: t("vehicle"), dataKey: "vehicleName" },
          { header: t("task"), dataKey: "taskName" },
          { header: t("status"), dataKey: "status" },
          { header: t("due_in"), dataKey: "dueIn" },
          { header: t("due_on"), dataKey: "dueOn" },
          { header: t("last_serviced"), dataKey: "lastServiced" },
        ];
        let reminderRows = [];
        rows.forEach((row) => {
          const { PERCENTAGE } = row;
          const vehicleName = row.NAME;
          const taskName = screen.includes("maintenancereminders")
            ? row.service_task_name
            : row.expense_task_name;
          const distanceUnit =
            row.primary_meter === "Hours"
              ? getDistanceUnit("Hours")
              : getDistanceUnit(distance);
          const lastServicedOdo = +row.last_odo_of_service
            ? `${row.last_odo_of_service} ${distanceUnit}`
            : 0;
          const lastServicedDate = row.last_date_of_service
            ? row.last_date_of_service
            : 0;
          let lastServiced = `${lastServicedOdo} / ${lastServicedDate}`;
          if (lastServicedOdo && !lastServicedDate) {
            lastServiced = lastServicedOdo;
          }

          if (!lastServicedOdo && lastServicedDate) {
            lastServiced = lastServicedDate;
          }

          //dueInDays
          const dueInDays =
            +row["DUE IN"] && +row.due_days
              ? Math.round(Number(row["DUE IN"]))
              : 0;

          //dueInOdo
          const dueInOdo = +row.due_odo
            ? Math.abs(row["MAX ODO"] - row["DUE ODO"])?.toFixed(2)
            : 0;

          //dueInText
          let dueIn = NATEXT;
          if (+row.PERCENTAGE < 100) {
            if (dueInDays && +dueInOdo > 0) {
              dueIn = `${dueInOdo} ${distanceUnit} ${t("or")} ${dueInDays} ${t(
                "days_sm_other"
              )}`;
            } else if (!dueInDays && +dueInOdo > 0) {
              dueIn = `${dueInOdo} ${distanceUnit}`;
            } else if (dueInDays && +dueInOdo <= 0) {
              dueIn = `${dueInDays} ${t("days_sm_other")}`;
            }
          }

          //dueOnDate
          let dueOnDate = 0;
          if (dueInDays > 0 && +row.due_days) {
            dueOnDate = addDays(new Date(), dueInDays);
          }

          //dueOnOdo
          let dueOnOdo = 0;
          if (+row.due_odo) {
            dueOnOdo = +row["DUE ODO"];
          }

          //dueOnText
          let dueOn = NATEXT;
          if (dueOnDate && dueOnOdo) {
            dueOn = `${dueOnOdo} ${distanceUnit} ${t("or")} ${format(
              dueOnDate,
              "yyyy-MM-dd hh:mm:ss"
            )}`;
          } else if (!dueOnDate && dueOnOdo) {
            dueOn = `${dueOnOdo} ${distanceUnit}`;
          } else if (dueOnDate && !dueOnOdo) {
            dueOn = `${format(dueOnDate, "yyyy-MM-dd hh:mm:ss")}`;
          }

          //Status
          let status = t("scheduled_caps");
          let color = "#5CB85C";
          if (PERCENTAGE <= 90) {
            status = t("scheduled_caps");
            color = "#5CB85C";
          } else if (PERCENTAGE > 90 && PERCENTAGE < 100) {
            status = t("due_soon_caps");
            color = "#F0AD4E";
          } else if (PERCENTAGE >= 100) {
            status = t("overdue_caps");
            color = "#D9534F";
          }

          reminderRows.push({
            vehicleName,
            taskName,
            status,
            dueIn,
            dueOn,
            lastServiced,
          });
        });

        generatePdfNew(reminderRows, remindersCols);
      } else {
        colOrder
          .filter((no) => no <= orderLimit + 1)
          .forEach((value) => {
            columns.push({
              header: defaultColumns[value - 1].header,
              dataKey: defaultColumns[value - 1].accessor,
            });
          });
        generatePdfNew(rows, columns);
      }
    }
  };

  const generatePdfNew = (rows, columns) => {
    let pdfsize = "a0";
    if (columns.length > 11) {
      pdfsize = "a2";
    }
    if (columns.length >= 7 && columns.length <= 11) {
      pdfsize = "a3";
    }
    if (columns.length < 7) {
      pdfsize = "a4";
    }

    const dataKeyArr = columns.map((col) => col?.dataKey);

    // if (
    //   dataKeyArr?.includes("service_task_name") ||
    //   dataKeyArr?.includes("expense_task_name")
    // ) {
    //   pdfsize = "a3";
    // }
    let colStyles = {};
    columns.forEach((col, i) => {
      colStyles[i] = { cellWidth: "auto", minCellWidth: 100 };
    });

    const doc = new jsPDF("l", "pt", pdfsize);
    doc.setDisplayMode("original", "continuous", "UseOutlines");
    doc.text(pdfHeader, 40, 40);
    doc.setFontSize(11);
    const text = t("report_gen_by_pdf_text", { userName });
    doc.text(text, 40, 70);
    autoTable(doc, {
      //tableWidth: 200,
      startY: 100,
      theme: "grid",

      bodyStyles: {
        valign: "top",
      },
      //styles: {
      //minCellWidth: 100,
      //overflow: "ellipsize",
      //cellWidth: "auto",
      //font: "MyFont",
      //},
      columnStyles: {
        columns: { cellWidth: "auto" },
      },

      headStyles: {
        fillColor: "#2D4154",
        height: "100px",
      },
      body: rows,
      columns: columns,
      didDrawPage: function () {
        var str = `${t("created_on")}: ` + Date();
        doc.text(str, 40, doc.internal.pageSize.height - 10);
      },
    });
    doc.save(`sf_${pdfFileName}_${new Date().toISOString().split("T")[0]}`);
    setPdfLoading(false);
  };

  const handleImport = () => {
    if (process.env.REACT_APP_ENV === "prod") {
      // window.gtag(
      //   "send",
      //   "event",
      //   `${getScreenName(screen)}_import`,
      //   "Clicked"
      // );
      window.gtag("event", `${getScreenName(screen)}_import`);
    }
    if (tag === "free-user") {
      setSubscribeMsg(t("subscribe_msg"));
      setShowSubscribePopup(true);
      return;
    }

    if (tag === "essential-user" && screen?.includes("parts")) {
      setSubscribeMsg(t("subscribe_to_advanced_msg"));
      setShowSubscribePopup(true);
      return;
    }
    setShowImportPopup(true);

    setToggleOptions(false);
    // setImportData({
    //   show: true,

    //   data: { modalHeading: "Import Daily Mileage into Simply Fleet" },
    // });
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribeMsg}
        />
      )}
      {/* {showImportPopup && (
        <ImportComponent
          showPopup={showImportPopup}
          setShowPopup={setShowImportPopup}
          modalHeading={'Import Daily Mileage into Simply Fleet'}
        />
      )} */}
      <OptionsMenuWrap id="more-options">
        {modulesForCsvPdf.includes(moduleName) && (
          <>
            <OptionsMenu
              onClick={async () => await handleExportPdf()}
              id="export-pdf-button"
            >
              {pdfLoading ? (
                <Loading loading={true} />
              ) : (
                <>
                  <span>{t("export_pdf")}</span>
                  {tag === "free-user" && (
                    <SubscribeTooltipIcon message={t("subscribe_msg")} />
                  )}
                </>
              )}
            </OptionsMenu>
            {!screen.includes("users") &&
              !screen.includes("vehicles") &&
              !screen.includes("workorders") &&
              !screen.includes("dailytimesheet") &&
              !screen.includes("inspectionscheduling") && (
                <OptionsMenu
                  onClick={async () => await handleExportCsv()}
                  id="export-csv-button"
                >
                  {loading ? (
                    <Loading loading={true} />
                  ) : (
                    <>
                      <span>{t("export_csv")}</span>
                      {tag === "free-user" && (
                        <div className="d-flex justify-content-end">
                          <SubscribeTooltipIcon message={t("subscribe_msg")} />
                        </div>
                      )}
                    </>
                  )}
                </OptionsMenu>
              )}
            {(screen.includes("users") ||
              screen.includes("vehicles") ||
              screen.includes("workorders") ||
              screen.includes("dailytimesheet") ||
              screen.includes("inspectionscheduling")) && (
              <CSVLink
                data={csvData}
                filename="simply-fleet-csv"
                //onClick={() => setLoading(true)}
                //onLoadedData={() => setLoading(false)}
              >
                <OptionsMenu
                  // onClick={() => {
                  //   // if (process.env.ENV === "prod") {
                  //   window.gtag(
                  //     "send",
                  //     "event",
                  //     `${getScreenName(screen)}_csv`,
                  //     "Clicked"
                  //   );
                  //   // }
                  // }}
                  //onClick={async () => await handleExportCsv()}
                  onClick={(e) => {
                    if (process.env.REACT_APP_ENV === "prod") {
                      // window.gtag(
                      //   "send",
                      //   "event",
                      //   `${getScreenName(screen)}_csv`,
                      //   "Clicked"
                      // );
                      window.gtag("event", `${getScreenName(screen)}_csv`);
                    }
                    if (tag === "free-user") {
                      e.preventDefault();
                      setSubscribeMsg(t("subscribe_msg"));
                      setShowSubscribePopup(true);
                      return;
                    }
                  }}
                  id="export-csv-button"
                >
                  {loading ? (
                    <Loading loading={true} />
                  ) : (
                    <>
                      <span>{t("export_csv")} </span>
                      {tag === "free-user" && (
                        <SubscribeTooltipIcon message={t("subscribe_msg")} />
                      )}
                    </>
                  )}
                </OptionsMenu>
              </CSVLink>
            )}
          </>
        )}
        {modulesForImport.includes(moduleName) && roleId != 4 && (
          <OptionsMenu id="import-button" onClick={handleImport}>
            <span>{t("import")}</span>
            {tag === "free-user" && (
              <SubscribeTooltipIcon message={t("subscribe_msg")} />
            )}
            {tag === "essential-user" && screen?.includes("parts") && (
              <SubscribeTooltipIcon message={t("subscribe_to_advanced_msg")} />
            )}
          </OptionsMenu>
        )}
      </OptionsMenuWrap>
    </>
  );
};

const Loading = () => {
  return (
    <LoaderWrap>
      <Loader>
        <path fill={colorBlue} d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </Loader>
    </LoaderWrap>
  );
};

// styles

export const OptionsMenuWrap = styled.div`
  z-index: 100;
  position: absolute;
  width: 120px;
  padding: 0;
  //top: 125px;
  //${right}: 20px;
  right: 24px;
  border: 1px solid lightgray;
  background: white;

  @media screen and (max-width: 320px) {
    //${right}: -50px;
  }
`;

export const OptionsMenu = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  white-space: nowrap;
  padding: 8px;
  margin-top: 0;
  background: ${colorBackground};
  color: ${colorTextNew};

  &:hover {
    color: ${colorBlue};
  }

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`;

export const MoreOptionsButtonContainer = styled(Button)`
  margin-right: 0px;
  padding: 4px 8px;
  height: 35px;

  @media screen and (max-width: 480px) {
    ${marginRight}: 0;
  }
`;

const Loader = styled.svg`
  animation: linear load 2s infinite;
  margin: auto;
  width: 26px;
  height: 26px;

  @keyframes load {
    from {
      transform: rotate(0deg) scale(0.75);
    }
    to {
      transform: rotate(360deg) scale(0.75);
    }
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: auto;
  height: auto;
`;
