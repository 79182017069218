import React, { useState } from "react";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import { formInputStyles } from "../../shared/constants/styles";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../shared/components/TableElements";
import { scrollbarStyles } from "../../utils/palette";
import styled from "styled-components";
import { useEffect } from "react";

import { updatePassword } from "firebase/auth";
import { auth } from "../../api/firebase";
import { emailPattern } from "../../shared/helpers";
import { useHistory } from "react-router";
import { logErrors, postApi, urlConfig } from "../../api/getUser";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";

const UpdatePasswordForm = ({ updatePWData, setUpdatePWData }) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");
  //const dispatch = useDispatch();
  const history = useHistory();
  //const location = useLocation();

  const email = watch("email");
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const { setSignUp } = useAuth();

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //console.log(updatePWData);

  //Set Email
  useEffect(() => {
    setValue("email", updatePWData?.email);
  }, []);

  const signIn = async () => {
    setSignUp(true);
    const apiData = {
      querystring: "check_user_email_v2",
      email: email,
    };
    const res = await axios.post(urlConfig.getInfoV2, apiData);
    const checkUserEmailRes = res.data[0];
    if (!checkUserEmailRes?.success) {
      setError(
        "email",
        {
          type: "custom",
          message: errMsg,
        },
        { shouldFocus: true }
      );
      setSaveBtnLoading(false);
      const errLogData = {
        url: urlConfig.getInfoV2,
        method_type: "POST",
        error: JSON.stringify(checkUserEmailRes),
        request: JSON.stringify(apiData),
        placeholder: "",
      };
      await logErrors(errLogData);
      return;
    }

    if (checkUserEmailRes?.success) {
      const { code } = checkUserEmailRes;
      if (code != 10) {
        let errMsg = t("listing_err_msg");
        if (code == 11) {
          errMsg = t("email_not_found_msg");
        }
        if (code == 12) {
          errMsg = t("acc_deleted_err");
        }
        if (code == 13) {
          errMsg = t("acc_deactivated_err");
        }
        setError(
          "email",
          {
            type: "custom",
            message: errMsg,
          },
          { shouldFocus: true }
        );
        setSaveBtnLoading(false);
        const errLogData = {
          url: urlConfig.getInfoV2,
          method_type: "POST",
          error: JSON.stringify(checkUserEmailRes),
          request: JSON.stringify(apiData),
          placeholder: "",
        };
        await logErrors(errLogData);
        return;
      }
    }

    if (checkUserEmailRes.success && checkUserEmailRes?.code == 10) {
      //Check if sign in user is operator
      const {
        meta_data: { role_id },
      } = checkUserEmailRes;

      if (!role_id || role_id == 3) {
        setError(
          "email",
          {
            type: "custom",
            message: t("not_authorized_to_login_err_msg"),
          },
          { shouldFocus: true }
        );
        setSaveBtnLoading(false);
        const errLogData = {
          url: urlConfig.getInfoV2,
          method_type: "POST",
          error: JSON.stringify(checkUserEmailRes),
          request: JSON.stringify(apiData),
          placeholder: "",
        };
        await logErrors(errLogData);
        return;
      }

      let notificationsApiData = {
        querystring: "createnotifications",
        pagesource: "login",
      };
      postApi(notificationsApiData, "commonNew");
      setSignUp(false);
      localStorage.setItem("logout", false);

      if (role_id === 4) {
        history.push("/workorders", { fromLogin: true });
      } else if (role_id == 1 || role_id == 2) {
        history.push("/dashboard", { fromLogin: true });
      }
    }
    // else {
    //   let errorMessage = "";
    //   if (user_data === "email not found") {
    //     errorMessage = t("email_not_found_msg");
    //     setError(
    //       "email",
    //       {
    //         type: user_data,
    //         message: errorMessage,
    //       },
    //       { shouldFocus: true }
    //     );
    //   } else if (user_data === "No_auth") {
    //     errorMessage = t("not_authorized_to_login_err_msg");
    //     setError(
    //       "email",
    //       {
    //         type: user_data,
    //         message: errorMessage,
    //       },
    //       { shouldFocus: true }
    //     );
    //   }
    //   setSaveBtnLoading(false);
    //   return;
    // }
  };

  const onSubmit = async () => {
    try {
      setSaveBtnLoading(true);
      await updatePassword(auth?.currentUser, password);
      //const res = await auth?.currentUser?.updatePassword(password);
      //const { signIn } = updatePWData;
      await signIn();
      setSaveBtnLoading(false);
    } catch (e) {
      console.log(e);
      setError(
        "email",
        {
          type: e.message,
          message: e.message,
        },
        { shouldFocus: true }
      );
      setSaveBtnLoading(false);
    }
  };

  return (
    <FormContainerWithScroll
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex justify-content-center align-items-center"
      id="update-password-form"
    >
      <Container id="update-password-form-container">
        <Row className="pt-4 d-flex justify-content-center">
          <Col md={8}>
            <h5 id="change-pw-heading">
              {t("change_pw_for_first_time_login")}
            </h5>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <FormGroupLabel id="email-label">
              {t("email_address")}
            </FormGroupLabel>
            <FormItem disabled={true} id="email-div">
              <FormField
                name="email"
                id="email"
                control={control}
                component="input"
                type="email"
                defaultValue=""
                placeholder={t("email_address")}
                style={formInputStyles}
                disabled={true}
                rules={{
                  required: t("required_err"),
                  pattern: {
                    value: emailPattern,
                    message: t("invalid_email_err_msg"),
                  },
                }}
                //onKeyUp={handleSIOdoChange}
              />
              {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
            </FormItem>

            {errors?.email && <Error error={errors.email.message} />}
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <FormGroupLabel id="password-label">{t("password")}</FormGroupLabel>
            <FormItem disabled={false} id="password-div">
              <FormField
                name="password"
                id="password"
                control={control}
                component="input"
                type="password"
                defaultValue=""
                placeholder={t("password")}
                style={formInputStyles}
                disabled={false}
                rules={{
                  required: t("required_err"),
                  minLength: {
                    value: 6,
                    message: t("invalid_pwd"),
                  },
                  validate: {
                    changePassword: (v) =>
                      v !== updatePWData?.existingPassword ||
                      t("change_pw_msg"),
                  },
                }}
                //onKeyUp={handleSIOdoChange}
              />
            </FormItem>

            {errors?.password && <Error error={errors.password.message} />}
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <FormGroupLabel id="confirm-password-label">
              {t("confirm_password")}
            </FormGroupLabel>
            <FormItem disabled={false} id="confirm-password-div">
              <FormField
                name="confirmPassword"
                id="confirm-password"
                control={control}
                component="input"
                type="password"
                defaultValue=""
                placeholder={t("confirm_password")}
                style={formInputStyles}
                disabled={false}
                rules={{
                  required: t("required_err"),
                  minLength: {
                    value: 6,
                    message: t("invalid_pwd"),
                  },
                  validate: {
                    matchPassword: (v) =>
                      v === password || t("pw_mismatch_err_msg"),
                  },
                }}
                //onKeyUp={handleSIOdoChange}
              />
            </FormItem>

            {errors?.confirmPassword && (
              <Error error={errors.confirmPassword.message} />
            )}
          </Col>
        </Row>

        <Row className="d-flex justify-content-center my-4">
          <Col md={8} className="d-flex justify-content-center gap-2">
            <FilterButton
              id="cancel-btn"
              type="button"
              onClick={() => setUpdatePWData(null)}
              // style={{ width: "min-content" }}
            >
              {t("cancel")}
            </FilterButton>
            <AddFormButton
              id="save-btn"
              style={{ background: "#003f7d" }}
              disabled={saveBtnLoading}
            >
              {saveBtnLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          </Col>
        </Row>
      </Container>
    </FormContainerWithScroll>
  );
};

export default UpdatePasswordForm;

//styles
const FormContainerWithScroll = styled(FormContainer)`
  //height: ${window.innerHeight - 270}px;
  height: 380px;
  width: ${window.innerWidth - 800}px;
  overflow: auto;
  border: 1px solid lightgray;
  background: white;
  border-radius: 5px;
  ${scrollbarStyles};
`;
