import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
  Label,
} from "../../shared/components/form/FormElements";
import { formInputStyles } from "../../shared/constants/styles";
import FormField from "../../shared/components/form/FormField";

import Error from "@/shared/components/form/Error";
import DocUploader from "../../shared/components/form/DocUploader";
import { s3Url } from "../../shared/constants";

const LicenseForm = ({
  type,
  rowData,
  licenseInfo,
  licenseDocFile,
  setLicenseDocFile,
}) => {
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  //const [docFile, setDocFile] = useState("");
  const [showDocToUser, setShowDocToUser] = useState(true);

  //const docName = watch("docName");
  const licenseNo = watch("licenseNo");
  const licenseExpiry = watch("licenseExpiry");
  const licenseAddress = watch("licenseAddress");
  //const licenseDocFile = watch("licenseDocFile");

  const status = watch("status");

  const isDisabled = !status;

  //Populate fields on edit
  useEffect(() => {
    if (type === "edit") {
      if (rowData) {
        const { license_info, license_img, org_id, user_id } = rowData;
        //let licenseData = "";
        if (license_info) {
          const { license } = JSON.parse(license_info);
          //licenseData = JSON.parse(license_info);
          console.log("licenseDatanew", license);
          setValue("licenseNo", license[0]);
          setValue("licenseExpiry", license[1]);
          setValue("licenseAddress", license[2]);
        }

        //console.log('license_img',license_img);

        if (license_img) {
          const ext = license_img.split(".").pop();
          setLicenseDocFile({
            imgName: license_img,
            url: `${s3Url}/org_${org_id}/user_${user_id}/license/${license_img}`,
            ext,
          });
          // setValue("licenseDocFile", {
          //   imgName: license_img,
          //   url: `https://s3.us-east-2.amazonaws.com/simply-fleet.com/image_data/org_${org_id}/user_${user_id}/license/${license_img}`,
          // });
        } else {
          //setValue("");
          setLicenseDocFile("");
        }
      }
    }
  }, [rowData]);

  //Populate main license ref data on change of local data
  // useEffect(() => {
  //   licenseInfo.current = {
  //     licenseNo,
  //     licenseExpiry,
  //     licenseAddress: licenseAddress,
  //     licenseImg: docFile,
  //   };
  // }, [licenseNo, licenseExpiry, licenseAddress, docFile]);

  return (
    //<FormContainer>
    <Container>
      <Row>
        <Col xs={6} className="mb-3">
          <FormGroupLabel>{t("doc_name")}</FormGroupLabel>
          <FormItem readOnly={true} style={{ width: "424px" }}>
            <p style={{ width: "-webkit-fill-available" }}>License</p>
          </FormItem>

          {errors?.docName && <Error error={errors.docName.message} />}
        </Col>
      </Row>

      <Row>
        <Col className="my-1">
          {/* <FormGroupLabel style={{ height: "0.75rem" }}></FormGroupLabel> */}
          <FormValueContainer>
            <CheckBoxInput
              type="checkbox"
              id="showDocToUser"
              name="showDocToUser"
              value="showDocToUser"
              checked={showDocToUser}
              onChange={() => setShowDocToUser((prev) => !prev)}
              style={{ marginRight: "0.5rem" }}
              disabled={true}
            />
            <Label htmlFor="showDocToUser" disabled={true}>
              {t("make_doc_available_to_operator")}
            </Label>
          </FormValueContainer>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <DocUploader
            docFile={licenseDocFile}
            setDocFile={setLicenseDocFile}
            isDisabled={isDisabled}
            accFileTypes={"image/*"}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex align-items-center gap-2 mt-3 mb-2">
          <FormGroupLabel className="m-0" style={{ width: "198px" }}>
            {t("license_number")}
          </FormGroupLabel>
          <FormItem
            disabled={isDisabled}
            //tabIndex={0}
          >
            <FormField
              name="licenseNo"
              control={control}
              component="input"
              defaultValue=""
              //isAboveError={isAboveError}
              placeholder={t("license_number")}
              style={formInputStyles}
              disabled={isDisabled}
              //onKeyUp={handleSIOdoChange}
            />
          </FormItem>

          {errors?.licenseNo && <Error error={errors.licenseNo.message} />}
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center gap-2 my-2">
          <FormGroupLabel className="m-0" style={{ width: "198px" }}>
            {t("expiry")}
          </FormGroupLabel>
          <FormItem
            disabled={isDisabled}
            //tabIndex={0}
          >
            <FormField
              name="licenseExpiry"
              control={control}
              component="input"
              defaultValue=""
              //isAboveError={isAboveError}
              placeholder={t("expiry")}
              style={formInputStyles}
              disabled={isDisabled}
              //onKeyUp={handleSIOdoChange}
            />
          </FormItem>

          {errors?.licenseExpiry && (
            <Error error={errors.licenseExpiry.message} />
          )}
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center gap-2 mt-2 mb-4">
          <FormGroupLabel className="m-0" style={{ width: "198px" }}>
            {t("address")}
          </FormGroupLabel>
          <FormItem
            disabled={isDisabled}
            //tabIndex={0}
          >
            <FormField
              name="licenseAddress"
              control={control}
              component="input"
              defaultValue=""
              //isAboveError={isAboveError}
              placeholder={t("address")}
              style={formInputStyles}
              disabled={isDisabled}
              //onKeyUp={handleSIOdoChange}
            />
          </FormItem>

          {errors?.licenseAddress && (
            <Error error={errors.licenseAddress.message} />
          )}
        </Col>
      </Row>
    </Container>
    //</FormContainer>
  );
};

export default LicenseForm;
