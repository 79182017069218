import React, { useEffect, useState } from "react";
//import { ItemContainer, ItemLabel, ItemValue } from "../ReactTableRightPanel";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import {
  getDisplayDate,
  getDistanceUnit,
  getTranslatedFuelTypeUnit,
  getVehDocUrl,
} from "../../../../helpers";
import { Col, Modal, Row } from "react-bootstrap";
import {
  colorBg,
  colorBorder,
  colorLightBlueStatus,
  taskBgColor,
} from "../../../../../utils/palette";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NATEXT, s3Url } from "../../../../constants";
import { useTranslation } from "react-i18next";
import ZoomModal from "../../../ZoomModal";
import { LinkSpan } from "../../../form/FormElements";
import { postApi } from "../../../../../api/getUser";
import AssignedEntitiesModal, { ListContainer } from "../AssignedEntitiesModal";
import {
  ActionIconContainer,
  AddFormButton,
  DataRow,
} from "../../../TableElements";

const VehiclesRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { currency, distance } = useSelector((state) => state.units);
  const users = useSelector((state) => state.users.list);
  const [documents, setDocuments] = useState([]);
  const [showDocsPopup, setShowDocsPopup] = useState(false);

  const [usersAssigned, setUsersAssigned] = useState([]);
  const [showAssignedUsersPopup, setShowAssignedUsersPopup] = useState(false);

  const [showCustomSpecs, setShowCustomSpecs] = useState(false);

  const {
    name,
    veh_type,
    make,
    model,
    veh_active,
    secondary_status,
    fuel_type,
    primary_meter,
    vin_no,
    year,
    license_no,
    starting_odo,
    purchase_price,
    custom_specs,
    odo,
  } = rowData;

  let customSpecs = [];
  if (custom_specs) {
    const customSpecsArr = custom_specs.split(":::");
    let count = 1;
    customSpecsArr.forEach((spec) => {
      const [key, value] = spec.split("~~");
      customSpecs.push({ title: key, author: value, id: count });
      count += 1;
    });
  }

  useEffect(() => {
    //Fetch docs
    (async () => {
      if (rowData?.additional_documents) {
        const additionalDocsData = JSON.parse(rowData?.additional_documents);
        const allDocsArr = additionalDocsData.All_Documents;
        let temp = [];
        let count = 0;

        for (let doc of allDocsArr) {
          const [[key, value]] = Object.entries(doc);
          console.log(key, value);
          const { items, image, visible } = value;
          let isVisible = visible;

          if (typeof visible === "object") {
            isVisible = visible[0];
          }

          //For users, image format has two formats

          // let updatedDate = "";
          // if (items[0]?.title === "Updated date") {
          //   updatedDate = items[0]?.author;
          // }
          // (async () => {
          const { url, extension } = await getVehDocUrl(
            rowData.org_id,
            rowData.veh_id,
            image
          );
          temp.push({
            id: Date.now() + count,
            // docName: key,
            // existingDoc: true,
            // imgUpdated: false,
            // fields: updatedDate ? items.slice(1, items.length) : items,
            // updatedDate: updatedDate ? updatedDate : "",
            // isVisible: isVisible,
            // image: {
            //   url: url,
            //   extension: extension,
            //   type: image,
            // },
            url: url,
            extension: extension,
            type: image,
          });

          count += 1;
        }

        setDocuments(temp);
      } else {
        setDocuments([]);
      }
    })();
  }, [rowData]);

  //Effect to fetch assigned users
  useEffect(() => {
    if (users?.length > 0) {
      (async () => {
        let selectedUsers = [];
        const res = await postApi(
          {
            veh_id: rowData?.veh_id,
            querystring: "getsinglevehicle",
          },
          "commonNew"
        );
        const { user_data } = res;
        if (user_data.length > 0) {
          const selectedUserIds = user_data
            ?.filter((u) => u.operator_vehicle_action !== 3)
            .map((u) => u.user_id);
          selectedUsers = users
            .filter((u) => selectedUserIds.includes(u.user_id))
            .map((u) => {
              const { org_id, user_id, user_img, name, label } = u;
              let imageUrl = "";
              let userInitials = "";

              if (user_img) {
                imageUrl = `${s3Url}/org_${org_id}/user_${user_id}/profile/${user_img}`;
              } else {
                const userNameArr = name.split(" ");
                userNameArr.forEach(
                  (name) => (userInitials += name[0]?.toUpperCase())
                );
                userInitials = userInitials.slice(0, 3);
              }

              return { label, imageUrl, userInitials };
            });
        }
        setUsersAssigned(selectedUsers);
      })();
    }
  }, [rowData, users]);

  return (
    <>
      {showCustomSpecs && (
        <CustomSpecsPopup
          showPopup={showCustomSpecs}
          setShowPopup={setShowCustomSpecs}
          customSpecs={customSpecs}
        />
      )}
      {showDocsPopup && (
        <ZoomModal
          showModal={showDocsPopup}
          setShowModal={setShowDocsPopup}
          docs={documents}
        />
      )}
      {showAssignedUsersPopup && (
        <AssignedEntitiesModal
          showPopup={showAssignedUsersPopup}
          setShowPopup={setShowAssignedUsersPopup}
          heading={t("assigned_users")}
          assignedEntities={usersAssigned}
        />
      )}
      <Row>
        {veh_type && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("type")}</ItemLabel>
              <ItemValue>{veh_type}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {license_no && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("license")}</ItemLabel>
              <ItemValue>{license_no}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {vin_no && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("vin")}</ItemLabel>
              <ItemValue>{vin_no}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {make && model && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("make_and_model")}</ItemLabel>
              <ItemValue>{`${make} ${model}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!year && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("year")}</ItemLabel>
              <ItemValue>{year}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {fuel_type && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("fuel_type")}</ItemLabel>
              <ItemValue>{getTranslatedFuelTypeUnit(fuel_type, t)}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!primary_meter && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("primary_meter")}</ItemLabel>
              <ItemValue>{primary_meter}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!purchase_price && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("purchase_price")}</ItemLabel>
              <ItemValue>{`${purchase_price} ${currency}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {/* {!!starting_odo && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("starting_odo")}</ItemLabel>
              <ItemValue>{starting_odo}</ItemValue>
            </ItemContainer>
          </Col>
        )} */}
        {!!odo && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("current_odo")}</ItemLabel>
              <ItemValue>{`${odo} ${getDistanceUnit(
                primary_meter === "Hours" ? primary_meter : distance
              )}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {/* <Col md={12}>
        <ItemContainer>
        <ItemLabel>{t("vehicle_documents")}</ItemLabel>
        <ItemValue>{"Vehicle Docs"}</ItemValue>
        </ItemContainer>
        </Col>
        <Col md={6}>
        <ItemContainer>
        <ItemLabel>{t("assigned_operators")}</ItemLabel>
        <ItemValue>{"Assigned Ops"}</ItemValue>
        </ItemContainer>
        </Col>
        <Col md={6}>
        <ItemContainer>
          <ItemLabel>{t("assignment_history")}</ItemLabel>
          <ItemValue>{"Assignment History"}</ItemValue>
          </ItemContainer>
        </Col> */}

        {documents.length > 0 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("vehicle_documents")}</ItemLabel>
              <LinkSpan onClick={() => setShowDocsPopup(true)}>
                {t("count_documents_added", { count: documents.length })}
              </LinkSpan>
            </ItemContainer>
          </Col>
        )}

        {usersAssigned.length > 0 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("assigned_users")}</ItemLabel>
              <LinkSpan onClick={() => setShowAssignedUsersPopup(true)}>
                {t("count_users_assigned", {
                  count: usersAssigned.length,
                })}
              </LinkSpan>
            </ItemContainer>
          </Col>
        )}

        {customSpecs.length > 0 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("custom_specifications")}</ItemLabel>
              <LinkSpan onClick={() => setShowCustomSpecs(true)}>
                {t("count_specs_added", {
                  count: customSpecs.length,
                })}
              </LinkSpan>
            </ItemContainer>
          </Col>
        )}
      </Row>

      {/* {custom_specs && (
        <>
          <Row className="my-2">
            <Col>
              <ItemLabel>{t("custom_specifications")}</ItemLabel>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <ItemLabel>{t("detail_title")}</ItemLabel>
            </Col>
            <Col md={2}></Col>
            <Col md={4}>
              <ItemLabel>{t("detail")}</ItemLabel>
            </Col>
          </Row>
          {customSpecs.map((spec) => (
            <Row key={spec.id} className="my-1">
              <Col md={4}>
                <p>{spec.title}</p>
              </Col>
              <Col md={2}></Col>
              <Col md={4}>
                <ItemValue style={{ wordWrap: "normal" }}>
                  {spec.author}
                </ItemValue>
              </Col>
            </Row>
          ))}
        </>
      )} */}
    </>
  );
};

export default VehiclesRightPanel;

export const CustomSpecsPopup = ({
  showPopup,
  setShowPopup,
  customSpecs,
  heading,
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal show={showPopup} onHide={() => setShowPopup(false)} size="md">
      <Modal.Header>
        <h4>{heading || t("custom_specifications")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={() => setShowPopup(false)}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>

      <Modal.Body>
        <ListContainer>
          {customSpecs.map((spec) => (
            <SpecsRow key={spec.id}>
              <Col>
                <p>{spec?.title}</p>
              </Col>
              <Col>
                <p>{spec?.author}</p>
              </Col>
            </SpecsRow>
          ))}
        </ListContainer>
      </Modal.Body>

      <Modal.Footer>
        <AddFormButton
          id="ok-button"
          type="button"
          onClick={() => setShowPopup(false)}
        >
          {t("ok")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

//styles
export const SpecsRow = styled(Row)`
  border-bottom: 1px solid ${colorBorder};
  // &:last-child {
  //   border: none;
  // }
  padding: 0.5rem;
  //margin: 0;
`;
