import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  Label,
  UnitSpan,
} from "../../shared/components/form/FormElements";
import FormHeader from "../../shared/components/form/FormHeader";
import { useTranslation } from "react-i18next";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import Select from "react-select";
import Error from "@/shared/components/form/Error";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Card, CardBody } from "@/shared/components/Card";
import { useHistory, useLocation } from "react-router";
import FormField from "../../shared/components/form/FormField";
import DatePicker from "react-datepicker";
import {
  CustomInput,
  Desc,
  TimeSelect,
} from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactor,
  getDistanceFactorForEff,
  getDistanceFactorforOdo,
  getEditUsers,
  getHourlyVolumeFactorEff,
  getImgString,
  getInitialStartTime,
  getVolumeFactor,
  getVolumeFactorForEff,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  handleVehicleOnChange,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";
import { colorGray } from "../../utils/palette";
import FileUploader from "../../shared/components/form/FileUploader";
import ImageWithZoom from "../../shared/components/form/ImageWithZoom";
import { post, postApi, putApi } from "../../api/getUser";
import format from "date-fns/format";
import { useDispatch } from "react-redux";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { FileUploaderContainer, uploadImgToS3 } from "../Issues/IssuesForm";
import useFetchOdoHint from "../../shared/custom-hooks/useFetchOdoHint";
import { useAuth } from "../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../shared/components/SubscribeTooltipIcon";
import useFetchOdo from "../../shared/custom-hooks/useFetchOdo";
import FileUploaderNew from "../../shared/components/form/FileUploaderNew";
import ErrorPopup from "../../shared/components/ErrorPopup";
import { s3Url } from "../../shared/constants";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const FillupsForm = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  //console.log("errors", errors);
  const { userId, orgId } = useSelector((state) => state.user);
  const { currency } = useSelector((state) => state.units);
  const { distance, volume, consumption, hourlyConsumption } = useSelector(
    (state) => state.units
  );

  const location = useLocation();
  //const { data, screen, type } = location?.state;
  const data = location?.state?.data;
  const screen = location?.state?.screen;
  const type = location?.state?.type;
  const rowData = data?.rowData;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //This state is used to update the price per label
  const [qtyUnitLabel, setQtyUnitLabel] = useState(t("gal_uk_const"));

  //hook-form variables
  const vehicle = watch("vehicle");
  const date = watch("date");
  const time = watch("time");
  const odometer = watch("odometer");
  const quantity = watch("quantity");
  const qtyUnit = watch("qtyUnit");
  const price = watch("price");
  const totalCost = watch("totalCost");
  const fuelType = watch("fuelType");
  const octane = watch("octane");
  const fuelBrand = watch("fuelBrand");
  const fillingStation = watch("fillingStation");
  const transactionType = watch("transactionType");
  const cardNo = watch("cardNo");
  const filledBy = watch("filledBy");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );

  const users = useSelector((state) => state.users.list);

  const [userOptions, setUserOptions] = useState([]);

  const [fillupImgs, setFillupImgs] = useState([]);

  const [status, setStatus] = useState("new");

  const [partialTank, setPartialTank] = useState(false);
  const [missedPrevFillup, setMissedPrevFillup] = useState(false);

  // const [highOdoMsg, setHighOdoMsg] = useState("");
  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const { tag } = useAuth();

  const fuelTypeOptions = [
    {
      value: "Petrol",
      label: t("petrol_const"),
    },
    {
      value: "Diesel",
      label: t("diesel_const"),
    },
  ];

  const transactionTypeOptions = [
    {
      value: "Cash",
      label: t("cash_const"),
    },
    {
      value: "Check",
      label: t("check_const"),
    },
    {
      value: "Credit card",
      label: t("credit_card_const"),
    },
    {
      value: "Debit card",
      label: t("debit_card_const"),
    },
  ];

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    date,
    time,
  });

  const { odoFetchedMsg, setOdoFetchedMsg } = useFetchOdo({
    vehicle,
    type,
    setValue,
    lastOdoValue,
    setShowHighOdoErr,
  });

  //Initial Effect to set initial values
  useEffect(() => {
    if (type !== "edit") {
      setValue(
        "filledBy",
        users?.find((u) => u.user_id === userId)
      );

      setValue("date", new Date());
      setValue("time", getInitialStartTime());

      let defaultTransactionTypeValue = "Credit card";
      //Check if transaction type preference is already set in local storage
      const transactionTypePref = localStorage.getItem("transactionType");

      if (transactionTypePref) {
        defaultTransactionTypeValue = transactionTypePref;
      }
      setValue(
        "transactionType",
        transactionTypeOptions.find(
          (t) => t.value === defaultTransactionTypeValue
        )
      );

      // setValue(
      //   "fuelType",
      //   fuelTypeOptions.find((t) => t.value === "Diesel")
      // );
    }
  }, [userId, users]);

  //Effect to update fuelType on vehicle change
  useEffect(() => {
    if (vehicle && type !== "edit") {
      const fuelType = vehicle?.fuel_type || "Diesel";
      setValue(
        "fuelType",
        fuelTypeOptions.find((t) => t.value === fuelType)
      );
    }
  }, [vehicle]);

  //Effect to update fields for Edit
  useEffect(() => {
    if (type === "edit") {
      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)
      );
      setValue("date", new Date(rowData?.fillup_date * 1000));
      setValue("time", format(new Date(rowData?.fillup_date * 1000), "HH:mm"));
      const distanceFactor = getDistanceFactorforOdo(
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
        distance
      );
      // console.log(
      //   distanceFactor,
      //   vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
      //   distance
      // );
      const odoUpd = rowData?.odo
        ? (+rowData?.odo / distanceFactor)?.toFixed(2)
        : 0;

      setValue("odometer", odoUpd);
      setValue("quantity", +rowData?.qty ? +rowData?.qty?.toFixed(2) : 0);
      setValue("qtyUnit", rowData?.qty_unit);
      let priceVal = 0;
      if (rowData?.total_cost && rowData?.qty) {
        priceVal = (+rowData?.total_cost / +rowData?.qty)?.toFixed(3);
      }
      setValue("price", priceVal);
      if (rowData?.total_cost) {
        setValue("totalCost", rowData?.total_cost?.toFixed(2));
      }
      setValue(
        "fuelType",
        fuelTypeOptions?.find((f) => f.value === rowData?.fuel_type)
      );
      setValue("octane", rowData?.octane);
      setValue("fuelBrand", rowData?.fuel_brand);
      setValue("fillingStation", rowData?.filling_station);
      setValue(
        "transactionType",
        transactionTypeOptions?.find(
          (f) => f.value === rowData?.transaction_type
        )
      );
      setValue("cardNo", rowData?.card_number);

      //Set user and user options
      (async () => {
        const editUsers = await getEditUsers(
          rowData?.veh_id,
          rowData?.filled_by
        );
        if (editUsers?.length > 0) {
          const selectedUser = editUsers?.find(
            (u) => u.user_id === rowData?.filled_by
          );
          setValue("filledBy", selectedUser);
          setUserOptions(editUsers);
        }
      })();

      // const selectedUser = users?.find((u) => u.user_id === rowData?.filled_by);
      // setValue("filledBy", selectedUser);
      setValue("comments", rowData?.comments);

      setPartialTank(+rowData?.p_fill);
      setMissedPrevFillup(+rowData?.m_fill);

      //Logic to set Images
      let imgNames = [];
      const editImgName = rowData?.receipt_img_name;
      if (editImgName) {
        imgNames = editImgName.split(":::");

        const imgs = [];
        imgNames.map((imgName, i) => {
          imgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `${s3Url}/org_${orgId}/fillups/${imgName}`,
            extension: imgName.split(".").pop(),
          });
        });
        console.log("imgs", imgs);
        setFillupImgs(imgs);
      }
    }
  }, [rowData, vehiclesList]);

  //Effect to show odo too high warning
  // useEffect(() => {
  //   //console.log(startingOdo, lastOdoValue, +startingOdo - +lastOdoValue > 1000);
  //   if (lastOdoValue && +odometer - +lastOdoValue > 1000) {
  //     setHighOdoMsg(
  //       "Unusually high odometer reading detected. Kindly validate for accuracy."
  //     );
  //   } else {
  //     setHighOdoMsg("");
  //   }
  // }, [odometer, lastOdoValue]);

  //Clear cardNo value on change of transaction type
  useEffect(() => {
    if (
      transactionType?.value === "Cash" ||
      transactionType?.value === "Check"
    ) {
      setValue("cardNo", "");
    }
  }, [transactionType]);

  //Limit card number to 4 digits
  useEffect(() => {
    if (
      (transactionType?.value === "Debit card" ||
        transactionType?.value === "Credit card") &&
      cardNo
    ) {
      if (cardNo?.length > 4) {
        setValue("cardNo", cardNo.slice(0, 4));
      }
    }
  }, [cardNo]);

  // //Effect to change odo hint data on change of vehicle, date and time
  // useEffect(() => {
  //   if (vehicle && date && time) {
  //     (async () => {
  //       let filterEndDate = getDateWithTime(date, time);

  //       const res = await postApi(
  //         {
  //           querystring: "getOdometerFromDate",
  //           sub_querystring: "",
  //           veh_id: vehicle.vehicleId,
  //           filter_date_start: 0,
  //           filter_date_end: filterEndDate,
  //         },
  //         "dashboard"
  //       );
  //       if (res.success === 1) {
  //         let [lastOdo, nextOdo] = res.odohint_data;
  //         lastOdo = +lastOdo;
  //         nextOdo = +nextOdo;
  //         //console.log(lastOdo, nextOdo);
  //         const distanceFactor = getDistanceFactorforOdo(
  //           vehicle?.primary_meter,
  //           distance
  //         );
  //         if (lastOdo) {
  //           lastOdo = lastOdo / distanceFactor;
  //           lastOdo = lastOdo.toFixed(2);
  //         }
  //         if (nextOdo) {
  //           nextOdo = nextOdo / distanceFactor;
  //           nextOdo = nextOdo.toFixed(2);
  //         }

  //         if (lastOdo && nextOdo) {
  //           setOdoHint(
  //             t("last_odo") + lastOdo + ", " + t("next_odo") + nextOdo
  //           );
  //         } else if (lastOdo && !nextOdo) {
  //           setOdoHint(t("last_odo") + lastOdo);
  //         } else if (!lastOdo && nextOdo) {
  //           setOdoHint(t("next_odo") + nextOdo);
  //         }
  //       }
  //     })();
  //   }
  // }, [vehicle, date, time]);

  // //Effect to update Odo Unit on vehicle change
  // useEffect(() => {
  //   if (vehicle) {
  //     setOdoUnit(getOdoUnit(vehicle?.primary_meter));
  //   }
  // }, [vehicle]);

  //Effect to update Price/{quanity unit label}
  useEffect(() => {
    //console.log(qtyUnit);
    if (qtyUnit === "Gallons (UK)") {
      setQtyUnitLabel(t("gal_uk_const"));
    } else if (qtyUnit === "Gallons (US)") {
      setQtyUnitLabel(t("gal_us_const"));
    } else if (qtyUnit === "Liters") {
      setQtyUnitLabel(t("ltr_const"));
    }
  }, [qtyUnit]);

  const onSubmit = async () => {
    try {
      setSaveBtnLoading(true);
      const fillupId =
        type === "edit"
          ? rowData?.fillup_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      const { imgString, imgsToS3 } = getImgString(
        fillupImgs,
        fillupId,
        userId,
        orgId,
        "fillups"
      );

      let effDistanceFactor = getDistanceFactorForEff(distance, consumption);
      let effVolumeFactor = getVolumeFactorForEff(volume, consumption);

      if (vehicle?.primary_meter === "Hours") {
        effDistanceFactor = 1;
        effVolumeFactor = getHourlyVolumeFactorEff(volume, hourlyConsumption);
      }

      const volumeFactor = getVolumeFactor(qtyUnit, volume);
      const qtyConverted = quantity ? +quantity * volumeFactor : 0;
      const distanceFactor = getDistanceFactor(
        vehicle?.primary_meter,
        distance
      );
      let odoConverted = odometer * distanceFactor;

      //Odo Validation
      const odoCheckApiData = {
        odo: odoConverted,
        veh_id: vehicle.vehicleId,
        datetocheck: getDateWithTime(date, time),
        querystring: "get_around_dates_v3",
        check_from: "fillups",
        check_id: type === "edit" ? rowData.fillup_id : "",
      };

      // const odoRes = await postApi(odoCheckApiData, "commonNew");
      // console.log("odoResponse", odoRes);
      // if (!odoRes.success) {
      //   setError(
      //     "odometer",
      //     { type: "custom", message: odoRes.arounddates_data },
      //     { shouldFocus: true }
      //   );
      //   setSaveBtnLoading(false);
      //   return;
      // }

      const odoValidationRes = await odoValidation(
        "odometer",
        odoCheckApiData,
        setError,
        setSaveBtnLoading,
        t,
        distanceFactor,
        odometer
      );

      if (!odoValidationRes) {
        return;
      }

      const apiData = {
        querystring: "add_fillup",
        action: type === "edit" ? 2 : 1,
        card_number: cardNo ? cardNo : 0,
        comments: comments || "",
        currency: currency,
        dist_fact: distanceFactor,
        effdistfact: effDistanceFactor,
        effvolfact: effVolumeFactor,
        operator_userid: filledBy?.user_id,
        filling_station: fillingStation,
        fillup_date: getDateWithTime(date, time),
        fillup_id: fillupId,
        fuel_brand: fuelBrand,
        fuel_type: fuelType?.value,
        m_fill: missedPrevFillup ? 1 : 0,
        octane: octane,
        odo: odoConverted,
        odo_unit: vehicle?.primary_meter === "Hours" ? "Hours" : distance,
        operation: type === "edit" ? "update" : "create",
        p_fill: partialTank ? 1 : 0,
        qty: qtyConverted,
        qty_unit: volume,
        receipt_img_name: imgString,
        source: "web",
        sync_version: "v2",
        total_cost: totalCost,
        transaction_type: transactionType?.value,
        veh_id: vehicle?.vehicleId,
        vol_fact: volumeFactor,
      };

      setSaveBtnLoading(true);
      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("fillup") })
          : t("record_added_msg", { recordName: t("fillup") });
      //(async () => {
      const response = await putApi(apiData, "fillups");

      if (response.success === 1) {
        if (imgsToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imgsToS3);
          if (docsUploaded) {
            dispatch(addMsg(snackbarMsg));
            //history.goBack();
            history.push("/fillups", { fromCancel: false });
            setSaveBtnLoading(false);
          }
        } else {
          dispatch(addMsg(snackbarMsg));
          //history.goBack();
          history.push("/fillups", { fromCancel: false });
          setSaveBtnLoading(false);
        }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          screen={screen}
          title={type && type === "edit" ? t("edit_fill_up") : t("add_fill_up")}
          //title={t("add_issue")}
          //saveButtonText={"Save Reminder"}
          handleCancel={() => history.push("/fillups", { fromCancel: true })}
          disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          errors={errors}
          rowData={rowData}
        />
        <Container>
          {/* <Row> */}
          {/* <Col> */}
          <Card>
            <CardBody
              style={{
                height: `${window.innerHeight - 150}px`,
                overflow: "auto",
                //padding: "0px 12px",
              }}
            >
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      {/* Vehicle */}
                      <FormGroupLabel id="vehicle-label">
                        {t("vehicle")}
                      </FormGroupLabel>
                      <Controller
                        name="vehicle"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            id="vehicle-select"
                            options={vehicles}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            //onChange={onChange}
                            onChange={(v) =>
                              handleVehicleOnChange(
                                v,
                                onChange,
                                "filledBy",
                                setValue,
                                setUserOptions,
                                userId
                              )
                            }
                            //onCreateOption={handleCreate}
                            placeholder={t("vehicle_placeholder")}
                            styles={formStyles}
                            isDisabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                  </Row>
                  {vehicle && (
                    <>
                      <Row>
                        <Col>
                          <FormGroupLabel id="date-label">
                            {t("date_tv")}
                          </FormGroupLabel>
                          <Controller
                            name="date"
                            defaultValue={new Date()}
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <DatePicker
                                id="datepicker"
                                locale={getDateFnsLocale()}
                                dateFormat={"P"}
                                dropdownMode="scroll"
                                maxDate={new Date()}
                                // locale={
                                //   window.navigator.userLanguage ||
                                //   window.navigator.language
                                // }
                                //minDate={new Date()}
                                selected={value}
                                onChange={onChange}
                                customInput={
                                  <CustomInput
                                  //disabled={status == 1 || status == 2}
                                  />
                                }
                                placeholderText={t("date_tv")}
                                showMonthDropdown
                                showYearDropdown
                                //disabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{
                              required: t("required_err"),
                            }}
                          />
                          {errors?.date && (
                            <Error error={errors.date.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel id="time-label">
                            {t("time_tv")}
                          </FormGroupLabel>
                          <FormItem disabled={status == 1 || status == 2}>
                            <FormField
                              id="time"
                              name="time"
                              control={control}
                              component="input"
                              type="time"
                              //defaultValue={getInitialStartTime}
                              placeholder={t("time_tv")}
                              style={formInputStyles}
                              disabled={status == 1 || status == 2}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.time && (
                            <Error error={errors.time.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroupLabel id="odometer-label">
                            {t("odometer")}
                          </FormGroupLabel>
                          <FormItem
                            disabled={false}
                            showWarning={showHighOdoErr}
                            //tabIndex={0}
                          >
                            <FormField
                              id="odometer"
                              name="odometer"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              rules={{ required: t("required_err") }}
                              //errors={errors}
                              // rules={{
                              //   required:
                              //     +serviceIntervalDuration || +serviceIntervalOdo
                              //       ? false
                              //       : t("odo_or_duration"),
                              //   min: {
                              //     value: 0,
                              //     message: t("greater_than_zero_err"),
                              //   },
                              // }}
                              defaultValue=""
                              //isAboveError={isAboveError}
                              placeholder={t("odometer")}
                              style={formInputStyles}
                              disabled={false}
                              onChange={(e) => {
                                clearErrors("odometer");
                                setValue("odometer", e.target.value);
                                if (odoFetchedMsg) {
                                  setOdoFetchedMsg("");
                                }
                              }}
                              onKeyUp={(e) => {
                                const { value } = e.target;
                                if (
                                  value &&
                                  lastOdoValue &&
                                  +value - +lastOdoValue > 1000
                                ) {
                                  setShowHighOdoErr(true);
                                } else {
                                  setShowHighOdoErr(false);
                                }
                              }}
                            />
                            <UnitSpan>{odoUnit}</UnitSpan>
                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>
                          {odoFetchedMsg && (
                            <HintText>{odoFetchedMsg}</HintText>
                          )}
                          <HintText>{odoHint}</HintText>

                          {/* {errors?.odometer && (
                          <Error error={errors.odometer.message} />
                        )} */}
                          {/* {highOdoMsg ? (
                          <Error error={highOdoMsg} />
                        ) : (
                          errors?.odometer && (
                            <Error error={errors.odometer.message} />
                          )
                        )} */}

                          {/* {errors?.odometer ? (
                          <Error error={errors.odometer.message} />
                        ) : (
                          showHighOdoErr && (
                            <Error error={t("high_odo_err_msg")} />
                          )
                        )} */}
                          {showHighOdoErr && (
                            <Error error={t("high_odo_err_msg")} />
                          )}
                          {errors?.odometer && (
                            <Error error={errors.odometer.message} />
                          )}
                        </Col>
                        <Col>
                          <FormGroupLabel id="qty-label">
                            {t("qty_tv")}
                          </FormGroupLabel>
                          <FormItem
                          //disabled={false}
                          >
                            <FormField
                              id="quantity"
                              name="quantity"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              onKeyUp={(e) => {
                                if (e.target.value && price) {
                                  const totalCostVal = (
                                    +e.target.value * +price
                                  ).toFixed(2);
                                  setValue("totalCost", totalCostVal);
                                } else {
                                  setValue("totalCost", 0);
                                }
                              }}
                              rules={{ required: t("required_err") }}
                              defaultValue=""
                              placeholder={t("qty_tv")}
                              style={formInputStyles}
                              //disabled={false}
                            />
                            <TimeSelect
                              {...register("qtyUnit")}
                              id="qty-select"
                              //defaultValue="gal(US)"
                              defaultValue={volume}
                              //disabled={!serviceIntervalDuration}
                              // onClick={() => {
                              //   setError("notifyMeDuration", {
                              //     type: "maxValue",
                              //   });
                              // }}
                            >
                              <option value="Gallons (UK)" id="gallons-uk">
                                {t("gal_uk_const")}
                              </option>
                              <option value="Gallons (US)" id="gallons-us">
                                {t("gal_us_const")}
                              </option>
                              <option value="Liters" id="liters">
                                {t("ltr_const")}
                              </option>
                            </TimeSelect>

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.quantity && (
                            <Error error={errors.quantity.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <FormGroupLabel id="price-label">{`${t(
                            "cost_per_unit_tv"
                          )} ${qtyUnitLabel}`}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              id="price"
                              name="price"
                              control={control}
                              component="input"
                              //rules={{ required: t("required_err") }}
                              type="number"
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              onKeyUp={(e) => {
                                if (e.target.value && quantity) {
                                  const totalCostVal = (
                                    +e.target.value * +quantity
                                  ).toFixed(2);
                                  setValue("totalCost", totalCostVal);
                                } else {
                                  setValue("totalCost", 0);
                                }
                              }}
                              defaultValue=""
                              placeholder={`${t(
                                "cost_per_unit_tv"
                              )} ${qtyUnitLabel}`}
                              style={formInputStyles}
                              //disabled={false}
                            />

                            {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                          </FormItem>

                          {errors?.price && (
                            <Error error={errors.price.message} />
                          )}
                        </Col>

                        <Col>
                          <FormGroupLabel id="total-cost-label">
                            {t("tc_tv")}
                          </FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              id="total-cost"
                              name="totalCost"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              onKeyUp={(e) => {
                                if (e.target.value && quantity) {
                                  const priceVal = (
                                    +e.target.value / +quantity
                                  )?.toFixed(3);
                                  setValue("price", priceVal);
                                } else {
                                  setValue("price", 0);
                                }
                              }}
                              defaultValue=""
                              placeholder={t("tc_tv")}
                              style={formInputStyles}
                              //disabled={false}
                            />
                          </FormItem>

                          {errors?.totalCost && (
                            <Error error={errors.totalCost.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          {/* Vehicle */}
                          <FormGroupLabel>{t("fuel_type")}</FormGroupLabel>
                          <Controller
                            name="fuelType"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={fuelTypeOptions}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                //onCreateOption={handleCreate}
                                placeholder={t("fuel_type_placeholder")}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{ required: t("required_err") }}
                          />

                          {errors?.fuelType && (
                            <Error error={errors.fuelType.message} />
                          )}
                        </Col>

                        <Col>
                          <FormGroupLabel>{t("oct_tv")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="octane"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={(e) => handleNegative(e)}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              defaultValue=""
                              placeholder={t("oct_tv")}
                              style={formInputStyles}
                              //disabled={false}
                            />
                          </FormItem>

                          {errors?.octane && (
                            <Error error={errors.octane.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroupLabel>{t("fb_tv")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="fuelBrand"
                              control={control}
                              component="input"
                              type="text"
                              defaultValue=""
                              placeholder={t("fb_tv")}
                              style={formInputStyles}
                              //disabled={false}
                            />
                          </FormItem>

                          {errors?.fuelBrand && (
                            <Error error={errors.fuelBrand.message} />
                          )}
                        </Col>

                        <Col>
                          <FormGroupLabel>{t("fs_tv")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="fillingStation"
                              control={control}
                              component="input"
                              type="text"
                              defaultValue=""
                              placeholder={t("fs_tv")}
                              style={formInputStyles}
                              //disabled={false}
                            />
                          </FormItem>

                          {errors?.fillingStation && (
                            <Error error={errors.fillingStation.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          {/* Vehicle */}
                          <FormGroupLabel>
                            {t("transaction_type")}
                          </FormGroupLabel>
                          <Controller
                            name="transactionType"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={transactionTypeOptions}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={(v) => {
                                  onChange(v);
                                  localStorage.setItem(
                                    "transactionType",
                                    v?.value
                                  );
                                }}
                                //onCreateOption={handleCreate}
                                placeholder={t("transaction_type_placeholder")}
                                styles={formStyles}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.transactionType && (
                            <Error error={errors.transactionType.message} />
                          )}
                        </Col>

                        <Col>
                          <FormGroupLabel>
                            {transactionType?.value === "Check"
                              ? t("check_number")
                              : t("card_number")}
                          </FormGroupLabel>
                          <FormItem
                            disabled={transactionType?.value === "Cash"}
                          >
                            <FormField
                              name="cardNo"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              //onKeyUp={handleCardNoCheck}
                              defaultValue=""
                              placeholder={
                                transactionType?.value === "Check"
                                  ? t("check_number")
                                  : t("last_4_digits")
                              }
                              style={formInputStyles}
                              disabled={transactionType?.value === "Cash"}
                            />
                          </FormItem>

                          {errors?.cardNo && (
                            <Error error={errors.cardNo.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          {/* Filled By */}
                          <FormGroupLabel>{t("filled_by")}</FormGroupLabel>
                          <Controller
                            name="filledBy"
                            control={control}
                            //defaultValue={users?.length > 0 ? users[0] : ""}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={userOptions}
                                onChange={onChange}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                //onCreateOption={handleCreate}
                                placeholder={t("user_placeholder")}
                                styles={formStyles}
                                isDisabled={status == 1 || status == 2}
                              />
                            )}
                            rules={{ required: t("required_err") }}
                          />

                          {errors?.filledBy && (
                            <Error error={errors.filledBy.message} />
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>

                          {/* <FormItem>
                            <FormField
                              name="comments"
                              control={control}
                              component="input"
                              type="text"
                              defaultValue={""}
                              placeholder={t("notes_tv")}
                              style={formInputStyles}
                            />
                          </FormItem> */}

                          <Controller
                            name="comments"
                            control={control}
                            //defaultValue={users?.length > 0 ? users[0] : ""}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <AutoResizeTextArea
                                ref={textAreaRef}
                                onChange={onChange}
                                value={value}
                                placeholder={t("notes_tv")}
                                rows={1}
                                //isDisabled={status == 1 || status == 2}
                              />
                            )}
                            //rules={{ required: t("required_err") }}
                          />

                          {errors?.comments && (
                            <Error error={errors.comments.message} />
                          )}
                        </Col>
                      </Row>
                      <Row className="px-1 pt-2 pb-3">
                        <Col>
                          <FormGroupLabel>
                            {t("images")}
                            {tag === "free-user" && (
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                              />
                            )}
                          </FormGroupLabel>
                          <FileUploaderNew
                            disabled={false}
                            images={fillupImgs}
                            setImages={setFillupImgs}
                            shouldDelete={true}
                          />
                          {/* <FileUploaderContainer>
                          {fillupImgs?.map((img) => {
                            return (
                              <ImageWithZoom
                                key={img.id}
                                img={img}
                                //handleDeleteImg={handleDeleteImg}
                                images={fillupImgs}
                                setImages={setFillupImgs}
                                shouldDelete={true}
                              />
                            );
                          })}

                          <FileUploader
                            //handleFileUpload={handleFileUpload}
                            disabled={false}
                            images={fillupImgs}
                            setImages={setFillupImgs}
                          />
                        </FileUploaderContainer> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>

                {/* <Col md={2}></Col> */}
                {vehicle && (
                  <Col md={6}>
                    <Row style={{ marginTop: "2.75rem" }}>
                      <Col>
                        <CheckBoxInput
                          type="checkbox"
                          id="partialTank"
                          name="partialTank"
                          value="partialTank"
                          checked={partialTank}
                          onChange={() => setPartialTank((prev) => !prev)}
                          style={{ marginRight: "0.5rem" }}
                          disabled={false}
                        />
                        <Label htmlFor="partialTank" disabled={false}>
                          {t("pf_tv")}
                        </Label>
                        <Desc style={{ paddingLeft: "1.25rem" }}>
                          {t("partial_tank_desc")}
                        </Desc>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "1rem" }}>
                      <Col>
                        <CheckBoxInput
                          type="checkbox"
                          id="missedPrevFillup"
                          name="missedPrevFillup"
                          value="missedPrevFillup"
                          checked={missedPrevFillup}
                          onChange={() => setMissedPrevFillup((prev) => !prev)}
                          style={{ marginRight: "0.5rem" }}
                          disabled={false}
                        />
                        <Label htmlFor="missedPrevFillup" disabled={false}>
                          {t("mf_tv")}
                        </Label>
                        <Desc style={{ paddingLeft: "1.25rem" }}>
                          {t("missed_prev_fillup_desc")}
                        </Desc>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </FormContainer>
    </>
  );
};

export default FillupsForm;

//styles
