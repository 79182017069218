import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
  FilterButton,
} from "../../../shared/components/TableElements";
import { PhotoUploadComponent } from "../../UserManagement/UserDetailsForm";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
  FormValueContainer,
} from "../../../shared/components/form/FormElements";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import { NATEXT, s3Url } from "../../../shared/constants";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import FileUploaderNew from "../../../shared/components/form/FileUploaderNew";
import { CustomInput } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  getDateFnsLocale,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  uploadDocsToS3,
} from "../../../shared/helpers";
import { Card, CardBody } from "../../../shared/components/Card";
import { useAuth } from "../../../contexts/AuthContext";
import { post, postApi } from "../../../api/getUser";
import ErrorPopup from "../../../shared/components/ErrorPopup";
import getUnixTime from "date-fns/getUnixTime";
import defaultPartIcon from "@/shared/img/default/default_parts.png";

const PartsFormPopup = ({
  showPopup,
  setShowPopup,
  mode,
  rowData,
  //setShowSnackbar,
  setSnackbarMsg,
  //onFinish,
}) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);
  const { orgId, userId } = useSelector((state) => state.user);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { tag, setReload } = useAuth();

  let heading = t("add_part");
  let type = "add";
  if (mode === "add-batch") {
    heading = t("add_batch");
    type = "edit";
  }
  if (mode === "edit-batch") {
    heading = t("edit_batch");
    // type = "edit";
  }

  // if (mode === "edit-part") {
  //   heading = t("edit_part");
  // }

  const partName = watch("partName");
  const partNotes = watch("partNotes");
  const purchaseQuantity = watch("purchaseQuantity");
  const warehouseLocation = watch("warehouseLocation");
  const purchaseDate = watch("purchaseDate");
  const costPerUnit = watch("costPerUnit");
  const purchaseCost = watch("purchaseCost");
  const tax = watch("tax");
  const discount = watch("discount");
  const vendor = watch("vendor");
  const storeRoom = watch("storeRoom");
  const rackNo = watch("rackNo");
  const comments = watch("comments");
  const barcode = watch("barcode");

  const [showErrPopup, setShowErrPopup] = useState(false);

  const [partPhoto, setPartPhoto] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [grandTotal, setGrandTotal] = useState("");
  const [batchImgs, setBatchImgs] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [warehouseLocOptions, setWarehouseLocOptions] = useState([]);
  const [syncErrMsg, setSyncErrMsg] = useState("");

  const isDisabled = false; //Being used in customfields

  //Initial Effect
  useEffect(() => {
    //Fetch warehouse locations
    (async () => {
      const locationApiJson = {
        querystring: "fetch_all_locations",
      };
      const locationsRes = await postApi(locationApiJson, "inventory");
      if (locationsRes) {
        const { inventory_data } = locationsRes;
        if (inventory_data?.length > 0) {
          //let locationsArr = inventory_data.split(",");
          let locOptions = [];
          // if (locationsArr?.length > 0) {
          for (let loc of inventory_data) {
            locOptions.push({ label: loc, value: loc });
          }
          setWarehouseLocOptions(locOptions);
          // }
        }
      }
    })();
  }, []);

  //On change of row data
  useEffect(() => {
    //Coming from Create new part in add parts popup
    if (mode === "add-part" && rowData?.part_name) {
      setValue("partName", rowData?.part_name);
    }

    if (mode === "add-batch" || mode === "edit-batch") {
      setValue("partName", rowData?.part_name);
      setValue("partNotes", rowData?.part_notes);
      setValue("barcode", rowData?.barcode);
      //Logic to set Part Image
      if (rowData?.part_img) {
        const imageUrl = `${s3Url}/org_${orgId}/parts/${rowData?.part_id}/${rowData.part_img}`;
        setPartPhoto({ url: imageUrl, imgName: rowData.part_img });
      } else {
        setPartPhoto({ url: defaultPartIcon });
      }
    }
    // else {
    //   if (mode !== "add-part") {
    //     setPartPhoto({ url: defaultPartIcon });
    //   }
    // }

    if (mode === "edit-batch") {
      const {
        vendor,
        location,
        store_room,
        rack_number,
        custom_fields,
        purchase_date,
        purchase_cost_per_unit,
        purchase_total_cost,
        purchase_quantity,
        tax,
        discount,
        notes,
        images,
      } = rowData;
      console.log("rowData", rowData);
      setValue("purchaseDate", new Date(purchase_date * 1000));
      setValue("vendor", vendor || "");
      setValue("warehouseLocation", { label: location, value: location });
      setValue("storeRoom", store_room || "");
      setValue("rackNo", rack_number || "");

      //Custom Fields
      if (custom_fields) {
        const customFieldsArr = JSON.parse(custom_fields);
        if (customFieldsArr?.length > 0) {
          let temp = customFieldsArr.map((f, i) => ({
            ...f,
            id: Date.now() + i,
          }));
          setCustomFields(temp);
        }
      }

      setValue("costPerUnit", +purchase_cost_per_unit || "");
      setValue("purchaseCost", +purchase_total_cost || "");
      setValue("purchaseQuantity", +purchase_quantity || "");
      setValue("discount", +discount || "");
      setValue("tax", +tax || "");
      setValue("comments", notes);

      //Logic to set Images
      let imgNames = [];
      if (images) {
        imgNames = images.split(":::");

        const imgs = [];
        imgNames.map((imgName, i) => {
          imgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `${s3Url}/org_${orgId}/parts/${rowData.part_id}/batch/${imgName}`,
            extension: imgName.split(".").pop(),
          });
        });
        setBatchImgs(imgs);
      }
    }
  }, [rowData, mode]);

  //Set grand total on change of relevant fields
  useEffect(() => {
    let grandTotal = +purchaseCost + +tax - +discount;
    if (+grandTotal > 0) {
      setGrandTotal(grandTotal.toFixed(2));
    } else {
      setGrandTotal("");
    }
  }, [purchaseCost, tax, discount]);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleAddFields = () => {
    setCustomFields((prev) => {
      const temp = [...prev];
      temp.push({ id: Date.now(), key: "", value: "" });
      return temp;
    });
  };

  const handleFieldChange = (e, id) => {
    const { name, value } = e.target;
    setCustomFields((prev) =>
      //let temp = [];
      prev.map((field) => {
        if (field.id === id) {
          return { ...field, [name]: value };
        } else {
          return field;
        }
      })
    );
  };

  const handleRemoveField = (id) => {
    setCustomFields((prev) => prev.filter((field) => field.id !== id));
  };

  const handleTotalCostChange = (e) => {
    if (e.target.value && purchaseQuantity) {
      const priceVal = (+e.target.value / +purchaseQuantity)?.toFixed(3);
      setValue("costPerUnit", priceVal);
    } else {
      setValue("costPerUnit", 0);
    }
  };

  const handleCostPerUnitChange = (e) => {
    if (e.target.value && purchaseQuantity) {
      const totalCostVal = (+e.target.value * +purchaseQuantity).toFixed(2);
      setValue("purchaseCost", totalCostVal);
    } else {
      setValue("purchaseCost", 0);
    }
  };

  const handleQuantityChange = (e) => {
    if (e.target.value && costPerUnit) {
      const totalCostVal = (+e.target.value * +costPerUnit).toFixed(2);
      setValue("purchaseCost", totalCostVal);
    } else {
      setValue("purchaseCost", 0);
    }
  };

  //Handler for creating new warehouse loc
  const handleCreateWarehouseLoc = (data) => {
    const newType = { label: data, value: data };
    setWarehouseLocOptions((prev) => {
      let temp = [...prev];
      temp.push(newType);
      return temp;
    });
    setValue("warehouseLocation", newType);
  };

  const handleErrPopupClose = () => {
    setShowErrPopup(false);
    setSyncErrMsg("");
  };

  const addPart = async () => {
    try {
      if (mode === "add-part") {
        if (!partName) {
          setError(
            "partName",
            {
              type: "required",
              message: t("required_err"),
            },
            { shouldFocus: true }
          );
          return;
        }

        if (warehouseLocation || purchaseQuantity) {
          if (!purchaseQuantity) {
            setError(
              "purchaseQuantity",
              {
                type: "required",
                message: t("required_err"),
              },
              { shouldFocus: true }
            );
            return;
          }
          if (!warehouseLocation) {
            setError(
              "warehouseLocation",
              {
                type: "required",
                message: t("required_err"),
              },
              { shouldFocus: true }
            );
            return;
          }
        }
      }

      if (mode === "add-batch" || mode === "edit-batch") {
        if (!purchaseQuantity) {
          setError(
            "purchaseQuantity",
            {
              type: "required",
              message: t("required_err"),
            },
            { shouldFocus: true }
          );
          return;
        }
        if (!warehouseLocation) {
          setError(
            "warehouseLocation",
            {
              type: "required",
              message: t("required_err"),
            },
            { shouldFocus: true }
          );
          return;
        }
      }

      const partId =
        mode === "add-part" ? `${userId}_${Date.now() + 1}` : rowData?.part_id;
      const partApiJson = {
        querystring: "add_edit_parts",
        part_id: partId,
        part_name: partName,
        notes: partNotes || "",
        barcode: barcode || null,
        image: partPhoto?.imgName || "",
        action: 1,
      };

      let imgsToS3 = [];
      setSaveBtnLoading(true);

      if (mode === "add-part") {
        const partApiRes = await postApi(partApiJson, "inventory");
        if (!partApiRes.success) {
          if (partApiRes?.action_value === "record_exists") {
            setSyncErrMsg(t("dup_part_name_err"));
          }

          if (partApiRes?.action_value === "barcode_record_exists") {
            setSyncErrMsg(t("dup_barcode_err"));
          }
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }

        if (partPhoto?.file) {
          const key = `image_data/org_${orgId}/parts/${partId}/${partPhoto?.imgName}`;
          imgsToS3.push({ file: partPhoto?.file, key });
        }

        // if (partApiRes.success && !warehouseLocation && !purchaseCost) {
        //   setSaveBtnLoading(false);
        //   setShowPopup(false);
        //   setSnackbarMsg(t("record_added_msg", { recordName: t("part") }));
        //   return;
        // }
      }

      if (warehouseLocation && purchaseQuantity) {
        //let noOfBatches = 0;
        const partBatchId =
          mode === "edit-batch"
            ? rowData.parts_batch_id
            : `${userId}_${Date.now() + 2}`;
        // const batchIdDisplay = `${partName.slice(0, 4)?.toLocaleUpperCase()}_${
        //   noOfBatches + 10
        // }`;

        let customFieldsStr = "";
        let customFieldsArr = [];
        if (customFields.length > 0) {
          customFields.forEach((f) => {
            if (f.key && f.value) {
              customFieldsArr.push({ key: f.key, value: f.value });
            }
          });
        }
        if (customFieldsArr.length > 0) {
          customFieldsStr = JSON.stringify(customFieldsArr);
        }

        let batchImgsString = "";
        if (batchImgs.length > 0) {
          let count = 1;
          for (let img of batchImgs) {
            if (img?.hasOwnProperty("existing")) {
              batchImgsString += `${img?.imgName}:::`;
            } else {
              if (img.file) {
                const extension = img?.file?.name.split(".").pop();
                const imgName = `${userId}_${Date.now() + count}.${extension}`;
                const key = `image_data/org_${orgId}/parts/${partId}/batch/${imgName}`;

                batchImgsString += `${imgName}:::`;

                imgsToS3.push({
                  ...img,
                  key: key,
                });
                count += 1;
              }
            }
          }
          batchImgsString = batchImgsString.slice(
            0,
            batchImgsString.length - 3
          );
        }

        let availableQty = +purchaseQuantity || 0;
        if (mode === "edit-batch") {
          availableQty = +rowData?.available_quantity || 0;
        }

        const batchApiJson = {
          querystring: "add_edit_parts_batches",
          parts_batch_id: partBatchId,
          //batch_id: batchIdDisplay,
          part_id: partId,
          location: warehouseLocation?.value || "",
          purchase_date: getUnixTime(purchaseDate),
          vendor: vendor || "",
          store_room: storeRoom || "",
          rack_number: rackNo || "",
          available_quantity: availableQty,
          purchase_quantity: +purchaseQuantity || 0,
          purchase_cost_per_unit: +costPerUnit || 0,
          purchase_total_cost: +purchaseCost,
          discount: +discount || 0,
          tax: +tax || 0,
          custom_fields: customFieldsStr,
          images: batchImgsString,
          notes: comments || "",
          action: mode === "edit-batch" ? 2 : 1,
        };
        const batchApiRes = await postApi(batchApiJson, "inventory");

        if (!batchApiRes.success) {
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }

        if (batchApiRes.success && mode !== "edit-batch") {
          const transactionId = `${userId}_${Date.now() + 3}`;
          const batchTransactionsApiJson = {
            transaction_id: transactionId,
            parts_batch_id: partBatchId,
            transaction_date: getUnixTime(new Date()),
            querystring: "add_edit_batch_transactions",
            old_qty: 0,
            new_qty: +purchaseQuantity || 0,
            reason: 0,
            notes: t("new_batch_purchased"),
            action: 1,
          };
          const batchTransactionsApiRes = await postApi(
            batchTransactionsApiJson,
            "inventory"
          );
          if (!batchTransactionsApiRes?.success) {
            setSaveBtnLoading(false);
            setShowErrPopup(true);
            return;
          }
        }
      }

      const moduleName = mode === "add-part" ? t("part") : t("batch");
      const snackbarMsg =
        mode === "edit-batch"
          ? t("record_edited_msg", { recordName: moduleName })
          : t("record_added_msg", { recordName: moduleName });
      //Upload imgs to s3
      if (imgsToS3.length > 0) {
        const docsUploaded = await uploadDocsToS3(imgsToS3);
        if (docsUploaded) {
          setReload("parts");
          setSaveBtnLoading(false);
          setShowPopup(false);
          setSnackbarMsg(snackbarMsg);
          // if (onFinish) {
          //   onFinish();
          // }
          //setShowSnackbar(true);
          return;
        }
      }

      setReload("parts");
      setSaveBtnLoading(false);
      setShowPopup(false);
      setSnackbarMsg(snackbarMsg);
      // if (onFinish) {
      //   onFinish();
      // }
    } catch (e) {
      console.log(e);
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={syncErrMsg || ""}
          onClose={handleErrPopupClose}
        />
      )}
      <Modal show={showPopup} size="lg">
        <FormContainer
          className="m-0 p-0 d-block"
          //onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header>
            <h4>{heading}</h4>
            <div
              className="d-flex justify-content-end"
              //style={{ width: "-webkit-fill-available" }}
            >
              <ActionIconContainer onClick={handleClose}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <Container className="m-0 p-0">
              <Card className="m-0 p-0">
                <CardBody
                  //className="m-0 p-0"
                  style={{
                    height: `${window.innerHeight - 180}px`,
                    overflow: "auto",
                  }}
                >
                  <Row className="d-flex align-items-center">
                    <Col md={4}>
                      <Row>
                        <Col>
                          <PhotoUploadComponent
                            photo={partPhoto}
                            setPhoto={setPartPhoto}
                            isDisabled={
                              mode === "add-batch" || mode === "edit-batch"
                            }
                            module={"parts"}
                            //rowData={rowData}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {mode === "add-part" ? (
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <FormGroupLabel className="m-0 p-0">
                              {t("part_name")}
                            </FormGroupLabel>
                            <FormItem disabled={false}>
                              <FormField
                                name="partName"
                                control={control}
                                component="input"
                                defaultValue=""
                                placeholder={t("part_name")}
                                style={formInputStyles}
                                disabled={false}
                                rules={{
                                  required:
                                    mode === "add-part"
                                      ? t("required_err")
                                      : false,
                                }}
                              />
                            </FormItem>

                            {errors?.partName && (
                              <Error error={errors.partName.message} />
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <FormGroupLabel>{t("upc_barcode")}</FormGroupLabel>
                            <FormItem disabled={false}>
                              <FormField
                                name="barcode"
                                control={control}
                                component="input"
                                defaultValue=""
                                placeholder={t("upc_barcode")}
                                style={formInputStyles}
                                disabled={false}
                              />
                            </FormItem>

                            {errors?.barcode && (
                              <Error error={errors.barcode.message} />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroupLabel>{t("notes")}</FormGroupLabel>
                            <FormItem disabled={false}>
                              <FormField
                                name="partNotes"
                                control={control}
                                component="input"
                                defaultValue=""
                                placeholder={t("notes")}
                                style={formInputStyles}
                                disabled={false}
                              />
                            </FormItem>

                            {errors?.partNotes && (
                              <Error error={errors.partNotes.message} />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <Col md={6} className="d-flex flex-column gap-1">
                        <Row>
                          <Col md={12}>
                            <FormGroupLabel className="m-0 p-0">
                              {t("part_name")}
                            </FormGroupLabel>
                            <FormValueContainer>
                              {rowData?.part_name}
                            </FormValueContainer>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroupLabel className="m-0 p-0">
                              {t("upc_barcode")}
                            </FormGroupLabel>
                            <FormValueContainer>
                              {rowData?.barcode || NATEXT}
                            </FormValueContainer>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroupLabel className="m-0 p-0">
                              {t("notes")}
                            </FormGroupLabel>
                            <FormValueContainer>
                              {rowData?.part_notes
                                ? rowData?.part_notes
                                : NATEXT}
                            </FormValueContainer>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <hr className="mt-4" />

                  {mode === "add-part" && (
                    <h4 className="mb-2">{t("add_batch")}</h4>
                  )}

                  <Row>
                    <Col md={4}>
                      <FormGroupLabel id="date-label" className="m-0">
                        {t("purchase_date")}
                      </FormGroupLabel>
                      <Controller
                        name="purchaseDate"
                        defaultValue={new Date()}
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <DatePicker
                            id="datepicker"
                            locale={getDateFnsLocale()}
                            dateFormat={"P"}
                            dropdownMode="scroll"
                            maxDate={new Date()}
                            selected={value}
                            onChange={onChange}
                            customInput={
                              <CustomInput
                              //disabled={status == 1 || status == 2}
                              />
                            }
                            placeholderText={t("purchase_date")}
                            showMonthDropdown
                            showYearDropdown
                            //disabled={status == 1 || status == 2}
                          />
                        )}
                        rules={{
                          required: t("required_err"),
                        }}
                      />
                      {errors?.purchaseDate && (
                        <Error error={errors.purchaseDate.message} />
                      )}
                    </Col>
                    <Col md={4}>
                      <FormGroupLabel className="m-0">
                        {t("vendor")}
                      </FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="vendor"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("vendor")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.vendor && (
                        <Error error={errors.vendor.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <FormGroupLabel>{t("warehouse_loc")}</FormGroupLabel>
                      <Controller
                        name="warehouseLocation"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          <CreatableSelect
                            id="warehouse-location-select"
                            options={warehouseLocOptions}
                            //value={vehicles.find((c) => c.value === value)}
                            value={value}
                            onChange={onChange}
                            //onChange={onChange}
                            //   onChange={(v) =>
                            //     handleVehicleOnChange(
                            //       v,
                            //       onChange,
                            //       "filledBy",
                            //       setValue,
                            //       setUserOptions,
                            //       userId
                            //     )
                            //   }
                            onCreateOption={handleCreateWarehouseLoc}
                            placeholder={t("warehouse_loc_placeholder")}
                            styles={formStyles}
                            //isDisabled={status == 1 || status == 2}
                          />
                        )}
                        // rules={{
                        //   required:
                        //     mode === "add-batch" || mode === "edit-batch"
                        //       ? t("required_err")
                        //       : false,
                        // }}
                      />

                      {errors?.warehouseLocation && (
                        <Error error={errors.warehouseLocation.message} />
                      )}
                    </Col>

                    <Col md={4}>
                      <FormGroupLabel>{t("store_room")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="storeRoom"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("store_room")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.storeRoom && (
                        <Error error={errors.storeRoom.message} />
                      )}
                    </Col>

                    <Col md={4}>
                      <FormGroupLabel>{t("rack_no")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="rackNo"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("rack_no")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.rackNo && (
                        <Error error={errors.rackNo.message} />
                      )}
                    </Col>
                  </Row>

                  {/* Custom Fields */}
                  {customFields.length > 0 && (
                    <Row className="pt-4">
                      <Col md={8}>
                        <h5>{t("custom_fields")}</h5>
                      </Col>
                    </Row>
                  )}

                  {customFields.length > 0 && (
                    <Row>
                      <Col md={3} sm={5} xs={5}>
                        <FormGroupLabel>{t("detail_title")}</FormGroupLabel>
                      </Col>
                      <Col md={3} sm={5} xs={5}>
                        <FormGroupLabel>{t("detail")}</FormGroupLabel>
                      </Col>
                    </Row>
                  )}
                  {customFields?.map((field) => {
                    return (
                      <React.Fragment key={field.id}>
                        <Row className="d-flex align-items-center">
                          <Col
                            md={3}
                            sm={5}
                            xs={5}
                            className="d-flex align-items-center gap-2 mt-2 mb-2"
                          >
                            <FormItem
                              disabled={isDisabled}
                              //style={{ width: "160px" }}
                              //disabled={status == 2}
                              //tabIndex={0}
                            >
                              <input
                                type="text"
                                name="key"
                                id="field-key"
                                value={field.key}
                                style={formInputStyles}
                                onChange={(e) => handleFieldChange(e, field.id)}
                                disabled={isDisabled}
                              />
                            </FormItem>
                          </Col>

                          <Col
                            md={3}
                            sm={5}
                            xs={5}
                            className="d-flex align-items-center gap-2 mt-2 mb-2"
                          >
                            <FormItem disabled={isDisabled}>
                              <input
                                type="text"
                                name="value"
                                id="field-value"
                                value={field.value}
                                style={formInputStyles}
                                onChange={(e) => handleFieldChange(e, field.id)}
                                disabled={isDisabled}
                              />
                            </FormItem>
                          </Col>
                          {!isDisabled && (
                            <Col className="mt-2 mb-2">
                              <ActionIconContainer
                                onClick={() => handleRemoveField(field.id)}
                              >
                                <i className="fi fi-rr-cross-small" />
                              </ActionIconContainer>
                            </Col>
                          )}
                        </Row>
                      </React.Fragment>
                    );
                  })}

                  {!isDisabled && (
                    <Row className="pt-3">
                      <Col>
                        <FilterButton type="button" onClick={handleAddFields}>
                          + {t("add_fields")}
                        </FilterButton>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col md={4}>
                      <FormGroupLabel>{t("purchase_qty")}</FormGroupLabel>
                      {mode === "add-part" || mode === "add-batch" ? (
                        <>
                          <FormItem disabled={false}>
                            <FormField
                              name="purchaseQuantity"
                              control={control}
                              component="input"
                              type="number"
                              onKeyDown={handleNegative}
                              onWheel={handleNumberInputOnWheelPreventChange}
                              onKeyUp={(e) => handleQuantityChange(e)}
                              defaultValue=""
                              placeholder={t("purchase_qty")}
                              style={formInputStyles}
                              disabled={false}
                              // rules={{
                              //   required:
                              //     mode === "add-batch" || mode === "edit-batch"
                              //       ? t("required_err")
                              //       : false,
                              // }}
                            />
                          </FormItem>

                          {errors?.purchaseQuantity && (
                            <Error error={errors.purchaseQuantity.message} />
                          )}
                        </>
                      ) : (
                        <FormValueContainer>
                          {rowData?.purchase_quantity}
                        </FormValueContainer>
                      )}
                    </Col>
                    <Col md={4}>
                      <FormGroupLabel>{t("cost_per_unit")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="costPerUnit"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={handleNegative}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          onKeyUp={(e) => handleCostPerUnitChange(e)}
                          defaultValue=""
                          placeholder={t("cost_per_unit")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.costPerUnit && (
                        <Error error={errors.costPerUnit.message} />
                      )}
                    </Col>
                    <Col md={4}>
                      <FormGroupLabel>{t("purchase_cost")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="purchaseCost"
                          control={control}
                          component="input"
                          type="number"
                          defaultValue=""
                          onKeyDown={handleNegative}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          onKeyUp={(e) => handleTotalCostChange(e)}
                          placeholder={t("purchase_cost")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.purchaseCost && (
                        <Error error={errors.purchaseCost.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}></Col>
                    <Col>
                      <FormGroupLabel>{t("discount")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="discount"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={handleNegative}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          defaultValue=""
                          placeholder={t("discount")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.discount && (
                        <Error error={errors.discount.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8}></Col>
                    <Col>
                      <FormGroupLabel>{t("tax")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="tax"
                          control={control}
                          component="input"
                          type="number"
                          onKeyDown={handleNegative}
                          onWheel={handleNumberInputOnWheelPreventChange}
                          defaultValue=""
                          placeholder={t("tax")}
                          style={formInputStyles}
                          disabled={false}
                          //rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.tax && <Error error={errors.tax.message} />}
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col md={8}></Col>
                    <Col>
                      <div style={{ position: "relative" }}>
                        <FormGroupLabel
                          style={{
                            position: "absolute",
                            right: 245,
                            top: -14,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t("grand_total")}
                        </FormGroupLabel>
                        <span style={{ fontWeight: 500, width: "140px" }}>
                          {grandTotal ? `${grandTotal} ${currency}` : NATEXT}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="comments"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("notes_tv")}
                          style={formInputStyles}
                          disabled={false}
                        />
                      </FormItem>

                      {errors?.comments && (
                        <Error error={errors.comments.message} />
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroupLabel>
                        {t("images")}
                        {tag === "free-user" && (
                          <SubscribeTooltipIcon message={t("subscribe_msg")} />
                        )}
                      </FormGroupLabel>
                      <FileUploaderNew
                        disabled={false}
                        images={batchImgs}
                        setImages={setBatchImgs}
                        shouldDelete={true}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <AddFormButton
              id="save-form-button"
              type="button"
              onClick={addPart}
            >
              {saveBtnLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          </Modal.Footer>
        </FormContainer>
      </Modal>
    </>
  );
};

export default PartsFormPopup;
