import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  colorAccent,
  colorHover,
  colorText,
  sidebarColor,
  colorRed,
  colorGray,
  colorBlue,
  colorGreen,
  colorTextNew,
} from "@/utils/palette";
import { left, marginLeft, marginRight } from "@/utils/directions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lighten } from "polished";
import {
  colorGrayNew,
  colorYellowNew,
  sidenavLinkHoverColor,
  sidenavLinkTextColor,
} from "../../../../utils/palette";
import { useAuth } from "../../../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../../../shared/components/SubscribeTooltipIcon";
import { useTranslation } from "react-i18next";

// export const getIconComponent = (value) => {
//   let Component = Home;
//   switch (value) {
//     case "user":
//       Component = User;
//       break;
//     case "vehicles":
//       Component = Vehicles;
//       break;
//     case "daily-time-sheet":
//       Component = DailyTimeSheet;
//       break;
//     case "daily-mileage":
//       Component = DailyMileage;
//       break;
//     case "fillups":
//       Component = Fillups;
//       break;
//     case "services":
//       Component = Services;
//       break;
//     case "other-expenses":
//       Component = OtherExpenses;
//       break;
//     case "reminders":
//       Component = Reminders;
//       break;
//     case "trips":
//       Component = Trips;
//       break;
//     case "vehicle-inpection":
//       Component = VehicleInspection;
//       break;
//     case "inspection-tasks":
//       Component = InspectionTasks;
//       break;
//     case "inspection-forms":
//       Component = InspectionForms;
//       break;
//     case "inspection-scheduling":
//       Component = InspectionScheduling;
//       break;
//     case "inspection-submissions":
//       Component = InspectionSubmissions;
//       break;
//     case "Billing":
//       Component = Billing;
//       break;
//     case "settings":
//       Component = Settings;
//       break;
//     case "organization-settings":
//       Component = OrganizationSettings;
//       break;
//     case "operator-settings":
//       Component = OperatorSettings;
//       break;
//     case "groups":
//       Component = Groups;
//       break;
//     case "faq":
//       Component = Faq;
//       break;
//     case "demo-video":
//       Component = DemoVideo;
//       break;
//     case "feedback":
//       Component = Feedback;
//       break;
//     case "contact-support":
//       Component = ContactSupport;
//       break;
//     default:
//       Component = Home;
//   }
//   return <Component fill={sidenavLinkTextColor} />;
// };

const SidebarLink = ({ title, icon, newLink, route, onClick, id }) => {
  const { tag } = useAuth();
  const { t } = useTranslation("common");

  let showDollarIcon = false;
  let subscribeMsg = t("subscribe_msg");
  if (route.includes("workorders") || route.includes("parts")) {
    if (tag === "essential-user") {
      showDollarIcon = true;
      subscribeMsg = t("subscribe_to_advanced_msg");
    }
  }

  if (route.includes("groups")) {
    if (tag === "free-user") {
      showDollarIcon = true;
      subscribeMsg = t("subscribe_msg");
    }
  }

  if (route.includes("integrations")) {
    if (tag === "free-user" || tag === "essential-user") {
      showDollarIcon = true;
      subscribeMsg = t("subscribe_to_advanced_msg");
    }
  }

  return (
    <li style={{ padding: "7px 0px" }} id={id || "sidebar-link"}>
      <SidebarNavLink to={route} onClick={onClick} activeClassName="active">
        <SidebarIcon className={icon}></SidebarIcon>
        <SidebarLinkTitle>{title}</SidebarLinkTitle>

        {newLink && (
          <NewBadge bg="custom">
            <span style={{ fontSize: "8px" }}>{t("new")}</span>
          </NewBadge>
        )}
        {showDollarIcon && (
          <div
            className="d-flex align-items-center justify-content-center mx-1"
            style={{ marginBottom: "2px" }}
          >
            <SubscribeTooltipIcon message={subscribeMsg} />
          </div>
        )}
      </SidebarNavLink>
    </li>
  );
};

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLink.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => {},
};

export default SidebarLink;

// region STYLES

export const SidebarLinkTitle = styled.span`
  padding-left: 4px;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  color: ${sidenavLinkTextColor};
`;

export const IconContainer = styled.div`
  width: 14px;
  height: 14px;

  svg {
    margin-bottom: 10px;
  }
`;

export const SidebarIcon = styled.i`
  font-size: 16px;
  position: relative;
  top: 2px;
  color: ${colorGrayNew};
`;

export const SidebarNavLink = styled(NavLink)`
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: ${colorTextNew};
  text-align: ${left};
  font-size: 14px;

  svg {
    color: ${colorTextNew};
  }

  &.active {
    color: ${colorBlue};
    &:before {
      opacity: 1;
    }

    svg {
      color: ${colorBlue};
    }

    ${IconContainer} {
      svg {
        g {
          //fill: ${colorBlue};
          fill: ${colorYellowNew};
        }
      }
    }

    ${SidebarLinkTitle} {
      color: ${colorYellowNew};
    }

    ${SidebarIcon} {
      color: ${colorYellowNew};
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    //background: ${colorAccent};
    background: ${colorYellowNew};
    opacity: 0;
    transition: all 0.3s;
    ${left}: 0;
  }

  &:hover {
    text-decoration: none;
    //background-color: ${lighten(0.2, colorBlue)};
    background-color: ${sidenavLinkHoverColor};

    color: ${colorBlue};

    &:before {
      opacity: 1;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;

    span {
      position: relative;
      animation: none;
      ${left}: 0;
    }
  }
`;

export const NewBadge = styled(Badge)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 16px;
  background-color: ${colorYellowNew};
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  ${marginLeft}: 5px;
  color: black;
`;

// export const SidebarLinkIcon = styled.span`
//   font-size: 13px;
//   line-height: 13px;
//   color: ${lighten(0.25, colorGray)};
//   ${marginRight}: 10px;
// `;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 13px;
  line-height: 13px;
  //color: ${colorGray};
  color: ${colorGrayNew};
  ${marginRight}: 10px;
`;
export const SidebarLinkIcon = ({ icon }) => (
  <StyledFontAwesomeIcon icon={icon} />
);

// endregion
