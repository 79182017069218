import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  CostElement,
  CostInput,
  FormGroupLabel,
  FormItem,
  LinkSpan,
  UnitSpan,
} from "./FormElements";
import { colorBg } from "../../../utils/palette";
import {
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../helpers";
import Error from "@/shared/components/form/Error";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AddFormButton, Icon, ActionIconContainer } from "../TableElements";
import {
  MultiValueOption,
  TaskCheckboxInput,
  TaskLabel,
} from "../../../components/VehicleInspection/InspectionForms/InspectionFormsForm";
import CreatableSelect from "react-select/creatable";
import { formInputStyles, openDropdownStyles } from "../../constants/styles";
import { useSelector } from "react-redux";
import { fetchServicesTasks } from "../../../features/servicesTasks/servicesTasksSlice";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../../features/formData/formDataSlice";
import AddButton from "./AddButton";
import { components } from "react-select";
import ServiceTasksFormPopup from "../../../components/Services/ServiceTasksFormPopup";
import { useRef } from "react";
import { postApi } from "../../../api/getUser";
import { updateServiceTaskOptions } from "../../../components/Services/ServicesForm";
import { NATEXT } from "../../constants";
import AddPartsPopup from "../../../components/Inventory/components/AddPartsPopup";
import UnitsConsumedPopup from "../../../components/Inventory/components/UnitsConsumedPopup";
import PartsFormPopup from "../../../components/Inventory/Parts/PartsFormPopup";
import SubscribePopup from "../SubscribePopup";
import { useAuth } from "../../../contexts/AuthContext";

const ServiceTasks = ({
  showAddServiceModal,
  setShowAddServiceModal,
  serviceTasks,
  setServiceTasks,
  partsCostTotal,
  setPartsCostTotal,
  laborCostTotal,
  setLaborCostTotal,
  tax,
  setTax,
  discount,
  setDiscount,
  total,
  setTotal,
  grandTotal,
  setGrandTotal,
  showServiceTasksError,
  setShowServiceTasksError,
  showDiscountError,
  workOrderStatus,
  showAddServiceTaskFormPopup,
  setShowAddServiceTaskFormPopup,
  selectedVehicleId,
  serviceTaskOptions,
  setServiceTaskOptions,
  serviceTasksRef,
  serviceTasksChanged,
  setServiceTasksChanged,
  vehicle,
  partBatches,
  setPartBatches,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => state.units);
  const { tag } = useAuth();

  const serviceTasksErrorMsg = t("invalid_st_msg");

  const [showAddPartsPopup, setShowAddPartsPopup] = useState(false);
  const [showUnitsConsumedPopup, setShowUnitsConsumedPopup] = useState(false);
  //service task detail id for which part is being added
  //to be set on click of add part
  const serviceTaskDetailId = useRef(null);

  // const [partBatches, setPartBatches] = useState([]);

  const [showPartsForm, setShowPartsForm] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(false);
  const [partRowData, setPartRowData] = useState(false);

  //This state is used to check whether Parts Popup has been opened from +Add Parts in Parts Consumed popup
  //If so, then Parts Consumed popup needs to be reopened once Parts popup is closed
  const [fromUnitsConsumed, setFromUnitsConsumed] = useState(false);

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [subscribePopupErrMsg, setsubscribePopupErrMsg] = useState("");

  //Effect to get all service tasks
  useEffect(() => {
    (async () => {
      const apiData = {
        //email: "testgroup@12.in",
        querystring: "getallservicetask",
        service_veh_id: "All",
      };
      dispatch(fetchServicesTasks(apiData));
    })();
  }, []);

  useEffect(() => {
    //To check if any service task is undefined
    const isUndefined = serviceTasks?.some((t) => !t);
    if (serviceTasks?.length > 0 && !isUndefined) {
      setShowServiceTasksError(false);
      const laborTotalArr = serviceTasks
        .map((t) => t.laborCost)
        .filter((ele) => ele);
      const partsTotalArr = serviceTasks
        .map((t) => t.partsCost)
        .filter((ele) => ele);
      const totalArr = serviceTasks
        .map((t) => t.totalCost)
        .filter((ele) => ele);
      let partsTotal = partsTotalArr.reduce((sum, n) => +sum + +n, 0);
      let laborTotal = laborTotalArr.reduce((sum, n) => +sum + +n, 0);
      if (+partsTotal) {
        partsTotal = +partsTotal.toFixed(2);
      }
      if (+laborTotal) {
        laborTotal = +laborTotal.toFixed(2);
      }
      setPartsCostTotal(partsTotal);
      setLaborCostTotal(laborTotal);
      let total = +partsTotal + +laborTotal;
      if (+total) {
        total = +total.toFixed(2);
      }
      setTotal(total);
      let totalCost = +partsTotal + +laborTotal - +discount + +tax;
      if (+totalCost) {
        totalCost = totalCost.toFixed(2);
      }
      if (totalCost >= 0) {
        setGrandTotal(totalCost);
      } else {
        setGrandTotal(0);
      }
      //setGrandTotal(partsTotal + laborTotal - discount + tax);
    } else {
      setPartsCostTotal("");
      setLaborCostTotal("");
      setTotal("");
      setGrandTotal(0);
    }
  }, [serviceTasks, discount, tax]);

  const handleServiceTaskDelete = (id) => {
    setServiceTasks((prev) => {
      const temp = prev.filter((t) => t.service_task_id !== id);
      return temp;
    });

    //Update Service Tasks Edit
    serviceTasksRef.current = serviceTasksRef.current.reduce((result, t) => {
      //The task to be deleted was already present in edit tasks
      //Check if t.hasOwnProperty("service_task_detail_id") since if the task does not
      //have 'service_task_detail_id', the task has been added and removed in the
      //same edit cycle.
      //Alternatively, we can check if action === 2, since all existing tasks will have action 2
      if (
        t.service_task_id === id &&
        t.action === 2
        //t.hasOwnProperty("service_task_detail_id") // Or action === 2
      ) {
        //Make all corresponding batches action 3, if any
        let batches = [];
        if (t?.batches?.length > 0) {
          batches = t.batches.map((b) => ({ ...b, action: 3 }));
        }
        result.push({ ...t, action: 3, batches });
      }
      if (
        t.service_task_id !== id ||
        (t.service_task_id === id && t.action === 3)
      ) {
        result.push(t);
        //return t;
      }

      return result;
    }, []);

    //Update service tasks changed flag
    if (!serviceTasksChanged) {
      setServiceTasksChanged(true);
    }
  };

  const handlePartsChange = (e, id) => {
    let { value } = e.target;

    setServiceTasks((prev) => {
      const temp = prev.map((item) => {
        if (item.service_task_id === id) {
          return {
            ...item,
            partsCost: +value == 0 ? "" : +value,
            totalCost:
              +value == 0
                ? (+item.laborCost)?.toFixed(2)
                : (+value + +item.laborCost)?.toFixed(2),
          };
        } else {
          return item;
        }
      });
      return temp;
    });

    //Update Service Tasks Edit
    serviceTasksRef.current = serviceTasksRef.current.map((item) => {
      if (item.service_task_id === id && item.action !== 3) {
        return {
          ...item,
          partsCost: +value == 0 ? "" : +value,
          totalCost:
            +value == 0
              ? (+item.laborCost)?.toFixed(2)
              : (+value + +item.laborCost)?.toFixed(2),
        };
      } else {
        return item;
      }
    });

    //Update service tasks changed flag
    if (!serviceTasksChanged) {
      setServiceTasksChanged(true);
    }
  };

  const handleLaborChange = (e, id) => {
    const { value } = e.target;
    setServiceTasks((prev) => {
      const temp = prev.map((item) => {
        if (item.service_task_id === id) {
          return {
            ...item,
            laborCost: +value == 0 ? "" : +value,
            totalCost:
              +value == 0
                ? (+item.partsCost)?.toFixed(2)
                : (+value + +item.partsCost)?.toFixed(2),
          };
        } else {
          return item;
        }
      });
      return temp;
    });

    //Update Service Tasks Edit
    serviceTasksRef.current = serviceTasksRef.current.map((item) => {
      if (item.service_task_id === id && item.action !== 3) {
        return {
          ...item,
          laborCost: +value == 0 ? "" : +value,
          totalCost:
            +value == 0
              ? (+item.partsCost)?.toFixed(2)
              : (+value + +item.partsCost)?.toFixed(2),
        };
      } else {
        return item;
      }
    });

    //Update service tasks changed flag
    if (!serviceTasksChanged) {
      setServiceTasksChanged(true);
    }
  };

  const handleAddEditPart = (task) => {
    if (tag === "essential-user") {
      setsubscribePopupErrMsg(t("subscribe_to_advanced_msg"));
      setShowSubscribePopup(true);
      return;
    }
    //We are taking from serviceTasksRef since it contains the service_task_detail_id
    //which we require for parts/batches
    const selectedTaskFromRef = serviceTasksRef.current.find(
      (t) => t.service_task_id === task.service_task_id
    );
    serviceTaskDetailId.current = selectedTaskFromRef.service_task_detail_id;
    if (task?.partsDisplayArr?.length > 0) {
      setShowUnitsConsumedPopup(true);
    } else {
      setShowAddPartsPopup(true);
    }
  };

  const togglePartsViewMore = (task) => {
    setServiceTasks((prev) => {
      let temp = [...prev];
      temp = temp.map((t) =>
        t.service_task_id === task.service_task_id
          ? { ...t, viewMore: !t.viewMore }
          : t
      );
      return temp;
    });
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={subscribePopupErrMsg}
        />
      )}
      {showAddPartsPopup && (
        <AddPartsPopup
          showPopup={showAddPartsPopup}
          setShowPopup={setShowAddPartsPopup}
          serviceTaskDetailId={serviceTaskDetailId}
          serviceTasks={serviceTasks}
          setServiceTasks={setServiceTasks}
          partBatches={partBatches}
          setPartBatches={setPartBatches}
          showUnitsConsumedPopup={showUnitsConsumedPopup}
          setShowUnitsConsumedPopup={setShowUnitsConsumedPopup}
          serviceTasksRef={serviceTasksRef}
          setPartRowData={setPartRowData}
          setShowPartsForm={setShowPartsForm}
          fromUnitsConsumed={fromUnitsConsumed}
          setFromUnitsConsumed={setFromUnitsConsumed}
        />
      )}
      {showUnitsConsumedPopup && (
        <UnitsConsumedPopup
          showPopup={showUnitsConsumedPopup}
          setShowPopup={setShowUnitsConsumedPopup}
          serviceTaskDetailId={serviceTaskDetailId}
          partBatches={partBatches}
          setPartBatches={setPartBatches}
          serviceTasksRef={serviceTasksRef}
          //serviceTasks={serviceTasks}
          setServiceTasks={setServiceTasks}
          setShowAddPartsPopup={setShowAddPartsPopup}
          setFromUnitsConsumed={setFromUnitsConsumed}
          serviceTasksChanged={serviceTasksChanged}
          setServiceTasksChanged={setServiceTasksChanged}
        />
      )}
      {/* {showPartsForm && (
        <PartsFormPopup
          showPopup={showPartsForm}
          setShowPopup={setShowPartsForm}
          mode={"add-batch"}
          rowData={partRowData}
          setSnackbarMsg={setSnackbarMsg}
          onFinish={() => setShowAddPartsPopup(true)}
          //setShowSnackbar={setShowSnackbar}
        />
      )} */}
      <AddServiceModal
        showAddServiceModal={showAddServiceModal}
        setShowAddServiceModal={setShowAddServiceModal}
        serviceTasks={serviceTasks}
        setServiceTasks={setServiceTasks}
        showAddServiceTaskFormPopup={showAddServiceTaskFormPopup}
        setShowAddServiceTaskFormPopup={setShowAddServiceTaskFormPopup}
        selectedVehicleId={selectedVehicleId}
        serviceTaskOptions={serviceTaskOptions}
        setServiceTaskOptions={setServiceTaskOptions}
        serviceTasksRef={serviceTasksRef}
        serviceTasksChanged={serviceTasksChanged}
        setServiceTasksChanged={setServiceTasksChanged}
        vehicle={vehicle}
      />
      <div style={{ backgroundColor: colorBg }}>
        <Row className="px-3 py-2">
          <Col md={5}>
            <FormGroupLabel className="m-0">{t("task_name")}</FormGroupLabel>
          </Col>
          <Col md={2}>
            <FormGroupLabel
              className="m-0"
              style={{ textAlign: "right" }}
            >{`${t("parts")} (${currency})`}</FormGroupLabel>
          </Col>
          <Col md={2}>
            <FormGroupLabel
              className="m-0"
              style={{ textAlign: "right" }}
            >{`${t("labor")} (${currency})`}</FormGroupLabel>
          </Col>
          <Col md={2}>
            <FormGroupLabel
              className="m-0"
              style={{ textAlign: "right" }}
            >{`${t("total")} (${currency})`}</FormGroupLabel>
          </Col>
        </Row>
        {serviceTasks?.map((task) => {
          return (
            <React.Fragment key={task?.service_task_id}>
              <Row className="px-3 py-2 d-flex align-items-center">
                <Col md={3}>
                  <Row className="m-0 p-0 d-flex gap-2 align-items-center">
                    <Col md={1} className="m-0 p-0">
                      {task?.partsDisplayArr?.length > 0 && (
                        <i
                          className={`fi fi-rr-angle-small-${
                            task.viewMore ? "down" : "right"
                          }`}
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: "2px",
                          }}
                          onClick={() => togglePartsViewMore(task)}
                        ></i>
                      )}
                    </Col>
                    <Col md={10} className="m-0 p-0">
                      <p>{task?.service_task_name}</p>
                    </Col>
                  </Row>
                  {/* <div className="d-flex gap-2 align-items-center">
                    {task?.partsDisplayArr?.length > 0 && (
                      // <ActionIconContainer
                      //   onClick={() => togglePartsViewMore(task)}
                      // >
                      <i
                        className={`fi fi-rr-angle-${
                          task.viewMore ? "down" : "right"
                        }`}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "2px",
                        }}
                        onClick={() => togglePartsViewMore(task)}
                      ></i>
                      // </ActionIconContainer>
                    )}
                    <p>{task?.service_task_name}</p>
                  </div> */}
                </Col>
                {workOrderStatus !== 3 && (
                  <Col md={2}>
                    <LinkSpan onClick={() => handleAddEditPart(task)}>
                      {task?.noOfParts
                        ? t("count_parts", { count: task.noOfParts })
                        : t("select_part")}
                    </LinkSpan>
                  </Col>
                )}
                <Col md={2}>
                  <CostInput
                    type="number"
                    //min="0"
                    onKeyDown={(e) => handleNegative(e)}
                    onWheel={handleNumberInputOnWheelPreventChange}
                    //value={task.partsCost}
                    //defaultValue={0}
                    value={task?.partsCost}
                    onChange={(e) =>
                      handlePartsChange(e, task?.service_task_id)
                    }
                  />
                </Col>
                <Col md={2}>
                  <CostInput
                    type="number"
                    onWheel={handleNumberInputOnWheelPreventChange}
                    //min="0"
                    //value={task.laborCost}
                    //defaultValue={0}
                    value={task?.laborCost}
                    onKeyDown={(e) => handleNegative(e)}
                    onChange={(e) =>
                      handleLaborChange(e, task?.service_task_id)
                    }
                  />
                </Col>
                <Col md={2}>
                  <CostElement>{task?.totalCost}</CostElement>
                </Col>
                <Col md={1}>
                  {workOrderStatus !== 3 && (
                    <ActionIconContainer
                      style={{ top: 1 }}
                      onClick={() =>
                        handleServiceTaskDelete(task?.service_task_id)
                      }
                    >
                      <i className="fi fi-rr-cross-small" />
                    </ActionIconContainer>
                  )}
                </Col>
              </Row>
              {task?.viewMore && task?.partsDisplayArr?.length > 0 && (
                <Row style={{ background: "white" }} className="mx-3 my-2 p-2">
                  <Col>
                    <Row>
                      <Col>
                        <p style={{ fontSize: 12, fontWeight: 300 }}>
                          {t("parts_used_from_inventory")}
                        </p>
                      </Col>
                    </Row>
                    {task?.partsDisplayArr.map((partStr) => (
                      <Row>
                        <Col>
                          <p>{partStr}</p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              )}
            </React.Fragment>
          );
        })}
        {workOrderStatus !== 3 && (
          <Row className="px-4 py-0">
            <AddButton handleClick={() => setShowAddServiceModal(true)} />
          </Row>
        )}
        <Row className="pt-2 pb-0 m-0">
          <hr className="p-0 m-0" />
        </Row>
        <Row className="px-4 py-2">
          <Col md={5}>
            <p>{t("total")}</p>
          </Col>
          <Col md={2}>
            <CostElement>{partsCostTotal}</CostElement>
          </Col>
          <Col md={2}>
            <CostElement>{laborCostTotal}</CostElement>
          </Col>
          <Col md={2}>
            <CostElement style={{ padding: "0 0.25rem" }}>{total}</CostElement>
          </Col>
        </Row>
      </div>
      {showServiceTasksError && <Error error={serviceTasksErrorMsg} />}

      <Row className="py-3">
        <Col className="d-flex justify-content-end align-items-end flex-column gap-3">
          <div className="d-flex align-items-center justify-content-center">
            <FormGroupLabel htmlFor="discount" className="m-0 px-2">
              {t("discount")}
            </FormGroupLabel>
            <FormItem style={{ width: "140px" }}>
              <input
                name="discount"
                id="discount-input"
                type="number"
                onWheel={handleNumberInputOnWheelPreventChange}
                //min={0}
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
                onKeyDown={(e) => handleNegative(e)}
                //defaultValue=""
                //placeholder={t("odometer")}
                style={{ ...formInputStyles }}
                //disabled={status == 2}
              />
              <UnitSpan>{currency}</UnitSpan>
            </FormItem>
          </div>
          {showDiscountError && (
            <Error error={t("discount_greater_than_total_err_msg")} />
          )}

          <div className="d-flex align-items-center justify-content-center">
            <FormGroupLabel htmlFor="tax" className="m-0 px-2">
              {t("tax")}
            </FormGroupLabel>
            <FormItem
              style={{ width: "140px" }}
              //disabled={status == 2}
              //tabIndex={0}
            >
              <input
                name="tax"
                id="tax-input"
                type="number"
                onWheel={handleNumberInputOnWheelPreventChange}
                value={tax}
                onChange={(e) => setTax(e.target.value)}
                //min={0}
                onKeyDown={(e) => handleNegative(e)}
                //defaultValue=""
                //placeholder={t("odometer")}
                style={{ ...formInputStyles }}
                //disabled={status == 2}
              />
              <UnitSpan>{currency}</UnitSpan>
            </FormItem>

            {/* {errors?.tax && <Error error={errors.tax.message} />} */}
          </div>

          <div
            className="d-flex align-items-center justify-content-center"
            //style={{ width: "140px" }}
          >
            <FormGroupLabel className="m-0 px-2">
              {t("grand_total")}
            </FormGroupLabel>
            <span style={{ fontWeight: 500, width: "140px" }}>
              {grandTotal ? `${grandTotal} ${currency}` : NATEXT}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ServiceTasks;

export const AddServiceModal = ({
  showAddServiceModal,
  setShowAddServiceModal,
  serviceTasks,
  setServiceTasks,
  selectedVehicleId,
  serviceTaskOptions,
  setServiceTaskOptions,
  serviceTasksRef,
  serviceTasksChanged,
  setServiceTasksChanged,
  vehicle,
}) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const newTaskInfo = useRef("");
  const [newServiceTaskId, setNewServiceTaskId] = useState("");
  const [showAddServiceTaskFormPopup, setShowAddServiceTaskFormPopup] =
    useState(false);

  const [serviceTasksToShow, setServiceTasksToShow] =
    useState(serviceTaskOptions);

  useEffect(() => {
    if (serviceTasks.length > 0) {
      const serviceTaskIds = serviceTasks.map((t) => t.service_task_id);
      setServiceTasksToShow(
        serviceTaskOptions.filter(
          (task) => !serviceTaskIds.includes(task.service_task_id)
        )
      );
    } else {
      setServiceTasksToShow(serviceTaskOptions);
    }
  }, [serviceTaskOptions, serviceTasks]);

  //To add newly created service task
  useEffect(() => {
    if (newServiceTaskId) {
      setShowAddServiceModal(true);
      (async () => {
        // if (serviceTasksStatus === "idle") {
        const apiData = {
          //email: "testgroup@12.in",
          querystring: "getallservicetask",
          service_veh_id: selectedVehicleId,
        };
        // const res = await postApi(apiData, "commonNew");
        // if (res.success) {
        //   const { user_data } = res;
        //   setServiceTaskOptions(user_data);
        // }
        const serviceTasksRes = await updateServiceTaskOptions(apiData);
        setServiceTaskOptions(serviceTasksRes);

        //Add newly created service task to service tasks
        const newServiceTask = serviceTasksRes?.find(
          (t) => t.service_task_id === newServiceTaskId
        );
        if (
          newServiceTask &&
          !serviceTasks?.find((t) => t.service_task_id === newServiceTaskId)
        ) {
          setServiceTasks((prev) => [...prev, newServiceTask]);

          //Update Service Tasks Edit
          serviceTasksRef.current.push({
            ...newServiceTask,
            action: 1,
            service_task_detail_id: `${vehicle.vehicleId}_${
              Date.now() + serviceTasksRef.current.length + 1
            }`,
          });

          //Update service tasks changed flag
          if (!serviceTasksChanged) {
            setServiceTasksChanged(true);
          }
        }
      })();
    }
  }, [newServiceTaskId]);

  // useEffect(() => {
  //   if (newServiceTaskId) {
  //     const newServiceTask = serviceTaskOptions?.find(
  //       (t) => t.service_task_id === newServiceTaskId
  //     );
  //     if (
  //       newServiceTask &&
  //       !serviceTasks?.find((t) => t.service_task_id === newServiceTaskId)
  //     ) {
  //       setServiceTasks((prev) => [...prev, newServiceTask]);

  //       //Update Service Tasks Edit
  //       serviceTasksRef.current.push({
  //         ...newServiceTask,
  //         action: 1,
  //         service_task_detail_id: `${vehicle.vehicleId}_${
  //           Date.now() + serviceTasksRef.current.length + 1
  //         }`,
  //       });

  //       //Update service tasks changed flag
  //       if (!serviceTasksChanged) {
  //         setServiceTasksChanged(true);
  //       }
  //     }
  //   }
  // }, [serviceTaskOptions]);

  //Effect to select tasks based on existing service tasks
  // useEffect(() => {
  //   if (showAddServiceModal) {
  //     setSelectedTasks(serviceTasks);
  //   }
  // }, [showAddServiceModal, serviceTasks]);

  // useEffect(() => {
  //   setSelectedTasks(serviceTasks);
  // }, [serviceTasks]);

  //Effect to clear selected tasks
  useEffect(() => {
    setSelectedTasks([]);
  }, [showAddServiceModal]);

  const handleOK = () => {
    if (selectedTasks.length > 0) {
      setServiceTasks((prev) => [...prev, ...selectedTasks]);

      //Update service tasks changed flag
      if (!serviceTasksChanged) {
        setServiceTasksChanged(true);
      }

      for (let task of selectedTasks) {
        serviceTasksRef.current.push({
          ...task,
          action: 1,
          service_task_detail_id: `${vehicle.vehicleId}_${
            Date.now() + serviceTasksRef.current.length + 1
          }`,
          batches: [], //batches of parts
        });
      }
    }
    setShowAddServiceModal(false);
  };

  const handleCreate = (value) => {
    newTaskInfo.current = { taskName: value, vehicleId: selectedVehicleId };
    setShowAddServiceModal(false);
    setShowAddServiceTaskFormPopup(true);
  };

  return (
    <>
      <ServiceTasksFormPopup
        showPopup={showAddServiceTaskFormPopup}
        setShowPopup={setShowAddServiceTaskFormPopup}
        newTaskInfo={newTaskInfo}
        newServiceTaskId={newServiceTaskId}
        setNewServiceTaskId={setNewServiceTaskId}
      />
      <Modal
        show={showAddServiceModal}
        onHide={() => setShowAddServiceModal((prev) => !prev)}
      >
        <Modal.Body
          style={
            {
              //height: "-webkit-fill-available",
              //height: `${window.innerHeight - 100}px`,
            }
          }
        >
          <div className="d-flex justify-content-end mb-2">
            <ActionIconContainer
              onClick={() => setShowAddServiceModal((prev) => !prev)}
            >
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
          <div
            style={{
              height: `${window.innerHeight - 170}px`,
            }}
          >
            <CreatableSelect
              options={serviceTasksToShow}
              value={selectedTasks}
              //value={vehicles.find((c) => c.value === value)}
              //value={serviceTask}
              onChange={setSelectedTasks}
              onCreateOption={handleCreate}
              placeholder={t("service_task_placeholder")}
              styles={openDropdownStyles}
              defaultMenuIsOpen
              isMulti
              menuIsOpen
              isClearable
              isSearchable
              closeMenuOnSelect={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              components={{
                Option: MultiValueOption,
              }}
              //onCreateOption={(data) => console.log(data)}
              //isDisabled={status == 1 || status == 2}
            />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <AddFormButton onClick={handleOK}>{t("ok")}</AddFormButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
