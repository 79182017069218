import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import { putApi } from "../../api/getUser";
import { LinkSpan } from "../../shared/components/form/FormElements";
import {
  ActionIconContainer,
  ScrollableTableDiv,
  TableHeadSticky,
} from "../../shared/components/TableElements";
import { Modal, Table } from "react-bootstrap";
import { colorBorder, colorLightGray } from "../../utils/palette";
import { getDistanceUnit } from "../../shared/helpers";
import { useSelector } from "react-redux";
import { NATEXT, s3Url } from "../../shared/constants";

const SingleVehicleInfo = ({ vehicle }) => {
  const { t } = useTranslation("common");
  const { distance } = useSelector((state) => state.units);
  const { orgId } = useSelector((state) => state.user);

  const [operators, setOperators] = useState([]);
  const [currentOdo, setCurrentOdo] = useState(0);
  const [showOperatorsPopup, setShowOperatorsPopup] = useState(false);

  useEffect(() => {
    if (vehicle?.vehicleId) {
      (async () => {
        const apiData = {
          querystring: "get_sidepanel_data", //"getvehicleoperators",
          veh_id: vehicle.vehicleId,
          //filter_date_end: new Date() / 1000,
        };

        const res = await putApi(apiData, "dashboard");
        if (res.success) {
          const { current_odo, vehicle_info, vehicle_operators_data } =
            res.data;
          setCurrentOdo(current_odo[0].odo);
          if (vehicle_operators_data.length > 0) {
            setOperators(vehicle_operators_data);
          }
        }
      })();
    }
  }, [vehicle]);

  const imageUrl =
    vehicle?.veh_img && vehicle?.veh_img !== NATEXT
      ? `${s3Url}/org_${orgId}/vehicle_${vehicle?.veh_id}/${vehicle?.veh_img}`
      : defaultVehicleIcon;

  const OperatorsPopup = () => {
    return (
      <Modal
        show={showOperatorsPopup}
        onHide={() => setShowOperatorsPopup(false)}
      >
        <Modal.Header>
          <h4>{vehicle.name}</h4>
          <div className="d-flex justify-content-end">
            <ActionIconContainer onClick={() => setShowOperatorsPopup(false)}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ScrollableTableDiv>
            <Table
              bordered
              className="m-0 p-0"
              style={{ borderColor: colorBorder }}
            >
              <TableHeadSticky
              // style={
              //   {
              //     //   background: colorLightGray,
              //     //   //border: `2px solid ${colorLightGray}`,
              //     //   position: "sticky",
              //     //   zIndex: 100,
              //     //   top: -2,
              //   }
              // }
              >
                <tr>
                  <th>{t("name")}</th>
                </tr>
              </TableHeadSticky>
              <tbody>
                {operators.map((o) => (
                  <tr>
                    <td>{o.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollableTableDiv>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      {showOperatorsPopup && <OperatorsPopup />}
      <div className="d-flex flex-column gap-3">
        <div className="d-flex align-items-center gap-2">
          <img
            src={imageUrl}
            alt="vehicle-img"
            style={{ width: "40px", height: "40px" }}
            className="rounded-circle"
          />

          <div className="d-flex flex-column">
            <span style={{ fontSize: "16px", fontWeight: 500 }}>
              {vehicle?.name}
            </span>
            {vehicle.group_name && (
              <span style={{ fontSize: "12px", fontWeight: 400 }}>
                {vehicle?.group_name}
              </span>
            )}
            {vehicle.secondary_status === 1 && vehicle.veh_active === 0 && (
              <span style={{ fontSize: "12px", color: "red", fontWeight: 400 }}>
                {t("out_of_service")}
              </span>
            )}
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <Label>{t("current_odo")}: </Label>
          <Value>{`${currentOdo} ${getDistanceUnit(
            vehicle?.primary_meter === "Hours"
              ? vehicle?.primary_meter
              : distance
          )}`}</Value>
        </div>

        {operators.length > 0 && (
          <div className="d-flex flex-column">
            <Label>{t("assigned_operators")}</Label>
            <Value>
              {operators
                .slice(0, 4)
                .map((o) => o.name)
                .join(", ")}
              <LinkSpan
                style={{ fontSize: "14px", marginLeft: "0.25rem" }}
                onClick={() => setShowOperatorsPopup(true)}
              >
                {t("view_all")}
              </LinkSpan>
            </Value>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleVehicleInfo;

//styles
const Label = styled.span`
  font-size: 12px;
  font-weight: 300;
`;

const Value = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
