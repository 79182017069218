import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  HintText,
  Label,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import { Col, Container, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { format } from "date-fns";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import {
  CustomInput,
  Desc,
  TimeSelect,
} from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { postApi } from "../../../api/getUser";
import {
  getDateFnsLocale,
  getDateWithTime,
  getDistanceFactorForEff,
  getDistanceFactorforOdo,
  getImgString,
  getInitialStartTime,
  getVolumeFactor,
  getVolumeFactorForEff,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  isObjEmpty,
  odoValidation,
  uploadDocsToS3,
} from "../../../shared/helpers";
import useFetchOdoHint from "../../../shared/custom-hooks/useFetchOdoHint";
import FileUploader from "../../../shared/components/form/FileUploader";
import ImageWithZoom from "../../../shared/components/form/ImageWithZoom";
import { FileUploaderContainer } from "../../Issues/IssuesForm";
import { scrollbarStyles } from "../../../utils/palette";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import { useAuth } from "../../../contexts/AuthContext";
import FileUploaderNew from "../../../shared/components/form/FileUploaderNew";
import ErrorPopup from "../../../shared/components/ErrorPopup";
import { s3Url } from "../../../shared/constants";
import useAutosizeTextArea from "../../../shared/custom-hooks/useAutosizeTextArea";

const FillupsFormPopup = ({
  showPopup,
  setShowPopup,
  newRecordData,
  setNewRecordData,
  startingOdo,
  depDate,
  userOptions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { userId, orgId } = useSelector((state) => state.user);
  const { currency } = useSelector((state) => state.units);
  const { distance, volume, consumption, hourlyConsumption } = useSelector(
    (state) => state.units
  );

  const { tag } = useAuth();
  const type = newRecordData?.type;
  const rowData = newRecordData?.rowData;

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  //This state is used to update the price per label
  const [qtyUnitLabel, setQtyUnitLabel] = useState(t("gal_uk_const"));

  //hook-form variables
  const vehicle = watch("vehicle");
  const date = watch("date");
  const time = watch("time");
  const odometer = watch("odometer");
  const quantity = watch("quantity");
  const qtyUnit = watch("qtyUnit");
  const price = watch("price");
  const totalCost = watch("totalCost");
  const fuelType = watch("fuelType");
  const octane = watch("octane");
  const fuelBrand = watch("fuelBrand");
  const fillingStation = watch("fillingStation");
  const transactionType = watch("transactionType");
  const cardNo = watch("cardNo");
  const filledBy = watch("filledBy");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );

  const users = useSelector((state) => state.users.list);

  const [fillupImgs, setFillupImgs] = useState([]);

  const [status, setStatus] = useState("new");

  const [partialTank, setPartialTank] = useState(false);
  const [missedPrevFillup, setMissedPrevFillup] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  const fuelTypeOptions = [
    {
      value: "Petrol",
      label: t("petrol_const"),
    },
    {
      value: "Diesel",
      label: t("diesel_const"),
    },
  ];

  const transactionTypeOptions = [
    {
      value: "Cash",
      label: t("cash_const"),
    },
    {
      value: "Check",
      label: t("check_const"),
    },
    {
      value: "Credit card",
      label: t("credit_card_const"),
    },
    {
      value: "Debit card",
      label: t("debit_card_const"),
    },
  ];

  const { odoHint, odoUnit, lastOdoValue } = useFetchOdoHint({
    vehicle,
    date,
    time,
  });

  const [showHighOdoErr, setShowHighOdoErr] = useState(false);

  //Initial Effect
  //Effect to set initial values
  useEffect(() => {
    if (type === "add") {
      setValue(
        "vehicle",
        vehicles?.find((v) => v.vehicleId === newRecordData?.vehicleId)
      );
      setValue(
        "filledBy",
        userOptions?.find((u) => u.user_id === newRecordData?.userId)
      );

      setValue("date", depDate);
      setValue("time", getInitialStartTime(depDate));

      // setValue(
      //   "transactionType",
      //   transactionTypeOptions.find((t) => t.value === "Credit card")
      // );

      let defaultTransactionTypeValue = "Credit card";
      //Check if transaction type preference is already set in local storage
      const transactionTypePref = localStorage.getItem("transactionType");

      if (transactionTypePref) {
        defaultTransactionTypeValue = transactionTypePref;
      }
      setValue(
        "transactionType",
        transactionTypeOptions.find(
          (t) => t.value === defaultTransactionTypeValue
        )
      );

      // setValue(
      //   "fuelType",
      //   fuelTypeOptions.find((t) => t.value === "Diesel")
      // );

      if (startingOdo) {
        setValue("odometer", startingOdo);
      }
    }
  }, [userId]);

  //Effect to update fuelType on vehicle change
  useEffect(() => {
    if (vehicle && type === "add") {
      const fuelType = vehicle?.fuel_type || "Diesel";
      setValue(
        "fuelType",
        fuelTypeOptions.find((t) => t.value === fuelType)
      );
    }
  }, [vehicle]);

  //Effect for Edit
  useEffect(() => {
    if (type === "edit" && !newRecordData?.newRecordId) {
      const { rowData } = newRecordData;
      console.log(rowData);

      setValue(
        "vehicle",
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)
      );
      setValue("date", new Date(rowData?.fillup_date * 1000));
      setValue("time", format(new Date(rowData?.fillup_date * 1000), "HH:mm"));
      const distanceFactor = getDistanceFactorforOdo(
        vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
        distance
      );
      // console.log(
      //   distanceFactor,
      //   vehicles.find((v) => v.vehicleId === rowData?.veh_id)?.primary_meter,
      //   distance
      // );

      const odoUpd = rowData?.odo
        ? (+rowData?.odo / distanceFactor)?.toFixed(2)
        : 0;

      setValue("odometer", odoUpd);
      setValue("quantity", +rowData?.qty ? +rowData?.qty?.toFixed(2) : 0);
      setValue("qtyUnit", rowData?.qty_unit);
      let priceVal = 0;
      if (rowData?.total_cost && rowData?.qty) {
        priceVal = (+rowData?.total_cost / +rowData?.qty)?.toFixed(3);
      }
      setValue("price", priceVal);
      if (rowData?.total_cost) {
        setValue("totalCost", rowData?.total_cost?.toFixed(2));
      }
      setValue(
        "fuelType",
        fuelTypeOptions?.find((f) => f.value === rowData?.fuel_type)
      );
      setValue("octane", rowData?.octane);
      setValue("fuelBrand", rowData?.fuel_brand);
      setValue("fillingStation", rowData?.filling_station);
      setValue(
        "transactionType",
        transactionTypeOptions?.find(
          (f) => f.value === rowData?.transaction_type
        )
      );
      setValue("cardNo", rowData?.card_number);

      const selectedUser = userOptions?.find(
        (u) => u.user_id === rowData?.filled_by
      );
      setValue("filledBy", selectedUser);
      setValue("comments", rowData?.comments);

      setPartialTank(+rowData?.p_fill);
      setMissedPrevFillup(+rowData?.m_fill);

      //Logic to set Images
      let imgNames = [];
      const editImgName = rowData?.receipt_img_name;
      if (editImgName) {
        imgNames = editImgName.split(":::");

        const imgs = [];
        imgNames.map((imgName, i) => {
          imgs.push({
            existing: true,
            imgName: imgName,
            id: Date.now() + i,
            url: `${s3Url}/org_${orgId}/fillups/${imgName}`,
          });
        });
        console.log("imgs", imgs);
        setFillupImgs(imgs);
      }
    }
  }, [newRecordData, vehiclesList]);

  //Effect to update Price/{quanity unit label}
  useEffect(() => {
    //console.log(qtyUnit);
    if (qtyUnit === "Gallons (UK)") {
      setQtyUnitLabel(t("gal_uk_const"));
    } else if (qtyUnit === "Gallons (US)") {
      setQtyUnitLabel(t("gal_us_const"));
    } else if (qtyUnit === "Liters") {
      setQtyUnitLabel(t("ltr_const"));
    }
  }, [qtyUnit]);

  //Effect to update total cost on change of quantity and price
  useEffect(() => {
    if (!price && !quantity) {
      setValue("totalCost", "");
    } else {
      setValue("totalCost", +price * +quantity);
    }
  }, [price, quantity]);

  //Clear cardNo value on change of transaction type
  useEffect(() => {
    if (
      transactionType?.value === "Cash" ||
      transactionType?.value === "Check"
    ) {
      setValue("cardNo", "");
    }
  }, [transactionType]);

  //Limit card number to 4 digits
  useEffect(() => {
    if (
      (transactionType?.value === "Debit card" ||
        transactionType?.value === "Credit card") &&
      cardNo
    ) {
      if (cardNo?.length > 4) {
        setValue("cardNo", cardNo.slice(0, 4));
      }
    }
  }, [cardNo]);

  const handleClose = () => {
    setNewRecordData((prev) => ({
      ...prev,
      screenName: "",
      type: "",
      newRecordId: "",
    }));
    // reset({
    //   expenseTaskName: "",
    //   comments: "",
    // });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const submitFillup = async () => {
    try {
      setSaveBtnLoading(true);
      const fillupId =
        type === "edit"
          ? rowData?.fillup_id
          : `${vehicle?.vehicleId}_${Date.now()}`;

      const { imgString, imgsToS3 } = getImgString(
        fillupImgs,
        fillupId,
        userId,
        orgId,
        "fillups"
      );

      //console.log(imgString, imgsToS3);

      const distanceFactor = getDistanceFactorforOdo(
        vehicle?.primary_meter,
        distance
      );
      let odoConverted = odometer * distanceFactor;

      //Odo Validation
      const odoCheckApiData = {
        odo: odoConverted,
        veh_id: vehicle.vehicleId,
        datetocheck: getDateWithTime(date, time),
        querystring: "get_around_dates_v3",
        check_from: "fillups",
        check_id: type === "edit" ? rowData.fillup_id : "",
      };

      const odoValidationRes = await odoValidation(
        "odometer",
        odoCheckApiData,
        setError,
        setSaveBtnLoading,
        t,
        distanceFactor,
        odometer
      );

      if (!odoValidationRes) {
        return;
      }

      let effDistanceFactor = getDistanceFactorForEff(distance, consumption);
      let effVolumeFactor = getVolumeFactorForEff(volume, consumption);

      if (vehicle?.primary_meter === "Hours") {
        effDistanceFactor = 1;
        effVolumeFactor = getHourlyVolumeFactorEff(volume, hourlyConsumption);
      }

      const volumeFactor = getVolumeFactor(qtyUnit, volume);
      const qtyConverted = quantity ? +quantity * volumeFactor : 0;

      const apiData = {
        querystring: "add_fillup",
        action: type === "edit" ? 2 : 1,
        card_number: cardNo ? cardNo : 0,
        comments: comments,
        currency: currency,
        dist_fact: distanceFactor,
        effdistfact: effDistanceFactor,
        effvolfact: effVolumeFactor,
        operator_userid: filledBy?.user_id,
        filling_station: fillingStation,
        fillup_date: getDateWithTime(date, time),
        fillup_id: fillupId,
        fuel_brand: fuelBrand,
        fuel_type: fuelType?.value,
        m_fill: missedPrevFillup ? 1 : 0,
        octane: octane,
        odo: odoConverted,
        odo_unit: vehicle?.primary_meter === "Hours" ? "Hours" : distance,
        operation: type === "edit" ? "update" : "create",
        p_fill: partialTank ? 1 : 0,
        qty: qtyConverted,
        qty_unit: volume,
        receipt_img_name: imgString,
        source: "web",
        sync_version: "v2",
        total_cost: totalCost,
        transaction_type: transactionType?.value,
        veh_id: vehicle?.vehicleId,
        vol_fact: volumeFactor,
      };

      setSaveBtnLoading(true);
      const snackbarMsg =
        type === "edit"
          ? "Fillup Edited Successfully"
          : "Fillup Added Successfully";
      const response = await postApi(apiData, "fillups");
      //console.log(response);

      if (response.success === 1) {
        const newFillupId =
          type === "add"
            ? response?.fillup_data[0]?.inserted_fillup_id
            : rowData?.fillup_id;

        setNewRecordData((prev) => ({
          ...prev,
          newRecordId: newFillupId,
        }));
        if (imgsToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imgsToS3);
          if (docsUploaded) {
            setSaveBtnLoading(false);
            setShowPopup(false);
          }
        } else {
          setSaveBtnLoading(false);
          setShowPopup(false);
        }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <Modal show={showPopup} size="xl">
        <Modal.Header>
          <h4>{type === "edit" ? t("edit_fill_up") : t("add_fill_up")}</h4>
          <div
            className="d-flex justify-content-end"
            //style={{ width: "-webkit-fill-available" }}
          >
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <ModalBody>
          <FormContainer
          //onSubmit={handleSubmit(onSubmit)}
          >
            <Container>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          {/* Vehicle */}
                          <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                          <Controller
                            name="vehicle"
                            control={control}
                            //errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={vehicles}
                                //value={vehicles.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                //onCreateOption={handleCreate}
                                placeholder={t("vehicle_placeholder")}
                                styles={formStyles}
                                isDisabled={true}
                              />
                            )}
                            rules={{ required: t("required_err") }}
                          />

                          {errors?.vehicle && (
                            <Error error={errors.vehicle.message} />
                          )}
                        </Col>
                      </Row>
                      {vehicle && (
                        <>
                          <Row>
                            <Col>
                              <FormGroupLabel>{t("date_tv")}</FormGroupLabel>
                              <Controller
                                name="date"
                                //defaultValue={new Date()}
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <DatePicker
                                    id="datepicker"
                                    //minDate={new Date()}
                                    selected={value}
                                    onChange={onChange}
                                    customInput={
                                      <CustomInput
                                      //disabled={status == 1 || status == 2}
                                      />
                                    }
                                    placeholderText={t("date_tv")}
                                    locale={getDateFnsLocale()}
                                    dateFormat={"P"}
                                    showMonthDropdown
                                    showYearDropdown
                                    //disabled={status == 1 || status == 2}
                                  />
                                )}
                                rules={{
                                  required: t("required_err"),
                                }}
                              />
                              {errors?.date && (
                                <Error error={errors.date.message} />
                              )}
                            </Col>
                            <Col>
                              <FormGroupLabel>{t("time_tv")}</FormGroupLabel>
                              <FormItem disabled={status == 1 || status == 2}>
                                <FormField
                                  name="time"
                                  control={control}
                                  component="input"
                                  type="time"
                                  //defaultValue={getInitialStartTime}
                                  placeholder={t("time_tv")}
                                  style={formInputStyles}
                                  disabled={status == 1 || status == 2}
                                />

                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.time && (
                                <Error error={errors.time.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroupLabel>{t("odometer")}</FormGroupLabel>
                              <FormItem
                                disabled={false}
                                showWarning={showHighOdoErr}
                              >
                                <FormField
                                  name="odometer"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={(e) => handleNegative(e)}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  rules={{ required: t("required_err") }}
                                  //errors={errors}
                                  // rules={{
                                  //   required:
                                  //     +serviceIntervalDuration || +serviceIntervalOdo
                                  //       ? false
                                  //       : t("odo_or_duration"),
                                  //   min: {
                                  //     value: 0,
                                  //     message: t("greater_than_zero_err"),
                                  //   },
                                  // }}
                                  defaultValue=""
                                  //isAboveError={isAboveError}
                                  placeholder={t("odometer")}
                                  style={formInputStyles}
                                  disabled={false}
                                  onKeyUp={(e) => {
                                    const { value } = e.target;
                                    if (
                                      value &&
                                      lastOdoValue &&
                                      +value - +lastOdoValue > 1000
                                    ) {
                                      setShowHighOdoErr(true);
                                    } else {
                                      setShowHighOdoErr(false);
                                    }
                                  }}
                                />
                                <UnitSpan>{odoUnit}</UnitSpan>
                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>
                              <HintText>{odoHint}</HintText>

                              {/* {errors?.odometer && (
                              <Error error={errors.odometer.message} />
                            )} */}
                              {errors?.odometer ? (
                                <Error error={errors.odometer.message} />
                              ) : (
                                showHighOdoErr && (
                                  <Error error={t("high_odo_err_msg")} />
                                )
                              )}
                            </Col>
                            <Col>
                              <FormGroupLabel>{t("qty_tv")}</FormGroupLabel>
                              <FormItem
                              //disabled={false}
                              >
                                <FormField
                                  name="quantity"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={(e) => handleNegative(e)}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  rules={{ required: t("required_err") }}
                                  defaultValue=""
                                  placeholder={t("qty_tv")}
                                  style={formInputStyles}
                                  onKeyUp={(e) => {
                                    if (e.target.value && price) {
                                      const totalCostVal = (
                                        +e.target.value * +price
                                      ).toFixed(2);
                                      setValue("totalCost", totalCostVal);
                                    } else {
                                      setValue("totalCost", 0);
                                    }
                                  }}
                                  //disabled={false}
                                />
                                <TimeSelect
                                  {...register("qtyUnit")}
                                  defaultValue={volume}
                                  //disabled={!serviceIntervalDuration}
                                  // onClick={() => {
                                  //   setError("notifyMeDuration", {
                                  //     type: "maxValue",
                                  //   });
                                  // }}
                                >
                                  <option value="Gallons (UK)">
                                    {t("gal_uk_const")}
                                  </option>
                                  <option value="Gallons (US)">
                                    {t("gal_us_const")}
                                  </option>
                                  <option value="Liters">
                                    {t("ltr_const")}
                                  </option>
                                </TimeSelect>

                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.quantity && (
                                <Error error={errors.quantity.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <FormGroupLabel>{`${t(
                                "cost_per_unit_tv"
                              )} ${qtyUnitLabel}`}</FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="price"
                                  control={control}
                                  component="input"
                                  //rules={{ required: t("required_err") }}
                                  type="number"
                                  onKeyDown={(e) => handleNegative(e)}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  defaultValue=""
                                  placeholder={`${t(
                                    "cost_per_unit_tv"
                                  )} ${qtyUnitLabel}`}
                                  style={formInputStyles}
                                  onKeyUp={(e) => {
                                    if (e.target.value && quantity) {
                                      const totalCostVal = (
                                        +e.target.value * +quantity
                                      ).toFixed(2);
                                      setValue("totalCost", totalCostVal);
                                    } else {
                                      setValue("totalCost", 0);
                                    }
                                  }}
                                />

                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>

                              {errors?.price && (
                                <Error error={errors.price.message} />
                              )}
                            </Col>

                            <Col>
                              <FormGroupLabel>{t("tc_tv")}</FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="totalCost"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={(e) => handleNegative(e)}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  defaultValue=""
                                  placeholder={t("tc_tv")}
                                  style={formInputStyles}
                                  onKeyUp={(e) => {
                                    if (e.target.value && quantity) {
                                      const priceVal = (
                                        +e.target.value / +quantity
                                      )?.toFixed(3);
                                      setValue("price", priceVal);
                                    } else {
                                      setValue("price", 0);
                                    }
                                  }}
                                />
                              </FormItem>

                              {errors?.totalCost && (
                                <Error error={errors.totalCost.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              {/* Vehicle */}
                              <FormGroupLabel>{t("fuel_type")}</FormGroupLabel>
                              <Controller
                                name="fuelType"
                                control={control}
                                //errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    options={fuelTypeOptions}
                                    //value={vehicles.find((c) => c.value === value)}
                                    value={value}
                                    onChange={onChange}
                                    //onCreateOption={handleCreate}
                                    placeholder={t("fuel_type_placeholder")}
                                    styles={formStyles}
                                    //isDisabled={status == 1 || status == 2}
                                  />
                                )}
                                rules={{ required: t("required_err") }}
                              />

                              {errors?.fuelType && (
                                <Error error={errors.fuelType.message} />
                              )}
                            </Col>

                            <Col>
                              <FormGroupLabel>{t("oct_tv")}</FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="octane"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={(e) => handleNegative(e)}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  defaultValue=""
                                  placeholder={t("oct_tv")}
                                  style={formInputStyles}
                                  //disabled={false}
                                />
                              </FormItem>

                              {errors?.octane && (
                                <Error error={errors.octane.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroupLabel>{t("fb_tv")}</FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="fuelBrand"
                                  control={control}
                                  component="input"
                                  type="text"
                                  defaultValue=""
                                  placeholder={t("fb_tv")}
                                  style={formInputStyles}
                                  //disabled={false}
                                />
                              </FormItem>

                              {errors?.fuelBrand && (
                                <Error error={errors.fuelBrand.message} />
                              )}
                            </Col>

                            <Col>
                              <FormGroupLabel>{t("fs_tv")}</FormGroupLabel>
                              <FormItem disabled={false}>
                                <FormField
                                  name="fillingStation"
                                  control={control}
                                  component="input"
                                  type="text"
                                  defaultValue=""
                                  placeholder={t("fs_tv")}
                                  style={formInputStyles}
                                  //disabled={false}
                                />
                              </FormItem>

                              {errors?.fillingStation && (
                                <Error error={errors.fillingStation.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              {/* Vehicle */}
                              <FormGroupLabel>
                                {t("transaction_type")}
                              </FormGroupLabel>
                              <Controller
                                name="transactionType"
                                control={control}
                                //errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    options={transactionTypeOptions}
                                    //value={vehicles.find((c) => c.value === value)}
                                    value={value}
                                    //onChange={onChange}
                                    onChange={(v) => {
                                      onChange(v);
                                      localStorage.setItem(
                                        "transactionType",
                                        v?.value
                                      );
                                    }}
                                    placeholder={t(
                                      "transaction_type_placeholder"
                                    )}
                                    styles={formStyles}
                                    //isDisabled={status == 1 || status == 2}
                                  />
                                )}
                                //rules={{ required: t("required_err") }}
                              />

                              {errors?.transactionType && (
                                <Error error={errors.transactionType.message} />
                              )}
                            </Col>

                            <Col>
                              <FormGroupLabel>
                                {transactionType?.value === "Check"
                                  ? t("check_number")
                                  : t("card_number")}
                              </FormGroupLabel>
                              <FormItem
                                disabled={transactionType?.value === "Cash"}
                              >
                                <FormField
                                  name="cardNo"
                                  control={control}
                                  component="input"
                                  type="text"
                                  defaultValue=""
                                  placeholder={
                                    transactionType?.value === "Check"
                                      ? t("check_number")
                                      : t("last_4_digits")
                                  }
                                  style={formInputStyles}
                                  disabled={transactionType?.value === "Cash"}
                                />
                              </FormItem>

                              {errors?.cardNo && (
                                <Error error={errors.cardNo.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              {/* Filled By */}
                              <FormGroupLabel>{t("filled_by")}</FormGroupLabel>
                              <Controller
                                name="filledBy"
                                control={control}
                                defaultValue={users?.length > 0 ? users[0] : ""}
                                //errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <Select
                                    options={userOptions}
                                    //value={vehicles.find((c) => c.value === value)}
                                    value={value}
                                    onChange={onChange}
                                    //onCreateOption={handleCreate}
                                    placeholder={t("user_placeholder")}
                                    styles={formStyles}
                                    isDisabled={true}
                                  />
                                )}
                                rules={{ required: t("required_err") }}
                              />

                              {errors?.filledBy && (
                                <Error error={errors.filledBy.message} />
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                              {/* <FormItem>
                                <FormField
                                  name="comments"
                                  control={control}
                                  component="input"
                                  type="text"
                                  defaultValue={""}
                                  placeholder={t("notes_tv")}
                                  style={formInputStyles}
                                />
                              </FormItem> */}

                              <Controller
                                name="comments"
                                control={control}
                                //defaultValue={users?.length > 0 ? users[0] : ""}
                                //errors={errors}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <AutoResizeTextArea
                                    ref={textAreaRef}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={t("notes_tv")}
                                    rows={1}
                                    //isDisabled={status == 1 || status == 2}
                                  />
                                )}
                                //rules={{ required: t("required_err") }}
                              />

                              {errors?.comments && (
                                <Error error={errors.comments.message} />
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <FormGroupLabel>
                              {t("images")}{" "}
                              {tag === "free-user" && (
                                <SubscribeTooltipIcon
                                  message={t("subscribe_msg")}
                                />
                              )}
                            </FormGroupLabel>
                            <Col>
                              <FileUploaderNew
                                disabled={false}
                                images={fillupImgs}
                                setImages={setFillupImgs}
                                shouldDelete={true}
                              />
                              {/* <FileUploaderContainer>
                              {fillupImgs?.map((img) => {
                                return (
                                  <ImageWithZoom
                                    key={img.id}
                                    img={img}
                                    //handleDeleteImg={handleDeleteImg}
                                    images={fillupImgs}
                                    setImages={setFillupImgs}
                                    shouldDelete={true}
                                  />
                                );
                              })}

                              <FileUploader
                                //handleFileUpload={handleFileUpload}
                                disabled={false}
                                images={fillupImgs}
                                setImages={setFillupImgs}
                              />

                              {false && (
                                <p
                                  style={{
                                    textAlign: "center",
                                    width: "-webkit-fill-available",
                                    color: colorGray,
                                  }}
                                >
                                  No Images Uploaded
                                </p>
                              )}
                            </FileUploaderContainer> */}
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>

                    {/* <Col md={2}></Col> */}
                    {vehicle && (
                      <Col md={6}>
                        <Row style={{ marginTop: "2.75rem" }}>
                          <Col>
                            <CheckBoxInput
                              type="checkbox"
                              id="partialTank"
                              name="partialTank"
                              value="partialTank"
                              checked={partialTank}
                              onChange={() => setPartialTank((prev) => !prev)}
                              style={{ marginRight: "0.5rem" }}
                              disabled={false}
                            />
                            <Label htmlFor="partialTank" disabled={false}>
                              {t("pf_tv")}
                            </Label>
                            <Desc style={{ paddingLeft: "1.25rem" }}>
                              {t("partial_tank_desc")}
                            </Desc>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: "1rem" }}>
                          <Col>
                            <CheckBoxInput
                              type="checkbox"
                              id="missedPrevFillup"
                              name="missedPrevFillup"
                              value="missedPrevFillup"
                              checked={missedPrevFillup}
                              onChange={() =>
                                setMissedPrevFillup((prev) => !prev)
                              }
                              style={{ marginRight: "0.5rem" }}
                              disabled={false}
                            />
                            <Label htmlFor="missedPrevFillup" disabled={false}>
                              {t("mf_tv")}
                            </Label>
                            <Desc style={{ paddingLeft: "1.25rem" }}>
                              {t("missed_prev_fillup_desc")}
                            </Desc>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </FormContainer>
        </ModalBody>
        <Modal.Footer>
          <AddFormButton
            id="save-form-button"
            type="button"
            onClick={handleSubmit(submitFillup)}
            disabled={saveBtnLoading}
          >
            {saveBtnLoading ? <CustomLoader /> : t("save")}
          </AddFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FillupsFormPopup;

//styles

const ModalBody = styled(Modal.Body)`
  margin: 0;
  padding: 0;
  height: ${window.innerHeight - 220}px;
  overflow: auto;
  ${scrollbarStyles}
`;
