import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: "",
  userImg: "",
  userId: "",
  orgName: "",
  orgImg: "",
  orgId: "",
  roleId: "",
  orgDate: "",
  country: "",
  address: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      //console.log(action);
      state.userName = action.payload.user_name;
      state.userId = action.payload.user_id;
      state.userImg = action.payload.user_img;
      state.orgName = action.payload.name;
      state.orgImg = action.payload.org_img;
      state.orgId = action.payload.org_id;
      state.roleId = action.payload.role_id;
      state.orgDate = action.payload.create_date;
      state.country = action.payload.country;
      state.address = action.payload.address_1;
    },
    clearUser: (state, action) => {
      state.userName = "";
      state.userId = "";
      state.userImg = "";
      state.orgName = "";
      state.orgImg = "";
      state.orgId = "";
      state.roleId = "";
      state.orgDate = "";
      state.country = "";
      state.address = "";
    },
  },
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
