import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../shared/components/TableElements";
import {
  AutoResizeTextArea,
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  Label,
} from "../../shared/components/form/FormElements";
import { Col, Container, Row } from "react-bootstrap";
import FormHeader from "../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import FormField from "../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { formInputStyles, formStyles } from "../../shared/constants/styles";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { postApi } from "../../api/getUser";
import { useDispatch } from "react-redux";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { fetchExpenseTasks } from "../../features/expenseTasks/expenseTasksSlice";
import { isObjEmpty } from "../../shared/helpers";
import useAutosizeTextArea from "../../shared/custom-hooks/useAutosizeTextArea";

const ExpenseTasksFormPopup = ({
  showPopup,
  setShowPopup,
  newTaskInfo,
  setNewTaskId,
  from,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.slice(1, vehiclesList.length);

  const { userId } = useSelector((state) => state.user);

  const type = location?.state?.type;
  const rowData = location?.state?.rowData;
  const expenseTaskOptions = useSelector((state) => state.expenseTasks.list);

  //Coming from Add Service
  //   const mode = location?.state?.mode;
  //   const fromType = location?.state?.fromType;
  //   const route = location?.state?.route;
  //   const redirect = location?.state?.redirect;
  //   const routeData = location?.state?.routeData;
  //   const serviceData = location?.state?.routeData?.serviceData;
  //console.log(mode, route, redirect);

  const [recurringTask, setRecurringTask] = useState(false);
  const [applyToAllVeh, setApplyToAllVeh] = useState(true);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const expenseTaskName = watch("expenseTaskName");
  const comments = watch("comments");

  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comments);

  const { taskName, vehicleId } = newTaskInfo;

  useEffect(() => {
    setValue("expenseTaskName", taskName);
  }, [taskName]);

  useEffect(() => {
    if (from && from === "add-renewal") {
      setRecurringTask(true);
    }
  }, []);

  const handleClose = () => {
    reset({
      expenseTaskName: "",
      comments: "",
    });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const addExpenseTask = async () => {
    if (!isObjEmpty(errors)) {
      return;
    }
    // let taskOptions = [...expenseTaskOptions];
    // //if task is being added from renewal, then only check recurring tasks
    // if (from && from === "add-renewal") {
    //   taskOptions = taskOptions.filter((t) => +t.recurring);
    // }
    for (let expenseTask of expenseTaskOptions) {
      if (applyToAllVeh) {
        if (
          expenseTask.expense_task_name.toLocaleLowerCase() ===
          expenseTaskName.trim().toLocaleLowerCase()
        ) {
          let errMsg = t("dup_expense_task_name_error");
          if (from && from === "add-renewal" && +!expenseTask.recurring) {
            errMsg = t("dup_expense_task_name_error_from_reminder");
          }
          setError(
            "expenseTaskName",
            {
              type: "custom",
              message: errMsg,
            },
            { shouldFocus: true }
          );
          return;
        }
      } else {
        if (
          expenseTask.expense_task_name.toLocaleLowerCase() ===
            expenseTaskName.trim().toLocaleLowerCase() &&
          (expenseTask.veh_id === vehicleId || expenseTask.veh_id === "All")
        ) {
          let errMsg = t("dup_expense_task_name_error");
          if (from && from === "add-renewal" && +!expenseTask.recurring) {
            errMsg = t("dup_expense_task_name_error_from_reminder");
          }
          setError(
            "expenseTaskName",
            {
              type: "custom",
              message: errMsg,
            },
            { shouldFocus: true }
          );
          return;
        }
      }
    }

    const expenseTaskId = `${userId}_${Date.now()}`;

    const apiData = {
      querystring: "add_expense_task",
      expense_task_id: expenseTaskId,
      expense_task_name: expenseTaskName,
      recurring: recurringTask ? 1 : 0,
      expensetask_veh_id: applyToAllVeh ? "All" : vehicleId,
      comment: comments,
      source: "web",
      sync_version: "v2",
      action: 1,
    };

    console.log(apiData);

    // (async () => {
    setSaveBtnLoading(true);
    const response = await postApi(apiData, "commonNew");
    if (response.success === 1) {
      setSaveBtnLoading(false);
      const apiData = {
        querystring: "getallexpensetask",
        expense_veh_id: "All",
      };
      dispatch(fetchExpenseTasks(apiData));
      setNewTaskId(expenseTaskId);
      setShowPopup(false);
    }
    // })();
  };

  return (
    <Modal show={showPopup} size="md">
      <Modal.Header>
        <h4>{t("add_expense_task")}</h4>
        <div
          className="d-flex justify-content-end"
          //style={{ width: "-webkit-fill-available" }}
        >
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FormContainer
        //onSubmit={handleSubmit(onSubmit)}
        >
          <Container>
            <Card>
              <CardBody
                style={{
                  height: `${window.innerHeight - 260}px`,
                  overflow: "auto",
                }}
              >
                <Row>
                  <Col md={12}>
                    <FormGroupLabel className="m-0">
                      {t("expense_task_name")}
                    </FormGroupLabel>
                    <FormItem disabled={false}>
                      <FormField
                        name="expenseTaskName"
                        control={control}
                        component="input"
                        defaultValue=""
                        placeholder={t("expense_task_name")}
                        style={formInputStyles}
                        disabled={false}
                        rules={{ required: t("required_err") }}
                      />
                    </FormItem>

                    {errors?.expenseTaskName && (
                      <Error error={errors.expenseTaskName.message} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroupLabel>{t("notes_tv")}</FormGroupLabel>
                    {/* <FormItem disabled={false}>
                      <FormField
                        name="comments"
                        control={control}
                        component="input"
                        defaultValue=""
                        placeholder={t("notes_tv")}
                        style={formInputStyles}
                        disabled={false}
                      />
                    </FormItem> */}

                    <Controller
                      name="comments"
                      control={control}
                      //defaultValue={users?.length > 0 ? users[0] : ""}
                      //errors={errors}
                      render={({ field: { onChange, value, ref } }) => (
                        <AutoResizeTextArea
                          ref={textAreaRef}
                          onChange={onChange}
                          value={value}
                          placeholder={t("notes_tv")}
                          rows={1}
                          //isDisabled={status == 1 || status == 2}
                        />
                      )}
                      //rules={{ required: t("required_err") }}
                    />

                    {errors?.comments && (
                      <Error error={errors.comments.message} />
                    )}
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md={12}>
                    <CheckBoxInput
                      type="checkbox"
                      id="recurringTask"
                      name="recurringTask"
                      value="recurringTask"
                      checked={recurringTask}
                      onChange={() => setRecurringTask((prev) => !prev)}
                      style={{ margin: "0 8px 0 0" }}
                      disabled={from === "add-renewal"}
                    />
                    <Label
                      htmlFor="recurringTask"
                      disabled={from === "add-renewal"}
                    >
                      {t("recurring_task")}
                    </Label>
                  </Col>
                  <Desc style={{ paddingLeft: "2rem" }}>
                    {t("no_reminder_for_nonrecurring_expense")}
                  </Desc>
                </Row>
                <Row className="pt-3">
                  <Col md={12} className="pt-2">
                    <CheckBoxInput
                      type="checkbox"
                      id="applyToAllVeh"
                      name="applyToAllVeh"
                      value="applyToAllVeh"
                      checked={applyToAllVeh}
                      onChange={() => setApplyToAllVeh((prev) => !prev)}
                      style={{ margin: "0 8px 0 0" }}
                    />
                    <Label htmlFor="applyToAllVeh">{t("apply_all_vehs")}</Label>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </FormContainer>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton
          id="save-form-button"
          type="button"
          onClick={addExpenseTask}
        >
          {saveBtnLoading ? <CustomLoader /> : t("save")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpenseTasksFormPopup;
