import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
  useColumnOrder,
} from "react-table";
import styled from "styled-components";
import { scrollbarStyles } from "@/utils/palette";
import ReactTableHeader from "./ReactTableHeader";
import BodyReactTable, { PageLoading } from "./ReactTableBody";
import ReactTablePagination from "./ReactTablePagination";
import { Table } from "../../TableElements";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateColumnOrder,
  updatePageLength,
  updateStart,
} from "../../../../features/filter/filterSlice";
import RowSelectionCheckbox from "./RowSelectionCheckbox";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  colorBlue,
  colorLightGray,
  colorYellowNew,
} from "../../../../utils/palette";
import { useState } from "react";
import { lighten } from "polished";

import RightPanel from "./RightPanel";
import TempFilter from "./TempFilter";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";
import NotInspectedVehiclesPopup from "../../NotInspectedVehiclesPopup";
import { initialLoadDelay } from "../../../constants";

const ReactTableConstructor = ({
  tableConfig,
  tableOptions,
  valuesArray,
  initialData,
  screen,
  notInspectedVehiclesLength,
  vehiclesTableData,
  rightPanelData,
  setRightPanelData,
  pauses,
}) => {
  const {
    isEditable,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
  } = tableConfig;

  const { t } = useTranslation("common");

  const { loading, setLoading } = useAuth();
  const firstTimeLoadRef = useRef(true);

  const [showMissedInspectionsPopup, setShowMissedInspectionsPopup] =
    useState(false);

  //Data for submissions date filter
  const [submissionsData, setSubmissionsData] = useState({
    warningText: `${t("in")} ${t("the")} ${t("last_7_days_sm")}`,
    filterText: t("last_7_days"),
    dateOption: "last-7-days",
    from: new Date(),
    to: null,
  });

  const {
    allColumns,
    visibleColumns,
    getToggleHideAllColumnsProps,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    setHiddenColumns,
    setColumnOrder,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, sortBy, columnOrder },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <RowSelectionCheckbox
                {...getToggleAllRowsSelectedProps()}
                id="row-selection-header"
              />
            </div>
          ),
          width: 40,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <RowSelectionCheckbox
                {...row.getToggleRowSelectedProps()}
                id={row.index}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
    //...tableOptionalHook
  );

  const dispatch = useDispatch();

  const vehiclesList = useSelector((state) => state.vehicles.list);

  const start = useSelector((state) => state.filter.data.start);
  const colOrder = useSelector((state) => state.columns.order);
  const rowDataStatus = useSelector((state) => state.filter.status);
  const { noOfRecords } = useSelector((state) => state.columns);

  //To set loading
  // useEffect(() => {
  //   if (
  //     screen.includes("dailymileage") ||
  //     screen.includes("maintenancereminders") ||
  //     screen.includes("renewals") ||
  //     screen.includes("inspectionsubmissions")
  //   ) {
  //     if (rowDataStatus === "succeeded") {
  //       setLoading(true);
  //     } else {
  //       setLoading(false);
  //     }
  //   } else {
  //     if (columnOrderStatus === "succeeded" && rowDataStatus === "succeeded") {
  //       setLoading(true);
  //     }
  //     if (columnOrderStatus !== "succeeded" || rowDataStatus !== "succeeded") {
  //       setLoading(false);
  //     }
  //   }
  // }, [columnOrderStatus, rowDataStatus]);

  useEffect(() => {
    setTimeout(() => {
      firstTimeLoadRef.current = false;
    }, initialLoadDelay);
  }, []);

  useEffect(() => {
    //console.log("colOrder", colOrder);
    if (
      columnOrder.length > 0 &&
      !screen.includes("addnew") &&
      !firstTimeLoadRef.current
    ) {
      if (sortBy) {
        //console.log("sortBy", sortBy);
        let index = 0;

        columnOrder.forEach((col, i) => {
          if (col === sortBy[0].id) {
            index = i;
          }
        });
        const data = {
          id: index,
          desc: sortBy[0].desc === true ? "desc" : "asc",
        };
        //setTimeout(() => dispatch(updateColumnOrder(data)), 0);

        dispatch(updateColumnOrder(data));
      }
    }
  }, [columnOrder, screen]);

  //Set column order and hidden columns
  useEffect(() => {
    setColumnOrder(colOrder);

    let columnsToBeFiltered = [...Array(allColumns.length).keys()].slice(2);

    let hiddenCols = columnsToBeFiltered.filter((no) => !colOrder.includes(no));
    setHiddenColumns(hiddenCols);
  }, [colOrder, allColumns]);

  useEffect(() => {
    if (sortBy && !firstTimeLoadRef.current) {
      let index = 0;

      columnOrder.forEach((col, i) => {
        if (col === sortBy[0].id) {
          index = i;
        }
      });
      const data = {
        id: index,
        desc: sortBy[0].desc === true ? "desc" : "asc",
      };

      dispatch(updateColumnOrder(data));
    }
  }, [sortBy]);

  useEffect(() => {
    if (rowDataStatus === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [rowDataStatus]);

  //Close Right Panel when data is filtered/changed
  useEffect(() => {
    setRightPanelData({ show: false, content: "" });
    if (start === 0) {
      gotoPage(0);
    }
  }, [rows]);

  useEffect(() => {
    if (!firstTimeLoadRef.current) {
      console.log("pageSize", pageSize);
      dispatch(updatePageLength(pageSize));
    }
  }, [pageSize]);

  //Update no of records to show
  useEffect(() => {
    if (noOfRecords) {
      setPageSize(noOfRecords);
    }
  }, [noOfRecords]);

  useEffect(() => {
    // if (!firstTimeLoadRef.current) {
    const fetchStart = pageSize * pageIndex;
    dispatch(updateStart(fetchStart));
    // }
  }, [pageIndex]);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
  );

  //console.log(valuesArray);

  //const Loading = () => <div>Loading...</div>;

  return (
    <>
      {screen.includes("inspectionsubmissions") && (
        <NotInspectedVehiclesPopup
          showPopup={showMissedInspectionsPopup}
          setShowPopup={setShowMissedInspectionsPopup}
          data={vehiclesTableData}
        />
      )}
      {/* <div>
        <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
        All
      </div> */}
      {/* {allColumns.map(column => {
        //let obj = {...column.getToggleHiddenProps()};
        //console.log(column.getToggleHiddenProps()[0]);
        return <div key={column.id}>
          <label>
            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
            {column.Header}
          </label>
        </div>
      })} */}
      <Row style={{ background: lighten(0.15, "lightgray") }} className="py-2">
        {/* <ReactTableFilter
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
          allColumns={allColumns}
          columnsOrder={columnsOrder}
          columnsHidden={columnsHidden}
          //toggleHideColumn={toggleHideColumn}
          generatePdf={generatePdf}
          valuesArray={valuesArray}
          initialData={initialData}
          defaultColumns={defaultColumns}
          selectedFlatRows={selectedFlatRows}
          toggleAllRowsSelected={toggleAllRowsSelected}
          screen={screen}
          pageSize={pageSize}
        /> */}
        <Col className="p-0">
          <TempFilter
            rows={rows}
            dataLength={dataLength}
            allColumns={allColumns}
            valuesArray={valuesArray}
            initialData={initialData}
            selectedFlatRows={selectedFlatRows}
            screen={screen}
            setSubmissionsData={setSubmissionsData}
            setColumnOrder={setColumnOrder}
            setHiddenColumns={setHiddenColumns}
          />
        </Col>
      </Row>
      {screen.includes("inspectionsubmissions") &&
        vehiclesTableData?.length > 0 && (
          <Row>
            <div
              style={{ background: colorYellowNew }}
              className="px-4 py-1 d-flex gap-1"
            >
              <i
                className="fi fi-rr-triangle-warning"
                style={{ position: "relative", top: "2px", margin: "0 4px" }}
              ></i>
              {/* {notInspectedVehiclesLength}/
              {vehiclesList?.filter((v) => v.veh_active == 1)?.length} vehicles
              have not been inspected
              <span>{submissionsData.warningText}. </span> */}
              <span>
                {t("no_of_veh_not_inspected_text", {
                  vehiclesNotInspected: notInspectedVehiclesLength,
                  totalVehicles: vehiclesList?.filter((v) => v.veh_active == 1)
                    ?.length,
                  dateText: submissionsData.warningText,
                })}
              </span>
              <span
                id="click-to-view-missed-insp-details-link"
                onClick={() =>
                  setShowMissedInspectionsPopup(!showMissedInspectionsPopup)
                }
                style={{
                  color: "blue",
                  borderBottom: "1px solid blue",
                  cursor: "pointer",
                }}
              >
                {t("click_here_to_view_details")}
              </span>
            </div>
          </Row>
        )}
      <Row
        style={{ background: "white", height: "100%" }}
        className="my-0 py-0"
      >
        <Col className="m-0 p-0">
          <Row className="m-0 p-0" style={{ height: "100%" }}>
            <Col
              md={rightPanelData.show ? 8 : 12}
              style={{ padding: 0, height: "100%" }}
            >
              {loading && (
                //||
                //columnOrderStatus === "loading"
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )}
              {!loading && (
                //columnOrderStatus !== "loading" &&
                <TableWrap
                  pagination={withPagination}
                  active={rightPanelData.show}
                  height={
                    valuesArray?.length
                      ? window.innerHeight - 300
                      : vehiclesTableData?.length > 0
                      ? window.innerHeight - 260
                      : window.innerHeight - 240
                  }
                >
                  <Table
                    {...getTableProps()}
                    bordered
                    id="data-table"
                    className={
                      !screen.includes("maintenancereminders") &&
                      !screen.includes("renewals")
                        ? "table-hover"
                        : ""
                    }
                  >
                    {/* Removed bordered property from Table and added className='resizable-table'*/}
                    <ReactTableHeader
                      headerGroups={headerGroups}
                      isSortable={isSortable}
                      isResizable={isResizable}
                    />
                    <BodyReactTable
                      page={page}
                      getTableBodyProps={getTableBodyProps}
                      prepareRow={prepareRow}
                      updateDraggableData={updateDraggableData}
                      updateEditableData={updateEditableData}
                      isEditable={isEditable}
                      withDragAndDrop={withDragAndDrop}
                      rightPanelData={rightPanelData}
                      setRightPanelData={setRightPanelData}
                      screen={screen}
                    />
                    {/* {rows.length === 0 && (  //removed pageCount === pageIndex + 1 ||
              <ReactTableFooter footerGroups={footerGroups} />
            )} */}
                  </Table>
                  {rows.length === 0 && (
                    <div style={{ textAlign: "center", fontWeight: 600 }}>
                      {t("no_records")}
                    </div>
                  )}
                </TableWrap>
              )}
              {withPagination && rows.length > 0 && (
                <ReactTablePagination
                  page={page}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  pageOptions={pageOptions}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  pageCount={pageCount}
                  setPageSize={setPageSize}
                  manualPageSize={manualPageSize}
                  dataLength={dataLength}
                  screen={screen}
                  rows={rows}
                />
              )}
            </Col>
            {rightPanelData.show && (
              <Col md={4} className="p-0">
                <RightPanel
                  pauses={pauses}
                  screen={screen}
                  rightPanelData={rightPanelData}
                  setRightPanelData={setRightPanelData}
                  height={
                    valuesArray?.length
                      ? window.innerHeight - 300
                      : vehiclesTableData?.length > 0
                      ? window.innerHeight - 260
                      : window.innerHeight - 240
                  }
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {/* <Modal show={showMissedInspectionsPopup} onHide={() => setShowMissedInspectionsPopup(!showMissedInspectionsPopup)}>
        <Modal.Header>
          <p style={{ fontWeight: 500, fontSize: "16px" }}>
            Non Inspected Vehicles
          </p>
          <ActionIcon
            className="fi fi-rr-cross-small"
            onClick={() => setShowMissedInspectionsPopup(!showMissedInspectionsPopup)}
          ></ActionIcon>
        </Modal.Header>
        <Modal.Body>
          <Container className="m-0 p-0">
            <Row
              style={{
                border: `1px solid ${colorLightGray}`,
                background: colorLightGray,
              }}
              className="py-2 mx-0"
            >
              <Col>
                <div>Vehicle Name</div>
              </Col>
              <Col>
                <div>Operator</div>
              </Col>
            </Row>
            <NotAssignedVehiclesContainer>
              {vehiclesTableData?.map((veh) => {
                return (
                  <Row
                    style={{ border: `1px solid ${colorLightGray}` }}
                    className="py-2 px-0 mx-0"
                  >
                    <Col>
                      <div>{veh.vehicleName}</div>
                    </Col>
                    <Col>
                      {veh.users.length > 0 ? (
                        <>
                          {veh.users?.map((user) => {
                            return <div>{user.userName}</div>;
                          })}
                        </>
                      ) : (
                        <div>Not Assigned</div>
                      )}
                    </Col>
                  </Row>
                );
              })}
            </NotAssignedVehiclesContainer>
          </Container>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

ReactTableConstructor.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  //tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
};

ReactTableConstructor.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
  },
  tableOptions: [
    {
      columns: [],
      data: [],
      setFilterValue: () => {},
      updateDraggableData: () => {},
      updateEditableData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
      manualPagination: false,
    },
  ],
};

export default ReactTableConstructor;
//console.log(window.innerHeight);

// region STYLES

export const TableWrap = styled.div`
  display: block;
  overflow-x: auto;
  //height: ${window.innerHeight - 300}px;
  height: ${(p) => p.height}px;

  // @media screen and (min-width: 1280px) {
  //   height: 55vh;
  // }

  // @media screen and (min-width: 1536px) {
  //   height: 60vh;
  // }

  // @media screen and (min-width: 1920px) {
  //   height: 70vh;
  // }

  // @media screen and (min-width: 2880px) {
  //   height: 75vh;
  // }

  ${(p) =>
    p.active &&
    `
  margin: 0 5px 0 0;
  border-right: 1px solid ${colorLightGray};
  border-bottom: 1px solid ${colorLightGray};
  `};

  ${scrollbarStyles};

  ${(props) =>
    props.pagination
      ? `
    //margin-bottom: 1rem;
    //height: 100%;
  `
      : `
    //height: 100%;

    tbody {
      top: 30px;
    }
  `}
`;

export const TableSpinner = styled(Spinner)`
  color: ${colorBlue};
`;

export const NotAssignedVehiclesContainer = styled.div`
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
  //overflow-x: hidden;
  // /${scrollbarStyles};
  //width: -webkit-fill-width;
`;

// endregion
