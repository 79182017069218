import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colorBorder, colorBackground, colorHover } from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {
  colorGrayNew,
  sidenavColor,
  sidenavLinkHoverColor,
} from "../../../../utils/palette";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../contexts/AuthContext";
import SubscribeTooltipIcon from "../../../../shared/components/SubscribeTooltipIcon";

const SidebarContent = ({ onClick, collapse, setShowImportPopup }) => {
  const { t } = useTranslation("common");

  //const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { pathname } = location;
  const { roleId } = useSelector((state) => state.user);

  const [isRemindersOpen, setIsRemindersOpen] = useState(false);
  const [isVehicleInspectionOpen, setIssVehicleInspectionOpen] =
    useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isExpensesOpen, setIsExpensesOpen] = useState(false);

  const { tag, showFaultCodes } = useAuth();

  useEffect(() => {
    if (pathname.includes("reminders")) {
      setIsRemindersOpen(true);
    } else if (pathname.includes("inspection")) {
      setIssVehicleInspectionOpen(true);
    } else if (pathname.includes("setup")) {
      setIsSettingsOpen(true);
    } else if (pathname.includes("services")) {
      setIsServicesOpen(true);
    } else if (pathname.includes("expenses")) {
      setIsExpensesOpen(true);
    }
  }, []);

  return (
    <SidebarContentWrap collapse={collapse}>
      {roleId === 4 ? (
        <SidebarBlock collapse={collapse}>
          {/* <SidebarLink
            title={t("user_management")}
            icon="fi fi-rr-user"
            route="/users"
            onClick={onClick}
          /> */}
          {/* <SidebarLink
            title={t("vehicles")}
            icon="fi fi-rr-truck-moving"
            route="/vehicles"
            onClick={onClick}
          /> */}
          <SidebarCategory
            title={t("expenses")}
            icon="fi fi-rr-dollar"
            collapse={collapse}
            isScreenOpen={isExpensesOpen}
            id={"expenses"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("expense_log")}
              icon="fi fi-sr-search-dollar"
              route="/expenses/expenses"
              onClick={onClick}
              id={"expense-logs"}
            />
            <SidebarLink
              title={t("expense_tasks")}
              icon="fi fi-rr-dollar"
              route="/expenses/expensetasks"
              onClick={onClick}
              id={"expense-tasks"}
            />
          </SidebarCategory>
          <SidebarLink
            title={t("issues")}
            icon="fi fi-rr-engine-warning"
            route="/issues"
            onClick={onClick}
            id={"issues"}
          />
          {showFaultCodes && (
            <SidebarLink
              title={t("fault_codes")}
              icon="fi fi-rr-engine"
              route="/faultcodes"
              onClick={onClick}
              id={"fault-codes"}
            />
          )}
          <SidebarLink
            title={t("work_orders")}
            icon="fi fi-rr-note"
            route="/workorders"
            onClick={onClick}
            id={"work-orders"}
          />
          <SidebarCategory
            title={t("inventory")}
            icon="fi fi-rr-warehouse-alt"
            collapse={collapse}
            isScreenOpen={isVehicleInspectionOpen}
            id={"inventory"}
            isNew={true}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("parts")}
              icon="fi fi-rr-supplier-alt"
              route="/inventory/parts"
              onClick={onClick}
              id={"parts"}
            />
            {/* <SidebarLink
              title={t("usage")}
              icon="fi fi-rr-priority-arrows"
              route="/inventory/usage"
              onClick={onClick}
            /> */}
          </SidebarCategory>
          <SidebarLink
            title={t("vehicles")}
            icon="fi fi-rr-truck-moving"
            route="/vehicles"
            onClick={onClick}
            id={"vehicles"}
          />
          <SidebarLink
            title={t("users")}
            icon="fi fi-rr-user"
            route="/users"
            onClick={onClick}
            id={"users"}
          />
          <SidebarCategory
            title={t("setup")}
            icon="fi fi-rr-settings-sliders"
            collapse={collapse}
            isScreenOpen={isSettingsOpen}
            id={"setup"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("org_settings")}
              icon="fi fi-br-it"
              route="/setup/organization"
              onClick={onClick}
              id={"organization"}
            />
          </SidebarCategory>
        </SidebarBlock>
      ) : (
        <SidebarBlock collapse={collapse}>
          <SidebarLink
            title={t("dashboard")}
            icon="fi fi-rr-home"
            route="/dashboard"
            onClick={onClick}
            id={"dashboard"}
          />
          {/* <SidebarLink
            title={t("user_management")}
            icon="fi fi-rr-user"
            route="/users"
            onClick={onClick}
          /> */}
          {/* <SidebarLink
            title={t("vehicles")}
            icon="fi fi-rr-truck-moving"
            route="/vehicles"
            onClick={onClick}
          /> */}
          <SidebarLink
            title={t("se_mileage")}
            icon="fi fi-rr-tachometer-average"
            route="/dailymileage"
            onClick={onClick}
            id={"daily-mileage"}
          />
          <SidebarLink
            title={t("fillups")}
            icon="fi fi-rr-gas-pump-alt"
            route="/fillups"
            onClick={onClick}
            id={"fillups"}
          />
          <SidebarLink
            title={t("trips")}
            icon="fi fi-rr-road"
            route="/trips"
            onClick={onClick}
            id={"trips"}
          />
          <SidebarLink
            title={t("daily_ts")}
            icon="fi fi-rr-calendar-clock"
            route="/dailytimesheet"
            onClick={onClick}
            id={"daily-timesheet"}
          />

          <SidebarCategory
            title={t("services")}
            icon="fi fi-rr-car-mechanic"
            collapse={collapse}
            isScreenOpen={isServicesOpen}
            id={"services"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("service_log")}
              icon="fi fi-br-it-alt"
              route="/services/services"
              onClick={onClick}
              id={"service-logs"}
            />
            <SidebarLink
              title={t("service_tasks")}
              icon="fi fi-rr-car-mechanic"
              route="/services/servicetasks"
              onClick={onClick}
              id={"service-tasks"}
            />
          </SidebarCategory>
          <SidebarCategory
            title={t("expenses")}
            icon="fi fi-rr-dollar"
            collapse={collapse}
            isScreenOpen={isExpensesOpen}
            id={"expenses"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("expense_log")}
              icon="fi fi-sr-search-dollar"
              route="/expenses/expenses"
              onClick={onClick}
              id={"expense-logs"}
            />
            <SidebarLink
              title={t("expense_tasks")}
              icon="fi fi-rr-dollar"
              route="/expenses/expensetasks"
              onClick={onClick}
              id="expense-tasks"
            />
          </SidebarCategory>
          <SidebarCategory
            title={t("reminders")}
            icon="fi fi-rr-alarm-clock"
            collapse={collapse}
            isScreenOpen={isRemindersOpen}
            id={"reminders"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("maintenance_reminders")}
              icon="fi fi-rs-shipping-timed"
              route="/reminders/maintenancereminders"
              onClick={onClick}
              id="maintenance-reminders"
            />
            <SidebarLink
              title={t("renewals")}
              icon="fi fi-rr-calendar-day"
              route="/reminders/renewals"
              onClick={onClick}
              id={"renewals"}
            />
          </SidebarCategory>

          <SidebarCategory
            title={t("vehicle_inspections")}
            icon="fi fi-rr-clipboard-list-check"
            collapse={collapse}
            isScreenOpen={isVehicleInspectionOpen}
            id={"vehicle-inspections"}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("inspection_tasks")}
              icon="fi fi-rr-list-check"
              route="/inspection/inspectiontasks"
              onClick={onClick}
              id={"inspection-tasks"}
            />
            <SidebarLink
              title={t("inspection_forms")}
              icon="fi fi-rr-form"
              route="/inspection/inspectionforms"
              onClick={onClick}
              id={"inspection-forms"}
            />
            <SidebarLink
              title={t("inspection_schedules")}
              icon="fi fi-rr-circle-calendar"
              route="/inspection/inspectionscheduling"
              onClick={onClick}
              id={"inspection-schedules"}
            />
            <SidebarLink
              title={t("inspection_submissions")}
              icon="fi fi-rr-calendar-check"
              route="/inspection/inspectionsubmissions"
              onClick={onClick}
              id={"inspection-submissions"}
            />
          </SidebarCategory>
          <SidebarLink
            title={t("issues")}
            icon="fi fi-rr-engine-warning"
            route="/issues"
            onClick={onClick}
            id={"issues"}
          />
          {showFaultCodes && (
            <SidebarLink
              title={t("fault_codes")}
              icon="fi fi-rr-engine"
              route="/faultcodes"
              onClick={onClick}
              id={"fault-codes"}
            />
          )}
          <SidebarLink
            title={t("work_orders")}
            icon="fi fi-rr-note"
            route="/workorders"
            onClick={onClick}
            id={"work-orders"}
          />
          <SidebarCategory
            title={t("inventory")}
            icon="fi fi-rr-warehouse-alt"
            collapse={collapse}
            isScreenOpen={isVehicleInspectionOpen}
            id={"inventory"}
            isNew={true}
            //setIsOpen={setIsOpen}
          >
            <SidebarLink
              title={t("parts")}
              icon="fi fi-rr-supplier-alt"
              route="/inventory/parts"
              onClick={onClick}
              id={"parts"}
              //newLink
            />
            {/* <SidebarLink
              title={t("usage")}
              icon="fi fi-rr-priority-arrows"
              route="/inventory/usage"
              onClick={onClick}
              id={"usage"}
            /> */}
          </SidebarCategory>
          <SidebarLink
            title={t("vehicles")}
            icon="fi fi-rr-truck-moving"
            route="/vehicles"
            onClick={onClick}
            id={"vehicles"}
          />
          <SidebarLink
            title={t("users")}
            icon="fi fi-rr-user"
            route="/users"
            onClick={onClick}
            id={"users"}
          />
          <SidebarLink
            title={t("billing")}
            icon="fi fi-rr-credit-card"
            route="/billing"
            onClick={onClick}
            id={"billing"}
          />
          <SidebarCategory
            title={t("setup")}
            icon="fi fi-rr-settings-sliders"
            collapse={collapse}
            isScreenOpen={isSettingsOpen}
            //isNew={true}
            id={"setup"}
            //setIsOpen={setIsOpen}
          >
            <ImportContainer
              onClick={() => setShowImportPopup(true)}
              id="import"
            >
              <i
                className="fi fi-rr-file-import"
                style={{ position: "relative", top: 1 }}
              />
              <span>{t("import")}</span>
              {tag === "free-user" && (
                <div className="d-flex align-items-center">
                  <SubscribeTooltipIcon message={t("subscribe_msg")} />
                </div>
              )}
            </ImportContainer>
            <SidebarLink
              title={t("groups")}
              icon="fi fi-rr-car-bus"
              route="/setup/groups"
              onClick={onClick}
              id={"groups"}
            />
            <SidebarLink
              title={t("operator_settings")}
              icon="fi fi-rr-user-time"
              route="/setup/operator"
              onClick={onClick}
              id={"operator-settings"}
            />
            <SidebarLink
              title={t("org_settings")}
              icon="fi fi-br-it"
              route="/setup/organization"
              onClick={onClick}
              id={"organization-settings"}
            />
            <SidebarLink
              title={t("integrations")}
              icon="fi fi-rr-plug-connection"
              route={"/setup/integrations"}
              onClick={onClick}
              //newLink
              id={"integrations"}
            />
          </SidebarCategory>
        </SidebarBlock>
      )}
      {/* <SidebarBlock collapse={collapse}>
        <SidebarLink
          title={t("faq")}
          icon="faq"
          route="/faq"
          onClick={onClick}
        />
        <SidebarLink
          title={t("demo_video")}
          icon="demo-video"
          route="/demo"
          onClick={onClick}
        />
        <SidebarLink
          title={t("feedback")}
          icon="feedback"
          route="/feedback"
          onClick={onClick}
        />
        <SidebarLink
          title={t("contact_support")}
          icon="contact-support"
          route="/support"
          onClick={onClick}
        />
      </SidebarBlock> */}
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  //changeToDark: PropTypes.func.isRequired,
  //changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => {},
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  //overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        //background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          //background: ${colorHover(props)};
          //background: ${sidenavColor};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

const ImportContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${colorGrayNew};
  cursor: pointer;
  padding-left: 2rem;
  height: 36px;

  &:hover {
    text-decoration: none;

    background-color: ${sidenavLinkHoverColor};

    &:before {
      opacity: 1;
    }
  }

  // i {
  //   position: relative;
  //   top: 2px;
  // }
`;

// endregion
