import React, { useState, useEffect, useRef } from "react";
import { postApi } from "../../../api/getUser";
import { Col, Container, Row } from "react-bootstrap";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import { formInputStyles } from "../../../shared/constants/styles";
import FormHeader from "../../../shared/components/form/FormHeader";
import { Card, CardBody } from "@/shared/components/Card";
import { colorStatusActive } from "../../../utils/palette";
import getUnixTime from "date-fns/getUnixTime";
import InfoPopup from "../../../shared/components/InfoPopup";
import CheckboxInputComponent from "../../../shared/components/form/CheckboxInputComponent";
import Tooltip from "../../../shared/components/Tooltip";
import { Icon } from "../../../shared/components/TableElements";

const OperatorSettings = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  //hook form variables
  const dailyTSTime = watch("dailyTSTime");
  const startingMileageTime = watch("startingMileageTime");
  const endingMileageTime = watch("endingMileageTime");

  const [dailyTSMandatory, setDailyTSMandatory] = useState(true);
  const [dailyMileageMandatory, setDailyMileageMandatory] = useState(true);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const rowData = useRef(null);

  useEffect(() => {
    (async () => {
      const fetchOrgDetailsRes = await postApi(
        {
          querystring: "get_org_data",
        },
        "commonNew"
      );

      if (fetchOrgDetailsRes.success) {
        const { user_data } = fetchOrgDetailsRes;
        const orgData = user_data[0];
        rowData.current = orgData;
        const {
          daily_timesheet_reminder_time,
          starting_mileage_reminder_time,
          ending_mileage_reminder_time,
          daily_timesheet_required,
          starting_ending_mileage_required,
        } = orgData;

        setDailyTSMandatory(daily_timesheet_required === 1);
        setDailyMileageMandatory(starting_ending_mileage_required === 1);
        setValue("dailyTSTime", daily_timesheet_reminder_time.slice(0, 5));
        setValue(
          "startingMileageTime",
          starting_mileage_reminder_time.slice(0, 5)
        );
        setValue("endingMileageTime", ending_mileage_reminder_time.slice(0, 5));
      }
    })();
  }, []);

  const onSubmit = async () => {
    setSaveBtnLoading(true);
    const {
      name,
      address_1,
      address_2,
      org_img,
      country,
      currency,
      distance,
      volume,
      consumption,
      hourly_consumption,
      hourly_machines_present,
      phone,
      starting_ending_mileage_required,
      starting_mileage_reminder_time,
    } = rowData.current;

    const dailyMileageStart = startingMileageTime + ":00";
    const dailyMileageEnd = endingMileageTime + ":00";
    const dailyTS = dailyTSTime + ":00";

    const apiData = {
      querystring: "update_org_settings",
      org_name: name,
      org_country: country,
      org_distance: distance,
      org_volume: volume,
      org_consumption: consumption,
      org_currency: currency,
      org_addressone: address_1 ? address_1 : "",
      org_addresstwo: address_2 ? address_2 : "",
      orgimagename: org_img,
      login_user_daily_timesheet_required: dailyTSMandatory ? 1 : 0,
      login_user_daily_timesheet_reminder_time: dailyTS,
      hourly_machines_present: hourly_machines_present,
      hourly_consumption: hourly_consumption,
      login_user_starting_ending_mileage_required: dailyMileageMandatory
        ? 1
        : 0,
      login_user_starting_mileage_reminder_time: dailyMileageStart,
      login_user_ending_mileage_reminder_time: dailyMileageEnd,
      timestamp: getUnixTime(new Date()),
      phone: phone ? phone : 0,
    };

    const res = await postApi(apiData, "commonNew");
    if (res.success) {
      setShowSuccessPopup(true);
      setSaveBtnLoading(false);
    }
  };
  return (
    <>
      {showSuccessPopup && (
        <InfoPopup
          showPopup={showSuccessPopup}
          setShowPopup={setShowSuccessPopup}
          message={t("operator_settings_save_success_msg")}
          icon={{
            className: "fi fi-rs-check-circle",
            color: colorStatusActive,
          }}
          //onClose={() => location.reload()}
        />
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          //handleCancel={() => history.goBack()}
          //disableSave={!vehicle}
          isSaveLoading={saveBtnLoading}
          title={t("operator_settings")}
          //handleCancel={handleCancel}
        />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody style={{ height: "250px" }}>
                  <h4>{t("dts_settings")}</h4>
                  <Row className="mt-4">
                    <Col>
                      <CheckboxInputComponent
                        checked={dailyTSMandatory}
                        setChecked={setDailyTSMandatory}
                        label={t("daily_ts_reqd_label")}
                      />
                    </Col>
                  </Row>

                  {dailyTSMandatory && (
                    <>
                      <Row className="mt-4">
                        <Col className="d-flex align-items-center gap-2">
                          <FormGroupLabel className="m-0">
                            {t("remind_operator_at")}
                            <Tooltip text={t("average_speed_tooltip")}>
                              <Icon className="fi fi-rr-info px-1"></Icon>
                            </Tooltip>
                          </FormGroupLabel>
                          <FormItem
                            disabled={false}
                            id="daily-ts-time-div"
                            style={{ width: "120px" }}
                          >
                            <FormField
                              name="dailyTSTime"
                              id="daily-timesheet-time"
                              control={control}
                              component="input"
                              type="time"
                              //defaultValue=""
                              //placeholder={t("dailyTSTime")}
                              style={formInputStyles}
                              disabled={false}
                              rules={{ required: t("required_err") }}
                              //onKeyUp={handleSIOdoChange}
                            />
                          </FormItem>
                          {errors?.dailyTSTime && (
                            <Error error={errors.dailyTSTime.message} />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody style={{ height: "250px" }}>
                  <h4>{t("sem_settings")}</h4>

                  <Row className="mt-4">
                    <Col>
                      <CheckboxInputComponent
                        checked={dailyMileageMandatory}
                        setChecked={setDailyMileageMandatory}
                        label={t("daily_mileage_reqd_label")}
                      />
                    </Col>
                  </Row>

                  {dailyMileageMandatory && (
                    <>
                      <Row className="mt-4">
                        <Col
                          className="d-flex align-items-center justify-content-between"
                          md={10}
                        >
                          <FormGroupLabel
                            className="m-0"
                            //style={{ width: "250px" }}
                          >
                            {t("remind_operator_for_starting_mil_at")}
                            <Tooltip text={t("average_speed_tooltip")}>
                              <Icon className="fi fi-rr-info px-1"></Icon>
                            </Tooltip>
                          </FormGroupLabel>
                          <FormItem
                            disabled={false}
                            id="daily-ts-time-div"
                            style={{ width: "120px" }}
                          >
                            <FormField
                              name="startingMileageTime"
                              id="starting-mileage-time"
                              control={control}
                              component="input"
                              type="time"
                              //defaultValue=""
                              //placeholder={t("startingMileageTime")}
                              style={formInputStyles}
                              disabled={false}
                              rules={{ required: t("required_err") }}
                              //onKeyUp={handleSIOdoChange}
                            />
                          </FormItem>
                          {errors?.startingMileageTime && (
                            <Error error={errors.startingMileageTime.message} />
                          )}
                        </Col>
                      </Row>

                      <Row className="mt-4">
                        <Col
                          className="d-flex align-items-center justify-content-between"
                          md={10}
                        >
                          <FormGroupLabel
                            className="m-0"
                            //style={{ width: "250px" }}
                          >
                            {t("remind_operator_for_ending_mil_at")}
                            <Tooltip text={t("average_speed_tooltip")}>
                              <Icon className="fi fi-rr-info px-1"></Icon>
                            </Tooltip>
                          </FormGroupLabel>
                          <FormItem
                            disabled={false}
                            id="daily-ts-time-div"
                            style={{ width: "120px" }}
                          >
                            <FormField
                              name="endingMileageTime"
                              id="ending-mileage-time"
                              control={control}
                              component="input"
                              type="time"
                              //defaultValue=""
                              //placeholder={t("startingMileageTime")}
                              style={formInputStyles}
                              disabled={false}
                              rules={{ required: t("required_err") }}
                              //onKeyUp={handleSIOdoChange}
                            />
                          </FormItem>
                          {errors?.endingMileageTime && (
                            <Error error={errors.endingMileageTime.message} />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </FormContainer>
    </>
  );
};

export default OperatorSettings;
