import React, { useState } from "react";
import { OptionsMenu } from "../../../shared/components/table/components/MoreOptions/MoreOptions";
import { useTranslation } from "react-i18next";
import { RowOptionsButtonContainer } from "../../../shared/components/TableElements";
import useClickAway from "../../../shared/custom-hooks/useClickAway";
import styled from "styled-components";
import PartsFormPopup from "./PartsFormPopup";
import { DeletePopup } from "../../../shared/components/table/components/DeletePopup";
import { useAuth } from "../../../contexts/AuthContext";

const PartsRowOptions = ({
  rowData,
  setShowPartsForm,
  setMode,
  setShowEditPart,
  setSingleRowData,
  onEssentialUserAddBatch,
}) => {
  const { t } = useTranslation("common");
  const { tag } = useAuth();

  const [toggleOptions, setToggleOptions] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const clickAwayRef = useClickAway({
    handleCloseElement: () => setToggleOptions(false),
  });

  const handleClick = (e) => {
    e.preventDefault();
    setToggleOptions(!toggleOptions);
  };

  const handleAddBatch = (e) => {
    if (tag === "essential-user") {
      onEssentialUserAddBatch();
      setToggleOptions(false);
      return;
    }
    e.stopPropagation();
    setMode("add-batch");
    setSingleRowData(rowData);
    setShowPartsForm(true);
    setToggleOptions(false);
  };

  const RowOptionsMenu = ({
    setToggleOptions,
    setShowPartsForm,
    setMode,
    setShowDeletePopup,
  }) => {
    return (
      <>
        {/* Using RowOptionsMenuWrap as span since span tag will prevent side panel toggling */}
        <RowOptionsMenuWrap id="row-options">
          {/* <span> */}
          <OptionsMenu
            id="add-batch-btn"
            // onClick={(e) => {
            //   e.stopPropagation();
            //   setMode("add-batch");
            //   setSingleRowData(rowData);
            //   setShowPartsForm(true);
            //   setToggleOptions(false);
            // }}
            onClick={handleAddBatch}
          >
            {t("add_batch")}
          </OptionsMenu>
          {/* </span> */}
          <OptionsMenu
            id="edit-part-btn"
            onClick={() => {
              setSingleRowData(rowData);
              setShowEditPart(true);
              setToggleOptions(false);
            }}
          >
            {t("edit_part")}
          </OptionsMenu>
          <OptionsMenu
            id="delete-part-btn"
            onClick={() => {
              setShowDeletePopup(true);
              setToggleOptions(false);
            }}
          >
            {t("delete_part")}
          </OptionsMenu>
        </RowOptionsMenuWrap>
      </>
    );
  };

  return (
    <>
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          deleteApiData={{
            delete_ids: rowData.part_id,
            querystring: "multiple_delete",
            sub_query_string: "PARTS",
          }}
          screen={"parts"}
          //setShowInfoPopup={setShowInfoPopup}
          //setInfoData={setInfoData}
        />
      )}
      <div
        className="d-flex align-items-start justify-content-center position-relative"
        ref={clickAwayRef}
      >
        <RowOptionsButtonContainer
          id="row-options-button"
          style={{ marginTop: 0 }}
          variant="outline-secondary"
          size="sm"
          onClick={handleClick}
        >
          <i className="fi fi-rr-menu-dots-vertical"></i>
        </RowOptionsButtonContainer>

        {toggleOptions && (
          <RowOptionsMenu
            rowData={rowData}
            toggleOptions={toggleOptions}
            setToggleOptions={setToggleOptions}
            setMode={setMode}
            setShowPartsForm={setShowPartsForm}
            //showZoomedDoc={showZoomedDoc}
            //setShowZoomedDoc={setShowZoomedDoc}
            //showDeletePopup={showDeletePopup}
            setShowDeletePopup={setShowDeletePopup}
            // deleteApiData={deleteApiData}
            //setDeleteApiData={setDeleteApiData}
            //path={path}
          />
        )}
      </div>
    </>
  );
};

export default PartsRowOptions;

export const RowOptionsMenuWrap = styled.span`
  top: 35px;
  z-index: 1000;
  position: absolute;
  width: 120px;
  border: 1px solid lightgray;
`;
