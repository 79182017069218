import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ActionIconContainer,
  AddFormButton,
  FilterButton,
  RowOptionsButtonContainer,
} from "../../../shared/components/TableElements";
import {
  CheckBoxInput,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { formInputStyles } from "../../../shared/constants/styles";
import styled from "styled-components";
import { colorRed, colorRedNew, colorRowHover } from "../../../utils/palette";
import { RowOptionsMenuWrap } from "../../../shared/components/table/components/RowOptions";
import { OptionsMenu } from "../../../shared/components/table/components/MoreOptions/MoreOptions";
import useClickAway from "../../../shared/custom-hooks/useClickAway";
import { post, postApi } from "../../../api/getUser";
import { useSelector } from "react-redux";
import {
  handleNegative,
  handleNumberInputOnWheelPreventChange,
  isObjEmpty,
} from "../../../shared/helpers";
import Error from "@/shared/components/form/Error";
import { RowContainer } from "./AddPartsPopup";

const UnitsConsumedPopup = ({
  showPopup,
  setShowPopup,
  partBatches,
  setPartBatches,
  serviceTaskDetailId,
  serviceTasksRef,
  setServiceTasks,
  setShowAddPartsPopup,
  setFromUnitsConsumed,
  serviceTasksChanged,
  setServiceTasksChanged,
}) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);
  // let batchesSelected = serviceTasksRef.current.find(
  //   (t) => t.service_task_detail_id === serviceTaskDetailId.current
  // )?.batches;
  // batchesSelected = batchesSelected.filter((b) => b?.action !== 3);
  const [selectedBatches, setSelectedBatches] = useState([]);
  //To focus on units consumed input for a particular batch in case of error
  const [batchRefs, setBatchRefs] = useState({});
  //To set parts_batch_id of batch that has error in unitsConsumed input
  //Used to make input border red
  const [errInput, setErrInput] = useState("");

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    // let batchesSelected = serviceTasksRef.current.find(
    //   (t) => t.service_task_detail_id === serviceTaskDetailId.current
    // )?.batches;
    // batchesSelected = batchesSelected.filter((b) => b?.action !== 3);
    // setSelectedBatches(batchesSelected);

    let batchesConsumed = partBatches[serviceTaskDetailId?.current]?.filter(
      (b) => b?.action !== 3
    );
    setSelectedBatches(batchesConsumed);
    if (batchesConsumed?.length > 0) {
      let refs = {};
      batchesConsumed?.forEach((batch) => {
        refs[batch.parts_batch_id] = {
          ref: createRef(),
        };
      });
      setBatchRefs(refs);
    }
  }, [partBatches]);

  const handleClose = () => {
    const currentTask = serviceTasksRef.current.find(
      (task) => task.service_task_detail_id === serviceTaskDetailId.current
    );
    console.log(
      "current task batches in servicetasksref",
      currentTask?.batches
    );
    setPartBatches((prev) => {
      let temp = { ...prev };
      temp[serviceTaskDetailId.current] = [...currentTask?.batches];
      return temp;
    });

    setShowPopup(false);
  };

  const handleUnitsConsumedChange = (e, partsBatchId) => {
    console.log(e, partsBatchId);
    e.preventDefault();
    setErrInput("");
    const { value } = e.target;

    setPartBatches((prev) => {
      let temp = { ...prev };
      let existingBatches = temp[serviceTaskDetailId.current];
      const updatedBatches = existingBatches.reduce((result, b) => {
        if (b.parts_batch_id === partsBatchId) {
          result.push({
            ...b,
            unitsConsumed: +value || "",
            totalCost: (+b.purchase_cost_per_unit * +value)?.toFixed(2),
            availableQuantity: +b.currentlyAvailableQuantity - +value,
          });
        } else {
          result.push(b);
        }
        return result;
      }, []);
      temp[serviceTaskDetailId.current] = [...updatedBatches];
      return temp;
    });

    // setPartBatches((prev) => {
    //   let temp = { ...prev };
    //   let existingBatches = temp[serviceTaskDetailId.current];
    //   existingBatches.forEach((b) => {
    //     if (b.parts_batch_id === partsBatchId) {
    //       b.unitsConsumed = +value || "";
    //       b.totalCost = +b.purchase_cost_per_unit * +value;
    //       b.availableQuantity = +b.currentlyAvailableQuantity - +value;
    //     }
    //   });
    //   temp[serviceTaskDetailId.current] = [...existingBatches];
    //   return temp;
    // });

    // setSelectedBatches((prev) => {
    //   let temp = [...prev];
    //   temp.forEach((b) => {
    //     if (b.parts_batch_id === partsBatchId) {
    //       b.unitsConsumed = +value || "";
    //       b.totalCost = +b.purchase_cost_per_unit * +value;
    //       b.availableQuantity = +b.currentlyAvailableQuantity - +value;
    //     }
    //   });
    //   return temp;
    // });

    //Update errors - remove errors for that particular batch
    setErrMsg("");
    // serviceTasksRef.current.forEach((task) => {
    //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
    //     task.batches.forEach((b) => {
    //       if (b.parts_batch_id === partsBatchId) {
    //         b.unitsConsumed = +value;
    //         b.totalCost = +b.purchase_cost_per_unit * +value;
    //         b.availableQuantity = +b.currentlyAvailableQuantity - +value;
    //       }
    //     });
    //   }
    // });
  };

  const handleSubmit = () => {
    //Validations
    let errors = {};
    for (let batch of selectedBatches) {
      const { parts_batch_id } = batch;
      // if (!batch.unitsConsumed) {
      //   errors[parts_batch_id] = "Please enter the no of units consumed";
      // }
      if (!batch.unitsConsumed || batch.unitsConsumed <= 0) {
        setErrInput(parts_batch_id);
        setBatchRefs((prev) => {
          let temp = { ...prev };
          temp[parts_batch_id].ref.current.focus();
          return temp;
        });
        setErrMsg(t("units_consumed_less_than_zero_err"));
        return;

        // errors[parts_batch_id].errMsg = "Units consumed must be greater than 0";
        // setBatchErrors(errors);
        // return;
      } else if (batch.availableQuantity < 0) {
        setErrInput(parts_batch_id);
        setBatchRefs((prev) => {
          let temp = { ...prev };
          temp[parts_batch_id].ref.current.focus();
          return temp;
        });
        setErrMsg(t("consumed_qty_more_than_avail_qty_err"));
        return;

        // errors[parts_batch_id].errMsg =
        //   "Units consumed cannot be greater than the available quantity";
        // setBatchErrors(errors);
        // return;
      }
    }
    // setBatchErrors(errors);
    // if (!isObjEmpty(errors)) {
    //   return;
    // }

    //Update service tasks ref to currently consumed parts
    serviceTasksRef.current.forEach((task) => {
      if (task.service_task_detail_id === serviceTaskDetailId.current) {
        const consumedBatches = partBatches[serviceTaskDetailId.current];
        task.batches = [...consumedBatches];
      }
    });

    const currentTask = serviceTasksRef.current.find(
      (t) => t.service_task_detail_id === serviceTaskDetailId.current
    );
    const currentTaskBatches = currentTask.batches.filter(
      (b) => b.action !== 3
    ); //Non deleted batches
    const totalCostArr = currentTaskBatches
      .map((b) => +b.totalCost)
      .filter((total) => +total);
    const totalPartsCost = totalCostArr.reduce((accumulator, currentValue) => {
      return accumulator + +currentValue;
    }, 0);

    serviceTasksRef.current.forEach((task) => {
      if (task.service_task_detail_id === serviceTaskDetailId.current) {
        task.partsCost = +totalPartsCost;
        task.totalCost = +task.laborCost + +totalPartsCost;
      }
    });

    let partsDisplayArr = [];
    currentTaskBatches.forEach((b) => {
      const partDisplayString = `${b.part_name} (${b.unitsConsumed} ${t(
        "units"
      )} @ ${b.purchase_cost_per_unit} ${currency}) - ${
        b.totalCost
      } ${currency}`;
      partsDisplayArr.push(partDisplayString);
    });

    //Get no of parts used
    const noOfParts = [...new Set(currentTaskBatches.map((b) => b.part_id))]
      .length;

    setServiceTasks((prev) => {
      let temp = [...prev];

      temp = temp.map((task) => {
        if (task.service_task_id === currentTask?.service_task_id) {
          // task.partsCost = totalPartsCost;
          // task.viewMore = false;
          // task.partsDisplayArr = partsDisplayArr;
          return {
            ...task,
            partsCost: +totalPartsCost,
            totalCost: +task.laborCost + +totalPartsCost,
            viewMore: true,
            partsDisplayArr,
            noOfParts,
          };
        } else {
          return task;
        }
      });
      return temp;
    });

    //Update service tasks changed flag
    if (!serviceTasksChanged) {
      setServiceTasksChanged(true);
    }

    setShowPopup(false);
  };

  const handleRemoveBatch = (batch) => {
    // setSelectedBatches((prev) =>
    //   prev.filter((b) => b.parts_batch_id !== batch.parts_batch_id)
    // );

    setPartBatches((prev) => {
      let temp = { ...prev };
      let existingBatches = temp[serviceTaskDetailId.current];
      const updatedBatches = existingBatches.reduce((result, b) => {
        if (b.parts_batch_id === batch.parts_batch_id) {
          if (b.action === 2) {
            result.push({ ...b, action: 3 });
          }
        } else {
          result.push(b);
        }
        return result;
      }, []);
      temp[serviceTaskDetailId.current] = [...updatedBatches];
      return temp;
    });
    // serviceTasksRef.current.forEach((task) => {
    //   if (task.service_task_detail_id === serviceTaskDetailId.current) {
    //     let updatedBatches = [];
    //     for (let b of task.batches) {
    //       if (b.parts_batch_id === batch.parts_batch_id) {
    //         if (b.action === 2) {
    //           updatedBatches.push({ ...b, action: 3 });
    //         }
    //       } else {
    //         updatedBatches.push(b);
    //       }
    //     }
    //     task.batches = updatedBatches;
    //     // task.batches = task.batches.map((b) => {
    //     //   if (b.parts_batch_id === batch.parts_batch_id) {
    //     //     if (b.action === 2) {
    //     //       return { ...b, action: 3 };
    //     //     } else {
    //     //       return;
    //     //     }
    //     //   } else {
    //     //     return b;
    //     //   }
    //     // });
    //   }
    // });
  };

  return (
    <Modal show={showPopup} size="xl">
      <Modal.Header>
        <h4>{t("parts_consumed")}</h4>
        <div className="d-flex justify-content-end">
          <ActionIconContainer onClick={handleClose}>
            <i className="fi fi-rr-cross-small" />
          </ActionIconContainer>
        </div>
      </Modal.Header>
      <Modal.Body className="m-0 p-0">
        <Container className="m-0 p-0">
          <Row className="mt-2 mx-2">
            <Col md={3}></Col>
            <Col md={1}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>{t("batch_id")}</p>
            </Col>
            <Col md={1}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>{t("location")}</p>
            </Col>
            <Col md={1}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>{t("available")}</p>
            </Col>
            <Col md={3}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>{t("consumed")}</p>
            </Col>
            <Col md={1}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>
                {t("cost_per_unit")}
              </p>
            </Col>
            <Col md={1}>
              <p style={{ fontSize: 12, fontWeight: 300 }}>{t("tc_tv")}</p>
            </Col>
            <Col md={1}></Col>
          </Row>
          <RowContainer $height={`${window.innerHeight - 450}px`}>
            {selectedBatches.length === 0 && (
              <Row
                className="d-flex justify-content-center align-items-center"
                style={{ height: "-webkit-fill-available" }}
              >
                <Col md={2}>
                  <p>{t("no_records")}</p>
                </Col>
              </Row>
            )}
            {selectedBatches.map((batch, i) => (
              <React.Fragment key={batch.parts_batch_id}>
                <Row
                  className="m-2 py-2 d-flex align-items-center"
                  key={batch.parts_batch_id}
                  tabIndex={i + 1}
                >
                  <Col
                    md={3}
                    // className="d-flex gap-1 align-items-center"
                  >
                    <p>{batch.part_name}</p>
                    {/* {batch?.batchDeleted && (
                      <HintText>{`(${t("Deleted")})`}</HintText>
                    )} */}
                  </Col>
                  <Col md={1}>
                    <p style={{ position: "relative", right: "0.4rem" }}>
                      {batch.batch_id}
                    </p>
                  </Col>
                  <Col md={1}>
                    <p style={{ position: "relative", right: "0.4rem" }}>
                      {batch.location}
                    </p>
                  </Col>

                  <Col md={1}>
                    <p>{batch.availableQuantity}</p>
                  </Col>
                  <Col md={3} className="d-flex align-items-center">
                    <FormItem
                      style={{
                        borderColor:
                          errInput === batch.parts_batch_id ? colorRed : "",
                      }}
                      //disabled={batch?.batchDeleted}
                    >
                      <input
                        //disabled={batch?.batchDeleted}
                        ref={batchRefs[batch.parts_batch_id]?.ref}
                        name={`units-consumed-${batch.parts_batch_id}`}
                        type="number"
                        onWheel={handleNumberInputOnWheelPreventChange}
                        value={batch.unitsConsumed}
                        onChange={(e) =>
                          handleUnitsConsumedChange(e, batch.parts_batch_id)
                        }
                        onKeyDown={(e) => handleNegative(e)}
                        style={formInputStyles}
                      />
                    </FormItem>
                  </Col>
                  <Col md={1}>
                    <p style={{ marginLeft: "0.4rem" }}>
                      {batch.purchase_cost_per_unit}
                    </p>
                  </Col>
                  <Col md={1}>
                    <p style={{ marginLeft: "0.5rem" }}>{batch.totalCost}</p>
                  </Col>
                  <Col md={1}>
                    <ActionIconContainer
                      onClick={() => handleRemoveBatch(batch)}
                    >
                      <i className="fi fi-rr-cross-small" />
                    </ActionIconContainer>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </RowContainer>
          {errMsg && (
            <Row className="m-2">
              <Col>
                <Error error={errMsg} />
              </Col>
            </Row>
          )}
          <Row className="mx-2 my-3">
            <Col>
              <FilterButton
                onClick={() => {
                  setFromUnitsConsumed(true);
                  setShowAddPartsPopup(true);
                  setShowPopup(false);
                }}
              >{`+${t("select_parts")}`}</FilterButton>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <AddFormButton
          id="submit-form-button"
          type="button"
          onClick={handleSubmit}
        >
          {/* {saveBtnLoading ? <CustomLoader /> : t("save")} */}
          {t("submit")}
        </AddFormButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UnitsConsumedPopup;
