import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NATEXT, s3Url } from "../../../../../constants";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import {
  getDisplayDateWithTimeNew,
  getDistanceUnit,
} from "../../../../../helpers";
import { convertToDisplayTime } from "../../../../../helpers";
import {
  colorLightGray,
  failBgColor,
  failTextColor,
} from "../../../../../../utils/palette";
import { Icon } from "../../../../TableElements";

const InspectionPrintComponent = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const { distance, currency } = useSelector((state) => state.units);
  const { orgName, address, country } = useSelector((state) => state.user);
  const { rowData, submissionsData, imgArrData, vehicle } = props;
  const {
    form_name,
    inspection_date,
    start_time,
    end_time,
    veh_img,
    veh_id,
    name,
    primary_meter,
    user_name,
    comments,
    signature,
    inspection_id,
    org_id,
  } = rowData;

  const { license_no, vin_no, veh_active, secondary_status, veh_type } =
    vehicle;

  let vehicleStatus = "active";
  if (
    (veh_active === 0 && secondary_status === 0) ||
    (veh_active === 2 && secondary_status === 0)
  ) {
    vehicleStatus = "inactive";
  }
  if (veh_active === 0 && secondary_status === 1) {
    vehicleStatus = "outOfService";
  }

  const vehicleStatusStyles = {
    active: { text: t("active"), color: "green" },
    inactive: { text: t("inactive"), color: colorLightGray },
    outOfService: { text: t("out_of_service"), color: "red" },
  };

  //   let odoVal = odo;
  //   if (odo > 0 && odo !== -1000) {
  //     odoVal = (+odo / distanceFactor)?.toFixed(2);
  //   }

  const imageUrl = veh_img
    ? `${s3Url}/org_${org_id}/vehicle_${veh_id}/${veh_img}`
    : defaultVehicleIcon;

  return (
    <div ref={ref} className="m-5 d-flex flex-column gap-4">
      <div>
        <div>
          <p>{orgName}</p>
          <p>{address}</p>
          <p>{country}</p>
        </div>
      </div>

      <div>
        <div className="d-flex gap-2 align-items-center">
          <img
            src={imageUrl}
            alt="vehicle-img"
            width={40}
            height={40}
            // style={{ width: "40px", height: "40px" }}
            className="rounded-circle"
          />
          <div>
            <p style={{ fontSize: 16, fontWeight: 500 }}>{name}</p>
            <div className="d-flex gap-1">
              <p>{veh_type}</p>
              <p>-</p>
              <p style={{ color: vehicleStatusStyles[vehicleStatus]?.color }}>
                {vehicleStatusStyles[vehicleStatus]?.text}
              </p>
            </div>
            <div className="d-flex gap-1">
              <span>{t("vin")}:</span>
              <p>{vin_no || NATEXT}</p>
            </div>
            <div className="d-flex gap-1">
              <span>{t("license")}:</span>
              <p>{license_no || NATEXT}</p>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="d-flex gap-1">
            <span>{t("vin")}:</span>
            <p>{vin_no || NATEXT}</p>
          </div>
          <div className="d-flex gap-1">
            <span>{t("license")}:</span>
            <p>{license_no || NATEXT}</p>
          </div> */}
          {/* <p>{`${t("vin")}: ${vin_no || NATEXT}`}</p>
          <p>{`${t("license")}: ${license_no || NATEXT}`}</p> */}
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div>
          <span>{t("inspection_form_label")}</span>
          <p>{form_name}</p>
        </div>
        <div>
          <span>{t("inspection_by")}</span>
          <p>{user_name}</p>
        </div>

        <div>
          <span>{t("date_tv")}</span>
          <p>{getDisplayDateWithTimeNew(inspection_date)}</p>
        </div>

        <div>
          <span>{t("start_time")}</span>
          <p>{convertToDisplayTime(new Date(start_time * 1000))}</p>
        </div>

        <div>
          <span>{t("end_time")}</span>
          <p>{convertToDisplayTime(new Date(end_time * 1000))}</p>
        </div>
      </div>

      {/* Issues */}
      {submissionsData?.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <h5>{t("inspection_items")}</h5>
          <table style={{ width: "100%" }} className="table table-bordered">
            <thead>
              <tr>
                <th>{t("item_checklist")}</th>
                <th></th>
                {/* <th>{t("images")}</th> */}
              </tr>
            </thead>
            <tbody>
              {submissionsData.map((sub) => (
                <tr
                  key={sub.inspection_detail_id}
                  style={{
                    background: sub.pass === 0 ? failBgColor : "",
                  }}
                >
                  <td>{sub.task_name}</td>
                  <td>
                    <div className="d-flex flex-column gap-2">
                      {/* Pass/Fail */}
                      {[0, 1, 2].includes(sub.pass) && (
                        <div>
                          {sub.pass === 1 ? (
                            <span
                              style={{
                                color: "#4DC78E",
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {t("inspection_pass")}
                            </span>
                          ) : sub.pass === 0 ? (
                            <span
                              style={{
                                color: failTextColor,
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {t("inspection_fail")}
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                            >
                              {t("not_inspected")}
                            </span>
                          )}
                        </div>
                      )}

                      {/* Value */}
                      {sub.value && sub.value !== "-1000" && (
                        <div>
                          <span style={{ fontSize: "14px" }}>
                            {sub.value}{" "}
                            {sub.task_id === `${org_id}_odometer`
                              ? getDistanceUnit(
                                  primary_meter === "Hours" ? "Hours" : distance
                                )
                              : ""}
                          </span>
                        </div>
                      )}

                      {/* Image */}
                      {sub.image && sub.image !== "-1000" && (
                        <div className="d-flex gap-2">
                          {imgArrData[sub.inspection_detail_id]?.map((img) => (
                            <img
                              key={img.imgName}
                              src={img.url}
                              alt="insp-img"
                              width={40}
                              height={40}
                            />
                          ))}
                        </div>
                      )}

                      {/* Comments */}
                      {sub.comments && (
                        <div>
                          <span style={{ fontWeight: 300 }}>
                            <Icon className="fi fi-rr-comment"></Icon>
                            {sub.comments}
                          </span>
                        </div>
                      )}
                    </div>
                  </td>
                  {/* <td>
                    {issue?.images?.length > 0
                      ? issue.images.map((img) => (
                          <div className="d-flex align-items-center">
                            {["png", "jpg", "jpeg"].includes(img?.extension) ? (
                              <img
                                src={img.url}
                                width={40}
                                height={40}
                                alt={`issue-img-${img.id}`}
                              />
                            ) : (
                              <iframe
                                src={img.url}
                                width={40}
                                height={40}
                                //alt={`issue-img-${img.id}`}
                                //src={img.url}
                                //width={40}
                                //height={40}
                              />
                            )}
                          </div>
                        ))
                      : NATEXT}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {signature && (
        <div
          style={{
            border: `1px solid ${colorLightGray}`,
            borderRadius: "5px",
            width: 120,
            height: 80,
          }}
        >
          <img
            src={`${s3Url}/org_${org_id}/inspections/${inspection_id}/${signature}`}
            alt="signature-img"
            width={120}
            height={80}
          />
        </div>
      )}
      <div>
        <span>{t("notes_tv")}</span>
        <p>{comments || NATEXT}</p>
      </div>
    </div>
  );
});

export default InspectionPrintComponent;
