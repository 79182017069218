import React from "react";
import { Route, Switch } from "react-router-dom";
import Parts from "./Parts";
import Usage from "./Usage";
import NotFound404 from "../../containers/DefaultPage/404";

export default () => (
  <Switch>
    <Route path="/inventory/parts" component={Parts} />
    {/* <Route path="/inventory/usage" component={Usage} /> */}
    {/* <Route path="/inventory/*" component={NotFound404} /> */}
  </Switch>
);
