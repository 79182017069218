import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Col, Container, Row } from "react-bootstrap";
import {
  CheckBoxInput,
  FormContainer,
  FormGroupLabel,
  FormItem,
  Label,
} from "../../../shared/components/form/FormElements";
import { Card, CardBody } from "@/shared/components/Card";
import Error from "@/shared/components/form/Error";

// import { isObjEmpty } from "../../shared/helpers";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import FormField from "../../../shared/components/form/FormField";
import { formInputStyles, formStyles } from "../../../shared/constants/styles";
import { generateTreeArr, isObjEmpty } from "../../../shared/helpers";
import { useSelector } from "react-redux";
import { getGroups, postApi } from "../../../api/getUser";
import { updateGroupsList } from "../../../features/groups/groupsSlice";
import { TreeSelect } from "primereact/treeselect";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const GroupsFormPopup = ({
  showPopup,
  setShowPopup,
  newGroupInfo,
  setNewGroupInfo,
  mode,
}) => {
  //const history = useHistory();
  const dispatch = useDispatch();
  //const location = useLocation();
  const { t } = useTranslation("common");
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  let allGroups = useSelector((state) => state.groups.list);

  const { userId } = useSelector((state) => state.user);

  const [groups, setGroups] = useState([]);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  useEffect(() => {
    let groupOptions = [
      {
        label: t("parent_group"),
        value: "parent_group",
        level: 1,
        group_id: "parent_group",
        key: "parent_group",
        // data: {
        //   label: t("parent_group"),
        //   value: "parent_group",
        //   level: 1,
        //   group_id: "parent_group",
        // },
        parent_group: null,
      },
      ...allGroups.filter((g) => g.group_id),
    ];

    if (mode === "from-edit-group" && groupOptions.length > 0 && newGroupInfo) {
      groupOptions = groupOptions.filter(
        (g) => g.group_id !== newGroupInfo.group_id
      );
    }

    setGroups(generateTreeArr(groupOptions));
  }, [allGroups, newGroupInfo]);

  const groupName = watch("groupName");
  const parentGroup = watch("parentGroup");
  const desc = watch("desc");

  useEffect(() => {
    if (mode === "from-add-vehicle") {
      setValue("groupName", newGroupInfo?.name);
      setValue("parentGroup", "parent_group");
    }

    if (mode === "from-edit-group") {
      setValue("groupName", newGroupInfo.group_name);
      setValue("desc", newGroupInfo.description);
      if (newGroupInfo.parent_group) {
        setValue("parentGroup", newGroupInfo.parent_group);
      } else {
        setValue("parentGroup", "parent_group");
      }
    }

    if (mode === "from-add-sub-group") {
      setValue("parentGroup", newGroupInfo.group_id);
    }

    if (!mode) {
      setValue("parentGroup", "parent_group");
    }
  }, [newGroupInfo]);

  const handleClose = () => {
    //setNewGroupInfo(null);
    reset({
      groupName: "",
      parentGroup: "",
      desc: "",
    });
    if (!isObjEmpty(errors)) {
      clearErrors();
    }
    setShowPopup(false);
  };

  const addGroup = async () => {
    try {
      if (!groupName) {
        setError(
          "groupName",
          {
            type: "required",
            message: t("required_err"),
          },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors("groupName");
      }
      const parent = allGroups.find((g) => g.key === parentGroup);
      if (parent?.level && parent?.level > 4) {
        setError(
          "parentGroup",
          {
            type: "custom",
            message: t("create_sub_group_err_msg"),
          },
          { shouldFocus: true }
        );
        return;
      } else {
        clearErrors("parentGroup");
      }

      const parentGroupLevel = parent?.level || 0;
      const editGroupLevel = +newGroupInfo?.level || 1;
      let childGroups = [];
      if (
        mode === "from-edit-group" &&
        newGroupInfo?.groupNodes?.length > 0
        //&& parent
      ) {
        let childGroupLevels = [];
        newGroupInfo?.groupNodes?.forEach((id) => {
          const childGroup = allGroups.find((g) => g.group_id === id);
          if (childGroup) {
            childGroups.push(childGroup);
            childGroupLevels.push(childGroup?.level);
          }
        });
        const maxChildGroupLevel = Math.max(...childGroupLevels);
        if (maxChildGroupLevel > 0) {
          const noOfLevels = +maxChildGroupLevel - editGroupLevel + 1;
          const levelLimit = noOfLevels + +parentGroupLevel;
          //console.log("levelLimit", levelLimit);
          if (levelLimit > 5) {
            setError(
              "parentGroup",
              {
                type: "custom",
                message: t("group_level_err_msg"),
              },
              { shouldFocus: true }
            );
            return;
          }
        }
      }

      //console.log("errors", errors);

      if (!isObjEmpty(errors)) {
        return;
      }

      setSaveBtnLoading(true);

      if (mode === "from-edit-group") {
        let groupsToUpdate = [
          {
            parent_group: parent?.group_id || 0,
            group_name: groupName,
            description: desc || "",
            querystring: "add_group",
            level: parentGroupLevel + 1,
            group_id: newGroupInfo.group_id,
            source: "web",
          },
        ];
        const levelsShifted = parentGroupLevel + 1 - editGroupLevel;
        //console.log("parentGroupLevel", parentGroupLevel);
        //console.log("editGroupLevel", editGroupLevel);
        //console.log("levelsShifted", levelsShifted);
        if (levelsShifted !== 0) {
          childGroups.forEach((g) => {
            groupsToUpdate.push({
              parent_group: g?.parent_group,
              group_name: g.group_name,
              description: g.desc || "",
              querystring: "add_group",
              level: g?.level + levelsShifted,
              group_id: g.group_id,
              source: "web",
            });
          });
        }
        //console.log("groupsToUpdate", groupsToUpdate);

        let successRes = 0;
        for (let group of groupsToUpdate) {
          const res = await postApi(group, "commonNew");

          if (!res.success) {
            setSaveBtnLoading(false);
            setShowErrPopup(true);
            return;
          }

          if (res.success) {
            successRes += 1;
          }
          if (successRes === groupsToUpdate.length) {
            const groupsList = await getGroups("get_all_groups_list", t);
            dispatch(updateGroupsList(groupsList));
            const snackbarMsg = t("record_edited_msg", {
              recordName: t("group"),
            });
            dispatch(addMsg(snackbarMsg));
            setSaveBtnLoading(false);
            setShowPopup(false);
            return;
          }
        }
      } else {
        const apiData = {
          parent_group: parent?.group_id || 0,
          group_name: groupName,
          description: desc || "",
          querystring: "add_group",
          level: parent?.level ? parent?.level + 1 : 1,
          group_id: mode === "from-edit-group" ? newGroupInfo.group_id : "",
          source: "web",
        };

        const res = await postApi(apiData, "commonNew");
        if (res.success) {
          const groupId = res?.group_id;
          const groupsList = await getGroups("get_all_groups_list", t);
          const snackbarMsg =
            mode === "from-edit-group"
              ? t("record_edited_msg", { recordName: t("group") })
              : t("record_added_msg", { recordName: t("group") });
          dispatch(updateGroupsList(groupsList));
          if (mode === "from-add-vehicle") {
            setNewGroupInfo((prev) => ({ ...prev, id: groupId }));
          } else {
            dispatch(addMsg(snackbarMsg));
          }
          setSaveBtnLoading(false);
          setShowPopup(false);
        } else {
          setSaveBtnLoading(false);
          setShowErrPopup(true);
          return;
        }
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <Modal
        show={showPopup}
        size="md"
        style={{ zIndex: 999 }}
        //autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <h4>
            {mode === "from-edit-group" ? t("edit_group") : t("add_group")}
          </h4>
          <div
            className="d-flex justify-content-end"
            //style={{ width: "-webkit-fill-available" }}
          >
            <ActionIconContainer onClick={handleClose}>
              <i className="fi fi-rr-cross-small" />
            </ActionIconContainer>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            height: `${window.innerHeight - 320}px`,
            overflow: "auto",
            //zIndex: 1000,
          }}
        >
          <FormContainer>
            <Container>
              <Card>
                <CardBody
                // style={{
                //   height: `${window.innerHeight - 400}px`,
                //   overflow: "auto",
                // }}
                >
                  <Row>
                    <Col>
                      <FormGroupLabel className="m-0">
                        {t("group_name")}
                      </FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="groupName"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("group_name")}
                          style={formInputStyles}
                          disabled={false}
                          rules={{ required: t("required_err") }}
                        />
                      </FormItem>

                      {errors?.groupName && (
                        <Error error={errors.groupName.message} />
                      )}
                    </Col>

                    <Col>
                      <FormGroupLabel className="m-0">
                        {t("parent_group")}
                      </FormGroupLabel>
                      <Controller
                        name="parentGroup"
                        control={control}
                        //errors={errors}
                        render={({ field: { onChange, value, ref } }) => (
                          // <Select
                          //   options={groupOptions}
                          //   //value={vehicles.find((c) => c.value === value)}
                          //   value={value}
                          //   onChange={onChange}
                          //   //onCreateOption={handleCreate}
                          //   placeholder={t("parent_group")}
                          //   styles={formStyles}
                          //   isDisabled={false}
                          // />
                          <>
                            <TreeSelect
                              id="group-treeselect-dropdown"
                              value={value}
                              onChange={(g) => {
                                onChange(g);
                                clearErrors("parentGroup");
                              }}
                              options={groups}
                              //className="md:w-20rem w-full"
                              placeholder={t("select_item")}
                              filter={true}
                              //filterInputAutoFocus={true}
                              className="form-treeselect"
                              inputRef={ref}
                            ></TreeSelect>
                          </>
                        )}
                        rules={{ required: t("required_err") }}
                      />

                      {errors?.parentGroup && (
                        <Error error={errors.parentGroup.message} />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroupLabel>{t("desc")}</FormGroupLabel>
                      <FormItem disabled={false}>
                        <FormField
                          name="desc"
                          control={control}
                          component="input"
                          defaultValue=""
                          placeholder={t("desc")}
                          style={formInputStyles}
                          disabled={false}
                        />
                      </FormItem>

                      {errors?.desc && <Error error={errors.desc.message} />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </FormContainer>
        </Modal.Body>
        <Modal.Footer>
          <AddFormButton id="save-form-button" type="button" onClick={addGroup}>
            {saveBtnLoading ? <CustomLoader /> : t("save")}
          </AddFormButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupsFormPopup;
