import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  FormContainer,
  FormGroupLabel,
  FormItem,
} from "../../../shared/components/form/FormElements";
import { Card, CardBody } from "../../../shared/components/Card";
import { formInputStyles } from "../../../shared/constants/styles";
import FormField from "../../../shared/components/form/FormField";
import Error from "@/shared/components/form/Error";
import {
  ActionIconContainer,
  AddFormButton,
  CustomLoader,
} from "../../../shared/components/TableElements";
import { PhotoUploadComponent } from "../../UserManagement/UserDetailsForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { postApi } from "../../../api/getUser";
import ErrorPopup from "../../../shared/components/ErrorPopup";
import { uploadImgToS3 } from "../../Issues/IssuesForm";
import { useAuth } from "../../../contexts/AuthContext";
import { s3Url } from "../../../shared/constants";

const EditPartPopup = ({
  showPopup,
  setShowPopup,
  rowData,
  setSnackbarMsg,
}) => {
  const { t } = useTranslation("common");
  const { setReload } = useAuth();
  const { currency } = useSelector((state) => state.units);
  const { orgId } = useSelector((state) => state.user);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const partName = watch("partName");
  const partNotes = watch("partNotes");
  const barcode = watch("barcode");

  const [partPhoto, setPartPhoto] = useState("");
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [showErrPopup, setShowErrPopup] = useState(false);
  const [syncErrMsg, setSyncErrMsg] = useState("");

  //Effect on change of rowData(edit)
  useEffect(() => {
    if (rowData && orgId) {
      const { part_name, part_notes, part_img, part_id, barcode } = rowData;
      setValue("partName", part_name);
      setValue("partNotes", part_notes);
      setValue("barcode", barcode);

      //Logic to set Part Image
      if (part_img) {
        const imageUrl = `${s3Url}/org_${orgId}/parts/${part_id}/${part_img}`;

        setPartPhoto({ url: imageUrl, imgName: part_img });
      }
      //else {
      //   setPartPhoto("");
      // }
    }
  }, [rowData, orgId]);

  const handleErrPopupClose = () => {
    setShowErrPopup(false);
    setSyncErrMsg("");
  };

  const onSubmit = async () => {
    try {
      setSaveBtnLoading(true);
      const partApiJson = {
        querystring: "add_edit_parts",
        part_id: rowData?.part_id,
        part_name: partName,
        notes: partNotes || "",
        barcode: barcode || null,
        image: partPhoto?.imgName || "",
        action: 2,
      };
      const partApiRes = await postApi(partApiJson, "inventory");

      if (!partApiRes.success) {
        if (partApiRes?.action_value === "record_exists") {
          setSyncErrMsg(t("dup_part_name_err"));
        }
        if (partApiRes?.action_value === "barcode_record_exists") {
          setSyncErrMsg(t("dup_barcode_err"));
        }
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }

      if (partPhoto?.file) {
        const key = `image_data/org_${orgId}/parts/${rowData?.part_id}/${partPhoto?.imgName}`;
        const img = { file: partPhoto?.file, key };
        const status = await uploadImgToS3(img);
        if (status === 200) {
          setSaveBtnLoading(false);
          setShowPopup(false);
          setReload("parts");
          setSnackbarMsg(t("record_edited_msg", { recordName: t("part") }));
          return;
        }
      }

      setSaveBtnLoading(false);
      setShowPopup(false);
      setReload("parts");
      setSnackbarMsg(t("record_edited_msg", { recordName: t("part") }));
    } catch (e) {
      console.log(e);
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showErrPopup && (
        <ErrorPopup
          showPopup={showErrPopup}
          setShowPopup={setShowErrPopup}
          message={syncErrMsg || ""}
          onClose={handleErrPopupClose}
        />
      )}
      <Modal show={showPopup} size="md" centered>
        <FormContainer
          className="m-0 p-0 d-block"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Header>
            <h4>{t("edit_part")}</h4>
            <div
              className="d-flex justify-content-end"
              //style={{ width: "-webkit-fill-available" }}
            >
              <ActionIconContainer onClick={handleClose}>
                <i className="fi fi-rr-cross-small" />
              </ActionIconContainer>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container className="m-0 p-0">
              <Card className="m-0 p-0">
                <CardBody
                  //className="m-0 p-0"
                  style={{
                    height: `${window.innerHeight - 380}px`,
                    overflow: "auto",
                  }}
                >
                  <Row className="d-flex align-items-center">
                    <Col md={3}>
                      <PhotoUploadComponent
                        photo={partPhoto}
                        setPhoto={setPartPhoto}
                        module={"parts"}
                        //isDisabled={type === "edit"}
                        //rowData={rowData}
                      />
                    </Col>
                    <Col></Col>
                    <Col md={8}>
                      <Row>
                        <Col className="m-0 p-0">
                          <FormGroupLabel className="m-0 p-0">
                            {t("part_name")}
                          </FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="partName"
                              control={control}
                              component="input"
                              defaultValue=""
                              placeholder={t("part_name")}
                              style={formInputStyles}
                              disabled={false}
                              rules={{ required: t("required_err") }}
                            />
                          </FormItem>

                          {errors?.partName && (
                            <Error error={errors.partName.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="m-0 p-0">
                          <FormGroupLabel>{t("upc_barcode")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="barcode"
                              control={control}
                              component="input"
                              defaultValue=""
                              placeholder={t("upc_barcode")}
                              style={formInputStyles}
                              disabled={false}
                            />
                          </FormItem>

                          {errors?.barcode && (
                            <Error error={errors.barcode.message} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="m-0 p-0">
                          <FormGroupLabel>{t("notes")}</FormGroupLabel>
                          <FormItem disabled={false}>
                            <FormField
                              name="partNotes"
                              control={control}
                              component="input"
                              defaultValue=""
                              placeholder={t("notes")}
                              style={formInputStyles}
                              disabled={false}
                            />
                          </FormItem>

                          {errors?.notes && (
                            <Error error={errors.notes.message} />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <AddFormButton
              id="save-form-button"
              type="submit"
              //onClick={addServiceTask}
            >
              {saveBtnLoading ? <CustomLoader /> : t("save")}
            </AddFormButton>
          </Modal.Footer>
        </FormContainer>
      </Modal>
    </>
  );
};

export default EditPartPopup;
