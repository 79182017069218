import React, { useRef, useState } from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Modal,
  Row,
  ToggleButton,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import Select from "react-select";
import { Card, CardBody } from "@/shared/components/Card";
import {
  FormContainer,
  FormGroupLabel,
  FormHeading,
} from "@/shared/components/form/FormElements";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import {
  FormItem,
  UnitSpan,
} from "../../../shared/components/form/FormElements";
import {
  buttonColorNew,
  colorLightGray,
  colorTextNew,
} from "../../../utils/palette";
import { useEffect } from "react";
import { getInspectionScheduleFormInfo, postApi } from "../../../api/getUser";
import Error from "@/shared/components/form/Error";
import Tooltip from "@/shared/components/Tooltip";
import { useHistory, useLocation } from "react-router";
import FormField from "../../../shared/components/form/FormField";
import FormHeader from "../../../shared/components/form/FormHeader";
import {
  CloseIcon,
  CustomInput,
  Desc,
  DollarIcon,
  DueOnWrap,
  EditIcon,
  EmailPreviewText,
  NotifyMeWrap,
  ServicesPreviewHeading,
  TimeSelect,
  convertToDays,
} from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import {
  convertToDisplayDate,
  convertToTextDate,
  getDateFnsLocale,
  getDistanceUnit,
  handleNegative,
  handleNumberInputOnWheelPreventChange,
} from "../../../shared/helpers";
import { formStyles } from "../../../shared/constants/styles";
import { AddFormButton } from "../../../shared/components/TableElements";
import { NATEXT } from "../../../shared/constants";
import getUnixTime from "date-fns/getUnixTime";
import add from "date-fns/add";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import sub from "date-fns/sub";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import { useAuth } from "../../../contexts/AuthContext";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import SubscribeTooltipIcon from "../../../shared/components/SubscribeTooltipIcon";
import ErrorPopup from "../../../shared/components/ErrorPopup";

const InspectionScheduleForm = ({ isHorizontal, isAboveError }) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const { distance } = useSelector((state) => state.units);
  //const { rowData, screen, type } = location.state;
  const rowData = location?.state?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList
    .slice(1, vehiclesList.length)
    .filter(
      (v) =>
        v.veh_active === 1 || (v.veh_active === 0 && v.secondary_status === 1)
    );

  const [inspectionFormsOptions, setInspectionFormsOptions] = useState([]);

  const [firstTimeEdit, setFirstTimeEdit] = useState(true);
  //let firstTimeEdit = useRef(true);

  //React hook form fields
  const vehicle = watch("vehicle");
  const inspectionForm = watch("inspectionForm");
  const inspectionIntervalOdo = watch("inspectionIntervalOdo");
  const inspectionIntervalDuration = watch("inspectionIntervalDuration");
  const inspectionIntervalPeriod = watch("inspectionIntervalPeriod");
  const dueOnDate = watch("dueOnDate");
  const dueOnOdo = watch("dueOnOdo");
  const notifyMeOdo = watch("notifyMeOdo");
  const notifyMeDuration = watch("notifyMeDuration");
  const notifyMePeriod = watch("notifyMePeriod");
  const notes = watch("notes");
  //For One time
  const inspectionOdoOT = watch("inspectionOdoOT");
  const inspectionDateOT = watch("inspectionDateOT");
  const notifyMeOdoOT = watch("notifyMeOdoOT");
  const notifyMeDurationOT = watch("notifyMeDurationOT");
  const notifyMePeriodOT = watch("notifyMePeriodOT");

  const [dueOn, setDueOn] = useState({ odo: "", date: "" });

  const [dueOnPreview, setDueOnPreview] = useState({ odo: [], date: [] });
  const [notifyMePreview, setNotifyMePreview] = useState({ odo: [], date: [] });

  const [lastInspected, setLastInspected] = useState("");
  const [lastServicedText, setLastServicedText] = useState({
    header: "",
    desc: "",
    data: "",
  });

  const [schedulePreviewText, setSchedulePreviewText] = useState(
    t("schedule_preview_empty")
  );

  const [showDueOn, setShowDueOn] = useState(false);

  const [notifyMePreviewOT, setNotifyMePreviewOT] = useState({
    odo: "",
    date: "",
  });

  const [toggleButton, setToggleButton] = useState("recurring");
  const toggleButtons = [
    {
      id: 0,
      label: t("recurring"),
      value: "recurring",
    },
    {
      id: 1,
      label: t("one_time"),
      value: "oneTime",
    },
  ];

  const periodOptions = {
    days: t("days"),
    weeks: t("weeks"),
    months: t("months"),
    years: t("years"),
  };

  const usersList = useSelector((state) => state.users.list);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [lastServiceCalc, setLastServiceCalc] = useState({
    odo: 0,
    date: new Date(),
  });

  const { tag } = useAuth();

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //initial Effect
  useEffect(() => {
    if (type !== "edit") {
      setValue("inspectionIntervalPeriod", "days");
      setValue("notifyMePeriod", "days");
      setValue("inspectionIntervalPeriodOT", "days");
      setValue("notifyMePeriodOT", "days");
    }
  }, []);

  //Effect to set vehicle and recurring/one-time in case of edit
  useEffect(() => {
    if (type === "edit" && vehiclesList?.length > 0) {
      const selectedVehicle = vehicles.find(
        (veh) => veh.vehicleId === rowData.veh_id
      );
      setToggleButton(rowData.recurring ? "recurring" : "oneTime");
      setValue("vehicle", selectedVehicle);

      console.log(rowData.last_inspection_date);

      const lastInspDate = rowData?.last_inspection_date
        ? new Date(rowData?.last_inspection_date * 1000)
        : new Date();

      setLastServiceCalc({
        odo: rowData?.last_inspection_odo,
        date: lastInspDate,
      });
    }
  }, [vehiclesList]);

  const validateMaxNotifyMe = (v) => {
    // const notifyMeDays = convertToDays(+v, notifyMePeriod);
    // console.log(dueOn);
    // const temp = subDays(dueOn.date, notifyMeDays);
    // console.log(temp < dueOn.date);
    if (v) {
      return (
        convertToDays(+v, notifyMePeriod) <
        convertToDays(+inspectionIntervalDuration, inspectionIntervalPeriod)
      );
    } else {
      return true;
    }
  };

  const validateMaxNotifyMeOT = (v) => {
    if (v) {
      const notifyMeDate = new Date(inspectionDateOT);
      notifyMeDate.setDate(
        notifyMeDate.getDate() - convertToDays(v, notifyMePeriodOT)
      );
      return notifyMeDate > new Date();
    } else {
      return true;
    }
    // if (v) {
    //   return (
    //     convertToDays(+v, notifyMePeriod) <
    //     convertToDays(+inspectionIntervalDuration, inspectionIntervalPeriod)
    //   );
    // } else {
    //   return true;
    // }
  };

  //Populate Notify by in Schedule Preview for one time
  useEffect(() => {
    if (inspectionOdoOT) {
      if (+notifyMeOdoOT < +inspectionOdoOT) {
        setNotifyMePreviewOT((prev) => ({
          ...prev,
          odo: +inspectionOdoOT - +notifyMeOdoOT,
        }));
      } else {
        setNotifyMePreviewOT((prev) => ({ ...prev, odo: "" }));
      }

      if (!notifyMeOdoOT || +notifyMeOdoOT <= 0) {
        setNotifyMePreviewOT((prev) => ({ ...prev, odo: "" }));
      }
    }
    if (inspectionDateOT) {
      const notifyBy = new Date(inspectionDateOT);
      notifyBy.setDate(
        notifyBy.getDate() -
          convertToDays(+notifyMeDurationOT, notifyMePeriodOT)
      );
      if (notifyBy > new Date()) {
        setNotifyMePreviewOT((prev) => ({ ...prev, date: notifyBy }));
      } else {
        setNotifyMePreviewOT((prev) => ({ ...prev, date: "" }));
      }

      if (!notifyMeDurationOT || +notifyMeDurationOT <= 0) {
        setNotifyMePreviewOT((prev) => ({ ...prev, date: "" }));
      }
    }
  }, [notifyMeDurationOT, notifyMePeriodOT, notifyMeOdoOT]);

  //Notify me values reset when inspect on values are empty - For ONE TIME
  useEffect(() => {
    if (!inspectionOdoOT) {
      setValue("notifyMeOdoOT", "");
    }
  }, [inspectionOdoOT]);

  useEffect(() => {
    if (!inspectionDateOT) {
      setValue("notifyMeDurationOT", "");
    }
  }, [inspectionDateOT]);

  const [lastInspectionOdoData, setLastInspectionOdoData] = useState("");
  const [lastInspectionDateData, setLastInspectionDateData] = useState("");
  //Change Last Serviced Data text based on change in lastInspected
  useEffect(() => {
    if (lastInspected === "last-inspected") {
      setLastServicedText({
        header: t("last_inspected_on"),
        desc: t("last_inspected_on_desc"),
        data: `${lastInspectionOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )} (${convertToTextDate(lastInspectionDateData)})
          `,
      });
    } else if (lastInspected === "max-odo") {
      setLastServicedText({
        header: t("current_odometer_reading"),
        desc: t("current_reading_desc_inspection"),
        data: `${lastInspectionOdoData} ${getDistanceUnit(
          vehicle?.primary_meter === "Hours" ? "Hours" : distance
        )}`,
      });
    } else if (lastInspected === "no-value") {
      setLastServicedText({
        header: "",
        desc: t("current_reading_desc_inspection"),
        data: "",
      });
    }
  }, [lastInspected, lastInspectionOdoData, lastInspectionDateData]);

  //Schedule Preview-Change in dueOnOdo and notifyMeOdo
  useEffect(() => {
    if (+dueOn.odo > 0) {
      setDueOnPreview((prev) => ({
        ...prev,
        odo: [
          +dueOn.odo,
          +dueOn.odo + +(inspectionIntervalOdo * 1),
          +dueOn.odo + +(inspectionIntervalOdo * 2),
          +dueOn.odo + +(inspectionIntervalOdo * 3),
          +dueOn.odo + +(inspectionIntervalOdo * 4),
        ],
      }));
      if (+notifyMeOdo > 0 && +notifyMeOdo < +inspectionIntervalOdo) {
        const notifyByOdo = +dueOn.odo - +notifyMeOdo;
        setNotifyMePreview((prev) => ({
          ...prev,
          odo: [
            +notifyByOdo,
            notifyByOdo + +(inspectionIntervalOdo * 1),
            notifyByOdo + +(inspectionIntervalOdo * 2),
            notifyByOdo + +(inspectionIntervalOdo * 3),
            notifyByOdo + +(inspectionIntervalOdo * 4),
          ],
        }));
      } else {
        setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
      }
    } else {
      //setDueOdoArray([]);
      setDueOnPreview((prev) => ({ ...prev, odo: [] }));
      setNotifyMePreview((prev) => ({ ...prev, odo: [] }));
    }
    //console.log(dueOdoArray, dueOn);
  }, [dueOn.odo, notifyMeOdo]);

  //Scheduled Preview-dueDate logic for dueDate/inspectionIntervalDuration/durationUnit change
  useEffect(() => {
    let temp1 = new Date(dueOn.date);
    let temp2 = new Date(dueOn.date);
    let temp3 = new Date(dueOn.date);
    let temp4 = new Date(dueOn.date);
    let temp5 = new Date(dueOn.date);
    //console.log(inspectionIntervalPeriod);
    const noOfDays = convertToDays(
      inspectionIntervalDuration,
      inspectionIntervalPeriod
    );
    //console.log(noOfDays);
    temp1.setDate(temp1.getDate());
    temp2.setDate(temp2.getDate() + +noOfDays * 1);
    temp3.setDate(temp3.getDate() + +noOfDays * 2);
    temp4.setDate(temp4.getDate() + +noOfDays * 3);
    temp5.setDate(temp5.getDate() + +noOfDays * 4);
    //setDueDateArray([temp1, temp2, temp3, temp4, temp5]);

    setDueOnPreview((prev) => ({
      ...prev,
      date: [temp1, temp2, temp3, temp4, temp5],
    }));
  }, [dueOn.date, notifyMeDuration, notifyMePeriod]);

  useEffect(() => {
    const noOfDaysSI = convertToDays(
      +inspectionIntervalDuration,
      inspectionIntervalPeriod
    );
    const noOfDaysNM = convertToDays(+notifyMeDuration, notifyMePeriod);
    if (
      notifyMeDuration &&
      dueOnPreview.date.length > 0 &&
      +notifyMeDuration > 0 &&
      noOfDaysNM < noOfDaysSI
    ) {
      const { date } = dueOnPreview;
      let n1 = new Date(date[0]);
      let n2 = new Date(date[1]);
      let n3 = new Date(date[2]);
      let n4 = new Date(date[3]);
      let n5 = new Date(date[4]);
      n1.setDate(n1.getDate() - noOfDaysNM);
      n2.setDate(n2.getDate() - noOfDaysNM);
      n3.setDate(n3.getDate() - noOfDaysNM);
      n4.setDate(n4.getDate() - noOfDaysNM);
      n5.setDate(n5.getDate() - noOfDaysNM);

      setNotifyMePreview((prev) => ({
        ...prev,
        date: [n1, n2, n3, n4, n5],
      }));
    } else {
      setNotifyMePreview((prev) => ({
        ...prev,
        date: [],
      }));
    }
  }, [dueOnPreview.date, notifyMeDuration, notifyMePeriod]);

  //Set Initial NotifyMe Values Based on Service Interval Values
  useEffect(() => {
    if (!inspectionIntervalDuration && !inspectionIntervalOdo) {
      setSchedulePreviewText(t("schedule_preview_empty"));
    } else if (inspectionIntervalDuration > 0 || inspectionIntervalOdo > 0) {
      setSchedulePreviewText(
        t("schedule_preview_filled_inspection", {
          last_serviced_text:
            lastInspected === "last-serviced"
              ? t("last_inspection_date_sm")
              : t("current_reading_sm"),
        })
      );
    }

    if (tag !== "free-user") {
      if (inspectionIntervalDuration) {
        const noOfDays = convertToDays(
          inspectionIntervalDuration,
          inspectionIntervalPeriod
        );
        if (noOfDays >= 90) {
          setValue("notifyMeDuration", 15);
          setValue("notifyMePeriod", "days");
        }
      } else {
        setValue("notifyMeDuration", "");
      }

      if (inspectionIntervalOdo) {
        if (inspectionIntervalOdo >= 5000) {
          setValue("notifyMeOdo", 250);
        }
      } else {
        setValue("notifyMeOdo", "");
      }
    }
  }, [
    inspectionIntervalDuration,
    inspectionIntervalOdo,
    inspectionIntervalPeriod,
  ]);

  //Get Inspection Form List with Change in Vehicle
  useEffect(() => {
    (async () => {
      let inspectionForms = [];
      if (vehicle) {
        console.log(vehicle);
        const res = await postApi(
          {
            querystring: "getallinspection_form",
            service_veh_id: vehicle.vehicleId,
          },
          "commonNew"
        );

        if (res.success) {
          const { user_data } = res;
          inspectionForms = user_data.map((form) => ({
            formId: form.form_id,
            value: form.form_id,
            label: form.form_name,
            taskIds: form.task_ids,
          }));
          setInspectionFormsOptions(inspectionForms);
        }
        setValue("inspectionForm", "");
        setValue("inspectionIntervalOdo", "");
        setValue("inspectionIntervalDuration", "");
        setValue("notifyMeDuration", "");
        setValue("notifyMeOdo", "");

        setDueOn({ odo: "", date: "" });

        if (type === "edit" && firstTimeEdit) {
          if (inspectionForms.length > 0) {
            const selectedForm = inspectionForms.find(
              (form) => form.formId === rowData.inspection_form_id
            );
            setValue("inspectionForm", selectedForm);
          }
        }
      }
    })();
  }, [vehicle]);

  //Getting max_odo, lastservice...(form details) on change of vehicle or inspection form
  //let lastInspectionOdoData = useRef(0);
  //let lastInspectionDateData = useRef(new Date());
  useEffect(() => {
    (async () => {
      if (vehicle?.vehicleId && inspectionForm?.formId) {
        const data = await getInspectionScheduleFormInfo(
          "get_veh_inspection_lastinspectiondate",
          vehicle.vehicleId,
          inspectionForm.formId
        );
        //console.log(data);
        setLastInspected(data.type);
        setLastInspectionOdoData(data.lastInspectionOdo);
        setLastInspectionDateData(data.lastInspectionDate);

        if (type !== "edit") {
          setLastServiceCalc({
            date: data.lastInspectionDate,
            odo: data.lastInspectionOdo,
          });
        }
        //lastInspectionOdoData.current = data.lastInspectionOdo;
        //setValue("lastInspectionOdo", lastInspectionOdoData.current);
        //lastInspectionDateData.current = data.lastInspectionDate;
        //setValue("lastInspectionDate", lastInspectionDateData.current);

        //All values reset on change of inspection form in case of add
        // if (type !== "edit") {
        //   setValue("inspectionIntervalOdo", "");
        //   setValue("inspectionIntervalDuration", "");
        //   setValue("notifyMeDuration", "");
        //   setValue("notifyMeOdo", "");
        //   setValue("inspectionIntervalPeriod", "days");
        //   setValue("notifyMePeriod", "days");
        //   setValue("notifyMePeriodOT", "days");
        //   setDueOn({ odo: "", date: "" });
        // }

        if (type === "edit" && firstTimeEdit) {
          const {
            days_remaining,
            dueDate,
            dueOdo,
            due_days,
            due_days_unit,
            due_odo,
            days_threshold,
            days_threshold_unit,
            odo_threshold,
            last_inspection_date,
            emails,
            comments,
          } = rowData;

          console.log(rowData);

          if (rowData.recurring) {
            setValue(
              "inspectionIntervalOdo",
              due_odo && due_odo !== NATEXT ? +due_odo : ""
            );

            setValue(
              "inspectionIntervalDuration",
              due_days && due_days !== NATEXT ? +due_days : ""
            );

            //Confirm whether to send in days or convert to required period.
            const inspectionIntervalUnit = getPeriodFromUnits(due_days_unit);
            setValue("inspectionIntervalPeriod", "days");
            setValue(
              "notifyMeOdo",
              odo_threshold && odo_threshold !== NATEXT ? +odo_threshold : ""
            );

            setValue(
              "notifyMeDuration",
              days_threshold && days_threshold !== NATEXT ? +days_threshold : ""
            );

            //Confirm whether to send in days or convert to required period.
            const notifyMeUnit = getPeriodFromUnits(odo_threshold);
            setValue("notifyMePeriod", "days");
          } else {
            setValue(
              "inspectionOdoOT",
              due_odo && due_odo !== NATEXT ? +due_odo : ""
            );

            setValue(
              "inspectionDateOT",
              due_days &&
                last_inspection_date &&
                due_days !== NATEXT &&
                last_inspection_date !== NATEXT
                ? addDays(new Date(last_inspection_date * 1000), due_days)
                : null
            );

            setValue(
              "notifyMeOdoOT",
              odo_threshold && odo_threshold !== NATEXT ? +odo_threshold : ""
            );

            setValue(
              "notifyMeDurationOT",
              days_threshold && days_threshold !== NATEXT ? +days_threshold : ""
            );

            //Confirm whether to send in days or convert to required period.
            const notifyMeUnit = getPeriodFromUnits(odo_threshold);
            setValue("notifyMePeriodOT", "days");
          }

          let emailsArr = [];
          if (emails && emails !== NATEXT) {
            let selectedEmails = emails.split(":::");
            emailsArr = usersList.filter((user) =>
              selectedEmails.includes(user.email)
            );
            //console.log(emailsArr);
            setValue("userEmail", emailsArr);
          } else {
            setValue("userEmail", []);
          }
          console.log(comments && comments !== NATEXT);
          setValue("notes", comments && comments !== NATEXT ? comments : "");

          setFirstTimeEdit(false);
        }
      }
    })();
  }, [inspectionForm?.formId]);

  //handleChange Functions

  const handleToggleChange = (e) => {
    setToggleButton(e.currentTarget.value);

    setValue("notifyMeOdo", "");
    setValue("notifyMeOdoOT", "");
    setValue("notifyMeDuration", "");
    setValue("notifyMeDurationOT", "");

    setValue("inspectionIntervalOdo", "");
    setValue("inspectionIntervalDuration", "");
    setValue("inspectionOdoOT", "");
    setValue("inspectionDateOT", null);

    setNotifyMePreviewOT({ odo: "", date: "" });
    clearErrors("notifyMeOdo");
    clearErrors("notifyMeOdoOT");
    clearErrors("notifyMeDuration");
    clearErrors("notifyMeDurationOT");
    clearErrors("inspectionIntervalOdo");
    clearErrors("inspectionIntervalDuration");
    clearErrors("inspectionOdoOT");
    clearErrors("inspectionDateOT");

    //setValue("dueOdo", +lastInspectionOdo + +inspectionIntervalOdo);
  };

  const getPeriod = (duration) => {
    switch (duration) {
      case "days":
        return 0;
      case "weeks":
        return 1;
      case "months":
        return 2;
      case "years":
        return 3;
    }
  };

  const getPeriodFromUnits = (duration) => {
    switch (+duration) {
      case 0:
        return "days";
      case 1:
        return "weeks";
      case 2:
        return "months";
      case 3:
        return "years";
    }
  };

  //Effects to update inspectOn/dueOn(odo and date)
  useEffect(() => {
    if (inspectionIntervalOdo) {
      const dueOnOdo = +inspectionIntervalOdo + +lastServiceCalc.odo;
      setDueOn((prev) => ({
        ...prev,
        odo: dueOnOdo,
      }));
    }
  }, [inspectionIntervalOdo, lastServiceCalc]);

  useEffect(() => {
    const noOfDays = convertToDays(
      inspectionIntervalDuration,
      inspectionIntervalPeriod
    );
    if (lastServiceCalc.date) {
      const temp = add(new Date(lastServiceCalc.date), { days: noOfDays });
      setDueOn((prev) => ({ ...prev, date: temp }));
    }
  }, [inspectionIntervalDuration, inspectionIntervalPeriod, lastServiceCalc]);

  const handleInspectionIntervalDurationChange = () => {
    let temp = new Date(lastInspectionDateData);
    //console.log(inspectionIntervalPeriod);
    const noOfDays = convertToDays(
      inspectionIntervalDuration,
      inspectionIntervalPeriod
    );
    //console.log(noOfDays);
    temp.setDate(temp.getDate() + noOfDays);
    //setValue("dueDate", temp);
    setDueOn((prev) => ({ ...prev, date: temp }));
  };

  const handleInspectionIntervalOdoChange = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
    if (inspectionIntervalOdo && lastInspectionOdoData) {
      const dueOnOdo = +inspectionIntervalOdo + +lastInspectionOdoData;
      setDueOn((prev) => ({
        ...prev,
        odo: dueOnOdo,
      }));
    }
  };

  const handleEditIconClick = () => {
    clearErrors("dueOnOdo");
    setValue("dueOnDate", dueOn.date);
    setValue("dueOnOdo", dueOn.odo);
    console.log(dueOn);
    //setDueOnDate(dueOn.date);
    //setDueOnOdo(dueOn.odo);
    setShowDueOn(true);
  };

  const handleSaveDueOn = () => {
    if (inspectionIntervalOdo) {
      setError("dueOnOdo", {
        types: {
          required: t("required_err"),
          min: t("value_greater_than_interval_err_msg", {
            screenName: t("inspection_sm"),
          }),
        },
      });
      console.log(errors.dueOnOdo);

      if (dueOnOdo && +dueOnOdo >= +inspectionIntervalOdo) {
        clearErrors("dueOnOdo");
      }

      if (!errors.dueOnOdo) {
        let dueOn = dueOnOdo ? Number(dueOnOdo).toFixed(2) : 0;
        setDueOn({ date: dueOnDate, odo: dueOn });
        setShowDueOn(false);
      }
    } else {
      setDueOn((prev) => ({ ...prev, date: dueOnDate }));
      setShowDueOn(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setSaveBtnLoading(true);
      const {
        vehicle,
        inspectionForm,
        inspectionIntervalOdo,
        inspectionIntervalDuration,
        notifyMeOdo,
        notifyMeDuration,
        notifyMePeriod,
        inspectionIntervalPeriod,
        lastInspectionOdo,
        lastInspectionDate,
        userEmail,
        notes,
        dueDate,
        dueOdo,
        inspectionOdoOT,
        inspectionDateOT,
      } = data;

      const isRecurring = toggleButton === "recurring";

      if (!isRecurring && inspectionOdoOT) {
        const apiData = {
          querystring: "checkmaxodometer_v2",
          veh_id: vehicle?.vehicleId,
          odometer: inspectionOdoOT,
        };

        const oneTimeOdoCheckRes = await postApi(apiData, "commonNew");
        console.log(oneTimeOdoCheckRes);
        if (!oneTimeOdoCheckRes?.success) {
          const { vehiclemaxododata } = oneTimeOdoCheckRes;
          setError(
            "inspectionOdoOT",
            {
              type: "min",
              message: t("insp_schedule_one_time_odo_val_err", {
                vehicleName: vehicle?.name,
                vehicleMaxOdo: vehiclemaxododata,
              }),
            },
            { shouldFocus: true }
          );
          setSaveBtnLoading(false);
          return;
        } else {
          clearErrors("inspectionOdoOT");
        }
      }

      let inspectionIntervalUnit = getPeriod(inspectionIntervalPeriod);
      let notifyMeUnit = getPeriod(notifyMePeriod);
      let notifyMeUnitOT = getPeriod(notifyMePeriodOT);

      const notifyMeDays = notifyMeDuration
        ? convertToDays(notifyMeDuration, notifyMePeriod)
        : 0;

      const notifyMeDaysOT = notifyMeDurationOT
        ? convertToDays(notifyMeDurationOT, notifyMePeriodOT)
        : 0;

      const notifyMeOdometer = notifyMeOdo ? notifyMeOdo : "";
      const notifyMeOdometerOT = notifyMeOdoOT ? notifyMeOdoOT : "";

      const inspectionIntervalDays = inspectionIntervalDuration
        ? convertToDays(inspectionIntervalDuration, inspectionIntervalPeriod)
        : 0;

      let emailRecipients = "";
      if (userEmail) {
        userEmail.forEach((item) => {
          emailRecipients += item.email + ":::";
        });
      }
      emailRecipients = emailRecipients.slice(0, emailRecipients.length - 3);

      const lastDateOfInspection = Math.round(lastInspectionDateData / 1000);

      const lastOdoOfInspection = lastInspectionOdoData;

      let diffDays = 0;
      if (inspectionDateOT) {
        const inspectionDate = new Date(inspectionDateOT);
        const diffTime = Math.abs(inspectionDate - new Date());
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      const inspectionScheduleId =
        type === "edit"
          ? rowData.inspection_schedule_id
          : `${userId}_${Date.now()}`;

      let lastDateOfInsp = lastServiceCalc.date
        ? getUnixTime(lastServiceCalc.date)
        : getUnixTime(new Date());
      let lastOdoOfInsp = lastServiceCalc.odo ? lastServiceCalc.odo : 0;
      if (isRecurring) {
        if (inspectionIntervalDuration) {
          const noOfDays = convertToDays(
            inspectionIntervalDuration,
            inspectionIntervalPeriod
          );
          lastDateOfInsp = getUnixTime(
            sub(new Date(dueOn.date), { days: noOfDays })
          );
        }
        if (inspectionIntervalOdo) {
          lastOdoOfInsp = +dueOn.odo - +inspectionIntervalOdo;
        }
      } else {
        lastDateOfInsp = getUnixTime(new Date());
        lastOdoOfInsp = 0;
      }

      let dueOnOdoVal = inspectionIntervalOdo ? Number(dueOn.odo) : 0;
      let dueOnDateVal = inspectionIntervalDays ? getUnixTime(dueOn.date) : 0;
      if (!isRecurring) {
        dueOnOdoVal = inspectionOdoOT ? +inspectionOdoOT : 0;
        dueOnDateVal = inspectionDateOT ? getUnixTime(inspectionDateOT) : 0;
      }

      const jsonData = {
        source: "web",
        // veh_list_array:
        //   type === "edit"
        //     ? vehicle.vehicleId
        //     : JSON.stringify([vehicle.vehicleId]),
        veh_list_array: vehicle.vehicleId,
        querystring: "add_inspection_schedule",
        emails: emailRecipients,
        emails_sent: 0,
        inspection_schedule_id: inspectionScheduleId,
        inspection_form_id: inspectionForm.formId,
        recurring: isRecurring ? 1 : 0,
        // last_inspection_date:
        //   type === "edit"
        //     ? lastDateOfInspection
        //     : JSON.stringify([lastDateOfInspection]),
        // last_inspection_odo:
        //   type === "edit"
        //     ? lastOdoOfInspection
        //     : JSON.stringify([lastOdoOfInspection]),
        last_inspection_date: lastDateOfInsp,
        last_inspection_odo: +lastOdoOfInsp,
        due_days: isRecurring ? +inspectionIntervalDays : +diffDays,
        due_days_unit: isRecurring ? inspectionIntervalUnit : 0,
        due_odo: isRecurring ? +inspectionIntervalOdo : +inspectionOdoOT,
        operation: type === "edit" ? "update" : "create",
        days_threshold: isRecurring ? +notifyMeDays : +notifyMeDaysOT,
        days_threshold_unit: isRecurring ? notifyMeUnit : notifyMeUnitOT,
        odo_threshold: isRecurring ? +notifyMeOdometer : +notifyMeOdometerOT,
        desc: notes ? notes : "",
        action: type === "edit" ? 2 : 1,
        due_on_odo: dueOnOdoVal,
        due_on_date: dueOnDateVal,
        //comments: notes ? notes : "",
        sync_version: "v3",
      };
      // setSaveBtnLoading(true);
      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("insp_schedule") })
          : t("record_added_msg", { recordName: t("insp_schedule") });

      console.log(jsonData);

      const res = await postApi(jsonData, "commonNew");
      if (res.success) {
        reset({
          vehicle: "",
          inspectionForm: "",
          inspectionIntervalOdo: "",
          inspectionIntervalDuration,
          notifyMeOdo: "",
          notifyMeDuration: "",
          inspectionIntervalPeriod: "days",
          notifyMePeriod: "days",
          dueOnOdo: "",
          dueOnDate: "",
          //lastInspectionOdo: "",
          //lastInspectionDate: "",
          userEmail: "",
          notes: "",
          inspectionDateOT: "",
          inspectionOdoOT: "",
          notifyMeOdoOT: "",
          notifyMeDurationOT: "",
          inspectionIntervalPeriodOT: "days",
          notifyMePeriodOT: "days",
        });
        setSaveBtnLoading(false);
        dispatch(addMsg(snackbarMsg));
        //history.goBack();
        history.push("/inspection/inspectionscheduling", { fromCancel: false });
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };

  const handleCancel = () => {
    reset({
      vehicle: "",
      inspectionForm: "",
      inspectionIntervalOdo: "",
      inspectionIntervalDuration,
      notifyMeOdo: "",
      notifyMeDuration: "",
      inspectionIntervalPeriod: "days",
      notifyMePeriod: "days",
      dueOnOdo: "",
      dueOnDate: "",
      //lastInspectionOdo: "",
      //lastInspectionDate: "",
      userEmail: "",
      notes: "",
      inspectionDateOT: "",
      inspectionOdoOT: "",
      notifyMeOdoOT: "",
      notifyMeDurationOT: "",
      inspectionIntervalPeriodOT: "days",
      notifyMePeriodOT: "days",
    });

    //history.goBack();
    history.push("/inspection/inspectionscheduling", { fromCancel: true });
  };

  const inputStyles = {
    //borderColor: colorLightGray,
    color: colorTextNew,
    border: "none",
    borderRadius: 5,
    fontSize: "14px",
    outline: "none",
    width: "100%",
  };

  return (
    <>
      {showSubscribePopup && (
        <SubscribePopup
          showPopup={showSubscribePopup}
          setShowPopup={setShowSubscribePopup}
          message={t("subscribe_msg")}
        />
      )}
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormContainer
        horizontal={false}
        onSubmit={handleSubmit(onSubmit)}
        //style={{ width: "100%" }}
        //className='p-0'
      >
        <FormHeader
          title={
            type === "edit"
              ? t("edit_inspection_schedule")
              : t("add_inspection_schedule")
          }
          handleCancel={handleCancel}
          disableSave={!inspectionForm}
          isSaveLoading={saveBtnLoading}
        />

        <Container>
          <Row>
            <Col
              md={vehicle && inspectionForm ? 8 : 12}
              style={{ paddingRight: vehicle && inspectionForm ? "0" : "12px" }}
            >
              <Card className="m-0">
                <CardBody
                  style={{
                    overflowY: "scroll",
                    height: `${window.innerHeight - 150}px`,
                    padding: "16px 0",
                    overflowX: "hidden",
                    margin: 0,
                    //height: "650px",
                    //scrollbarStyles,
                  }}
                >
                  <Row className="px-3">
                    <Col
                      md={vehicle && inspectionForm ? 12 : 8}
                      className="d-flex justify-content-center"
                    >
                      <ButtonGroup>
                        {toggleButtons.map((item) => (
                          <Toggle
                            active={toggleButton === item.value}
                            disabled={type === "edit"}
                            key={item.id}
                            id={`${item.value}-toggle-button`}
                            type="radio"
                            variant="light"
                            name="radio"
                            value={item.value}
                            checked={toggleButton === item.value}
                            onChange={handleToggleChange}
                          >
                            {item.label}
                          </Toggle>
                        ))}
                      </ButtonGroup>
                    </Col>
                  </Row>
                  {/* Vehicle and Inspection Task */}
                  <Row className="px-3">
                    <Col md={vehicle && inspectionForm ? 6 : 4}>
                      <div>
                        <FormGroupLabel>{t("vehicle")}</FormGroupLabel>
                        <Controller
                          name="vehicle"
                          control={control}
                          //errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={vehicles}
                              //value={vehicles.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              placeholder={t("vehicle_placeholder")}
                              styles={formStyles}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.vehicle && (
                        <Error error={errors.vehicle.message} />
                      )}
                    </Col>
                    <Col md={vehicle && inspectionForm ? 6 : 4}>
                      <div>
                        <FormGroupLabel>
                          {t("inspection_form_label")}
                        </FormGroupLabel>
                        <Controller
                          name="inspectionForm"
                          control={control}
                          errors={errors}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={inspectionFormsOptions}
                              //value={serviceTasks.find((c) => c.value === value)}
                              value={value}
                              onChange={onChange}
                              placeholder={t("inspection_form_placeholder")}
                              styles={formStyles}
                              isDisabled={vehicle ? false : true}
                            />
                          )}
                          rules={{ required: t("required_err") }}
                        />
                      </div>
                      {errors?.inspectionForm && (
                        <Error error={errors.inspectionForm.message} />
                      )}
                    </Col>
                  </Row>
                  {vehicle && inspectionForm && (
                    <>
                      {toggleButton === "recurring" ? (
                        <>
                          <div className="px-3 pt-3 d-flex flex-column gap-1">
                            {lastInspected !== "no-value" && (
                              <>
                                <p
                                  style={{ fontSize: "12px", fontWeight: 300 }}
                                >
                                  {lastServicedText.header}
                                </p>
                                <p
                                  style={{ fontSize: "14px", fontWeight: 500 }}
                                >
                                  {lastServicedText.data}
                                </p>
                              </>
                            )}
                            <Desc>{lastServicedText.desc}</Desc>
                          </div>
                          <hr />
                          <Row className="px-3">
                            <h5>{t("inspection_interval")}</h5>
                            <FormGroupLabel>
                              {t("inspect_every")}
                              {/* <Tooltip text="Set a Reminder once in every...">
                            <Info />
                          </Tooltip> */}
                            </FormGroupLabel>
                            <Col md={4}>
                              <FormItem
                              //tabIndex={0}
                              >
                                <FormField
                                  name="inspectionIntervalOdo"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={handleNegative}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  rules={{
                                    required:
                                      +inspectionIntervalDuration ||
                                      +inspectionIntervalOdo
                                        ? false
                                        : t("odo_or_duration_err"),
                                    min: {
                                      value: 1,
                                      message: t("greater_than_zero_err"),
                                    },
                                  }}
                                  defaultValue=""
                                  isAboveError={isAboveError}
                                  placeholder={t("odometer")}
                                  style={inputStyles}
                                  //onKeyUp={handleInspectionIntervalOdoChange}
                                />
                                <UnitSpan>
                                  {getDistanceUnit(
                                    vehicle?.primary_meter === "Hours"
                                      ? "Hours"
                                      : distance
                                  )}
                                </UnitSpan>
                                {/* {errors?.[name] && <Error error={errors[name].message} top={true} />} */}
                              </FormItem>
                              {/* {errors?.inspectionIntervalOdo && (
                            <Error
                              error={errors.inspectionIntervalOdo.message}
                            />
                          )} */}
                            </Col>
                            <Col
                              md={1}
                              className="d-flex justify-content-center align-items-center p-0"
                            >
                              <span>{t("or")}</span>
                            </Col>
                            <Col>
                              <FormItem>
                                <FormField
                                  name="inspectionIntervalDuration"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={handleNegative}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  errors={errors}
                                  rules={{
                                    required:
                                      +inspectionIntervalOdo ||
                                      +inspectionIntervalDuration
                                        ? false
                                        : t("odo_or_duration_err"),
                                    min: {
                                      value: 1,
                                      message: t("greater_than_zero_err"),
                                    },
                                  }}
                                  defaultValue=""
                                  isAboveError={isAboveError}
                                  placeholder={t("duration")}
                                  style={inputStyles}
                                  // onKeyUp={
                                  //   handleInspectionIntervalDurationChange
                                  // }
                                />
                                <TimeSelect
                                  {...register("inspectionIntervalPeriod")}
                                  header={
                                    periodOptions[inspectionIntervalPeriod]
                                      ?.length * 15
                                  }
                                  defaultValue="days"
                                >
                                  <option value="days">{t("days")}</option>
                                  <option value="weeks">{t("weeks")}</option>
                                  <option value="months">{t("months")}</option>
                                  <option value="years">{t("years")}</option>
                                </TimeSelect>
                              </FormItem>
                              {/* {errors?.inspectionIntervalDuration && (
                            <Error
                              error={errors.inspectionIntervalDuration.message}
                            />
                          )} */}
                            </Col>
                            <Col
                              md={3}
                              className="d-flex justify-content-start align-items-center p-0"
                            >
                              <span>{t("whichever_comes_first")}</span>
                            </Col>
                          </Row>
                          {(errors.inspectionIntervalOdo ||
                            errors.inspectionIntervalDuration) && (
                            <Row className="px-3">
                              <Col md={4}>
                                {errors?.inspectionIntervalOdo && (
                                  <Error
                                    error={errors.inspectionIntervalOdo.message}
                                  />
                                )}
                              </Col>
                              <Col md={1}></Col>
                              <Col md={4}>
                                {errors?.inspectionIntervalDuration && (
                                  <Error
                                    error={
                                      errors.inspectionIntervalDuration.message
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </>
                      ) : (
                        <>
                          <hr />
                          <Row className="px-3">
                            <FormGroupLabel className="mt-0">
                              {t("inspect_on")}
                              {/* <Tooltip text="Inspect On">
                                <Info />
                              </Tooltip> */}
                            </FormGroupLabel>
                            <Col>
                              <FormItem>
                                <FormField
                                  name="inspectionOdoOT"
                                  control={control}
                                  component="input"
                                  type="number"
                                  onKeyDown={handleNegative}
                                  onWheel={
                                    handleNumberInputOnWheelPreventChange
                                  }
                                  errors={errors}
                                  rules={{
                                    required:
                                      inspectionOdoOT || inspectionDateOT
                                        ? false
                                        : t("odo_or_duration_err"),
                                    // min: {
                                    //   value: +lastServiceCalc.odo,
                                    //   message: `The odometer value needs to be higher than ${lastServiceCalc.odo}`, //t("greater_than_zero_err"),
                                    // },
                                  }}
                                  defaultValue=""
                                  isAboveError={isAboveError}
                                  placeholder={t("odometer")}
                                  style={inputStyles}
                                  //onKeyUp={handleDueOdoChange}
                                />
                                <UnitSpan>
                                  {getDistanceUnit(
                                    vehicle?.primary_meter === "Hours"
                                      ? "Hours"
                                      : distance
                                  )}
                                </UnitSpan>
                              </FormItem>
                              {/* {errors?.inspectionOdoOT && (
                                <Error error={errors.inspectionOdoOT.message} />
                              )} */}
                            </Col>
                            <Col
                              md={1}
                              className="d-flex justify-content-center align-items-center p-0"
                            >
                              <span>{t("or")}</span>
                            </Col>
                            <Col>
                              <Controller
                                name="inspectionDateOT"
                                defaultValue=""
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <DatePicker
                                    minDate={new Date()}
                                    selected={value}
                                    onChange={onChange}
                                    customInput={
                                      <CustomInput clearable={true} />
                                    }
                                    placeholderText={t("date_tv")}
                                    locale={getDateFnsLocale()}
                                    dateFormat={"P"}
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                  />
                                )}
                                rules={{
                                  required:
                                    inspectionOdoOT || inspectionDateOT
                                      ? false
                                      : t("insp_odo_or_date_reqd_err_msg"),
                                  min: {
                                    value: new Date(),
                                    message: t(
                                      "insp_date_less_than_current_date_err_msg"
                                    ),
                                  },
                                }}
                              />
                              {/* {errors?.inspectionDateOT && (
                                <Error
                                  error={errors.inspectionDateOT.message}
                                />
                              )} */}
                            </Col>
                            <Col
                              md={3}
                              className="d-flex justify-content-start align-items-center p-0"
                            >
                              <span>{t("whichever_comes_first")}</span>
                            </Col>
                          </Row>
                          {(errors.inspectionOdoOT ||
                            errors.inspectionDateOT) && (
                            <Row className="px-3">
                              <Col md={4}>
                                {errors?.inspectionOdoOT && (
                                  <Error
                                    error={errors.inspectionOdoOT.message}
                                  />
                                )}
                              </Col>
                              {/* <Col md={1}></Col>
                              <Col md={4}>
                                {errors?.inspectionDateOT && (
                                  <Error
                                    error={errors.inspectionDateOT.message}
                                  />
                                )}
                              </Col> */}
                            </Row>
                          )}
                        </>
                      )}
                      <hr />
                      <Row className="px-3">
                        <div className="d-flex align-items-center">
                          <h5>{t("notification_settings")}</h5>
                          {tag === "free-user" && (
                            <div
                              //className="mb-1"
                              style={{ marginBottom: "0.1rem" }}
                            >
                              <SubscribeTooltipIcon
                                message={t("subscribe_msg")}
                                handleClick={() => setShowSubscribePopup(true)}
                              />
                            </div>
                          )}
                        </div>
                        <Desc
                          style={{ paddingTop: "4px" }}
                          className="d-flex gap-1"
                        >
                          <span>{t("notifications_email_msg")}</span>
                          {tag === "free-user" && (
                            <span>{`(${t("paid_feature_str_sm")})`}</span>
                          )}
                        </Desc>
                        <FormGroupLabel>
                          {t("notify_me")}
                          {/* <span>
                            <Tooltip text="Remind me every..." placement="top">
                              <Info />
                            </Tooltip>
                          </span> */}
                        </FormGroupLabel>
                        {/* Notify Me Fields based on recurring/one time */}
                        <Col>
                          {toggleButton === "recurring" ? (
                            <FormItem
                              disabled={
                                !inspectionIntervalOdo || tag === "free-user"
                              }
                            >
                              <FormField
                                name="notifyMeOdo"
                                control={control}
                                component="input"
                                type="number"
                                onKeyDown={handleNegative}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                disabled={
                                  !inspectionIntervalOdo || tag === "free-user"
                                }
                                //errors={errors}
                                rules={{
                                  min: {
                                    value: 1,
                                    message: t("greater_than_zero_err"),
                                  },
                                  max: {
                                    value: +inspectionIntervalOdo - 1,
                                    message: t("greater_than_interval_err", {
                                      type: t("inspection_sm"),
                                    }),
                                  },
                                }}
                                defaultValue=""
                                //isAboveError={isAboveError}
                                placeholder={t("odometer")}
                                style={inputStyles}
                              />
                              <UnitSpan>
                                {getDistanceUnit(
                                  vehicle?.primary_meter === "Hours"
                                    ? "Hours"
                                    : distance
                                )}
                              </UnitSpan>
                            </FormItem>
                          ) : (
                            <FormItem
                              disabled={!inspectionOdoOT || tag === "free-user"}
                            >
                              <FormField
                                name="notifyMeOdoOT"
                                control={control}
                                component="input"
                                type="number"
                                onKeyDown={handleNegative}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                disabled={
                                  !inspectionOdoOT || tag === "free-user"
                                }
                                //errors={errors}
                                rules={{
                                  min: {
                                    value: 1,
                                    message: t("greater_than_zero_err"),
                                  },
                                  max: {
                                    value: +inspectionOdoOT - 1,
                                    message: t("greater_than_interval_err", {
                                      type: t("inspection_sm"),
                                    }),
                                  },
                                }}
                                defaultValue=""
                                //isAboveError={isAboveError}
                                placeholder={t("odometer")}
                                style={inputStyles}
                              />
                              <UnitSpan>
                                {getDistanceUnit(
                                  vehicle?.primary_meter === "Hours"
                                    ? "Hours"
                                    : distance
                                )}
                              </UnitSpan>
                            </FormItem>
                          )}
                        </Col>
                        <Col
                          md={1}
                          className="d-flex justify-content-center align-items-center p-0"
                        >
                          <span>{t("or")}</span>
                        </Col>
                        <Col>
                          {toggleButton === "recurring" ? (
                            <FormItem
                              disabled={
                                !inspectionIntervalDuration ||
                                tag === "free-user"
                              }
                            >
                              <FormField
                                name="notifyMeDuration"
                                control={control}
                                component="input"
                                type="number"
                                onKeyDown={handleNegative}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                errors={errors}
                                disabled={
                                  !inspectionIntervalDuration ||
                                  tag === "free-user"
                                }
                                rules={{
                                  min: {
                                    value: 1,
                                    message: t("greater_than_zero_err"),
                                  },
                                  validate: {
                                    maxValue: (v) =>
                                      validateMaxNotifyMe(v) ||
                                      t("greater_than_interval_err", {
                                        type: t("inspection_sm"),
                                      }),
                                  },
                                }}
                                defaultValue=""
                                isAboveError={isAboveError}
                                placeholder={t("duration")}
                                style={inputStyles}
                              />
                              {/* <Controller
                                name="notifyMePeriod"
                                defaultValue="days"
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <TimeSelect
                                    value={value}
                                    defaultValue="days"
                                    onChange={onChange}
                                    disabled={!inspectionIntervalDuration}
                                    header={periodOptions[notifyMePeriod]}
                                    onClick={() => {
                                      setError("notifyMeDuration", {
                                        type: "maxValue",
                                      });
                                    }}
                                  >
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                    <option value="years">Years</option>
                                  </TimeSelect>
                                )}
                              /> */}
                              <TimeSelect
                                {...register("notifyMePeriod")}
                                defaultValue="days"
                                disabled={
                                  !inspectionIntervalDuration ||
                                  tag === "free-user"
                                }
                                header={
                                  periodOptions[notifyMePeriod]?.length * 15
                                }
                                onClick={() => {
                                  setError("notifyMeDuration", {
                                    type: "maxValue",
                                  });
                                }}
                              >
                                <option value="days">{t("days")}</option>
                                <option value="weeks">{t("weeks")}</option>
                                <option value="months">{t("months")}</option>
                                <option value="years">{t("years")}</option>
                              </TimeSelect>
                            </FormItem>
                          ) : (
                            <FormItem
                              disabled={
                                !inspectionDateOT || tag === "free-user"
                              }
                            >
                              <FormField
                                name="notifyMeDurationOT"
                                control={control}
                                component="input"
                                type="number"
                                onKeyDown={handleNegative}
                                onWheel={handleNumberInputOnWheelPreventChange}
                                errors={errors}
                                disabled={
                                  !inspectionDateOT || tag === "free-user"
                                }
                                rules={{
                                  min: {
                                    value: 1,
                                    message: t("greater_than_zero_err"),
                                  },
                                  validate: {
                                    maxValue: (v) =>
                                      validateMaxNotifyMeOT(v) ||
                                      t("greater_than_interval_err", {
                                        type: t("inspection_sm"),
                                      }),
                                  },
                                }}
                                defaultValue=""
                                isAboveError={isAboveError}
                                placeholder={t("duration")}
                                style={inputStyles}
                              />
                              <TimeSelect
                                {...register("notifyMePeriodOT")}
                                defaultValue="days"
                                disabled={
                                  !inspectionDateOT || tag === "free-user"
                                }
                                onClick={() => {
                                  setError("notifyMeDurationOT", {
                                    type: "maxValue",
                                  });
                                }}
                              >
                                <option value="days">{t("days")}</option>
                                <option value="weeks">{t("weeks")}</option>
                                <option value="months">{t("months")}</option>
                                <option value="years">{t("years")}</option>
                              </TimeSelect>
                            </FormItem>
                          )}
                        </Col>
                        <Col
                          md={3}
                          className="d-flex justify-content-start align-items-center p-0"
                        >
                          <span>{t("before_inspection_date")}</span>
                        </Col>
                      </Row>

                      {toggleButton === "recurring" &&
                        (errors.notifyMeOdo || errors.notifyMeDuration) && (
                          <Row className="px-3">
                            <Col md={4}>
                              {errors?.notifyMeOdo && (
                                <Error error={errors.notifyMeOdo.message} />
                              )}
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}>
                              {errors?.notifyMeDuration && (
                                <Error
                                  error={errors.notifyMeDuration.message}
                                />
                              )}
                            </Col>
                          </Row>
                        )}

                      {toggleButton !== "recurring" &&
                        (errors.notifyMeOdoOT || errors.notifyMeDurationOT) && (
                          <Row className="px-3">
                            <Col md={4}>
                              {errors?.notifyMeOdoOT && (
                                <Error error={errors.notifyMeOdoOT.message} />
                              )}
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}>
                              {errors?.notifyMeDurationOT && (
                                <Error
                                  error={errors.notifyMeDurationOT.message}
                                />
                              )}
                            </Col>
                          </Row>
                        )}

                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("send_notification_to")}
                          {/* <Tooltip text={t("premium_feature")}>
                            <DollarIcon className="fi fi-ss-usd-circle"></DollarIcon>
                          </Tooltip> */}
                        </FormGroupLabel>
                        <Col>
                          <Controller
                            name="userEmail"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                options={usersList}
                                //value={usersList.find((c) => c.value === value)}
                                value={value}
                                onChange={onChange}
                                placeholder={t("noti_recipients")}
                                styles={formStyles}
                                isMulti
                                closeMenuOnSelect={false}
                                //hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                isDisabled={tag === "free-user"}
                              />
                            )}
                            // rules={{ required: true }}
                          />
                        </Col>
                      </Row>
                      <Row className="px-3">
                        <FormGroupLabel>
                          {t("notification_email_preview")}
                        </FormGroupLabel>
                        {(+inspectionIntervalOdo > 0 ||
                          +inspectionIntervalDuration > 0) &&
                          tag !== "free-user" && (
                            <EmailPreviewText>
                              {/* {t("email_reminder_msg")}{" "}
                              <strong>{vehicle.label}</strong>{" "}
                              {t("is_due_for_sm")}{" "}
                              <strong>{inspectionForm.label}</strong> */}
                              <Trans
                                i18nKey="email_reminder_msg"
                                t={t}
                                values={{
                                  vehicleName: vehicle.label,
                                  taskName: inspectionForm.label,
                                }}
                              />
                              {notifyMeOdo &&
                                notifyMePreview.odo.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>
                                      {`${notifyMeOdo} ${getDistanceUnit(
                                        vehicle?.primary_meter === "Hours"
                                          ? "Hours"
                                          : distance
                                      )} `}
                                    </strong>
                                  </>
                                )}
                              {notifyMeOdo &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                inspectionIntervalDuration &&
                                ` ${t("or")} `}
                              {notifyMeDuration &&
                                notifyMePreview.date.length > 0 && (
                                  <>
                                    <span> {t("in")} </span>
                                    <strong>
                                      {convertToDays(
                                        notifyMeDuration,
                                        notifyMePeriod
                                      )}{" "}
                                      {t("days_sm", {
                                        count: convertToDays(
                                          notifyMeDuration,
                                          notifyMePeriod
                                        ),
                                      })}
                                    </strong>
                                  </>
                                )}
                              {notifyMeOdo &&
                                notifyMeDuration &&
                                notifyMePreview.odo.length > 0 &&
                                notifyMePreview.date.length > 0 &&
                                `, ${t("whichever_comes_first")}`}
                              .{" "}
                              {lastInspected === "last-inspected" && (
                                <>
                                  {/* <span>{t("last_inspection_done_on")}</span>
                                  <strong>
                                    {" "}
                                    {convertToDisplayDate(
                                      lastInspectionDateData
                                    )}
                                  </strong>
                                  {`. `} */}
                                  <Trans
                                    i18nKey="last_inspection_done_on"
                                    t={t}
                                    values={{
                                      lastInspectedDate: convertToDisplayDate(
                                        lastInspectionDateData
                                      ),
                                    }}
                                  />{" "}
                                </>
                              )}
                              {t("email_log_msg_inspection")}
                            </EmailPreviewText>
                          )}
                        <Col>
                          <Controller
                            name="notes"
                            control={control}
                            errors={errors}
                            render={({ field: { onChange, value, ref } }) => (
                              <Textarea
                                rows={2}
                                id="notes"
                                name="notes"
                                onChange={onChange}
                                value={value}
                                placeholder={t("email_preview_placeholder")}
                                disabled={tag === "free-user"}
                              ></Textarea>
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>

            {vehicle && inspectionForm && (
              <Col md={4}>
                <Card>
                  <CardBody>
                    {toggleButton === "recurring" ? (
                      <div>
                        <FormHeading>{t("schedule_preview")}</FormHeading>
                        <Desc>{schedulePreviewText}</Desc>
                        {((inspectionIntervalOdo &&
                          +inspectionIntervalOdo > 0 &&
                          dueOn?.odo) ||
                          (inspectionIntervalDuration && dueOn?.date)) && (
                          <>
                            <ServicesPreviewHeading>
                              {t("next_5_inspections_due_on")}
                              {/* <span style={{ fontSize: "12px" }}>
                             {" "}
                             ({t("whichever_comes_first")})
                           </span> */}
                            </ServicesPreviewHeading>
                            <hr style={{ margin: "5px 0" }} />

                            {[0, 1, 2, 3, 4].map((num) => {
                              return (
                                <React.Fragment key={num}>
                                  <DueOnWrap>
                                    {+inspectionIntervalOdo > 0 &&
                                      dueOn.odo && (
                                        <span>{`${
                                          dueOnPreview.odo[num]
                                        } ${getDistanceUnit(
                                          vehicle?.primary_meter === "Hours"
                                            ? "Hours"
                                            : distance
                                        )}`}</span>
                                      )}
                                    {+inspectionIntervalDuration > 0 &&
                                      +inspectionIntervalOdo > 0 && (
                                        <span> {t("or")} </span>
                                      )}
                                    <span>
                                      {+inspectionIntervalDuration > 0 &&
                                      dueOn.date
                                        ? convertToTextDate(
                                            dueOnPreview.date[num]
                                          )
                                        : ""}
                                    </span>
                                    {num === 0 && (
                                      <EditIcon
                                        className="fi fi-rr-pencil"
                                        onClick={handleEditIconClick}
                                      ></EditIcon>
                                    )}
                                  </DueOnWrap>
                                  {(notifyMePreview.date.length > 0 ||
                                    notifyMePreview.odo.length > 0) && (
                                    <NotifyMeWrap>
                                      <i className="fi fi-rr-clock"></i>
                                      <span>{t("notify_by")} </span>

                                      {!!notifyMeOdo &&
                                        +notifyMeOdo > 0 &&
                                        +notifyMeOdo <
                                          +inspectionIntervalOdo && (
                                          <span>
                                            {`${
                                              notifyMePreview.odo[num]
                                            } ${getDistanceUnit(
                                              vehicle?.primary_meter === "Hours"
                                                ? "Hours"
                                                : distance
                                            )}`}
                                          </span>
                                        )}

                                      {+notifyMeDuration > 0 &&
                                        +notifyMeOdo > 0 &&
                                        +notifyMeOdo < +inspectionIntervalOdo &&
                                        convertToDays(
                                          notifyMeDuration,
                                          notifyMePeriod
                                        ) <
                                          convertToDays(
                                            inspectionIntervalDuration,
                                            inspectionIntervalPeriod
                                          ) && <span> {t("or")} </span>}
                                      {notifyMeDuration &&
                                        notifyMePreview.date.length > 0 && (
                                          <span>
                                            {convertToTextDate(
                                              notifyMePreview.date[num]
                                            )}
                                          </span>
                                        )}
                                    </NotifyMeWrap>
                                  )}
                                  <hr style={{ margin: "5px 0" }} />
                                </React.Fragment>
                              );
                            })}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <FormHeading>{t("schedule_preview")}</FormHeading>
                        {!inspectionDateOT && !inspectionOdoOT ? (
                          <Desc>
                            {t("schedule_preview_empty_insp_onetime")}
                          </Desc>
                        ) : (
                          <>
                            <ServicesPreviewHeading>
                              {t("next_inspection_due_on")}
                            </ServicesPreviewHeading>
                            <hr style={{ margin: "0 0 10px 0" }} />
                          </>
                        )}
                        <DueOnWrap>
                          {+inspectionOdoOT > 0 && (
                            <span>{`${inspectionOdoOT} ${getDistanceUnit(
                              vehicle?.primary_meter === "Hours"
                                ? "Hours"
                                : distance
                            )}`}</span>
                          )}
                          {+inspectionOdoOT > 0 && inspectionDateOT && (
                            <span> {` ${t("or")} `}</span>
                          )}
                          {inspectionDateOT && (
                            <span>{convertToTextDate(inspectionDateOT)}</span>
                          )}
                        </DueOnWrap>
                        <NotifyMeWrap>
                          {(notifyMePreviewOT.date ||
                            notifyMePreviewOT.odo) && (
                            <>
                              <i className="fi fi-rr-clock"></i>
                              <span>{t("notify_by")} </span>
                            </>
                          )}
                          {notifyMePreviewOT.odo && (
                            <span>{`${notifyMePreviewOT.odo} ${getDistanceUnit(
                              vehicle?.primary_meter === "Hours"
                                ? "Hours"
                                : distance
                            )}`}</span>
                          )}
                          {notifyMePreviewOT.odo && notifyMePreviewOT.date && (
                            <span> {t("or")} </span>
                          )}
                          {notifyMePreviewOT.date && (
                            <span>
                              {convertToTextDate(notifyMePreviewOT.date)}
                            </span>
                          )}
                        </NotifyMeWrap>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>

          {/* Due On Modal Pop-up */}
          <Modal
            show={showDueOn}
            onHide={() => setShowDueOn(!showDueOn)}
            centered
          >
            <Modal.Header>
              <p>{t("next_inspection_due_on")}</p>
              <CloseIcon
                className="fi fi-rr-cross-small"
                onClick={() => setShowDueOn(!showDueOn)}
              ></CloseIcon>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {dueOn.odo && inspectionIntervalOdo && (
                  <Col md={5}>
                    <FormItem>
                      <FormField
                        name="dueOnOdo"
                        control={control}
                        component="input"
                        type="number"
                        onKeyDown={handleNegative}
                        onWheel={handleNumberInputOnWheelPreventChange}
                        errors={errors}
                        //rules={{ required: "This is required field" }}
                        rules={{
                          required: t("required_err"),
                          min: {
                            value: +dueOn.odo,
                            message: t("negative_value_err_msg"),
                          },
                        }}
                        defaultValue={dueOn.odo}
                        isAboveError={isAboveError}
                        placeholder={t("odometer")}
                        style={{ ...inputStyles, padding: "5px 10px" }}
                      />
                      <UnitSpan>
                        {getDistanceUnit(
                          vehicle?.primary_meter === "Hours"
                            ? "Hours"
                            : distance
                        )}
                      </UnitSpan>
                    </FormItem>
                    {/* {errors?.dueOnOdo && (
                      <Error error={errors.dueOnOdo.message} />
                    )} */}
                  </Col>
                )}
                {dueOn.odo &&
                  inspectionIntervalOdo &&
                  inspectionIntervalDuration &&
                  dueOn.date && (
                    <Col
                      md={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span>{t("or")}</span>
                    </Col>
                  )}
                {dueOn.date && inspectionIntervalDuration && (
                  <Col md={5}>
                    {/* <DatePicker
                      selected={dueOnDate}
                      onChange={(e) => setDueOnDate(e)}
                      customInput={<CustomInput />}
                      minDate={new Date(dueOn.date)}
                    /> */}
                    <Controller
                      name="dueOnDate"
                      control={control}
                      defaultValue={dueOn.date}
                      render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                          selected={value}
                          onChange={onChange}
                          customInput={<CustomInput />}
                          placeholderText={t("due_on_date")}
                          locale={getDateFnsLocale()}
                          dateFormat={"P"}
                          showMonthDropdown
                          showYearDropdown
                          //minDate={new Date(dueOn.date)}
                        />
                      )}
                      //rules={{ required: true }}
                    />
                  </Col>
                )}
              </Row>

              {errors?.dueOnOdo && errors?.dueOnOdo?.types && (
                <Error
                  error={
                    dueOnOdo
                      ? errors.dueOnOdo.types.min
                      : errors.dueOnOdo.types.required
                  }
                />
              )}
              {/* </Row> */}
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <AddFormButton
                type="button"
                id="save-button"
                onClick={handleSaveDueOn}
              >
                {t("save")}
              </AddFormButton>
            </Modal.Footer>
          </Modal>
        </Container>
      </FormContainer>
    </>
  );
};

InspectionScheduleForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

InspectionScheduleForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default InspectionScheduleForm;

export const Textarea = styled.textarea``;

export const Toggle = styled(ToggleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border-color: lightgray;
  color: ${colorTextNew};
  background: white;

  &:hover {
    ${(p) =>
      !p.active &&
      `
      background: ${colorLightGray};
      border-color: ${colorLightGray};
      color: black;
      `};
  }

  // ${(p) => p.active && `background: ${buttonColorNew} !important`};

  // .btn:active {
  //   background-color: blue !important;
  // }
`;
