import { createGlobalStyle } from "styled-components";
import {
  colorBlue,
  colorBlueHover,
  colorText,
  colorAdditional,
  colorAccent,
  colorWhite,
  colorRed,
  colorBackgroundBody,
  scrollbarStyles,
} from "@/utils/palette";
import { left, borderLeft, paddingLeft } from "@/utils/directions";
import {
  colorBackground,
  colorGray,
  colorLightGray,
  colorTextNew,
  sidenavColor,
} from "../../utils/palette";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  //For Chrome
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    //-webkit-appearance: none;
    margin: 0;
  }

  //For Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  main {
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0 !important;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    //line-height: 1.6;
    font-weight:300;
    //height: 100vh;
    //overflow: hidden;
    background: ${colorBackgroundBody};
  }
   

  // .row > * {
  //   padding: 0 15px;
  // }

  * {
    box-sizing: inherit;
  }

  ul, ol {
    // doesn't do RTL, it break a sidebar
    padding-left: 15px;
    margin-bottom: 0;
    //list-style-image: url(${
      process.env.PUBLIC_URL
    }/img/circle.svg); //Removes the dot in sidenav on collapse
  }

  a {
    color: ${colorBlue};
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: ${colorBlueHover};
    }
  }

  button:focus, button:active {
    outline: none;
  }

  // img {
  //   width: 100%;
  // }

  label {
    margin-bottom: 8px;
  }

  //TYPOGRAPHY
  p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
    color: ${colorTextNew};
  }

  h1 {
    font-size: 36px;
    line-height: 48px;

    &.subhead {
      font-size: 30px;
      color: ${colorAdditional};
      line-height: 36px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 36px;

    &.subhead {
      font-size: 24px;
      color: ${colorAdditional};
      line-height: 32px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    //padding: 10px;

    &.subhead {
      font-size: 18px;
      color: ${colorAdditional};
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;

    &.subhead {
      font-size: 12px;
      color: ${colorAdditional};
      line-height: 16px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 18px;

    &.subhead {
      font-size: 10px;
      color: ${colorAdditional};
      line-height: 12px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 16px;

    &.subhead {
      font-size: 8px;
      color: ${colorAdditional};
      line-height: 10px;
    }
  }
  
  hr {
    opacity: 0.1;
  }
  
  .table-responsive {
    ${scrollbarStyles};
  }

  blockquote {
    font-size: 13px;
    font-style: italic;
    ${borderLeft}: 3px solid ${colorAccent};
    margin-bottom: 0;
    text-align: ${left};
    ${paddingLeft}: 10px;
  }

  .highlight {
    background-color: ${colorAccent};
    color: ${colorWhite};
  }

  .typography-message{
    text-align: ${left};
  }

  .red-text {
    color: ${colorRed};
  }

  .page-title {
    text-align: ${left};
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;

    &:last-child {
      margin-bottom: 30px;
    }

    &.page-title--not-last {
      margin-top: 20px;
    }
  }

  .page-subhead {
    text-align: ${left};
    margin-bottom: 20px;

    &.subhead {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  ::selection {
    color: ${colorWhite};
    background: ${colorAccent};
  }

  .first-letter-uppercase:first-letter {
    text-transform: uppercase;
  }

  .container {

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 1630px;
    }
  }


  .rc-slider-tooltip {
    padding-bottom: 2px;

    .rc-slider-tooltip-inner {
      margin-bottom: 5px;
      background-color: ${colorBackgroundBody};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      color: ${colorText};
      font-size: 12px;
      line-height: 12px;
      padding: 4px 4px;
      height: 20px;
      min-width: 28px;
      border-radius: 0;
    }

    &.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      border-top-color: ${colorBackgroundBody};
    }

    &.rc-slider-tooltip-hidden {
      display: block;
    }
  }


  // .row > * {
  //     padding: 16px;
  //  }
  

  //Styling for signature canvas
  .signature {
    border: 2px solid ${colorLightGray};
    border-radius: 5px;
    width: 240px;
    height: 120px;
    //cursor: url('../../../src/shared/img/pencil.svg'), auto;
    cursor: url(http://icons.iconarchive.com/icons/designcontest/vintage/16/Patent-Pen-icon.png) 0 30, progress;
    font-family: Homemade Apple;
  }



//Carousel icons
  .carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 30px;
  width: 30px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 2px solid black;
  background-image: none;
  background: white;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 16px;
  color: gray;
}

div.carousel-indicators button, div.carousel-indicators button.active {
  background-color: black;
}


.p-treeselect {
  border: 1px solid ${colorLightGray};
  height: 32px;
  max-width: 150px;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;

  &:hover, &:active, &:focus {
    border-color: ${colorGray};
  }
  

  box-shadow: none;
}
.form-treeselect {
  height: 36px;
  max-width: 200px;
}

.form-treeselect {
  .p-treeselect-header {
    display: none;
  }
}

.p-treeselect-panel {
  z-index: 9999;
}



.treeselect-with-value {
  border: 2px solid ${colorBlueHover};

  &:hover {
    border-color: ${colorBlue};
  }
}

.p-treeselect-close {
  display: none;
}

.p-treeselect-trigger-icon {
  margin-top: 1px;
  width: 14px;
  height: 14px;
}

.p-treeselect-filter {
  box-shadow: none;
}

// .p-treetable-tbody {
//   td {
//     display: flex;
//   }
// }

.custom-column {
  display: flex;
}


.modal-backdrop {
  z-index: 999;
}

.p-column-filter {
  box-shadow: none;
}

.p-treetable-table {
  //height: 400px;
  overflow: auto;
  ${scrollbarStyles};
}

.p-treetable-wrapper {
  height: ${window.innerHeight - 210}px;
  overflow: auto;
  ${scrollbarStyles};
}

.p-treetable-thead {
  position: sticky;
  top: 0;
  z-index: 100;
}

.p-steps ul {
  padding-left: 0;
}

.p-steps-item:before {
  border-width: 2px;
  border-color: ${colorBlue};
}

.p-steps-item.p-highlight p-steps-item.p-steps-current .p-steps-item:before {
  border-width: 2px;
  border-color: ${colorBlue};
}

.p-steps-number {
  backgorund: white;
  color: white;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
}

.p-steps-item.p-highlight .p-steps-number {
  background: ${sidenavColor};
  color: ${sidenavColor};
}

.p-disabled {
  opacity: 1;
}

.p-carousel {
  width: 700px;
  height: 470px;
}

.p-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-treeselect-filter  {
  height: 32px;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.react-datepicker__month-read-view--down-arrow {
  top: 0.2rem;
}

.react-datepicker__year-read-view--down-arrow {
  top: 0.2rem;
}


`;

export default GlobalStyles;
