import React from "react";
import format from "date-fns/format";
import getUnixTime from "date-fns/getUnixTime";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { postApi } from "../../api/getUser";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import {
  ALBUM_BUCKET_NAME,
  BUCKET_REGION,
  IDENTITY_POOL_ID,
  NATEXT,
  s3Url,
} from "../constants";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { toDataUrl } from "../../components/UserManagement/NewDocumentForm";
import enUS from "date-fns/locale/en-US";
import enGB from "date-fns/locale/en-GB";
import enIN from "date-fns/locale/en-IN";
import enAU from "date-fns/locale/en-AU";
import enCA from "date-fns/locale/en-CA";
import { useTranslation } from "react-i18next";

let allLocales;
import("date-fns/locale").then((locales) => {
  allLocales = locales;
});

const ligthTheme = {
  backgroundColor: "white",
  color: "#646777",
};

const darkTheme = {
  backgroundColor: "#2e2c34",
  color: "#dddddd",
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const emailPatternExisitng =
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;

// export const emailPattern =
//   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const urlPattern =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

export const firstLetterToUpperCase = (str) => {
  if (!str) return "";

  const firstLetterUpperCase = str[0].toUpperCase();
  return `${firstLetterUpperCase}${str.slice(1)}`;
};

export const getAdvancedFilterColumnName = (screen) => {
  if (screen.includes("fillups")) {
    return "fillups";
  }
  if (screen.includes("services")) {
    return "service";
  }
  if (screen.includes("expenses")) {
    return "expense";
  }
  if (screen.includes("trips")) {
    return "trips";
  } else if (
    screen.includes("maintenancereminders")
    //|| screen.includes("renewals")
  ) {
    return "maintenancereminders";
  } else if (screen.includes("renewals")) {
    return "renewals";
  } else if (screen.includes("inspectionscheduling")) {
    return "inspectionScheduling";
  } else if (screen.includes("inspectionsubmissions")) {
    return "inspectionsubmissions";
  } else if (screen.includes("workorders")) {
    return "workorders";
  } else if (screen.includes("parts")) {
    return "parts";
  } else {
    return screen;
  }
};

export const getScreenName = (page) => {
  let screenName = "";
  if (page.includes("fillups")) {
    screenName = "fillups";
  }
  if (page.includes("services")) {
    screenName = "service";
  }
  if (page.includes("expenses")) {
    screenName = "expense";
  }
  if (page.includes("trips")) {
    screenName = "trips";
  }
  if (page.includes("inspectionsubmissions")) {
    screenName = "inspectionsubmissions";
  }
  if (page.includes("inspectionscheduling")) {
    screenName = "inspectionschedules";
  }
  if (page.includes("inspectionforms")) {
    return "inspectionforms";
  }
  if (page.includes("inspectiontasks")) {
    return "inspectiontasks";
  }
  if (page.includes("maintenancereminders")) {
    return "maintenancereminders";
  }
  if (page.includes("renewals")) {
    return "renewals";
  }
  if (page.includes("issues")) {
    return "issues";
  }
  if (page.includes("workorders")) {
    return "work_order";
  }
  if (page.includes("servicetasks")) {
    return "servicetasks";
  }
  if (page.includes("expensetasks")) {
    return "expensetasks";
  }
  if (page.includes("dailymileage")) {
    return "dailymileage";
  }
  if (page.includes("vehicles")) {
    return "vehicles";
  }
  if (page.includes("users")) {
    return "users";
  }
  if (page.includes("groups")) {
    return "groups";
  }
  if (page.includes("dailytimesheet")) {
    return "dailytimesheet";
  }
  if (page.includes("faultcodes")) {
    return "faultcodes";
  }
  if (page.includes("parts")) {
    return "parts";
  }

  return screenName;
};

export const getScreenNameForImport = (page) => {
  let screenName = "";
  if (page.includes("fillups")) {
    screenName = "fillups";
  } else if (page.includes("services")) {
    screenName = "service";
  }
  if (page.includes("expenses")) {
    screenName = "expense";
  }
  if (page.includes("trips")) {
    screenName = "trips";
  }
  if (page.includes("inspectionsubmissions")) {
    screenName = "inspectionsubmissions";
  }
  if (page.includes("inspectionscheduling")) {
    screenName = "inspectionschedules";
  }
  if (page.includes("inspectionforms")) {
    return "inspectionforms";
  }
  if (page.includes("inspectiontasks")) {
    return "inspectiontasks";
  }
  if (page.includes("maintenancereminders")) {
    return "maintenancereminders";
  }
  if (page.includes("renewals")) {
    return "renewals";
  }
  if (page.includes("issues")) {
    return "issues";
  }
  if (page.includes("workorders")) {
    return "work_orders";
  }
  if (page.includes("servicetasks")) {
    return "servicetasks";
  }
  if (page.includes("expensetasks")) {
    return "expensetasks";
  }
  if (page.includes("dailymileage")) {
    return "dailymileage";
  }
  if (page.includes("vehicles")) {
    return "vehicles";
  }
  if (page.includes("users")) {
    return "users";
  }
  if (page.includes("inspectiontasks")) {
    return "inspectiontasks";
  }
  return screenName;
};

export const getColOrderQuery = (screen) => {
  let columnOrderQuery = "";
  if (screen === "fillups") {
    columnOrderQuery = "getfillupsorder";
  } else if (screen?.includes("services/services")) {
    columnOrderQuery = "getserviceorder";
  } else if (screen?.includes("expenses/expenses")) {
    columnOrderQuery = "getexpenseorder";
  } else if (screen === "trips") {
    columnOrderQuery = "gettripsorder";
  } else if (screen.includes("issues")) {
    columnOrderQuery = "getissuesorder";
  } else if (screen.includes("workorders")) {
    columnOrderQuery = "getwoorder";
  }
  return columnOrderQuery;
};

export const getDeleteQuery = (page) => {
  let deleteQuery = "";
  if (page.includes("fillups")) {
    deleteQuery = "fillups";
  } else if (page.includes("services")) {
    deleteQuery = "service";
  } else if (page.includes("expenses")) {
    deleteQuery = "expense";
  } else if (page.includes("trips")) {
    deleteQuery = "trips";
  } else if (page.includes("inspectionsubmissions")) {
    deleteQuery = "deleteInspectionsubmissions";
  } else if (page.includes("inspectionscheduling")) {
    return "deleteInspectionschedules";
  } else if (page.includes("inspectionforms")) {
    return "deleteInspectionforms";
  } else if (page.includes("inspectiontasks")) {
    return "deleteInspectiontasks";
  } else if (page.includes("maintenancereminders")) {
    return "deleteMaintenancereminders";
  } else if (page.includes("renewals")) {
    return "deleteRenewals";
  }
  return deleteQuery;
};

export const convertToTextDate = (date) => {
  return date.toLocaleDateString(navigator.language, {
    day: "numeric",
    year: "numeric",
    month: "short",
  });
};

export const convertToFilterDate = (date) => {
  return date.toLocaleDateString(navigator.language, {
    day: "numeric",
    year: "2-digit",
    month: "2-digit",
  });
};

export const convertToDisplayDate = (date) => {
  return date.toLocaleDateString(navigator.language, {
    day: "numeric",
    year: "numeric",
    month: "short",
    weekday: "short",
  });
};

export const convertToDisplayTime = (date) => {
  return date.toLocaleString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getTimestamp = (id, index) => {
  return `${id}_${Math.floor(Math.random() * Date.now() * 10)}`;
};

export const getSingleDeleteQuery = (page) => {
  if (page.includes("fillups")) {
    return "fillups";
  } else if (page.includes("services")) {
    return "service";
  } else if (page.includes("expenses")) {
    return "expense";
  } else if (page.includes("trips")) {
    return "trips";
  } else if (page.includes("inspectionsubmissions")) {
    return "deleteinspectionsubmission";
  } else if (page.includes("inspectionscheduling")) {
    return "deleteInspectionschedules";
  } else if (page.includes("inspectionforms")) {
    return "deleteInspectionforms";
  } else if (page.includes("inspectiontasks")) {
    return "deleteInspectiontasks";
  } else if (page.includes("maintenancereminders")) {
    return "deleteMaintenancereminders";
  } else if (page.includes("renewals")) {
    return "deleteRenewals";
  }
};

export const getInitialStartTime = (date = new Date()) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  let hr = hours;
  let min = minutes;
  if (hours < 10) {
    hr = "0" + hr;
  }
  if (minutes < 10) {
    min = "0" + min;
  }
  return `${hr}:${min}`;
};

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const handleNegative = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const handleNumberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  //e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  //function is done)
  // setTimeout(() => {
  //   e.target.focus();
  // }, 0);
};

export const getDisplayDate = (dateInSeconds) => {
  return format(new Date(dateInSeconds * 1000), "PP", {
    locale: getLocale(),
  }); //"dd-MMM-yyyy"
};

export const getDisplayTime = (dateInSeconds) => {
  return format(new Date(dateInSeconds * 1000), "h:mm a");
};

export const getDisplayDateWithTime = (dateInSeconds) => {
  return format(new Date(dateInSeconds * 1000), "PPp", { locale: getLocale() });
};

const getLocale = () => {
  const locale = navigator.language.replace("-", "");
  const rootLocale = locale.substring(0, 2);

  return allLocales ? allLocales[locale] || allLocales[rootLocale] : enIN;
};

export const getDateWithTime = (dateInMS, time) => {
  let dateWithTime = new Date(dateInMS);
  let [hours, minutes] = time?.split(":");
  if (hours < 10) {
    hours = hours[1];
  }

  if (minutes < 10) {
    minutes = minutes[1];
  }
  //let dateWithTime = "";
  dateWithTime = setHours(dateWithTime, hours);
  dateWithTime = setMinutes(dateWithTime, minutes);
  return getUnixTime(dateWithTime);
};

export const getImgString = (imgArr, rowId, userId, orgId, screenName) => {
  let imgsToS3 = [];

  let imgString = "";

  imgArr?.forEach((img, i) => {
    if (img?.hasOwnProperty("existing")) {
      imgString += `${img?.imgName}:::`;
    } else {
      if (img.file) {
        const extension = img?.file?.name.split(".").pop();
        const imgName = `${getTimestamp(userId, i)}.${extension}`;
        const key = `image_data/org_${orgId}/${screenName}/${imgName}`;

        imgString += `${imgName}:::`;

        imgsToS3.push({
          ...img,
          key: key,
        });
        // return {
        //   ...img,
        //   key: key,
        // };
      }
    }
  });
  imgString = imgString.slice(0, imgString.length - 3);

  return { imgString, imgsToS3 };
};

//For Array containing obj
export function checkForDuplicateObj(array, keyName) {
  return new Set(array.map((item) => item[keyName])).size !== array.length;
}

//For primitive arr
export function checkForDuplicates(array) {
  const seenValues = new Set();

  for (let value of array) {
    if (seenValues.has(value)) {
      return true;
    }
    seenValues.add(value);
  }

  return false;
}

export const getDistanceFactor = (vehicleDistanceUnit, orgDistanceUnit) => {
  let distanceFactor = 1;

  if (vehicleDistanceUnit == "Kilometers" && orgDistanceUnit == "Miles") {
    distanceFactor = 0.621;
  }
  //if vehicle PM is set to miles and org dist is km
  else if (vehicleDistanceUnit == "Miles" && orgDistanceUnit == "Kilometers") {
    distanceFactor = 1.609;
  }
  return distanceFactor;
};

export function getDistanceFactorforOdo(vehicleDistanceUnit, orgDistanceUnit) {
  let distanceFactor = 1;

  //if vehicle PM is set to km and org dist is miles
  if (vehicleDistanceUnit == "Kilometers" && orgDistanceUnit == "Miles") {
    distanceFactor = 0.621;
  }
  //if vehicle PM is set to miles and org dist is km
  else if (vehicleDistanceUnit == "Miles" && orgDistanceUnit == "Kilometers") {
    distanceFactor = 1.609;
  }
  return distanceFactor;
}

export const getOdoUnit = (distanceUnit) => {
  if (distanceUnit === "Kilometers") {
    return "Km";
  } else if (distanceUnit === "Miles") {
    return "Mi";
  } else if (distanceUnit === "Hours") {
    return "Hr";
  }
};

export const getDistanceUnit = (distanceUnit) => {
  if (distanceUnit === "Kilometers") {
    return "Km";
  } else if (distanceUnit === "Miles") {
    return "Mi";
  } else if (distanceUnit === "Hours") {
    return "Hr";
  }
};

export const uploadDocsToS3 = async (uploadToS3Arr) => {
  try {
    const client = new S3Client({
      region: "us-east-2",
      credentials: fromCognitoIdentityPool({
        identityPoolId: IDENTITY_POOL_ID,
        client: new CognitoIdentityClient({ region: BUCKET_REGION }),
      }),
      apiVersion: "2006-03-01",
    });

    const statusCodeArr = [];
    for (let i = 0; i < uploadToS3Arr.length; i++) {
      let currentImg = uploadToS3Arr[i];
      const command = new PutObjectCommand({
        Bucket: ALBUM_BUCKET_NAME,
        Key: currentImg.key,
        Body: currentImg.file,
        ContentDisposition: "",
        ContentType: currentImg.file.type,
        //ACL: "public-read",
      });
      const response = await client.send(command);
      const statusCode = response.$metadata.httpStatusCode;
      statusCodeArr.push(statusCode);
      if (statusCodeArr.length === uploadToS3Arr.length) {
        const allImgsUploaded = statusCodeArr.every((code) => code === 200);
        return allImgsUploaded;
      }
    }
  } catch (e) {
    console.error("s3 error", e);
    return e;
  }
};

export function getDistanceFactorForEff(orgDistanceUnit, orgConsumptionUnit) {
  let distanceFactor = 1;
  //check the consumption unit and the distance unit and volume unit and accordingly set the distance and volume factors
  //Get the set units
  //let orgDistanceUnit = store;
  //let orgConsumptionUnit = $("#org_consumption").val();
  //set the distance factor
  //set distance factor if distance is in km but consumption is in miles
  if (
    orgConsumptionUnit.substring(0, 1) == "m" &&
    orgDistanceUnit == "Kilometers"
  ) {
    distanceFactor = 0.621;
  }
  //set distance factor if distance is in miles but consumption is in km
  else if (
    orgConsumptionUnit.substring(0, 1) != "m" &&
    orgDistanceUnit == "Miles"
  ) {
    distanceFactor = 1.609;
  }
  //set the volume factor
  return distanceFactor;
}

//function to set volume factor for eff
export function getVolumeFactorForEff(orgVolumeUnit, orgConsumptionUnit) {
  let volumeFactor = 1;
  //check the consumption unit and the distance unit and volume unit and accordingly set the distance and volume factors
  //Get the set units
  // var orgVolumeUnit = $("#org_volume").val();
  // var orgConsumptionUnit = $("#org_consumption").val();
  //set the volume factor
  //set volume factor if consumption is in gallons(US)
  if (orgConsumptionUnit.includes("g (US)")) {
    if (orgVolumeUnit == "Liters") {
      volumeFactor = 0.264;
    } else if (orgVolumeUnit == "Gallons (UK)") {
      volumeFactor = 1.201;
    }
  }
  //set volume factor if consumption is in gallons(UK)
  else if (orgConsumptionUnit.includes("g (UK)")) {
    if (orgVolumeUnit == "Liters") {
      volumeFactor = 0.22;
    } else if (orgVolumeUnit == "Gallons (US)") {
      volumeFactor = 0.833;
    }
  }
  //set volume factor if consumption is in litres
  else {
    if (orgVolumeUnit == "Gallons (UK)") {
      volumeFactor = 4.546;
    } else if (orgVolumeUnit == "Gallons (US)") {
      volumeFactor = 3.785;
    }
  }
  return volumeFactor;
}

export const getVolumeFactor = (userVolumeUnit, orgVolumeUnit) => {
  let volumeFactor = 1;
  //set the volume factor
  if (userVolumeUnit == "Gallons (US)") {
    if (orgVolumeUnit == "Liters") {
      volumeFactor = 3.785;
    } else if (orgVolumeUnit == "Gallons (UK)") {
      volumeFactor = 0.833;
    }
  }
  //set volume factor if consumption is in gallons(UK)
  else if (userVolumeUnit == "Gallons (UK)") {
    if (orgVolumeUnit == "Liters") {
      volumeFactor = 4.546;
    } else if (orgVolumeUnit == "Gallons (US)") {
      volumeFactor = 1.201;
    }
  }
  //set volume factor if consumption is in litres
  if (userVolumeUnit == "Liters") {
    if (orgVolumeUnit == "Gallons (US)") {
      volumeFactor = 0.264;
    } else if (orgVolumeUnit == "Gallons (UK)") {
      volumeFactor = 0.22;
    }
  }
  return volumeFactor;
};

export const getTimeInHoursAndMinutes = (milliseconds) => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  //const seconds = Math.floor(((milliseconds % 360000) % 60000) / 1000);
  return `${hours}:${minutes}`;
};

export const daysHoursMinutes = (timeInSec) => {
  let t = timeInSec;
  let cd = 24 * 60 * 60,
    ch = 60 * 60,
    d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60);

  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  let display = "";

  if (d && d > 0) {
    display += d + "d ";
  }
  if (h) {
    display += h + "h ";
  }
  if (m) {
    display += m + "m";
  }

  if (!display) {
    display = NATEXT;
  }

  return { d, h, m, display };
};

export const generateGroupsTreeArr = (groups) => {
  let items = [];
  groups.forEach((g) => {
    const level = !g.level ? 1 : g.level;
    let item = {};
    if (!g.parent_group) {
      item.parentId = null;
    } else {
      item.parentId = g.parent_group;
    }

    item.children =
      g.groupNodes?.length > 0
        ? g.groupNodes.filter(
            (id) => groups.find((g) => g.group_id === id).level === level + 1
          )
        : [];
    item.groupNodes = g.groupNodes;
    item.label = g.label;
    item.value = g.value;
    item.id = g.group_id;
    item.groupId = g.groupId;
    item.group_id = g.group_id;
    item.isParent = g.groupNodes?.length > 0;
    item.showChildren = false;
    item.level = !g.level ? 1 : g.level;
    //item.fetched = false;
    item.spread = false;
    //item.show = !g.parent_group
    items.push(item);
  });

  return items;
};

export const generateTreeArr = (items, id = null, link = "parent_group") => {
  return items
    .filter((item) => item[link] === id)
    .map((item) => ({
      ...item,
      children: generateTreeArr(items, item.group_id),
    }));
};

//This method is used to fetch the row id key name and subquerystring for a screen
export const getMultiDeleteInfo = (page) => {
  let rowId = "";
  let subQuery = "";
  // if (page.includes("fillups")) {
  //   subQuery = "FILLUPS";
  //   rowId = "fillup_id";
  // }
  if (page.includes("services")) {
    subQuery = "SERVICES";
    rowId = "service_id";
  }
  if (page.includes("expenses")) {
    subQuery = "EXPENSES";
    rowId = "expense_id";
  }
  if (page.includes("trips")) {
    subQuery = "TRIPS";
    rowId = "trip_id";
  }
  if (page.includes("inspectionsubmissions")) {
    subQuery = "INSPECTIONS";
    rowId = "inspection_id";
  }
  if (page.includes("inspectionscheduling")) {
    subQuery = "INSPECTION_SCHEDULE";
    rowId = "inspection_schedule_id";
  }
  if (page.includes("inspectionforms")) {
    subQuery = "INSPECTION_FORMS";
    rowId = "form_id";
  }
  if (page.includes("inspectiontasks")) {
    subQuery = "INSPECTION_TASKS";
    rowId = "task_id";
  }
  if (page.includes("maintenancereminders")) {
    subQuery = "REMINDERS_SERVICE";
    rowId = "reminder_id";
  }
  if (page.includes("renewals")) {
    subQuery = "REMINDERS_EXPENSE";
    rowId = "reminder_id";
  }
  if (page.includes("issues")) {
    subQuery = "ISSUES";
    rowId = "issue_id";
  }
  if (page.includes("workorders")) {
    subQuery = "WORK_ORDERS";
    rowId = "wo_id";
  }
  if (page.includes("servicetasks")) {
    subQuery = "SERVICE_TASKS";
    rowId = "service_task_id";
  }
  if (page.includes("expensetasks")) {
    subQuery = "EXPENSE_TASKS";
    rowId = "expense_task_id";
  }
  if (page.includes("dailymileage")) {
    subQuery = "STARTING_ENDING_MILEAGE";
    rowId = "_id";
  }

  if (page.includes("fillups")) {
    subQuery = "FILLUPS";
    rowId = "fillup_id";
  }

  if (page.includes("dailytimesheet")) {
    subQuery = "DAILY_TIMESHEET";
    rowId = "timesheet_img";
  }

  if (page.includes("parts")) {
    subQuery = "PARTS";
    rowId = "part_id";
  }

  return { rowId, subQuery };
};

export const getUserDocUrl = async (orgId, userId, docName, extensionArr) => {
  let extension = "";
  if (extensionArr[0].includes("png")) {
    extension = "png";
  }
  if (extensionArr[0].includes("jpg")) {
    extension = "jpg";
  }
  if (extensionArr[0].includes("jpeg")) {
    extension = "jpeg";
  }
  if (extensionArr[0].includes("jfif")) {
    extension = "jfif";
  }
  if (extensionArr[0].includes("pdf")) {
    extension = "pdf";
  }

  let url = `${s3Url}/org_${orgId}/user_${userId}/Documents/${docName}.${extension}`;
  const res = await fetch(url);
  console.log("res", res);
  if (res.status === 404) {
    url = `image_data/org_${orgId}/user_${userId}/Documents/${docName}.txt`;
    extension = "txt";
  }
  return { url, extension };
};

export const getVehDocUrl = async (orgId, vehId, imgName) => {
  let imageName = imgName;
  let extension = "";
  if (imageName?.includes("png")) {
    extension = "png";
  }
  if (imageName?.includes("jpg")) {
    extension = "jpg";
  }
  if (imageName?.includes("jpeg")) {
    extension = "jpeg";
  }
  if (imageName?.includes("jfif")) {
    extension = "jfif";
  }
  if (imageName?.includes("pdf")) {
    extension = "pdf";
  }
  let url = `${s3Url}/org_${orgId}/vehicle_${vehId}/vehicle_doc/${imgName}`;
  const res = await fetch(url);
  if (res.status !== 200) {
    imageName = imageName?.replace(/\s+/g, "");
    url = `${s3Url}/org_${orgId}/vehicle_${vehId}/vehicle_doc/${imageName}`;
    return { url, extension };
  } else {
    return { url, extension };
  }
};

export const getDateFnsLocale = () => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  //console.log(locale);
  let dateFnsLocale = enIN;
  if (locale.includes("US")) {
    dateFnsLocale = enUS;
  }
  if (locale.includes("GB")) {
    dateFnsLocale = enGB;
  }
  if (locale.includes("AU")) {
    //import(enAU);
    dateFnsLocale = enAU;
  }
  if (locale.includes("CA")) {
    //import(enAU);
    dateFnsLocale = enCA;
  }
  return dateFnsLocale;
};

export const getVolumeUnit = (volume) => {
  //console.log("vol", volume);
  let volUnit = volume;
  if (volume === "Liters") {
    volUnit = "Ltr";
  }
  if (volume === "Gallons (UK)") {
    volUnit = "Gal (UK)";
  }
  if (volume === "Gallons (US)") {
    volUnit = "Gal (US)";
  }
  return volUnit;
};

export const odoValidation = async (
  odoFieldName,
  apiData,
  setError,
  setSaveBtnLoading,
  t,
  distanceFactor,
  inputOdo
) => {
  //const { t } = useTranslation("common");
  const odoRes = await postApi(apiData, "commonNew");
  console.log("odoResponse", odoRes);
  if (odoRes.success) {
    return 1;
  }
  if (!odoRes.success) {
    let odoErrMsg = "";
    const checkFrom = apiData.check_from;
    let { odometer, screen_name } = odoRes;
    const odoConverted = (odometer / distanceFactor)?.toFixed(2);
    if (screen_name === "starting odo") {
      odoErrMsg = t("odo_val_err_msg_veh", { startingOdo: odoConverted });
    } else {
      const screenName = getScreenNameForOdoVal(screen_name, t);
      console.log("screenName", screenName);
      let { date } = odoRes;
      let dateInSec = Number(date);
      const displayDate =
        checkFrom === "starting_ending_mileage"
          ? getDisplayDate(dateInSec)
          : getDisplayDateWithTime(dateInSec);
      odoErrMsg = t("odo_val_err_msg", {
        date: displayDate,
        odometer: odoConverted,
        screenName,
        inputOdo,
      });
      //odoErrMsg = `One of your ${screen_name} records shows an odometer reading of ${odometer}, dated ${displayDate}`;
    }

    setError(
      odoFieldName,
      { type: "custom", message: odoErrMsg },
      { shouldFocus: true }
    );
    setSaveBtnLoading(false);
    return 0;
  }
};

export const sortItems = (prev, curr, columnId) => {
  if (
    prev.original[columnId]?.toLowerCase() >
    curr.original[columnId]?.toLowerCase()
  ) {
    return 1;
  } else if (
    prev.original[columnId]?.toLowerCase() <
    curr.original[columnId]?.toLowerCase()
  ) {
    return -1;
  } else {
    return 0;
  }
};

export const handleHighOdoErr = (e, lastOdo, setErr) => {
  const { value } = e.target;
  if (value && lastOdo && +value - +lastOdo > 1000) {
    setErr(true);
  } else {
    setErr(false);
  }
};

export const getHourlyVolumeFactorEff = (
  orgVolumeUnit,
  orgHourlyConsumptionUnit
) => {
  let hourlyVolumeFactor = 1;
  //check the consumption unit and the distance unit and volume unit and accordingly set the distance and volume factors

  //set the volume factor
  //set volume factor if consumption is in gallons(US)
  if (orgHourlyConsumptionUnit.includes("G (US)/Hr")) {
    if (orgVolumeUnit == "Liters") {
      hourlyVolumeFactor = 0.264;
    } else if (orgVolumeUnit == "Gallons (UK)") {
      hourlyVolumeFactor = 1.201;
    }
  }
  //set volume factor if consumption is in gallons(UK)
  else if (orgHourlyConsumptionUnit.includes("G (UK)/Hr")) {
    if (orgVolumeUnit == "Liters") {
      hourlyVolumeFactor = 0.22;
    } else if (orgVolumeUnit == "Gallons (US)") {
      hourlyVolumeFactor = 0.833;
    }
  }
  //set volume factor if consumption is in litres
  else {
    if (orgVolumeUnit == "Gallons (UK)") {
      hourlyVolumeFactor = 4.546;
    } else if (orgVolumeUnit == "Gallons (US)") {
      hourlyVolumeFactor = 3.785;
    }
  }
  return hourlyVolumeFactor;
};

export const getListingDisplayDate = (dateInSec) => {
  return new Date(dateInSec * 1000).toLocaleDateString(navigator.language, {
    day: "numeric",
    year: "numeric",
    month: "short",
  });
};

export const getDisplayDateWithTimeNew = (dateInSec) => {
  return new Date(dateInSec * 1000).toLocaleDateString(navigator.language, {
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const handleVehicleOnChange = async (
  vehicle,
  onChange,
  userKey,
  setValue,
  setUserOptions,
  userId
) => {
  onChange(vehicle);

  const apiData = {
    veh_id: vehicle?.vehicleId,
    querystring: "getvehicleoperators",
  };
  const res = await postApi(apiData, "commonNew");
  if (res.success) {
    const { user_data } = res;
    if (user_data?.length > 0) {
      let temp = user_data.map((u) => ({
        ...u,
        label: u.name,
        value: u.user_id,
        user_id: u.user_id,
      }));
      const defaultUser = temp.find((u) => u.user_id === userId);
      if (defaultUser) {
        setValue(userKey, defaultUser);
      } else {
        setValue(userKey, "");
      }

      setUserOptions(temp);
    }
  }
};

export const getEditUsers = async (vehicleId, userId) => {
  const apiData = {
    querystring: "get_vehicle_operators_edit",
    veh_id: vehicleId,
    edit_user_id: userId,
  };
  const res = await postApi(apiData, "commonNew");
  let result = [];
  if (res.success) {
    const { user_data } = res;
    result = user_data.map((u) => ({
      ...u,
      label: u.name,
      value: u.user_id,
      user_id: u.user_id,
    }));
  }
  return result;
};

export const getTranslatedPMUnit = (unit, t) => {
  if (unit === "Kilometers") {
    return t("kilometers");
  }
  if (unit === "Miles") {
    return t("miles");
  }
  if (unit === "Hours") {
    return t("hours_const");
  }
};

export const getTranslatedFuelTypeUnit = (unit, t) => {
  if (unit === "Petrol") {
    return t("petrol_const");
  }
  if (unit === "Diesel") {
    return t("diesel_const");
  }
};

export const getTranslatedTransactionType = (transactionType, t) => {
  switch (transactionType) {
    case "Cash":
      return t("cash_const");
    case "Check":
      return t("check_const");
    case "Credit card":
      return t("credit_card_const");
    case "Debit card":
      return t("debit_card_const");
  }
};

export const getTranslatedVolumeUnit = (volUnit, t) => {
  switch (volUnit) {
    case "Gallons (UK)":
      return t("gallons_uk");
    case "Gallons (US)":
      return t("gallons_us");
    case "Liters":
      return t("liters");
  }
};

export const downloadImage = async (imageSrc, ext = "jpg") => {
  console.log(imageSrc);
  const image = await fetch(imageSrc, {
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  const downloadDate = new Date();
  link.download = `Simply Fleet ${format(
    downloadDate,
    "yyyy-MM-dd"
  )} at ${format(downloadDate, "HH.mm")}.jpg`; //ext can be used if required
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getTranslatedReasonText = (reason, t) => {
  switch (reason) {
    case 0:
      return t("purchased");
    case 1:
      return t("consumed");
    case 2:
      return t("damaged");
    case 3:
      return t("stolen");
    case 4:
      return t("returned");
    case 5:
      return t("sold");
    case 6:
      return t("stock_recon");
  }
};

export const getScreenNameForOdoVal = (screenVal, t) => {
  const screenNameMapping = {
    fillup: t("fillups"),
    service: t("services"),
    expense: t("expenses"),
    trip: t("trips"),
    "daily mileage": t("se_mileage"),
    inspection: t("inspection_submissions"),
    issue: t("issues"),
    "work order": t("work_orders"),
  };
  return screenNameMapping[screenVal];
};
