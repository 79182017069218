import React from "react";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import {
  DueComponent,
  LastServiceComponent,
  StatusComponent,
} from "../../Reminders/MaintenanceReminders/MaintenanceRemindersTable";
import DataReactTable from "../../DataTable/components/DataReactTable";
import VehicleComponent from "../../../shared/components/table/components/VehicleComponent";
import { postApi, putApi, url } from "../../../api/getUser";
import {
  initialContainsData,
  initialRangeData,
  initialStatusData,
} from "../../../shared/constants/filterData";
import { addRowData } from "../../../features/row/rowSlice";
import RowOptions from "../../../shared/components/table/components/RowOptions";
import {
  AddFormButton,
  ColumnHeading,
} from "../../../shared/components/TableElements";
import { Link } from "react-router-dom";
import { NATEXT } from "../../../shared/constants";
import { useAuth } from "../../../contexts/AuthContext";
import { updateUserSettingsData } from "../../../features/columns/columnsSlice";
import getUnixTime from "date-fns/getUnixTime";
import { LinkSpan } from "../../../shared/components/form/FormElements";
import ExplorePopup from "../../../shared/components/ExplorePopup";
import { useLocation } from "react-router";
import { getListingDisplayDate } from "../../../shared/helpers";
import getDefaultFilterData from "../../../shared/constants/getDefaultFilterData";

const InspectionScheduleTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const location = useLocation();
  const fromCancel = location.state?.fromCancel;
  const {
    isLoggedIn,
    setLoading,
    reload,
    setReload,
    isClientApiCalled,
    setIsClientApiCalled,
  } = useAuth();
  const [rowData, setRowData] = useState([]);

  const { minDate } = useSelector((state) => state.columns);
  const { distance } = useSelector((state) => state.units);

  const [showExplorePopup, setShowExplorePopup] = useState(false);
  const { orgDate } = useSelector((state) => state.user);

  const rows = useSelector((state) => state.row.data);

  useEffect(() => {
    if (isLoggedIn && orgDate && (!isClientApiCalled || !fromCancel)) {
      (async () => {
        setIsClientApiCalled(true);
        setLoading(true);
        let defaultRows = 10;
        let minDate = orgDate;
        let colOrder = "[0,1,2,3,4]";

        const apiData = {
          querystring: "get_user_screen_settings",
          screen_name: "inspectionschedules",
        };
        const userSettingsRes = await putApi(apiData, "dashboard");
        console.log("userSettingsRes", userSettingsRes);
        if (userSettingsRes.success) {
          const { data } = userSettingsRes;
          if (data) {
            const { default_rows, min_date, col_order } = data;
            if (col_order) {
              colOrder = col_order;
            }
            defaultRows = default_rows;
            if (min_date) {
              minDate = min_date;
            }
          }
        }
        //dispatch(updateNoOfRecords({ defaultRows: defaultRows }));
        dispatch(
          updateUserSettingsData({
            default_rows: defaultRows,
            min_date: minDate,
            col_order: colOrder,
          })
        );

        const response = await postApi(
          { querystring: "get_all_inspectionschedule_v2" },
          "commonNew"
        );

        const rowData = response?.user_data;

        await generateRows(rowData);
        setLoading(false);
      })();
    }
  }, [isLoggedIn, orgDate]);

  //Reload Effect
  useEffect(() => {
    if (reload === "inspectionschedules") {
      (async () => {
        setReload("");
        const response = await postApi(
          { querystring: "get_all_inspectionschedule_v2" },
          "commonNew"
        );

        const rowData = response?.user_data;

        await generateRows(rowData);
      })();
    }
  }, [reload]);

  const generateRows = async (rows) => {
    const temp = [];
    rows.forEach((row) => {
      let daysRemaining = row.last_inspection_date
        ? (Date.now() / 1000 - row.last_inspection_date) / (3600 * 24)
        : 0;

      // let maxOdo = Math.max(
      //   row.maxservice,
      //   row.maxfillup,
      //   row.maxexpenses,
      //   row.maxtrip,
      //   row.maxmileage,
      //   row.maxlastserviceodo
      // );
      let maxOdo = row.max_odo ? +row.max_odo : 0;
      let odoRemaining = row.last_inspection_odo
        ? +maxOdo - +row.last_inspection_odo
        : maxOdo;

      const timePercentage = row.due_days
        ? (daysRemaining * 100) / row.due_days
        : 0;
      const odoPercentage = row.due_odo
        ? (odoRemaining * 100) / row.due_odo
        : 0;

      temp.push({
        inspection_schedule_id: row.inspection_schedule_id,
        row_id: row.inspection_schedule_id,
        inspection_form_id: row.inspection_form_id,
        name: row.name ? row.name : NATEXT,
        form_name: row.form_name ? row.form_name : NATEXT,
        last_inspection_date: row.last_inspection_date
          ? row.last_inspection_date
          : 0,
        last_inspection_odo: row.last_inspection_odo,
        due_days: row.due_days ? row.due_days : 0,
        due_odo: row.due_odo ? row.due_odo : 0,
        due_on_date: row.due_on_date ? row.due_on_date : 0,
        due_on_odo: row.due_on_odo ? row.due_on_odo : 0,
        days_threshold: row.days_threshold ? row.days_threshold : 0,
        odo_threshold: row.odo_threshold ? row.odo_threshold : 0,
        emails: row.emails ? row.emails : "",
        comments: row.comments ? row.comments : "",
        emails_sent: row.emails_sent,
        veh_id: row.veh_id,
        org_id: row.org_id,
        veh_img: row.veh_img,
        recurring: row.recurring,
        days_remaining: row.last_inspection_date
          ? (Date.now() / 1000 - row.last_inspection_date) / (3600 * 24)
          : NATEXT,
        odo_remaining: row.last_inspection_odo
          ? +maxOdo - row.last_inspection_odo
          : NATEXT,
        due_days_unit: row.due_days_unit,
        days_threshold_unit: row.days_threshold_unit,

        timePercentage: timePercentage,
        odoPercentage: odoPercentage,
        percentage: Math.max(timePercentage, odoPercentage),
        dueDate:
          row.last_inspection_date && row.due_days
            ? row.last_inspection_date + +row.due_days * 24 * 60 * 60
            : NATEXT,
        dueOdo: +row.last_inspection_odo + row.due_odo,
        dueInDate:
          row.last_inspection_date && row.due_days
            ? Date(row.last_inspection_date / 1000) + row.due_days - Date.now()
            : NATEXT,
        dueInOdo: row.last_inspection_odo
          ? row.last_inspection_odo + row.due_odo - +maxOdo
          : NATEXT,
        primary_meter: row.primary_meter ? row.primary_meter : distance,
        maxOdo,
      });
    });
    setRowData(temp);
    dispatch(addRowData(temp));
  };

  const columnHeaders = useMemo(
    () => [
      {
        id: 1,
        Header: t("vehicle"),
        accessor: "name",
        Cell: ({ row }) => (
          <VehicleComponent
            vehicleName={row.original.name}
            groupName="N/A"
            vehicleId={row.original.veh_id}
            vehicleImg={row.original.veh_img}
            orgId={row.original.org_id}
          />
        ),
      },
      {
        id: 2,
        Header: t("inspection_form_name"),
        accessor: "form_name",
        accessorId: "task_name",
        //accessorId: 'form_name'
      },
      {
        id: 3,
        Header: t("status"),
        accessor: "status",
        //accessorId: 'form_name',
        //disableSortBy: true,
        sortType: (prev, curr) => {
          if (prev.original.percentage > curr.original.percentage) {
            return 1;
          } else if (prev.original.percentage < curr.original.percentage) {
            return -1;
          } else {
            return 0;
          }
        },
        Cell: ({ row }) => {
          const { due_days, last_inspection_date } = row.original;
          let due = last_inspection_date + Number(due_days) * 86400;
          const currentDate = getUnixTime(new Date());
          const dueInDays = ((due - currentDate) / (60 * 60 * 24)).toFixed(0);
          return (
            <StatusComponent
              due_days={row.original.due_days}
              dueOdo={row.original}
              distance={row.original.dueInOdo}
              percentage={row.original.percentage}
              days={dueInDays}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: 4,
        Header: t("due_on"),
        accessor: "due",
        disableSortBy: true,
        Cell: ({ row }) => {
          //console.log(row);
          let dueDate = new Date(row.original.last_inspection_date * 1000);
          const dueDays = +row.original.due_days;
          let lastInspectionDate = new Date(
            row.original.last_inspection_date * 1000
          );
          dueDate.setDate(lastInspectionDate.getDate() + dueDays);
          dueDate =
            dueDate && dueDate instanceof Date && !isNaN(dueDate)
              ? getListingDisplayDate(dueDate / 1000)
              : NATEXT;
          return (
            <DueComponent
              distance={row.original.dueOdo}
              date={dueDate}
              due_days={row.original.due_days}
              due_odo={row.original.due_odo}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: 5,
        Header: t("last_inspected"),
        accessor: "last_inspection",
        disableSortBy: true,
        Cell: ({ row }) => {
          const dueDate = new Date(row.original.last_inspection_date * 1000);
          const lastInspectionDate =
            dueDate && dueDate instanceof Date && !isNaN(dueDate)
              ? getListingDisplayDate(dueDate / 1000)
              : NATEXT;
          return (
            <LastServiceComponent
              distance={row.original.last_inspection_odo}
              date={lastInspectionDate}
              rowData={row.original}
            />
          );
        },
      },
      {
        id: 6,
        Header: "",
        accessor: "edit",
        accessorId: "edit",
        Cell: ({ row }) => <RowOptions rowData={row?.original} />,
        disableSortBy: true,
      },
      // {
      //   id: "edit",
      //   Header: "",
      //   accessor: "edit",
      //   accessorId: "edit",
      //   Cell: () => <RowOptions />,
      //   disableSortBy: true,
      // },
    ],
    []
  );

  const { defaultContainsData, defaultRangeData } = getDefaultFilterData();

  const initialData = useMemo(
    () => ({
      // status: {
      //   column: "status",
      //   name: t("status"),
      //   ...initialStatusData,
      // },
      formName: {
        column: "formName",
        name: t("inspection_form_name"),
        accessor: "form_name",
        ...defaultContainsData,
      },
      daysRemaining: {
        column: "daysRemaining",
        name: t("days_remaining"),
        accessor: "dueInDate",
        ...defaultRangeData,
      },
      odoRemaining: {
        column: "odoRemaining",
        name: t("odo_remaining"),
        accessor: "dueInOdo",
        ...defaultRangeData,
      },
      lastInspectionOdo: {
        column: "lastInspectionOdo",
        name: t("last_inspection_odo"),
        accessor: "last_inspection_odo",
        ...defaultRangeData,
      },
      lastInspectionDate: {
        column: "lastInspectionDate",
        name: t("last_inspection_date"),
        accessor: "last_inspection_date",
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
      dueOdo: {
        column: "dueOdo",
        name: t("due_odo"),
        accessor: "dueOdo",
        ...defaultRangeData,
      },
      dueDate: {
        column: "dueDate",
        name: t("due_date"),
        accessor: "dueDate",
        dateOption: "all_time",
        from: new Date(minDate * 1000),
        to: new Date(),
        isFiltered: false,
      },
    }),
    [minDate]
  );

  const reactTableData = {
    tableHeaderData: columnHeaders,
    tableRowsData: rows,
  };

  //console.log('rows', rows);

  return (
    <>
      {showExplorePopup && (
        <ExplorePopup
          showPopup={showExplorePopup}
          setShowPopup={setShowExplorePopup}
          exploreLink={"https://embed.getsmartcue.com/I0WK8MKX"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          {/* <Col sm={10}>
            <ColumnHeading>Inspection Schedules</ColumnHeading>
          </Col> */}
          <Col className="d-flex align-items-center gap-2">
            <ColumnHeading>{t("inspection_schedules")}</ColumnHeading>
            {/* <LinkSpan
              onClick={() => setShowExplorePopup(true)}
              style={{ alignSelf: "end" }}
            >
              {t("explore")}
            </LinkSpan> */}
          </Col>
          <Col
            sm={2}
            className="d-flex justify-content-end align-items-center mb-1"
          >
            <Link to={"/inspection/inspectionscheduling/addnew"}>
              <AddFormButton>{t("add_inspection_schedule")}</AddFormButton>
            </Link>
          </Col>
        </Row>
        <Row>
          <DataReactTable
            reactTableData={reactTableData}
            initialData={initialData}
          />
        </Row>
      </Container>
    </>
  );
};

export default InspectionScheduleTable;
