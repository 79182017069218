import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ItemContainer, ItemLabel, ItemValue } from "./";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { taskBgColor } from "../../../../../utils/palette";
import { useTranslation } from "react-i18next";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { NATEXT, s3Url } from "../../../../constants";
import {
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedTransactionType,
} from "../../../../helpers";
import { postApi } from "../../../../../api/getUser";
import { dhm } from "../../../../../components/WorkOrders";
import { LinkSpan } from "../../../form/FormElements";
import ServiceTasksSidePanel from "./components/ServiceTasksSidePanel";

const ServicesRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { currency, distance } = useSelector((state) => state.units);
  const {
    service_date,
    odo,
    primary_meter,
    //total_cost,
    //service_task_ids,
    //labor_cost,
    //parts_cost,
    service_station,
    service_id,
    service_task_name,
    serviced_by,
    transaction_type,
    card_number,
    comments,
    org_id,
    receipt_img_name,
    tax,
    discount,
    uname,
    total_time,
    parts_total,
    labor_total,
    total,
  } = rowData;

  // let partsTotal, laborTotal, total;
  // if (total_cost) {
  //   let totalCost = JSON.parse(total_cost);
  //   partsTotal = totalCost.parts_total;
  //   laborTotal = totalCost.labor_cost_total;
  //   total = totalCost.total ? Number(totalCost.total) : 0;
  // }

  const [serviceTasks, setServiceTasks] = useState([]);

  useEffect(() => {
    //Single service fetch call on edit
    (async () => {
      const singleServiceApiData = {
        querystring: "fetchSingleServiceData",
        service_id: rowData?.service_id,
      };
      const singleServiceRes = await postApi(singleServiceApiData, "workOrder");
      if (singleServiceRes.success) {
        const {
          service_data,
          service_details_data,
          parts_consumed_details,
          parts_batch_data,
        } = singleServiceRes;

        if (service_details_data?.length > 0) {
          let serviceTaskDetails = [];
          for (let task of service_details_data) {
            const {
              service_task_detail_id,
              service_task_id,
              service_id,
              wo_id,
              labor_cost,
              parts_cost,
              total_cost,
              service_task_name,
            } = task;

            //Parts
            let noOfParts = 0;
            let partsDisplayArr = [];
            let batches = [];
            if (parts_consumed_details.length > 0) {
              let partsConsumedData = parts_consumed_details.map((p) => {
                const assocBatch = parts_batch_data.find(
                  (b) => b.parts_batch_id === p.parts_batch_id
                );
                return {
                  ...p,
                  ...assocBatch,
                  action: 2,
                  unitsConsumed: p.quantity_consumed,
                  availableQuantity: assocBatch.available_quantity,
                  currentlyAvailableQuantity:
                    +assocBatch.available_quantity + +p.quantity_consumed,
                  totalCost:
                    p.quantity_consumed * assocBatch.purchase_cost_per_unit,
                };
              });
              const assocPartBatches = partsConsumedData.filter(
                (t) => t.service_task_detail_id === service_task_detail_id
              );
              batches = [...assocPartBatches];
              //Get no of parts used
              noOfParts = [...new Set(assocPartBatches.map((b) => b.part_id))]
                .length;

              // assocPartBatches.forEach((b) => {
              //   const partDisplayString = `${b.part_name} (${
              //     b.unitsConsumed
              //   } ${t("units")} @ ${b.purchase_cost_per_unit} ${currency}) - ${
              //     b.totalCost
              //   } ${currency}`;
              //   partsDisplayArr.push(partDisplayString);
              // });
            }

            serviceTaskDetails.push({
              service_task_detail_id,
              service_task_id,
              service_id,
              service_task_name,
              wo_id,
              laborCost: +labor_cost ? +labor_cost : "",
              partsCost: +parts_cost ? +parts_cost : "",
              totalCost: +total_cost ? +total_cost : "",
              action: 2,
              label: service_task_name,
              value: service_task_id,
              noOfParts,
              partsDisplayArr,
              batches,
            });
          }
          setServiceTasks(serviceTaskDetails);
        }
      }
    })();
  }, [service_id]);

  let receiptsIdArr = [];
  let receiptsUrlData = [];
  if (receipt_img_name && receipt_img_name !== "N/A") {
    receiptsIdArr = receipt_img_name.split(":::");
    receiptsUrlData = receiptsIdArr.map((id) => ({
      id: id,
      url: `${s3Url}/org_${org_id}/services/${id}`,
    }));
    console.log(receiptsUrlData);
  }
  return (
    <>
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>{getListingDisplayDate(service_date)}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("time_tv")}</ItemLabel>
            <ItemValue>
              {new Date(1000 * service_date).toLocaleTimeString()}
            </ItemValue>
          </ItemContainer>
        </Col>
        {+odo > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("odometer")}</ItemLabel>
              <ItemValue>
                {odo}{" "}
                {getDistanceUnit(
                  primary_meter === "Hours" ? primary_meter : distance
                )}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {service_station && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("service_station")}</ItemLabel>
              <ItemValue>{service_station}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {/* {service_task_name && service_task_name !== "N/A" && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>Service Task</ItemLabel>
              <ItemValue>{service_task_name}</ItemValue>
            </ItemContainer>
          </Col>
        )} */}
      </Row>
      {serviceTasks.length > 0 && (
        <ServiceTasksSidePanel serviceTasks={serviceTasks} rowData={rowData} />
      )}

      <Row>
        {!!total_time && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("total_duration")}</ItemLabel>
              <ItemValue>{dhm(total_time * 1000)}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {transaction_type && transaction_type !== "N/A" && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("transaction_type")}</ItemLabel>
              <ItemValue>
                {getTranslatedTransactionType(transaction_type, t)}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!+card_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>
                {transaction_type === "Check"
                  ? t("check_number")
                  : t("card_number")}
              </ItemLabel>
              <ItemValue>{card_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {serviced_by && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("serviced_by")}</ItemLabel>
              <ItemValue>{uname}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {comments && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              {/* <textarea
                value={comments}
                disabled
                style={{ minWidth: "90%", margin: "1px", padding: "5px" }}
              ></textarea> */}
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        {receiptsUrlData.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {receiptsUrlData.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ServicesRightPanel;

export const ReceiptImg = styled.img`
  overflow: visible;
  width: 120px;
  height: 90px;
  &:active {
    transform: scale(2) translate(10%, 10%);
    overflow: visible;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 10px;
  ${(p) => p.active && `background: lightgray`}
`;
