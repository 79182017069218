import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, logErrors, urlConfig } from "../../api/getUser";
import { auth } from "../../api/firebase";

const initialState = {
  rowData: [],
  totalRecords: null,
  status: "initial",
  error: null,
  data: {
    draw: 1,
    length: 10,
    search: {
      value: "",
      regex: false,
    },
    order: [
      {
        column: 1,
        dir: "desc",
      },
    ],
    start_date: 0,
    end_date: Math.round(new Date() / 1000),
    start: 0,
    filter_group_id: "",
    filter_vehicle_id: "",
    querystring: "",
  },
  values: {},
  deletedData: {},
};

export const fetchData = createAsyncThunk(
  "filter/fetchData",
  async (apiData) => {
    try {
      const email = auth?.currentUser?.email;
      const jsonData = { ...apiData, email: email };
      //console.log("New Data in filter thunk", jsonData);
      const token = await getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.post(urlConfig.listing, jsonData, config);
      //console.log("Response Data", response);
      if (
        //response?.length === 0 ||
        response.data.errorMessage ||
        response.data[0]?.key === "error"
        // ||
        // !response.data[0]?.value?.success
      ) {
        const errLogData = {
          url: urlConfig.listing,
          method_type: "POST",
          error: JSON.stringify(response.data),
          request: JSON.stringify(jsonData),
          placeholder: "",
        };
        await logErrors(errLogData);
        return { data: [], values: {}, recordsTotal: 0, error: true };
      }
      const result = response?.data;
      const rowData = result?.data;
      const { data, draw, recordsFiltered, recordsTotal, ...values } = result;

      return { data, values, recordsTotal };
    } catch (err) {
      let error = err?.message;
      if (err.response) {
        // client received an error response (5xx, 4xx)
        error = JSON.stringify(err.response);
      }
      console.log("catch err", err);
      const errLogData = {
        url: urlConfig.listing,
        method_type: "POST",
        error: error,
        request: err?.config?.data,
        placeholder: "",
      };
      await logErrors(errLogData);
      return { data: [], values: {}, recordsTotal: 0, error: true };
      //return err.message;
    }
  }
);

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addSearchValue(state, action) {
      state.data.search.value = action.payload;
    },

    addGroupId(state, action) {
      //console.log("addGroupId Called");
      state.data.filter_group_id = action.payload;
    },

    addVehicleId(state, action) {
      //console.log("addVehicleId Called");
      state.data.filter_vehicle_id = action.payload;
    },

    updatePageLength(state, action) {
      state.data.length = action.payload;
    },

    updateStart(state, action) {
      state.data.start = action.payload;
    },

    updateColumnOrder(state, action) {
      //console.log(action);
      state.data.order[0].column = action.payload.id;
      state.data.order[0].dir = action.payload.desc;
    },

    updateValues(state, action) {
      state.values.footerTotalCost = action.payload.footerTotalCost;
      state.values.footerTotalQty = action.payload.footerTotalQty;
      state.values.footerTotalEff = action.payload.footerTotalEff;
      state.values.footerHourlyEff = action.payload.footerHourlyEff;
    },

    addAdvancedFilter(state, action) {
      const { column, name, radioName, ...rest } = action.payload;
      state.columns[column] = rest;
    },
    removeAdvancedFilter(state, action) {
      state.columns[action.payload] = {};
    },
    clearAllFilters(state, action) {
      action.payload.forEach((column) => {
        state.columns[column] = {};
      });
    },
    addFilterDate(state, action) {
      const { start, end } = action.payload;
      state.data.start_date = start;
      state.data.end_date = end;
    },
    removeFilterDate(state, action) {
      const { startDate, endDate } = action.payload;
      state.data.start_date = startDate;
      state.data.end_date = endDate;
    },
    updateDeletedData(state, action) {
      const { query } = action.payload;
      state.deletedData = { query };
    },
    updateInitialData(state, action) {
      const { query, startDate, endDate, order, length, search } =
        action.payload;
      state.data.draw = 1;
      state.data.length = length;
      state.data.search = {
        value: search,
        regex: false,
      };
      state.data.order = [
        {
          column: order,
          dir: action.payload?.orderType || "desc",
        },
      ];
      state.data.start_date = startDate;
      state.data.end_date = endDate;
      //state.data.filter_vehicle_id = "";
      //state.data.filter_group_id = "";
      state.data.querystring = query;
    },
    updateLoadingStatus(state, action) {
      state.status = "succeeded";
    },
    clearData(state) {
      state.rowData = [];
      state.totalRecords = null;
      state.status = "idle";
      state.error = null;

      state.data.draw = 1;
      state.data.length = 10;
      state.data.search = {
        value: "",
        regex: false,
      };
      state.data.order = [
        {
          column: 1,
          dir: "desc",
        },
      ];
      state.data.start_date = 0;
      state.data.end_date = 0;
      state.data.filter_vehicle_id = "";
      state.data.filter_group_id = "";
      state.data.querystring = "";
    },
    updateTotalRecords(state, action) {
      state.totalRecords = action.payload.recordsTotal;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (action.payload?.error) {
          state.status = "failed";
        } else {
          state.status = "succeeded";
        }
        state.rowData = action.payload.data;
        state.totalRecords = action.payload.recordsTotal;
        state.values = action.payload.values;
      })
      .addCase(fetchData.rejected, (state, action) => {
        console.log("action", action);
        state.rowData = [];
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  addSearchValue,
  addGroupId,
  addVehicleId,
  updatePageLength,
  updateStart,
  updateColumnOrder,
  updateValues,
  addAdvancedFilter,
  removeAdvancedFilter,
  clearAllFilters,
  addFilterDate,
  removeFilterDate,
  updateDeletedData,
  updateInitialData,
  updateLoadingStatus,
  clearData,
  updateTotalRecords,
} = filterSlice.actions;
export default filterSlice.reducer;
