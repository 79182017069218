import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  IconWrap,
  ItemContainer,
  ItemLabel,
  ItemValue,
  NotesValue,
  ReadMoreButton,
} from "./";
import { useState } from "react";
import { ReactComponent as PartialFillupIcon } from "@/shared/img/parking.svg";
import { ReactComponent as MissedFillupIcon } from "@/shared/img/gas-pump-slash.svg";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { useSelector } from "react-redux";
import {
  getDisplayDate,
  getDisplayTime,
  getDistanceFactorforOdo,
  getListingDisplayDate,
  getOdoUnit,
  getTranslatedFuelTypeUnit,
  getTranslatedTransactionType,
} from "../../../../helpers";
import { useTranslation } from "react-i18next";
import { getVolume } from "../../../../../components/FillUps/FillupsTable";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { NATEXT, s3Url } from "../../../../constants";

const FillupsRightPanel = ({
  rowData,
  clamped,
  setClamped,
  showReadMoreButton,
  notesRef,
}) => {
  const {
    name,
    fillup_date,
    odo,
    primary_meter,
    qty,
    qty_unit,
    total_cost,
    m_fill,
    p_fill,
    fillup_id,
    fuel_type,
    octane,
    fuel_brand,
    filling_station,
    transaction_type,
    filled_by,
    card_number,
    comments,
    veh_id,
    org_id,
    receipt_img_name,
    uname,
  } = rowData;

  const { t } = useTranslation("common");
  const { currency, distance } = useSelector((state) => state.units);
  const { orgId } = useSelector((state) => state.user);
  const users = useSelector((state) => state.users.list);
  const allVehicles = useSelector((state) => state.vehicles.list);

  let odoValue = odo
    ? `${odo} ${getOdoUnit(
        primary_meter === "Hours" ? primary_meter : distance
      )}`
    : NATEXT;

  let receiptsIdArr = [];
  let receiptsUrlData = [];
  if (receipt_img_name) {
    receiptsIdArr = receipt_img_name?.split(":::");
    receiptsUrlData = receiptsIdArr.map((id) => ({
      id: id,
      url: `${s3Url}/org_${orgId}/fillups/${id}`,
    }));
    //console.log(receiptsUrlData);
  }

  const { userId } = useSelector((state) => state.user);

  const filledBy = users?.find((u) => u.user_id === filled_by);

  let qtyUnitLabel = t("ltr_const");

  if (qty_unit === "Gallons (UK)") {
    //setQtyUnitLabel(t("gal_uk_const"));
    qtyUnitLabel = t("gal_uk_const");
  } else if (qty_unit === "Gallons (US)") {
    //setQtyUnitLabel(t("gal_us_const"));
    qtyUnitLabel = t("gal_us_const");
  } else if (qty_unit === "Liters") {
    //setQtyUnitLabel(t("ltr_const"));
    qtyUnitLabel = t("ltr_const");
  }

  //let images = receiptsUrlData.map((img) => ({ src: img.url, alt: img.id }));

  // const [imageModalData, setImageModalData] = useState({
  //   show: false,
  //   url: "",
  // });

  // const [visible, setVisible] = useState(false);

  // const handleImageClick = (imgUrl) => {
  //   setImageModalData({ show: true, url: imgUrl });
  // };

  // const onHide = () => {
  //   setImageModalData({ show: false, url: "" });
  // };

  //const [clamped, setClamped] = useState(true);
  //const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  //const notesRef = React.useRef(null);

  //const { collapse } = useSelector((state) => state.sidebar);

  //console.log('collapse', collapse);
  //let wasClamped = false;

  // useEffect(() => {
  //   const hasClamping = (el) => {
  //     const { clientHeight, scrollHeight } = el;
  //     //console.log(clientHeight, scrollHeight, collapse);
  //     return clientHeight !== scrollHeight;
  //   };

  //   const checkButtonAvailability = () => {
  //     if (notesRef.current) {
  //       // if (showReadMoreButton) {
  //       //   wasClamped = true;
  //       // }
  //       if (!clamped) {
  //         setClamped(true);
  //       }
  //       // Save current state to reapply later if necessary.
  //       //const hadClampClass = containerRef.current.classList.contains("clamp");
  //       // Make sure that CSS clamping is applied if aplicable.
  //       //if (!hadClampClass) containerRef.current.classList.add("clamp");
  //       // Check for clamping and show or hide button accordingly.

  //       //console.log(hasClamping(notesRef.current));
  //       setShowReadMoreButton(hasClamping(notesRef.current));
  //       // Sync clamping with local state.
  //       //if (!hadClampClass) containerRef.current.classList.remove("clamp");
  //     }
  //   };

  //   //const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

  //   setTimeout(() => {
  //     checkButtonAvailability();
  //   }, 500);
  //   window.addEventListener("resize", checkButtonAvailability);

  //   return () => {
  //     window.removeEventListener("resize", checkButtonAvailability);
  //   };
  // }, [notesRef, collapse]);

  return (
    <>
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>{getListingDisplayDate(fillup_date)}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("time_tv")}</ItemLabel>
            <ItemValue>{getDisplayTime(fillup_date)}</ItemValue>
          </ItemContainer>
        </Col>
        {+odo > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("odometer")}</ItemLabel>
              <ItemValue>{`${odo} ${getOdoUnit(
                primary_meter === "Hours" ? primary_meter : distance
              )}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+qty > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("qty_tv")}</ItemLabel>
              <ItemValue>
                {`${+qty?.toFixed(2)} ${getVolume(qty_unit)}`}{" "}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+total_cost > 0 && +qty > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{`${t(
                "cost_per_unit_tv"
              )} ${qtyUnitLabel}`}</ItemLabel>
              <ItemValue>{`${(total_cost / qty)?.toFixed(
                3
              )} ${currency}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+total_cost > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("tc_tv")}</ItemLabel>
              <ItemValue>{`${+total_cost?.toFixed(2)} ${currency}`}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {fuel_type && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("fuel_type")}</ItemLabel>
              <ItemValue>{getTranslatedFuelTypeUnit(fuel_type, t)}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+octane > 0 && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("oct_tv")}</ItemLabel>
              <ItemValue>{octane}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {fuel_brand && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("fb_tv")}</ItemLabel>
              <ItemValue>{fuel_brand}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {filling_station && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("fs_tv")}</ItemLabel>
              <ItemValue>{filling_station}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {transaction_type && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("transaction_type")}</ItemLabel>
              <ItemValue>
                {getTranslatedTransactionType(transaction_type, t)}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!+card_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("card_number")}</ItemLabel>
              <ItemValue>{card_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {filled_by && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("filled_by")}</ItemLabel>
              <ItemValue>{uname}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+p_fill === 1 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemValue>
                <IconWrap>
                  <PartialFillupIcon />
                </IconWrap>
                {/* Partial Tank Fill-up */}
                {t("pf_tv")}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {+m_fill === 1 && (
          <Col sm={12}>
            <ItemContainer>
              {/* <label>
                <CheckboxInput
                  name="m-fill"
                  type="checkbox"
                  checked={m_fill}
                  disabled
                />
                Missed Previous Fill-up
              </label> */}
              <ItemValue>
                <IconWrap>
                  <MissedFillupIcon />
                </IconWrap>
                {/* Missed Previous Fill-up */}
                {t("mf_tv")}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {comments && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              {/* <textarea
                value={comments}
                disabled
                style={{ minWidth: "90%", margin: "1px", padding: "5px" }}
              ></textarea> */}
              <NotesValue ref={notesRef} clamped={clamped}>
                {comments}
              </NotesValue>
              {showReadMoreButton && (
                <ReadMoreButton onClick={() => setClamped(!clamped)}>
                  {clamped ? t("read_more") : t("read_less")}
                </ReadMoreButton>
              )}
            </ItemContainer>
          </Col>
        )}
      </Row>

      <Row>
        {receiptsUrlData.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {receiptsUrlData.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default FillupsRightPanel;

export const ReceiptImg = styled.img`
  overflow: visible;
  cursor: pointer;
  width: 120px;
  height: 90px;
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 10px;
  ${(p) => p.active && `background: lightgray`};
  //width: inherit !important;
`;

// export const NotesValue = styled.p`
//   font-size: 14px;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   ${(p) =>
//     p.clamped &&
//     `
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   `};
// `;

// export const ReadMoreButton = styled.span`
//   font-size: 11px;
//   cursor: pointer;
//   &:hover {
//     border-bottom: 1px solid ${colorLightGray};
//   }
// `;

export const ImgContainer = styled.div`
  //overflow: hidden;

  // div {
  //   background: black;
  // }
  img {
    margin: auto;
    //width: auto !important;
    width: inherit;
    height: ${window.innerHeight - 200}px;
    max-width: 100%;
    display: block;
  }
`;

const ImageModal = ({ imageUrl }) => {
  const [zoomScale, setZoomScale] = useState(1);
  const handleZoomIn = () => {
    setZoomScale(zoomScale + 0.2);
  };
  const handleZoomOut = () => {
    setZoomScale(zoomScale - 0.2);
  };
  return (
    <Modal
      show={true}
      //onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ImgContainer className="modal-container" style={{ overflow: "hidden" }}>
        <ReactPanZoom
          src={imageUrl}
          zoomScale={zoomScale}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          //restrictPosition={true}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </ImgContainer>
    </Modal>
  );
};
