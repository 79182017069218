import React from "react";
import { Container, Col, Table } from "react-bootstrap";
import {
  ColumnHeaderRow,
  ColumnHeading,
  DataRow,
  Icon,
} from "../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colorStatusActive } from "../../utils/palette";
import { RightIcon, WrongIcon } from ".";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import FreeImg from "../../shared/img/Free.png";
import EssentialImg from "../../shared/img/Essential.png";
import AdvancedImg from "../../shared/img/Advanced.png";
import { useSelector } from "react-redux";
import Tooltip from "../../shared/components/Tooltip";

const ComparePlansTable = ({
  perVehiclePrice,
  toggleValue,
  //currency,
  currencyCode,
  insideModal,
  noOfVehicles,
  subscriptionData,
}) => {
  const { t } = useTranslation("common");

  let scanReceitpsSubtext = "";

  if (insideModal) {
    //if insdieModal is true, then it is an existing plan
    const productPlan = subscriptionData?.product;
    if (productPlan === "1" || productPlan === "2" || productPlan === "3") {
      scanReceitpsSubtext = t("5_scans_per_vehicle_per_month");
    } else {
      scanReceitpsSubtext = t("no_of_scans_per_month", {
        noOfScans: subscriptionData?.no_of_vehicles * 5,
      });
    }
  } else {
    scanReceitpsSubtext = t("no_of_scans_per_month", {
      noOfScans: noOfVehicles ? noOfVehicles * 5 : 25,
    });
  }

  const tableRowData = [
    {
      heading: false,
      featureName: t("no_of_users"),
      free: 3,
      essential: t("unlimited"),
      advanced: t("unlimited"),
    },
    {
      heading: false,
      featureName: t("driver_mgmt"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("uploading_documentation"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("tech_mgmt"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("vehicle_grouping"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: true,
      featureName: t("preventive_maintenance"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("scheduling_preventive_maint_reminders"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("veh_spec_maint_rec"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("in_app_reminder_notifs"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("service_history"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("email_notifs_for_reminders"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("uploading_rcpts_for_services"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: true,
      featureName: t("vehicle_inspections"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("creating_cust_inspection_forms"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("insp_history"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("insp_schedules"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("capturing_driver_signs"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("clicking_pics_during_an_insp"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("printing_out_insps"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: true,
      featureName: t("issues_mgmt"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("creating_issues_from_insps"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("capturing_adhoc_issues_by_drivers"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("attaching_imgs_to_issues"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    //WO Mgmt
    {
      heading: true,
      featureName: t("work_order_mgmt"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("creating_and_assigning_work_orders"),
      free: RightIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("attaching_invoices_and_rcpts_to_work_orders"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },

    //Fuel Mgmt
    {
      heading: true,
      featureName: t("fuel_mgmt"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("fuel_logging"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("auto_fuel_eff_calc"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("auto_detection_of_fuel_station"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("uploading_of_fuel_rcpts"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    //Trip Mgmt
    {
      heading: true,
      featureName: t("trip_mgmt"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("trip_logging"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("auto_distance_and_speed_detection"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("auto_loc_detection"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    {
      heading: true,
      featureName: t("expense_mgmt"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("expense_logging"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("creating_reminders_for_rec_expenses"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    //Mileage Tracking
    {
      heading: true,
      featureName: t("mileage_tracking"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("mileage_logging"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("daily_reminders_for_mileage_logging"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    //Inventory
    {
      heading: true,
      featureName: t("inventory"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("no_of_parts"),
      free: 3,
      essential: WrongIcon,
      advanced: t("unlimited"),
    },
    {
      heading: false,
      featureName: t("consume_inventory_in_services_and_wo"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("in_app_barcode_scanning"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("low_stock_alerts"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: t("coming_soon"),
    },
    {
      heading: false,
      featureName: t("generate_inventory_reports"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: t("coming_soon"),
    },

    //Reporting
    {
      heading: true,
      featureName: t("reporting"),
      free: null,
      essential: null,
      advanced: null,
      // subModule: [
      //   {
      //     heading: true,
      //     featureName: t("integrations"),
      //     free: null,
      //     essential: null,
      //     advanced: null,
      //   },
      //   {
      //     heading: false,
      //     featureName: t("telematics_integrations"),
      //     free: WrongIcon,
      //     essential: WrongIcon,
      //     advanced: RightIcon,
      //   },
      // ],
    },
    {
      heading: false,
      featureName: t("in_app_reporting"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("extracting_pdf_and_csv_reports"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    //Integrations
    {
      heading: true,
      featureName: t("integrations"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("telematics_integrations"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },

    {
      heading: true,
      featureName: t("data_storage"),
      free: `6 ${t("months")}`,
      essential: t("unlimited"),
      advanced: t("unlimited"),
    },

    {
      heading: true,
      featureName: t("mobile_apps"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },

    {
      heading: true,
      featureName: t("support"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("email_support"),
      free: RightIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("chat_support"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
    },

    {
      heading: true,
      featureName: t("data_setup_and_onboarding"),
      free: null,
      essential: null,
      advanced: null,
    },
    {
      heading: false,
      featureName: t("bulk_import"),
      free: WrongIcon,
      essential: RightIcon,
      advanced: RightIcon,
    },
    {
      heading: false,
      featureName: t("data_migration"),
      free: WrongIcon,
      essential: t("add_on"),
      advanced: t("add_on"),
    },
    {
      heading: false,
      featureName: t("onboarding_services"),
      free: WrongIcon,
      essential: t("add_on"),
      advanced: t("add_on"),
    },
    {
      heading: false,
      featureName: t("training"),
      free: WrongIcon,
      essential: t("add_on"),
      advanced: t("add_on"),
    },
    {
      heading: true,
      featureName: t("scan_and_read_receipts"),
      free: null,
      essential: null,
      advanced: null,
      tooltip: t("available_in_android_only"),
    },
    {
      heading: false,
      featureName: t("scan_receipts_for_fillups"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: RightIcon,
      subText: scanReceitpsSubtext,
    },
    {
      heading: false,
      featureName: t("scan_receipts_for_services"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: t("coming_soon"),
    },
    {
      heading: false,
      featureName: t("scan_receipts_for_wo"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: t("coming_soon"),
    },
    {
      heading: false,
      featureName: t("scan_receipts_for_expenses"),
      free: WrongIcon,
      essential: WrongIcon,
      advanced: t("coming_soon"),
    },
  ];

  const perDurationSmall =
    toggleValue === "yearly" ? t("year_sm") : t("month_sm");

  return (
    <div
      className="d-flex flex-column align-items-center"
      //style={{ backgroundColor: "white", padding: "2rem" }}
    >
      {!insideModal && (
        <ColumnHeading className="mb-2">{t("compare_plans")}</ColumnHeading>
      )}
      <Table bordered style={{ backgroundColor: "white" }}>
        <thead
          style={{
            position: "sticky",
            top: insideModal ? "-1px" : "60px",
            zIndex: 10,
            background: "white",
            marginTop: "10px",
          }}
        >
          <tr>
            <th style={{ textAlign: "center" }}></th>
            <th
              style={{ width: "20%" }}
              //className="d-flex justify-content-center align-items-center gap-1"
            >
              <div className="d-flex justify-content-center align-items-center gap-1">
                <img src={FreeImg} alt="free-icon-img" width={30} height={30} />
                <span style={{ fontSize: "20px" }}>{t("free_sm")}</span>
              </div>
            </th>

            <th
              style={{ width: "20%" }}
              //className="d-flex justify-content-center align-items-center gap-1"
            >
              <div className="d-flex justify-content-center align-items-center gap-1">
                <img
                  src={EssentialImg}
                  alt="essential-icon-img"
                  width={30}
                  height={30}
                />
                <span style={{ fontSize: "20px" }}>{t("essential")}</span>
              </div>
            </th>

            <th
              style={{ width: "20%" }}
              //className="d-flex justify-content-center align-items-center gap-1"
            >
              <div className="d-flex justify-content-center align-items-center gap-1">
                <img
                  src={AdvancedImg}
                  alt="advanced-icon-img"
                  width={30}
                  height={30}
                />
                <span style={{ fontSize: "20px" }}>{t("advanced")}</span>
              </div>
            </th>
          </tr>

          {/* <Col md={6}></Col>
        <Col>
          <ColumnHeading>{t("free_sm")}</ColumnHeading>
        </Col>
        <Col>
          <ColumnHeading>{t("essential")}</ColumnHeading>
          </Col>
          <Col>
          <ColumnHeading>{t("advanced")}</ColumnHeading>
        </Col> */}
        </thead>
        <tbody>
          <tr>
            <td>
              <HeadingData>{t("vehicle_and_user_mgmt")}</HeadingData>
            </td>
            <TD></TD>
            <TD></TD>
            <TD></TD>
          </tr>
          <tr>
            <td style={{ verticalAlign: "middle" }}>{t("no_of_vehicles")}</td>
            <TD>5</TD>
            <TD>
              <p style={{ fontWeight: 300 }}>{t("unlimited")}</p>
              <Desc>{`@${currencyCode} ${perVehiclePrice.essential} ${t(
                "per"
              )} ${t("vehicle_sm")}  ${t("per")} ${perDurationSmall}`}</Desc>
            </TD>
            <TD>
              <p style={{ fontWeight: 300 }}>{t("unlimited")}</p>
              <Desc>{`@${currencyCode} ${perVehiclePrice.advanced} ${t(
                "per"
              )} ${t("vehicle_sm")}  ${t("per")} ${perDurationSmall}`}</Desc>
            </TD>
          </tr>

          {/* {tableRowData.map((row) => {
          return row.heading ? (
            <tr>
              <HeadingData>{row.featureName}</HeadingData>
              <td></td>
              <td></td>
              <td></td>
              </tr>
              ) : (
                <tr>
                <td>{row.featureName}</td>
                <td>{row.free}</td>
                <td>{row.essential}</td>
                <td>{row.advanced}</td>
                </tr>
                );
              })} */}

          {tableRowData.map((row, i) => {
            return (
              <React.Fragment key={i}>
                {row.heading && (
                  <tr style={{ height: "34px" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  {row.heading ? (
                    <td>
                      <div
                        className={
                          row?.tooltip ? "d-flex align-items-center gap-2" : ""
                        }
                      >
                        <HeadingData>{row.featureName}</HeadingData>
                        {row?.tooltip && (
                          <Tooltip text={row.tooltip}>
                            <Icon className="fi fi-rr-info"></Icon>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div>
                        <p style={{ fontWeight: 300 }}>{row.featureName}</p>
                        {row?.subText && <Desc>{row?.subText}</Desc>}
                      </div>
                    </td>
                  )}
                  <TD>{row.free}</TD>
                  <TD>{row.essential}</TD>
                  <TD>{row.advanced}</TD>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        {/* <DataRow>
        <Col style={{ border: "1px solid lightgray" }}>
        <ColumnHeading>Vehicle & User Mgmt</ColumnHeading>
        </Col>
        </DataRow>
        <DataRow>
        <Col md={6} style={{ border: "1px solid lightgray" }}>
        <span>No of Vehicles</span>
        </Col>
        <Col style={{ border: "1px solid lightgray" }}>
          <span>5</span>
        </Col>
        <Col style={{ border: "1px solid lightgray" }}>
        <span>Unlimited</span>
        </Col>
        <Col style={{ border: "1px solid lightgray" }}>
          <span>Unlimited</span>
        </Col>
      </DataRow> */}
      </Table>
    </div>
  );
};

export default ComparePlansTable;

//styles

const PlansTable = styled.table`
  background: white;
  width: -webkit-fill-available;
`;

const HeadingData = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

const TD = styled.td`
  vertical-align: middle;
  text-align: center;
`;
