import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import MainWrapper from "../MainWrapper";
import NotFound404 from "../../DefaultPage/404/index";
import WrappedRoutes from "./WrappedRoutes";
import { auth } from "../../../api/firebase";
import { useSelector } from "react-redux";
import {
  getGroups,
  getUsers,
  getVehicles,
  postApi,
} from "../../../api/getUser";
import { updateUsersList } from "../../../features/users/usersSlice";
import { updateVehiclesList } from "../../../features/vehicles/vehiclesSlice";
import { updateGroupsList } from "../../../features/groups/groupsSlice";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../features/user/userSlice";
import { fetchServicesTasks } from "../../../features/servicesTasks/servicesTasksSlice";
import { updateUnits } from "../../../features/units/unitsSlice";
import Login from "../../../components/Login";
import { useAuth } from "../../../contexts/AuthContext";
import UnderMaintenanceComponent from "../../DefaultPage/UnderMaintenance";

const Router = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const vehiclesList = useSelector((state) => state.vehicles.list);
  const groupsList = useSelector((state) => state.groups.list);
  const usersList = useSelector((state) => state.users.list);

  const { isLoggedIn } = useAuth();

  // useEffect(() => {
  //   const token = sessionStorage.getItem("token");
  //   console.log("token", token);
  //   if (!token) {
  //     history.push("/login");
  //   }
  // }, []);

  // const [loggedIn, setLoggedIn] = useState(false);
  // useEffect(() => {
  //   console.log("Effect inside Router running");
  //   auth.onAuthStateChanged((user) => {
  //     console.log("onAuthStateChanged has ran");
  //     if (user) {
  //       //Get Token
  //       auth.currentUser
  //         .getIdToken()
  //         .then(function (idToken) {
  //           sessionStorage.setItem("token", idToken);
  //           //console.log("token from user obj", user.accessToken);
  //           console.log("idToken", idToken);
  //           //console.log(user.accessToken === idToken);
  //           //console.log("idToken", idToken === token);
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });

  //       console.log("user", user);
  //       const expirationTime = +user.stsTokenManager.expirationTime;
  //       sessionStorage.setItem("expirationTime", expirationTime);
  //       sessionStorage.setItem("email", user?.email);
  //       setLoggedIn(true);
  //     } else {
  //       setLoggedIn(false);
  //       history.push("/login");
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   if (loggedIn) {
  //     console.log("token inside login effect", sessionStorage.getItem("token"));
  //     //do loggedIn stuff
  //     console.log("usersList", usersList);
  //     // if (usersList?.length === 0) {
  //     (async () => {
  //       const usersList = await getUsers();
  //       console.log("usersList", usersList);
  //       dispatch(updateUsersList(usersList));
  //       //console.log('Groups List', groupsList);
  //     })();
  //     // }

  //     // if (vehiclesList?.length === 0) {
  //     console.log("vehicles api called from router");
  //     (async () => {
  //       const vehiclesList = await getVehicles("get_all_vehicle");
  //       dispatch(updateVehiclesList(vehiclesList));
  //       //console.log('Vehicles List', vehiclesList);
  //     })();
  //     // }

  //     // if (groupsList?.length === 0) {
  //     (async () => {
  //       const groupsList = await getGroups("get_all_groups_list");
  //       dispatch(updateGroupsList(groupsList));
  //       //console.log('Groups List', groupsList);
  //     })();
  //     // }
  //     //history.push("/issues");
  //     console.log("logged in");

  //     (async () => {
  //       const apiData = {
  //         querystring: "get_user_profile",
  //       };

  //       const response = await postApi(apiData, "commonNew");
  //       console.log("userData Response", response);
  //       const userData = response.user_data[0];
  //       dispatch(updateUser(userData));
  //     })();

  //     (async () => {
  //       const apiData = {
  //         querystring: "get_org_units",
  //       };

  //       const response = await postApi(apiData, "commonNew");
  //       console.log("unitsData Response", response);
  //       const unitsData = response.user_data[0];
  //       dispatch(updateUnits(unitsData));
  //     })();
  //   }
  // }, [loggedIn]);

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route path="/404" component={NotFound404} />
          <Route path="/login" component={Login} />
          <Route path="/" component={WrappedRoutes} />
          <Route path="*" component={NotFound404} />
          {/* <Route path="*" component={UnderMaintenanceComponent} /> */}
        </Switch>
        {/* <Redirect to="/dashboard" /> */}
      </main>
    </MainWrapper>
  );
};

export default Router;
