import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import TopbarLanguage from "../components/topbar/TopbarLanguage";
import TopbarMail from "../components/topbar/TopbarMail";
import TopbarProfile from "../components/topbar/TopbarProfile";
import TopbarNotification from "../components/topbar/TopbarNotification";
import TopbarSearch from "../components/topbar/TopbarSearch";
import TopbarSidebarButton from "../components/topbar/TopbarSidebarButton";
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from "../components/topbar/BasicTopbarComponents";
import TopbarOrganization from "../components/topbar/TopbarOrganization";
import {
  CustomLoader,
  FilterButton,
} from "../../../shared/components/TableElements";
import styled from "styled-components";
import {
  colorGray,
  colorLightGray,
  disabledBgColor,
  sidenavColor,
  sidenavLinkTextColor,
} from "../../../utils/palette";
import { postApi } from "../../../api/getUser";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import TopbarAnnouncements from "../components/topbar/TopbarAnnouncements";

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  // const [switchUIButtonLoading, setSwitchUIButtonLoading] = useState(false);

  // const handleSwitchToOldUI = async () => {
  //   try {
  //     setSwitchUIButtonLoading(true);
  //     const url =
  //       process.env.REACT_APP_ENV === "test"
  //         ? "https://web.simplyfleet.app/test/login.html"
  //         : "https://web.simplyfleet.app/app/login.html";
  //     if (process.env.REACT_APP_ENV === "prod") {
  //       // window.gtag("send", "event", "switch_old_ui", "Clicked");
  //       window.gtag("event", "switch_old_ui");
  //     }

  //     await postApi(
  //       { querystring: "send_swich_ui_email", source: "react" },
  //       "commonNew"
  //     );
  //     document.cookie = `uiPreference=old; path=/; domain=.simplyfleet.app`;
  //     setSwitchUIButtonLoading(false);
  //     window.location.href = url;
  //   } catch (e) {
  //     setSwitchUIButtonLoading(false);
  //     const url =
  //       process.env.REACT_APP_ENV === "test"
  //         ? "https://web.simplyfleet.app/test/login.html"
  //         : "https://web.simplyfleet.app/app/login.html";
  //     document.cookie = `uiPreference=old; path=/; domain=.simplyfleet.app`;
  //     window.location.href = url;
  //   }
  // };

  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarOrganization />
      </TopbarLeft>
      <TopbarRight>
        {/* <TopbarSearchWrap>
          <TopbarSearch />
        </TopbarSearchWrap> */}
        <TopbarRightOver>
          {/* <div className="d-flex justify-content-center align-items-center mx-3">
            <SwitchToOldUIButton
              onClick={handleSwitchToOldUI}
              disabled={switchUIButtonLoading}
            >
              {switchUIButtonLoading ? (
                <CustomLoader />
              ) : (
                <span>{t("switch_to_old_ui")}</span>
              )}
            </SwitchToOldUIButton>
          </div> */}
          <TopbarAnnouncements />
          <TopbarNotification />
          {/* <TopbarMail new /> */}
          <TopbarProfile />
          <TopbarLanguage />
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

//styles
const SwitchToOldUIButton = styled.button`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  color: ${sidenavLinkTextColor};
  border: 1px solid ${sidenavLinkTextColor};
  border-radius: 5px;
  padding: 0 10px;
  background: ${sidenavColor};
  height: 32px;
  &:hover {
    ${(p) => !p.disabled && `background: ${colorGray}`};
  }
`;
