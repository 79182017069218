import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Loading, { LoadIcon, LoadIconWrap } from "../../Loading";
import {
  colorBlue,
  colorLightGray,
  colorTableBorder,
} from "../../../../utils/palette";
import styled from "styled-components";
//import ReactTableDnDBody from "./ReactTableDnDBody";

const ReactTableDefaultBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  rightPanelData,
  setRightPanelData,
  screen,
}) => {
  const [selected, setSelected] = useState("");

  // const updatePreviewData = (row) => {
  //   if (rightPanelData.show === true) {
  //     if (row.id === selected) {
  //       setRightPanelData({
  //         show: false,
  //         content: "",
  //       });
  //     } else {
  //       setRightPanelData({
  //         show: true,
  //         content: row,
  //       });
  //     }
  //   } else {
  //     setRightPanelData({
  //       show: true,
  //       content: row,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   updatePreviewData(currentRow);
  // }, [selected, currentRow]);

  const handleRowClick = (e, row) => {
    //console.log(e.target.tagName);
    const { tagName } = e.target;
    if (
      tagName !== "IMG" &&
      tagName !== "svg" &&
      tagName !== "INPUT" &&
      tagName !== "path" &&
      tagName !== "SPAN" &&
      tagName !== "I" &&
      tagName !== "BUTTON" &&
      !screen.includes("inspectionforms") &&
      !screen.includes("inspectiontasks") &&
      !screen.includes("inspectionscheduling") &&
      !screen.includes("maintenancereminders") &&
      !screen.includes("renewals") &&
      !screen.includes("servicetasks") &&
      !screen.includes("expensetasks") &&
      !screen.includes("dailytimesheet")
    ) {
      setSelected(row.id);
      if (row.id === selected && rightPanelData.show === true) {
        setRightPanelData({
          show: false,
          content: "",
        });
      } else {
        setRightPanelData({
          show: true,
          content: row,
        });
      }
    }
  };

  return (
    <tbody {...getTableBodyProps()} id="data-table-body">
      {page?.map((row) => {
        prepareRow(row);
        return (
          <TableRow
            {...row.getRowProps()}
            onClickCapture={(e) => handleRowClick(e, row)}
            active={row.id === selected && rightPanelData.show}
          >
            {row.cells.map((cell) => {
              return (
                <td
                  style={{
                    color: "black",
                    //maxWidth: "300px",
                    fontWeight: "400",
                    borderLeft: cell.column.isBorderReqd
                      ? `1px solid ${colorTableBorder}`
                      : "none",
                    //paddingLeft: cell.column.isBorderReqd ? '32px' : '0'
                  }}
                  {...cell.getCellProps()}
                >
                  {cell.column.accessorId === "task_name" ||
                  cell.column.accessorId === "comments" ? (
                    <div
                      style={{
                        maxWidth: "400px",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        maxHeight: "8em",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {cell.render("Cell")}
                    </div>
                  ) : (
                    <div
                      style={{
                        maxWidth: cell?.column?.maxWidth || "300px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cell.render("Cell")}
                    </div>
                  )}
                </td>
              );
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
};

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page,
  getTableBodyProps,
  prepareRow,
  withDragAndDrop,
  updateDraggableData,
  rightPanelData,
  setRightPanelData,
  screen,
}) => {
  let message = "No Records";
  useEffect(() => {
    if (page?.length === 0) {
      setTimeout(() => (message = "No Records"), 0);
    }
  }, [page?.length]);

  return (
    <Fragment>
      <ReactTableDefaultBody
        page={page}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        rightPanelData={rightPanelData}
        setRightPanelData={setRightPanelData}
        screen={screen}
      />
    </Fragment>
  );
};

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
};

export default ReactTableBody;

export const PageLoading = ({ loading }) => (
  <PageLoad $loading={loading} className={`${loading ? "" : " loaded"}`}>
    <LoadIconWrap>
      <LoadIcon>
        <path fill={colorBlue} d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
      </LoadIcon>
    </LoadIconWrap>
  </PageLoad>
);

PageLoading.propTypes = {
  loading: PropTypes.bool,
};

PageLoading.defaultProps = {
  loading: false,
};

const PageLoad = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const TableRow = styled.tr`
  ${(p) => p.active && `background: ${colorLightGray}`};
`;
