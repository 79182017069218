import React from "react";
import { colorBg, colorLightGray } from "../../../utils/palette";
import styled from "styled-components";
import { useRef } from "react";
import { LinkAnchor, LinkSpan } from "../form/FormElements";
import { AddFormButton } from "../TableElements";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

const ImportFileUploader = ({
  importFile,
  setImportFile,
  mode,
  setMode,
  selectValues,
  setSelectValues,
  errMsg,
  setErrMsg,
  sampleFileName,
}) => {
  const { t } = useTranslation("common");
  //const hiddenFileInput = useRef(null);

  // const [dragActive, setDragActive] = React.useState(false);

  // const handleClick = (event) => {
  //   //console.log('click');
  //   if (hiddenFileInput && hiddenFileInput.current) {
  //     hiddenFileInput.current.click();
  //   }
  // };

  // const handleDrop = function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setDragActive(false);
  //   if (e.dataTransfer.files && e.dataTransfer.files[0]) {
  //     // at least one file has been dropped so do something
  //     // handleFiles(e.dataTransfer.files);
  //   }
  // };

  // handle drag events
  // const handleDrag = function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e.type === "dragenter" || e.type === "dragover") {
  //     setDragActive(true);
  //   } else if (e.type === "dragleave") {
  //     setDragActive(false);
  //   }
  // };

  // const handleChange = (event) => {
  //   event.preventDefault();
  //   const { files } = event.target;
  //   //console.log(event.target.files);
  //   const uploadedFile = files[0];
  //   const extension = uploadedFile.name.split(".").pop();
  //   console.log(uploadedFile);
  //   if (uploadedFile) {
  //     let columns = [];
  //     let firstRow = [];
  //     const fileReader = new FileReader();
  //     fileReader.readAsBinaryString(uploadedFile);

  //     fileReader.onload = (e) => {
  //       const data = e.target.result;
  //       if (data) {
  //         console.log("data", data);
  //         const arrData = data.split("\n");
  //         console.log(arrData);
  //         if (arrData.length >= 1) {
  //           columns = arrData[0]
  //             .split(",")
  //             .map((col) => col.replace(/[\r\n]+/gm, ""));

  //           // columns.forEach((column) => {
  //           //   for (let option of dailyMileageOptions) {
  //           //     if (option.possibleValues.includes(column)) {
  //           //       setSelectValues((prev) => ({ ...prev, [column]: option }));
  //           //     }
  //           //   }
  //           // });

  //           if (arrData.length >= 2) {
  //             firstRow = arrData[1].split("");
  //           }
  //         }
  //       }
  //       setImportFile({
  //         id: Date.now(),
  //         file: uploadedFile,
  //         url: URL.createObjectURL(uploadedFile),
  //         extension: extension,
  //         fileName: uploadedFile.name,
  //         columns: columns,
  //         firstRow: firstRow,
  //       });
  //     };

  //     // setImportFile({
  //     //   id: Date.now(),
  //     //   file: uploadedFile,
  //     //   url: URL.createObjectURL(uploadedFile),
  //     //   extension: extension,
  //     //   fileName: uploadedFile.name,
  //     //   columns: columns,
  //     //   firstRow: firstRow,
  //     // });
  //   }
  // };

  const onDrop = (files) => {
    const uploadedFile = files[0];
    const extension = uploadedFile.name.split(".").pop();
    //console.log(uploadedFile);
    if (uploadedFile) {
      let columns = [];
      let firstRow = [];
      let rows = [];
      const fileReader = new FileReader();
      fileReader.readAsText(uploadedFile);

      fileReader.onload = (e) => {
        //const data = e.target.result?.replace(/[\r]/gm, "");
        const data = e.target.result;
        if (data) {
          setErrMsg("");
          const arrData = data.split(/\r\n|\n|\r/);
          //console.log("arrData", arrData);
          if (arrData.length >= 1) {
            columns = arrData[0].split(",").map((col, i) => ({
              colIndex: i,
              colName: col.replace(/[\r\n'‘’"“”ï»¿]+/gm, "")?.trim(),
            }));

            // columns.forEach((column) => {
            //   for (let option of dailyMileageOptions) {
            //     if (option.possibleValues.includes(column)) {
            //       setSelectValues((prev) => ({ ...prev, [column]: option }));
            //     }
            //   }
            // });

            if (arrData.length >= 2) {
              firstRow = arrData[1].split(",");

              for (let i = 1; i < arrData.length; i++) {
                let row = arrData[i].split(",").map((val) => val.trim());
                if (row.some((val) => val)) {
                  rows.push(row);
                }
              }

              //console.log(rows);
            }

            // columns.forEach((column) => {
            //   setSelectValues((prev) => ({
            //     ...prev,
            //     [column.colIndex]: {
            //       value: "skip",
            //       label: "Skip",
            //       possibleValues: [],
            //     },
            //   }));
            // });
          }
          setImportFile({
            id: Date.now(),
            file: uploadedFile,
            url: URL.createObjectURL(uploadedFile),
            extension: extension,
            fileName: uploadedFile.name,
            columns: columns,
            firstRow: firstRow,
            rows: rows,
          });

          setMode(1);
        } else {
          setErrMsg(t("uploaded_file_empty_err_msg"));
          return;
        }
      };
    }
  };

  return (
    // <div className="d-flex flex-column gap-3">
    //   <FileUploadContainer
    //     onClick={handleClick}
    //     onDragEnter={handleDrag}
    //     onDrop={handleDrop}
    //   >
    //     <p>
    //       {importFile
    //         ? importFile.fileName
    //         : "Drag and drop files here or click here to browse"}
    //     </p>
    //     <input
    //       type="file"
    //       id="upload-import-file"
    //       name="upload-import-file"
    //       //accept="image/*"
    //       ref={hiddenFileInput}
    //       onChange={handleChange}
    //       accept=".csv"
    //       //onClick={handleChange}
    //       style={{ display: "none" }}
    //       //multiple
    //       disabled={false}
    //     />
    //     {dragActive && (
    //       <div
    //         id="drag-file-element"
    //         onDragEnter={handleDrag}
    //         onDragLeave={handleDrag}
    //         onDragOver={handleDrag}
    //         onDrop={handleDrop}
    //       ></div>
    //     )}
    //   </FileUploadContainer>
    //   <p>
    //     If you don’t have a CSV file, you can download a sample file{" "}
    //     <LinkAnchor
    //       style={{ fontSize: 14 }}
    //       href="https://web.simplyfleet.app/daily_mileage_import_sample.csv"
    //       target="_blank"
    //     >
    //       here
    //     </LinkAnchor>
    //     .
    //   </p>
    //   {/* <AddFormButton
    //     type="button"
    //     style={{ width: "min-content", alignSelf: "end" }}
    //     disabled={!importFile}
    //     onClick={() => setMode("column-mapping")}
    //   >
    //     Next
    //   </AddFormButton> */}
    // </div>

    // <form
    //   id="form-file-upload"
    //   onDragEnter={handleDrag}
    //   onSubmit={(e) => e.preventDefault()}
    // >
    //   <input
    //     ref={hiddenFileInput}
    //     type="file"
    //     id="input-file-upload"
    //     multiple={true}
    //     onChange={handleChange}
    //     style={{ display: "none" }}
    //   />
    //   <label
    //     id="label-file-upload"
    //     htmlFor="input-file-upload"
    //     className={dragActive ? "drag-active" : ""}
    //   >
    //     <FileUploadContainer>
    //       <p>Drag and drop your file here or Upload a file</p>
    //       {/* <button className="upload-button" onClick={handleClick}>
    //         Upload a file
    //       </button> */}
    //     </FileUploadContainer>
    //   </label>
    //   {dragActive && (
    //     <FileUploadContainer
    //       id="drag-file-element"
    //       onDragEnter={handleDrag}
    //       onDragLeave={handleDrag}
    //       onDragOver={handleDrag}
    //       onDrop={handleDrop}
    //     ></FileUploadContainer>
    //   )}
    // </form>
    <section className="d-flex flex-column gap-3">
      <p>
        {t("download_sample_file_msg")}{" "}
        <LinkAnchor
          style={{ fontSize: 14 }}
          // href={`https://web.simplyfleet.app/sample_import_files/${sampleFileName}.csv`}
          href={`https://s3.us-east-2.amazonaws.com/simplyfleet.app/sample_import_files/${sampleFileName}.csv`}
          target="_blank"
        >
          {t("here_sm")}
        </LinkAnchor>
        .
      </p>
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        accept={{ "text/csv": [".csv"] }} //text/*,
      >
        {({ getRootProps, getInputProps }) => (
          <FileUploadContainer {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />

            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              <i className="fi fi-rr-file-csv" style={{ fontSize: "45px" }}></i>

              {importFile && importFile.fileName ? (
                <p>{importFile.fileName}</p>
              ) : (
                <>
                  <p>{t("drag_and_drop_csv_file")}</p>
                  <LinkSpan style={{ fontSize: "14px" }}>
                    {t("or")} {t("click_to_select_file")}
                  </LinkSpan>
                </>
              )}
            </div>
          </FileUploadContainer>
        )}
      </Dropzone>
    </section>
  );
};

export default ImportFileUploader;

//styles
const FileUploadContainer = styled.div`
  border: 1px dashed ${colorLightGray};
  background: ${colorBg};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: ${window.innerHeight - 400}px;
  //cursor: pointer;
`;
