import React from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { Desc } from "../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import { Card, CardBody } from "../../shared/components/Card";
import { Toggle } from "../VehicleInspection/InspectionSchedule/InspectionScheduleForm";
import PlanCard from "./PlanCard";
import ComparePlansTable from "./ComparePlansTable";
import { FilterButton } from "../../shared/components/TableElements";
import styled from "styled-components";
import { colorBlueHover, colorLightGray } from "../../utils/palette";
import { useTranslation } from "react-i18next";
import {
  LinkAnchor,
  LinkSpan,
} from "../../shared/components/form/FormElements";
import { handleNegative } from "../../shared/helpers";

const NewPlan = ({
  priceDetailsData,
  totalPrice,
  perVehiclePrice,
  noOfVehicles,
  setNoOfVehicles,
  toggleValue,
  setToggleValue,
  subscriptionData,
}) => {
  const { t } = useTranslation("common");

  const toggleButtons = [
    {
      id: 0,
      label: t("monthly_caps"),
      value: "monthly",
    },
    {
      id: 1,
      label: t("yearly_caps"),
      value: "yearly",
    },
  ];

  const essentialFeatures = [
    {
      key: "preventive_maint_scheduling",
      label: t("preventive_maint_scheduling"),
      subFeatures: [],
    },
    {
      key: "manage_fuel_service_and_other_expenses",
      label: t("manage_fuel_service_and_other_expenses"),
      subFeatures: [],
    },
    {
      key: "digital_inspections",
      label: t("digital_inspections"),
      subFeatures: [],
    },
    // { key: "reporting", label: t("reporting"), subFeatures: [] },
    {
      key: "trip_mgmt",
      label: t("trip_mgmt"),
      // subFeatures: [
      //   { key: "telematics_integrations", label: t("telematics_integrations") },
      // ],
    },
    {
      key: "asset_and_user_mgmt",
      label: t("asset_and_user_mgmt"),
      subFeatures: [],
    },
  ];

  const advancedFeatures = [
    {
      key: "everything_in_essential",
      label: t("everything_in_essential"),
      subFeatures: [],
    },
    { key: "work_order_mgmt", label: t("work_order_mgmt"), subFeatures: [] },
    { key: "inventory_mgmt", label: t("inventory_mgmt"), subFeatures: [] },
    {
      key: "telematics_integrations",
      label: t("telematics_integrations"),
      tooltipText: t("view_available_ints_in_setup"),
    },
    { key: "priority_support", label: t("priority_support"), subFeatures: [] },
  ];

  const handleToggleChange = (e) => {
    setToggleValue(e.currentTarget.value);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col className="d-flex justify-content-center">
          <Card
            style={{ width: "fit-content" }}
            className="p-0"
            shadow={"true"}
          >
            <CardBody>
              <div>
                <div className="d-flex gap-2 align-items-center">
                  <p>{t("purchase_sub_for")} </p>
                  <NumberInput
                    value={noOfVehicles}
                    setValue={setNoOfVehicles}
                  />{" "}
                  <p>{t("vehicles_sm")}</p>
                </div>
                <Desc>{`${t("minimum")} 5 ${t("vehicles_sm")}`}</Desc>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center">
          <ButtonGroup>
            {toggleButtons.map((item) => (
              <Toggle
                className="m-0"
                active={toggleValue === item.value}
                disabled={false}
                key={item.id}
                id={`${item.value}-toggle-button`}
                type="radio"
                variant="light"
                name="radio"
                value={item.value}
                checked={toggleValue === item.value}
                onChange={handleToggleChange}
              >
                {item.label}
              </Toggle>
            ))}
          </ButtonGroup>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col className="d-flex justify-content-center" md={5}>
          <PlanCard
            heading={t("essential")}
            toggleValue={toggleValue}
            perVehiclePrice={perVehiclePrice.essential}
            totalPrice={totalPrice.essential}
            currency={priceDetailsData?.currency}
            currencyCode={priceDetailsData?.currency_code}
            features={essentialFeatures}
            product={"5"}
            noOfVehicles={noOfVehicles}
            subscriptionData={subscriptionData}
          />
        </Col>
        <Col className="d-flex justify-content-center" md={5}>
          <PlanCard
            heading={t("advanced")}
            toggleValue={toggleValue}
            perVehiclePrice={perVehiclePrice.advanced}
            totalPrice={totalPrice.advanced}
            currency={priceDetailsData?.currency}
            currencyCode={priceDetailsData?.currency_code}
            features={advancedFeatures}
            product={"6"}
            noOfVehicles={noOfVehicles}
            subscriptionData={subscriptionData}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center">
          <Card style={{ width: "fit-content" }} className="p-0" shadow="true">
            <CardBody>
              <div>
                {t("need_onboarding_and_migration_services")}{" "}
                <LinkAnchor
                  style={{ fontSize: 14 }}
                  href="https://www.simplyfleet.app/book-onboarding"
                >
                  {t("get_in_touch")}
                </LinkAnchor>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col>
          <h4>Compare Plans</h4>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <ComparePlansTable
            perVehiclePrice={perVehiclePrice}
            toggleValue={toggleValue}
            currency={priceDetailsData?.currency}
            currencyCode={priceDetailsData?.currency_code}
            noOfVehicles={noOfVehicles}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewPlan;

const NumberInput = ({ value, setValue }) => {
  const handlePlusMinus = (operation) => {
    if (operation === "+") {
      if (+value < 5) {
        setValue(5 + 1);
      } else {
        setValue(+value + 1);
      }
    } else if (operation === "-") {
      if (+value <= 5) {
        setValue(5);
      } else {
        setValue(+value - 1);
      }
    }
  };
  return (
    <div className="d-flex">
      <FilterButton
        style={{ minWidth: "min-content", borderRadius: "5px 0 0 5px" }}
        onClick={() => handlePlusMinus("-")}
        //className="d-flex justify-content-center align-items-center"
      >
        <i className="fi fi-rr-minus-small m-0 mt-1"></i>
      </FilterButton>
      <NumInput
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onWheel={(e) => e.target.blur()}
        min={5}
        onKeyDown={handleNegative}
        onBlur={() => {
          if (!value || value < 5) {
            setValue(5);
          }
        }}
      />
      <FilterButton
        style={{ minWidth: "min-content", borderRadius: "0 5px 5px 0" }}
        onClick={() => handlePlusMinus("+")}
      >
        <i className="fi fi-rr-plus-small m-0 mt-1"></i>
      </FilterButton>
    </div>
  );
};

//styles
const NumInput = styled.input`
  display: inline;
  width: 4rem;
  text-align: center;
  outline: none;
  border: 1px solid ${colorLightGray};
  &:focus {
    border-color: ${colorBlueHover};
  }
`;
