import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colorTextNew } from "@/utils/palette";
import { Button } from "@/shared/components/Button";
import CloseIcon from "mdi-react/CloseIcon";
import Alert from "@/shared/components/Alert";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { removeFilterDate } from "../../../../features/filter/filterSlice";
import {
  removeAdvancedFilter,
  clearAllAdvancedFilters,
} from "../../../../features/advancedFilter/advancedFilterSlice";
import { colorBlueHover, colorHover } from "../../../../utils/palette";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import {
  initialContainsData,
  initialDateData,
  initialfuelTypeData,
  initialRangeData,
  initialStatusData,
  initialTransactionTypeData,
} from "../../../constants/filterData";
import { Col, Container, Form, Row } from "react-bootstrap";
import { createFilter } from "react-select/dist/react-select.cjs.prod";
import { components } from "react-select";
import { useRef } from "react";
import { postApi, putApi } from "../../../../api/getUser";
import { selectStylesNew, statusStyles } from "../../../constants/styles";
import AdvancedFiltersNew from "./AdvancedFiltersNew";
import { FilterButton } from "../../TableElements";
import { ReactComponent as FilterIcon } from "@/shared/img/bars-filter.svg";
import {
  AppliedFilter,
  AppliedFiltersContainer,
  DeleteRowsContainer,
  RightSideContainer,
  SearchInput,
} from "./TempFilter";
import ColumnReorder from "./ColumnReorder";
import {
  convertToFilterDate,
  daysHoursMinutes,
  debounce,
  getMultiDeleteInfo,
  getScreenName,
} from "../../../helpers";
import MoreOptions from "./MoreOptions/MoreOptions";
import subDays from "date-fns/subDays";
import getUnixTime from "date-fns/getUnixTime";
import { useAuth } from "../../../../contexts/AuthContext";
import { TreeSelect } from "primereact/treeselect";
import ImportComponent from "../../ImportComponent";
import { DeletePopup } from "./DeletePopup";
import { NATEXT } from "../../../constants";
import InfoPopup from "../../InfoPopup";
import CombinedDateFilter from "./CombinedDateFilter";
import {
  clearMoreFilters,
  removeMoreFilters,
  updateExternalFilter,
} from "../../../../features/storedFilters/storedFiltersSlice";
import endOfToday from "date-fns/endOfToday";
import startOfToday from "date-fns/startOfToday";
import getDefaultFilterData from "../../../constants/getDefaultFilterData";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

const ReactTableFilterClient = ({
  rows,
  allColumns,
  valuesArray,
  initialData,
  selectedFlatRows,
  screen,
  dataLength,
  setLocalFilters,
  data,
  setData,
  columnOrder,
  setColumnOrder,
  hiddenColumns,
  setHiddenColumns,
  pageSize,
  setRightPanelData,
  gotoPage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [searchHasValue, setSearchHasValue] = useState(false);

  const [filterData, setFilterData] = useState(initialData);

  const [dateOption, setDateOption] = useState("all-time");
  const { minDate } = useSelector((state) => state.columns);

  const { roleId } = useSelector((state) => state.user);

  //For Inspection Scheduling
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [optionValues, setOptionValues] = useState(valuesArray);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const groupsList = useSelector((state) => state.groups.list);

  //const [vehicles, setVehicles] = useState([]);
  //const [groups, setGroups] = useState([]);

  //const [vehicle, setVehicle] = useState();
  //const [group, setGroup] = useState();

  //const [groupId, setGroupId] = useState(null);

  const rowData = useSelector((state) => state.row.data);

  const [statusArr, setStatusArr] = useState();

  const statusDataOptions = [
    { id: 0, value: "scheduled", label: t("scheduled") },
    { id: 1, value: "dueSoon", label: t("due_soon") },
    { id: 2, value: "overdue", label: t("overdue") },
  ];

  //Data for user management table
  const users = useSelector((state) => state.users.list);

  const serviceTasksList = useSelector((state) => state.servicesTasks.list);
  const expenseTasksList = useSelector((state) => state.expenseTasks.list);
  const inspectionTasksList = useSelector(
    (state) => state.inspectionTasks.list
  );

  const {
    reload,
    vehicle,
    setVehicle,
    groupId,
    setGroupId,
    vehicles,
    setVehicles,
    groups,
  } = useAuth();

  const [userFilters, setUserFilters] = useState([]);
  const [name, setName] = useState("");
  const [manager, setManager] = useState("");
  const userStatusOptions = [
    {
      value: "all",
      label: t("all_users"),
    },
    {
      value: 1,
      label: t("active"),
    },
    {
      value: 0,
      label: t("inactive"),
    },
  ];

  const userRoleOptions = [
    {
      value: 1,
      label: t("admin"),
    },
    {
      value: 2,
      label: t("manager"),
    },
    {
      value: 3,
      label: t("operator"),
    },
    {
      value: 4,
      label: t("technician"),
    },
  ];
  const [userStatus, setUserStatus] = useState(userStatusOptions[0]);
  const [userRole, setUserRole] = useState([]);

  //Data for Vehicles Filters
  const [vehicleFilters, setVehicleFilters] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);
  const [vehicleStatus, setVehicleStatus] = useState([]);

  const [showImportPopup, setShowImportPopup] = useState(false);

  //These two states are for Info Popup which is shown in case record cannot be deleted
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoData, setInfoData] = useState({});

  // const vehicleTypeOptions = useRef([
  //   { label: t("veh_type_value_truck"), value: t("veh_type_value_truck") },
  //   { label: t("veh_type_value_car"), value: t("veh_type_value_car") },
  //   { label: t("veh_type_value_pickup"), value: t("veh_type_value_pickup") },
  //   { label: t("veh_type_value_bus"), value: t("veh_type_value_bus") },
  //   { label: t("veh_type_value_trailer"), value: t("veh_type_value_trailer") },
  //   { label: t("veh_type_value_van"), value: t("veh_type_value_van") },
  //   { label: t("veh_type_value_tow"), value: t("veh_type_value_tow") },
  //   {
  //     label: t("veh_type_value_motorcycle"),
  //     value: t("veh_type_value_motorcycle"),
  //   },
  //   {
  //     label: t("veh_type_value_generator"),
  //     value: t("veh_type_value_generator"),
  //   },
  //   { label: t("veh_type_value_mover"), value: t("veh_type_value_mover") },
  //   { label: t("veh_type_value_loader"), value: t("veh_type_value_loader") },
  //   { label: t("veh_type_value_mixer"), value: t("veh_type_value_mixer") },
  // ]);

  //Active-1, Inactive-0
  const vehicleStatusOptions = [
    {
      label: t("active"),
      value: "active",
    },
    {
      label: t("inactive"),
      value: "inactive",
    },
    {
      label: t("in_service"),
      value: "inService",
    },
    {
      label: t("out_of_service"),
      value: "outOfService",
    },
    {
      label: t("unconfirmed"),
      value: "unconfirmed",
    },
  ];

  // const [dateFilterData, setDateFilterData] = useState({
  //   warningText: `${t("in")} ${t("the")} ${t("last_7_days_sm")}`,
  //   filterText: t("last_7_days"),
  //   dateOption: "last-7-days",
  //   from: new Date(),
  //   to: null,
  // });
  //const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  //const extDateFilterRef = useRef(null);

  const [inspectionScheduleFilters, setInspectionScheduleFilters] = useState(
    []
  );

  const inspectionScheduleRowData = useSelector((state) => state.row.data);

  const [multiDeleteApiData, setMultiDeleteApiData] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const storedFilters = useSelector((state) => state.storedFilters);
  const [filterBySearch, setFilterBySearch] = useState("");

  const [externalDatePreset, setExternalDatePreset] = useState({
    filterText: t("last_7_days"),
    from: subDays(startOfToday(), 6),
    to: endOfToday(), // new Date(),
    dateOption: "last-7-days",
  });

  //Service tasks filter
  useEffect(() => {
    if (screen.includes("servicetasks") && serviceTasksList?.length > 0) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];
      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      let temp = [...serviceTasksList];
      if (searchFilter && searchFilter?.value) {
        let key = "service_task_name";
        setFilterBySearch(searchFilter?.value);
        setSearchHasValue(true);
        temp = temp.filter((row) =>
          row[key]
            ?.toLocaleLowerCase()
            .includes(searchFilter?.value?.toLocaleLowerCase())
        );
      } else {
        setSearchHasValue(false);
      }
      setData(temp);
    }
  }, [storedFilters, screen, serviceTasksList]);

  //Expense Tasks Filter
  useEffect(() => {
    if (screen.includes("expensetasks") && expenseTasksList?.length > 0) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];
      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      let temp = [...expenseTasksList];
      if (searchFilter && searchFilter?.value) {
        let key = "expense_task_name";
        setFilterBySearch(searchFilter?.value);
        setSearchHasValue(true);
        temp = temp.filter((row) =>
          row[key]
            ?.toLocaleLowerCase()
            .includes(searchFilter?.value?.toLocaleLowerCase())
        );
      } else {
        setSearchHasValue(false);
      }
      setData(temp);
    }
  }, [storedFilters, screen, expenseTasksList]);

  //DailyTS filter
  useEffect(() => {
    if (screen.includes("dailytimesheet")) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];
      let dailyTSFilters = [];
      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      if (searchFilter && searchFilter?.value) {
        setFilterBySearch(searchFilter?.value);
        setSearchHasValue(true);
        dailyTSFilters.push({
          type: "search",
          id: "search",
          data: searchFilter?.value,
        });
      } else {
        setSearchHasValue(false);
      }

      const dateFilter = currentScreenFilters.find(
        (f) => f?.filterName === "date"
      );

      if (dateFilter) {
        const { filterText, fromUnix, toUnix, dateOption } = dateFilter?.value;
        const fromDate = fromUnix
          ? new Date(fromUnix * 1000)
          : subDays(new Date(), 6);
        const toDate = toUnix ? new Date(toUnix * 1000) : new Date();
        setExternalDatePreset({
          filterText: filterText ? filterText : t("last_7_days"),
          from: startOfDay(fromDate),
          to: endOfDay(toDate),
          dateOption: dateOption ? dateOption : "last-7-days",
        });

        dailyTSFilters.push({
          type: "date",
          data: { startDate: fromUnix, endDate: toUnix },
        });
      } else {
        dailyTSFilters.push({
          type: "date",
          data: {
            startDate: getUnixTime(subDays(startOfToday(), 6)),
            endDate: getUnixTime(endOfToday()),
          },
        });
      }

      applyDailyTSFilters(dailyTSFilters);
    }
  }, [storedFilters, screen, rowData]);

  //Inspection Tasks Filter
  useEffect(() => {
    if (screen.includes("inspectiontasks") && inspectionTasksList?.length > 0) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];
      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      let temp = [...inspectionTasksList];
      if (searchFilter && searchFilter?.value) {
        let key = "task_name";
        setFilterBySearch(searchFilter?.value);
        setSearchHasValue(true);
        temp = temp.filter((row) =>
          row[key]
            ?.toLocaleLowerCase()
            .includes(searchFilter?.value?.toLocaleLowerCase())
        );
      } else {
        setSearchHasValue(false);
      }
      setData(temp);
    }
  }, [storedFilters, screen, inspectionTasksList]);

  //Inspection Forms Filter
  useEffect(() => {
    if (screen.includes("inspectionforms") && rowData) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];
      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      let temp = [...rowData];
      if (searchFilter && searchFilter?.value) {
        setFilterBySearch(searchFilter?.value);
        setSearchHasValue(true);
        let val = searchFilter?.value?.toLocaleLowerCase();
        temp = temp.filter(
          (row) =>
            row.form_name?.toLocaleLowerCase().includes(val) ||
            row.description?.toLocaleLowerCase().includes(val)
        );
      } else {
        setSearchHasValue(false);
      }
      setData(temp);
    }
  }, [storedFilters, screen, rowData]);

  //Inspection Schdedule Filters
  useEffect(() => {
    if (screen.includes("inspectionscheduling") && rowData) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];

      let scheduleFilters = [];
      if (vehicle && vehicle?.vehicleId) {
        scheduleFilters.push({
          type: "vehicle",
          id: "vehicle",
          data: vehicle,
        });
      }

      if (groupId && groupId !== "all-groups") {
        const selectedGroup = groupsList.find((g) => g.group_id === groupId);
        const temp = vehiclesList.filter((singleVehicle) => {
          if (
            singleVehicle?.id === 0 ||
            groupId === singleVehicle?.groupId ||
            (selectedGroup?.groupNodes &&
              selectedGroup.groupNodes.includes(singleVehicle?.groupId))
          ) {
            return singleVehicle;
          }
        });

        const vehicleIds = temp
          .map((vehicle) => vehicle.vehicleId)
          .filter((id) => id);

        scheduleFilters.push({
          type: "group",
          id: "group",
          data: vehicleIds,
        });
      }

      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      if (searchFilter && searchFilter?.value) {
        setFilterBySearch(searchFilter?.value);
        // if (searchFilter?.value) {
        setSearchHasValue(true);
        scheduleFilters.push({
          type: "search",
          id: "search",
          data: searchFilter?.value,
        });
        // }
      } else {
        setSearchHasValue(false);
      }

      const statusFilter = currentScreenFilters.find(
        (f) => f?.filterName === "status"
      );
      if (statusFilter && statusFilter?.value) {
        setStatusArr(statusFilter?.value);
        scheduleFilters.push({
          type: "status",
          id: "status",
          data: statusFilter?.value,
        });
      }
      if (currentScreenFilters && currentScreenFilters?.length > 0) {
        const moreFilters = currentScreenFilters.filter((f) => f?.moreFilters);
        //const moreFilters = filters.services.filter((f) => f?.moreFilters);
        for (let filter of moreFilters) {
          scheduleFilters.push({
            id: filter.column,
            type: filter.column,
            data: filter,
          });
        }
        // moreFilters.forEach((filter) => {
        //   scheduleFilters.push({
        //     id: filter.column,
        //     type: filter.column,
        //     data: filter,
        //   });
        // });
        setAppliedFilters(moreFilters);
      }
      // setTimeout(() => {
      applyInspectionScheduleFilters(scheduleFilters);
      // }, 500);
      // setTimeout(() => {
      //setInspectionScheduleFilters(scheduleFilters);
      // }, 500);
    }
  }, [screen, storedFilters, vehicle, groupId, rowData]);

  //Vehicles filters
  useEffect(() => {
    if (screen.includes("vehicles") && rowData) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];

      let vehicleFilters = [];
      if (vehicle && vehicle?.vehicleId) {
        vehicleFilters.push({
          type: "vehicle",
          id: "vehicle",
          data: vehicle,
        });
      }

      if (groupId && groupId !== "all-groups") {
        const selectedGroup = groupsList.find((g) => g.group_id === groupId);
        const temp = vehiclesList.filter((singleVehicle) => {
          if (
            singleVehicle?.id === 0 ||
            groupId === singleVehicle?.groupId ||
            (selectedGroup?.groupNodes &&
              selectedGroup.groupNodes.includes(singleVehicle?.groupId))
          ) {
            return singleVehicle;
          }
        });

        const vehicleIds = temp.map((vehicle) => vehicle.vehicleId);
        vehicleFilters.push({
          type: "group",
          id: "group",
          data: vehicleIds,
        });
      }

      const searchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "search"
      );

      if (searchFilter && searchFilter?.value) {
        setFilterBySearch(searchFilter?.value);
        // if (searchFilter?.value) {
        setSearchHasValue(true);
        vehicleFilters.push({
          type: "search",
          id: "search",
          data: searchFilter?.value,
        });
        // }
      } else {
        setSearchHasValue(false);
      }

      const statusFilter = currentScreenFilters.find(
        (f) => f?.filterName === "status"
      );
      if (statusFilter && statusFilter?.value) {
        setVehicleStatus(statusFilter?.value);
        vehicleFilters.push({
          type: "status",
          id: "status",
          data: statusFilter?.value,
        });
      }

      // const typeFilter = currentScreenFilters.find(
      //   (f) => f?.filterName === "type"
      // );
      // if (typeFilter && typeFilter?.value) {
      //   setVehicleType(typeFilter?.value);
      //   vehicleFilters.push({
      //     type: "type",
      //     id: "type",
      //     data: typeFilter?.value,
      //   });
      // }

      if (currentScreenFilters && currentScreenFilters?.length > 0) {
        const moreFilters = currentScreenFilters.filter((f) => f?.moreFilters);
        for (let filter of moreFilters) {
          vehicleFilters.push({
            id: filter.column,
            type: filter.column,
            data: filter,
          });
        }

        setAppliedFilters(moreFilters);
      }

      applyVehicleFilters(vehicleFilters);
    }
  }, [screen, storedFilters, vehicle, groupId, rowData]);

  //Users filters
  useEffect(() => {
    if (screen.includes("users") && users) {
      const screenName = getScreenName(screen);
      const currentScreenFilters = storedFilters[screenName];

      let userFilters = [];

      const userNameSearchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "name"
      );

      if (userNameSearchFilter && userNameSearchFilter?.value) {
        if (!name) {
          setName(userNameSearchFilter?.value);
        }
        userFilters.push({
          type: "name",
          id: "name",
          data: userNameSearchFilter?.value,
        });
      } else {
        //setSearchHasValue(false);
      }

      const managerNameSearchFilter = currentScreenFilters?.find(
        (f) => f.filterName === "manager"
      );

      if (managerNameSearchFilter && managerNameSearchFilter?.value) {
        if (!manager) {
          setManager(managerNameSearchFilter?.value);
        }
        userFilters.push({
          type: "manager",
          id: "manager",
          data: managerNameSearchFilter?.value,
        });
      } else {
        //setSearchHasValue(false);
      }

      const rolesFilter = currentScreenFilters.find(
        (f) => f?.filterName === "userRole"
      );
      if (rolesFilter && rolesFilter?.value) {
        setUserRole(rolesFilter?.value);
        userFilters.push({
          type: "userRole",
          id: "userRole",
          data: rolesFilter?.value,
        });
      }

      const statusFilter = currentScreenFilters.find(
        (f) => f?.filterName === "status"
      );
      if (statusFilter && statusFilter?.value) {
        const userStatus = userStatusOptions.find(
          (u) => u.value === statusFilter?.value?.value
        );
        setUserStatus(userStatus);
        userFilters.push({
          type: "status",
          id: "status",
          data: statusFilter?.value,
        });
      }

      applyUserFilters(userFilters);
    }
  }, [screen, storedFilters, vehicle, groupId, users]);

  //Fault Code Filters
  useEffect(() => {
    if (screen.includes("faultcodes")) {
      const faultCodesFilters = storedFilters.faultcodes;
      let filters = [];

      if (rowData?.length > 0) {
        //Search
        const searchFilter = faultCodesFilters?.find(
          (f) => f.filterName === "search"
        );
        if (searchFilter && searchFilter?.value) {
          setFilterBySearch(searchFilter?.value);
          // if (searchFilter?.value) {
          setSearchHasValue(true);
          filters.push({
            type: "search",
            id: "search",
            data: searchFilter?.value,
          });
          // }
        } else {
          setSearchHasValue(false);
        }

        //Vehicle
        if (vehicle && vehicle?.vehicleId) {
          filters.push({
            type: "vehicle",
            id: "vehicle",
            data: vehicle,
          });
        }

        //Group
        if (groupId && groupId !== "all-groups") {
          const selectedGroup = groupsList.find((g) => g.group_id === groupId);
          const temp = vehiclesList.filter((singleVehicle) => {
            if (
              singleVehicle?.id === 0 ||
              groupId === singleVehicle?.groupId ||
              (selectedGroup?.groupNodes &&
                selectedGroup.groupNodes.includes(singleVehicle?.groupId))
            ) {
              return singleVehicle;
            }
          });
          const vehicleIds = temp
            .map((vehicle) => vehicle.veh_id)
            .filter((id) => id);
          filters.push({
            type: "group",
            id: "group",
            data: vehicleIds,
          });
        }
      }

      applyFaultCodesFilters(filters);
    }
  }, [screen, storedFilters, vehicle, groupId, rowData]);

  //Close External Date Filter when clicked outside
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!extDateFilterRef?.current?.contains(event.target)) {
  //       setIsDateFilterOpen(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  // }, [extDateFilterRef]);

  // useEffect(() => {
  //   if (screen.includes("vehicles")) {
  //     (async () => {
  //       const res = await postApi(
  //         { querystring: "get_vehicle_types" },
  //         "commonNew"
  //       );
  //       if (res?.success) {
  //         const { user_data } = res;
  //         // if (user_data.length > 0) {
  //         //   dispatch(updateVehicleTypes(user_data));
  //         // }
  //         user_data.forEach((obj) =>
  //           vehicleTypeOptions.current.push({
  //             label: obj.veh_type,
  //             value: obj.veh_type,
  //           })
  //         );
  //       }
  //     })();
  //   }
  // }, []);

  //Close right panel everytime data changes
  useEffect(() => {
    setRightPanelData({ show: false, content: "" });
    gotoPage(0);
  }, [reload, storedFilters, vehicle, groupId]);

  const applyInspectionScheduleFilters = (filters) => {
    let temp = [...inspectionScheduleRowData];
    if (filters.length === 0) {
      //setData(inspectionScheduleRowData); // Set data to all rows when no filters are applied
    } else {
      //setData(inspectionScheduleRowData); //Initially, set data to all existing rows before applying filters one by one
      filters.forEach((filter) => {
        if (filter.type === "search") {
          const data = filter?.data?.toLocaleLowerCase();
          temp = temp.filter(
            (row) =>
              row.name?.toLocaleLowerCase().includes(data) ||
              row.form_name?.toLocaleLowerCase().includes(data)
          );
        }
        if (filter.type === "group") {
          temp = temp.filter((row) => filter.data.includes(row?.veh_id)); //filter.data = vehicleIds
        }
        if (filter.type === "vehicle") {
          temp = temp.filter(
            (row) =>
              row.veh_id === filter.data?.vehicleId ||
              filter.data?.vehicleId === "" //filter.data = vehicle
          );
        }
        if (filter.type === "formName") {
          if (filter.data.radioValue === "is") {
            temp = temp.filter(
              (row) =>
                row[filter.data.accessor]?.toLocaleLowerCase() ===
                filter.data.input_1?.toLocaleLowerCase()
            );
          } else if (filter.data.radioValue === "is-not") {
            temp = temp.filter(
              (row) =>
                row[filter.data.accessor]?.toLocaleLowerCase() !==
                filter.data.input_1?.toLocaleLowerCase()
            );
          } else if (filter.data.radioValue === "contains") {
            temp = temp.filter((row) =>
              row[filter.data.accessor]
                ?.toLocaleLowerCase()
                .includes(filter.data.input_1?.toLocaleLowerCase())
            );
          }
        }
        if (
          filter.type === "daysRemaining" ||
          filter.type === "odoRemaining" ||
          filter.type === "lastInspectionOdo" ||
          filter.type === "dueOdo"
        ) {
          const { radioValue, input_1, input_2, accessor } = filter.data;
          if (radioValue === "greater-than") {
            temp = temp.filter((row) => row[accessor] >= +input_1);
          } else if (radioValue === "less-than") {
            temp = temp.filter((row) => row[accessor] <= +input_1);
          } else if (radioValue === "equal-to") {
            temp = temp.filter((row) => row[accessor] === +input_1);
          } else if (radioValue === "between") {
            temp = temp.filter(
              (row) => row[accessor] >= +input_1 && row[accessor] <= +input_2
            );
          }
        }
        if (filter.type === "lastInspectionDate" || filter.type === "dueDate") {
          const { from, to, column, name, dateOption, accessor } = filter.data;
          temp = temp.filter((row) => {
            return (
              row[accessor] >= from &&
              row[accessor] <= to &&
              row[accessor] !== NATEXT
            );
          });
        }
        if (filter.type === "status") {
          if (filter?.data?.length > 0) {
            let total = [];
            let filtered = [];
            filter.data?.forEach((f) => {
              if (f.value === "scheduled") {
                filtered = temp.filter((row) => row.percentage <= 90);
                total.push(...filtered);
                filtered = [];
              }
              if (f.value === "dueSoon") {
                filtered = temp.filter(
                  (row) => row.percentage > 90 && row.percentage <= 99
                );
                total.push(...filtered);
                filtered = [];
              }
              if (f.value === "overdue") {
                filtered = temp.filter((row) => row.percentage >= 100);
                total.push(...filtered);
                filtered = [];
              }
            });
            temp = [...total];
          }
        }
      });
    }
    setData(temp);
  };

  //Update Vehicle KPIs
  useEffect(() => {
    if (screen.includes("vehicles")) {
      const rowData = rows.map((r) => r.original);
      setOptionValues((prev) => {
        let copy = [...prev];
        let activeVehicles = rowData.filter(
          (v) =>
            v.veh_active == 1 || (v.veh_active == 0 && v.secondary_status == 1)
        );
        let downtimeInSeconds = rowData
          .map((r) => +r.down_time)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
        let downtime = daysHoursMinutes(downtimeInSeconds).display;
        copy = [
          { ...copy[0], value: activeVehicles?.length },
          { ...copy[1], value: downtime },
        ];

        return copy;
      });
    }
  }, [data]); //Using rows instead of data as data is not updating when search filter is applied

  const applyUserFilters = (filters) => {
    if (filters.length === 0) {
      setData(users); // Set data to all rows when no filters are applied
    } else {
      setData(users); //Initially, set data to all existing rows before applying filters one by one
      filters.forEach((filter) => {
        if (filter.type === "name") {
          setData(
            (prev) =>
              prev.filter((row) =>
                row.name
                  ?.toLocaleLowerCase()
                  .includes(filter.data?.toLocaleLowerCase())
              ) //filter.data = vehicleIds
          );
        }

        if (filter.type === "manager") {
          setData(
            (prev) =>
              prev.filter((row) =>
                row.manager_name
                  ?.toLocaleLowerCase()
                  .includes(filter.data?.toLocaleLowerCase())
              ) //filter.data = vehicleIds
          );
        }

        if (filter.type === "status") {
          if (filter.data.value === 0 || filter.data.value === 1) {
            setData(
              (prev) =>
                prev.filter((row) => row.user_active === filter.data.value) //filter.data = vehicleIds
            );
          }
        }

        if (filter.type === "userRole") {
          if (filter?.data?.length > 0) {
            const selectedRoles = filter.data.map((f) => f.value);
            setData(
              (prev) =>
                prev.filter((row) => selectedRoles.includes(row.role_id)) //filter.data = vehicleIds
            );
          }
        }
      });
    }
  };

  const applyVehicleFilters = (filters) => {
    let temp = vehiclesList?.slice(1, vehiclesList.length);
    if (filters.length === 0) {
      //setData(vehiclesList?.slice(1, vehiclesList.length)); // Set data to all rows when no filters are applied
      temp = vehiclesList?.slice(1, vehiclesList.length);
    } else {
      //setData(vehiclesList?.slice(1, vehiclesList.length));
      temp = vehiclesList?.slice(1, vehiclesList.length);
      filters?.forEach((filter) => {
        if (filter.type === "search") {
          const data = filter?.data?.toLocaleLowerCase();

          temp = temp.filter(
            (row) =>
              row.name?.toLocaleLowerCase().includes(data) ||
              row.make?.toLocaleLowerCase().includes(data) ||
              row.model?.toLocaleLowerCase().includes(data) ||
              row.vin_no?.toLocaleLowerCase().includes(data) ||
              row.license_no?.toLocaleLowerCase().includes(data) ||
              row.veh_type?.toLocaleLowerCase().includes(data) ||
              row.primary_meter?.toLocaleLowerCase().includes(data)
            //||
            //row.group_name?.toLocaleLowerCase().includes(data)
          );

          // const group = groupsList.find(g => g.group_id === )
        }
        if (filter.type === "group") {
          temp = temp.filter((row) => filter.data.includes(row?.veh_id));
        }
        if (filter.type === "vehicle") {
          temp = temp.filter(
            (row) =>
              row.veh_id === filter.data?.vehicleId ||
              filter.data?.vehicleId === ""
          );
        }

        // if (filter.type === "type") {
        //   if (filter?.data?.length > 0) {
        //     const selectedRoles = filter.data.map((f) => f.value);
        //     temp = temp.filter((row) => selectedRoles.includes(row.veh_type)); //filter.data = vehicleIds
        //   }
        // }

        if (filter.type === "status") {
          if (filter?.data?.length > 0) {
            const selectedStatuses = filter.data.map((s) => s.value);
            let filteredData = [];
            temp.forEach((row) => {
              let filteredDataIds = filteredData.map((row) => row.veh_id);
              if (selectedStatuses.includes("active")) {
                if (
                  (row.veh_active == 1 ||
                    (row.veh_active == 0 && row.secondary_status == 1)) &&
                  !filteredDataIds.includes(row.veh_id)
                ) {
                  filteredData.push(row);
                  filteredDataIds.push(row.veh_id);
                }
              }
              if (selectedStatuses.includes("inactive")) {
                if (
                  ((row.veh_active == 0 && row.secondary_status == 0) ||
                    (row.veh_active == 2 && row.secondary_status == 0)) &&
                  !filteredDataIds.includes(row.veh_id)
                ) {
                  filteredData.push(row);
                  filteredDataIds.push(row.veh_id);
                }
              }

              if (selectedStatuses.includes("outOfService")) {
                if (
                  row.veh_active == 0 &&
                  row.secondary_status == 1 &&
                  !filteredDataIds.includes(row.veh_id)
                ) {
                  filteredData.push(row);
                  filteredDataIds.push(row.veh_id);
                }
              }

              if (selectedStatuses.includes("inService")) {
                if (
                  row.veh_active == 1 &&
                  !filteredDataIds.includes(row.veh_id)
                ) {
                  filteredData.push(row);
                  filteredDataIds.push(row.veh_id);
                }
              }
              if (selectedStatuses.includes("unconfirmed")) {
                if (
                  row.veh_active == 0 &&
                  row.secondary_status == 2 &&
                  !filteredDataIds.includes(row.veh_id)
                ) {
                  filteredData.push(row);
                  filteredDataIds.push(row.veh_id);
                }
              }
            });
            temp = [...filteredData];
          }
        }

        //More Filters
        //Contains Filters
        if (
          filter.type === "make" ||
          filter.type === "model" ||
          filter.type === "vehicleType"
        ) {
          if (filter.data.radioValue === "is") {
            temp = temp.filter(
              (row) =>
                row[filter.data.accessor]?.toLocaleLowerCase() ===
                filter.data.input_1?.toLocaleLowerCase()
            );
          } else if (filter.data.radioValue === "is-not") {
            temp = temp.filter(
              (row) =>
                row[filter.data.accessor]?.toLocaleLowerCase() !==
                filter.data.input_1?.toLocaleLowerCase()
            );
          } else if (filter.data.radioValue === "contains") {
            temp = temp.filter((row) =>
              row[filter.data.accessor]
                ?.toLocaleLowerCase()
                .includes(filter.data.input_1?.toLocaleLowerCase())
            );
          }
        }

        //includes for is and includes and !includes for is-not since is and is-not filters are
        //not possible
        if (filter.type === "customSpecs" || filter.type === "docDetails") {
          if (filter.data.radioValue === "is") {
            temp = temp.filter((row) =>
              row[filter.data.accessor]
                ?.toLocaleLowerCase()
                .includes(filter.data.input_1?.toLocaleLowerCase())
            );
          } else if (filter.data.radioValue === "is-not") {
            temp = temp.filter(
              (row) =>
                !row[filter.data.accessor]
                  ?.toLocaleLowerCase()
                  .includes(filter.data.input_1?.toLocaleLowerCase())
            );
          } else if (filter.data.radioValue === "contains") {
            temp = temp.filter((row) =>
              row[filter.data.accessor]
                ?.toLocaleLowerCase()
                .includes(filter.data.input_1?.toLocaleLowerCase())
            );
          }
        }

        //Range Filters
        if (filter.type === "currentOdo") {
          const { radioValue, input_1, input_2, accessor } = filter.data;
          if (radioValue === "greater-than") {
            temp = temp.filter((row) => row[accessor] >= +input_1);
          } else if (radioValue === "less-than") {
            temp = temp.filter((row) => row[accessor] <= +input_1);
          } else if (radioValue === "equal-to") {
            temp = temp.filter((row) => row[accessor] === +input_1);
          } else if (radioValue === "between") {
            temp = temp.filter(
              (row) => row[accessor] >= +input_1 && row[accessor] <= +input_2
            );
          }
        }

        //Checkbox filters
        if (filter.type === "fuelType") {
          let total = [];
          let filtered = [];

          if (filter.data.diesel.value) {
            filtered = temp.filter((row) => row.fuel_type === "Diesel");
            total.push(...filtered);
            filtered = [];
          }
          if (filter.data.petrol.value) {
            filtered = temp.filter((row) => row.fuel_type === "Petrol");
            total.push(...filtered);
            filtered = [];
          }

          temp = [...total];
        }

        if (filter.type === "primaryMeter") {
          let total = [];
          let filtered = [];

          if (filter.data.miles.value) {
            filtered = temp.filter((row) => row.primary_meter === "Miles");
            total.push(...filtered);
            filtered = [];
          }
          if (filter.data.kilometers.value) {
            filtered = temp.filter((row) => row.primary_meter === "Kilometers");
            total.push(...filtered);
            filtered = [];
          }
          if (filter.data.hours.value) {
            filtered = temp.filter((row) => row.primary_meter === "Hours");
            total.push(...filtered);
            filtered = [];
          }

          temp = [...total];
        }
      });
    }
    setData(temp);
  };

  const applyDailyTSFilters = (filters) => {
    let tempRowData = [...rowData];
    if (filters.length === 0) {
      tempRowData = [...rowData];
      //setData(rowData); // Set data to all rows when no filters are applied
      //setData(rowData);
    } else {
      tempRowData = [...rowData];
      //setData(rowData);
      //setData(rowData);
      for (let filter of filters) {
        if (filter.type === "search") {
          let data = filter?.data?.toLocaleLowerCase();
          tempRowData = tempRowData.filter(
            (row) =>
              row.name?.toLocaleLowerCase().includes(data) ||
              row.veh_name?.toLocaleLowerCase().includes(data)
          );
        }
        if (filter.type === "date") {
          const { startDate, endDate } = filter?.data;
          tempRowData = tempRowData.filter(
            (row) =>
              row.timesheet_date >= startDate && row.timesheet_date <= endDate
          );
        }
      }
    }
    setData(tempRowData);
  };

  const applyFaultCodesFilters = (filters) => {
    let temp = [...rowData];
    if (filters.length === 0) {
      temp = [...rowData];
    } else {
      temp = [...rowData];
      filters?.forEach((filter) => {
        if (filter.type === "search") {
          const data = filter?.data?.toLocaleLowerCase();

          temp = temp.filter(
            (row) =>
              row.name?.toLocaleLowerCase().includes(data) ||
              row.faultCodes?.toLocaleLowerCase().includes(data) ||
              row.faultDescs?.toLocaleLowerCase().includes(data)
          );
        }
        if (filter.type === "group") {
          temp = temp.filter((row) => filter.data.includes(row?.veh_id));
        }
        if (filter.type === "vehicle") {
          temp = temp.filter(
            (row) =>
              row.veh_id === filter.data?.vehicleId ||
              filter.data?.vehicleId === ""
          );
        }
      });
    }
    setData(temp);
  };

  // const handleNameChange = useAsyncDebounce((item) => {
  //   const value = item.trim();
  //   // if (value) {
  //   //   setSearchHasValue(true);
  //   // } else {
  //   //   setSearchHasValue(false);
  //   // }
  //   //dispatch(addSearchValue(value));
  //   setName(value);
  // }, 200);

  // const handleManagerChange = useAsyncDebounce((item) => {
  //   const value = item.trim();
  //   // if (value) {
  //   //   setSearchHasValue(true);
  //   // } else {
  //   //   setSearchHasValue(false);
  //   // }
  //   //dispatch(addSearchValue(value));
  //   setManager(value);
  // }, 200);

  const onChange = (item) => {
    const value = item.trim();
    // if (value) {
    //   setSearchHasValue(true);
    // } else {
    //   setSearchHasValue(false);
    // }
    dispatch(
      updateExternalFilter({
        screen: getScreenName(screen),
        filterName: "search",
        filterValue: value,
      })
    );
  };

  //Handlers for user name and manager name search onChange
  const handleUserNameChange = (item) => {
    const value = item.trim();
    dispatch(
      updateExternalFilter({
        screen: getScreenName(screen),
        filterName: "name",
        filterValue: value,
      })
    );
  };
  const handleManagerNameChange = (item) => {
    const value = item.trim();
    dispatch(
      updateExternalFilter({
        screen: getScreenName(screen),
        filterName: "manager",
        filterValue: value,
      })
    );
  };

  const debouncedSearch = useCallback(debounce(onChange), []);
  //For users screen(name and manager search)
  const debouncedUserSearch = useCallback(debounce(handleUserNameChange), []);
  const debouncedManagerSearch = useCallback(
    debounce(handleManagerNameChange),
    []
  );

  let searchPlaceholder = "";
  if (screen.includes("servicetasks")) {
    searchPlaceholder = t("service_task_name");
  } else if (screen.includes("expensetasks")) {
    searchPlaceholder = t("expense_task_name");
  } else {
    searchPlaceholder = t("filter_by_keyword");
  }

  const commonProps = {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    setLocalFilters,
  };

  useEffect(() => {
    if (!screen.includes("vehicles")) {
      setOptionValues(valuesArray);
    }
  }, [valuesArray]);

  const handleCloseClick = (filter) => {
    const { column } = filter;
    if (column === "date") {
      let startDate = 0;
      let endDate = getUnixTime(new Date());
      dispatch(removeFilterDate({ startDate, endDate }));
    }
    // if (!screen.includes("inspectionscheduling")) {
    //   dispatch(removeAdvancedFilter({ column, screen }));
    // } else {
    //   setLocalFilters((prev) => prev.filter((f) => f.type !== column));
    // }
    setLocalFilters((prev) => prev.filter((f) => f.type !== column));
    setAppliedFilters((prev) => {
      let updatedFilters = prev.filter((filter) => filter.column !== column);
      return updatedFilters;
    });
    dispatch(
      removeMoreFilters({
        screen: getScreenName(screen),
        filterToRemove: filter,
      })
    );
    updateFilterState(filter);
  };

  const updateFilterState = (filter) => {
    if (Object.hasOwn(filter, "from")) {
      setFilterData((prev) => {
        const newData = {
          ...filter,
          //from: initialDateData.from,
          //to: initialDateData.to,
          from: new Date(minDate * 1000),
          to: new Date(),
          dateOption: "all_time",
          isFiltered: false,
        };
        return { ...prev, [filter.column]: newData };
      });
    } else if (Object.hasOwn(filter, "input_2")) {
      setFilterData((prev) => {
        const newData = {
          ...filter,
          radioValue: "greater-than",
          radioName: t("greater_than"),
          input_1: "",
          input_2: "",
          isFiltered: false,
        };
        return { ...prev, [filter.column]: newData };
      });
    } else if (Object.hasOwn(filter, "input_1")) {
      setFilterData((prev) => {
        const newData = {
          ...filter,
          radioValue: "is",
          radioName: t("is"),
          input_1: "",
          isFiltered: false,
        };
        return { ...prev, [filter.column]: newData };
      });
    } else {
      setFilterData((prev) => {
        let newData = {};
        if (filter.column === "transactionType") {
          newData = {
            column: "transactionType",
            name: t("transcation_type"),
            cash: { header: t("cash_const"), value: false },
            debitCard: { header: t("debit_card_const"), value: false },
            creditCard: { header: t("credit_card_const"), value: false },
            check: { header: t("check_const"), value: false },
            isFiltered: false,
            type: "checkbox",
          };
        } else if (filter.column === "fuelType") {
          newData = {
            column: "fuelType",
            name: t("fuel_type"),
            petrol: { header: t("petrol_const"), value: false },
            diesel: { header: t("diesel_const"), value: false },
            isFiltered: false,
            type: "checkbox",
          };
        } else if (filter.column === "status") {
          newData = {
            column: "status",
            name: t("status"),
            scheduled: { header: t("scheduled_caps"), value: false },
            dueSoon: { header: t("due_soon_caps"), value: false },
            overdue: { header: t("overdue_caps"), value: false },
            isFiltered: false,
          };
        } else if (filter.column === "primaryMeter") {
          newData = {
            column: "primaryMeter",
            name: t("primary_meter"),
            miles: { header: t("miles"), value: false },
            kilometers: { header: t("kilometers"), value: false },
            hours: { header: t("hours_const"), value: false },
            isFiltered: false,
            type: "checkbox",
          };
        }
        return { ...prev, [filter.column]: newData };
      });
    }
  };

  const handleClearAll = () => {
    const columns = appliedFilters.map((item) => item.column);
    // if (screen.includes("inspectionscheduling")) {
    //   setLocalFilters((prev) =>
    //     prev.filter((f) => f.type === "group" || f.type === "vehicle")
    //   );
    //   // setInspectionScheduleFilters((prev) =>
    //   //   prev.filter((f) => f.type === "group" || f.type === "vehicle")
    //   // );
    // } else {
    //   dispatch(clearAllAdvancedFilters({ columns, screen }));
    //   let startDate = 0;
    //   let endDate = getUnixTime(new Date());
    //   dispatch(removeFilterDate({ startDate, endDate }));
    // }
    setLocalFilters((prev) =>
      prev.filter((f) => f.type === "group" || f.type === "vehicle")
    );
    setAppliedFilters([]);
    dispatch(clearMoreFilters({ screen: getScreenName(screen) }));
    setFilterData(initialData);
  };

  const getFilterMessage = (filter) => {
    if (Object.hasOwn(filter, "from")) {
      return `${filter.fromString} - ${filter.toString}`;
    } else if (Object.hasOwn(filter, "input_2")) {
      if (filter.radioValue === "between") {
        return `${filter.radioName} ${filter.input_1} and ${filter.input_2}`;
      } else {
        return `${filter.radioName} ${filter.input_1}`;
      }
    } else if (Object.hasOwn(filter, "input_1")) {
      return `${filter.radioName} ${filter.input_1}`;
    } else {
      const { headersArray } = filter;

      let message = ``;
      headersArray.forEach((item, i) => {
        if (i === headersArray.length - 1) {
          message += item;
        } else {
          message += `${item}, `;
        }
      });
      return message;
    }
  };

  const handleDeleteSelected = async () => {
    const { rowId, subQuery } = getMultiDeleteInfo(screen);
    const rowIdArr = selectedFlatRows.map((item) => item.original[rowId]);
    const deleteIds = rowIdArr.join(":::");
    const apiData = {
      delete_ids: deleteIds,
      querystring: "multiple_delete",
      sub_query_string: subQuery,
    };
    setMultiDeleteApiData(apiData);
    setShowDeletePopup(true);
  };

  //DailyTS apply date filter handler
  const handleDateFilterApply = (date) => {
    if (date.from && date.to) {
      const dateOptionsObj = {
        today: t("today"),

        yesterday: t("yesterday"),

        "last-7-days": t("last_7_days"),

        "last-30-days": t("last_30_days"),

        "last-90-days": t("last_90_days"),

        "this-month": t("this_month"),

        "this-year": t("this_year"),
        "all-time": t("all_time"),

        custom: t("custom"),
      };
      const startDate = getUnixTime(startOfDay(date.from)); //Math.round(date.from / 1000);
      const endDate = getUnixTime(endOfDay(date.to)); // Math.round(date.to / 1000);

      // setDailyTSFilters((prev) => {
      //   let temp = prev.filter((f) => f.type !== "date");
      //   temp.push({ type: "date", data: { startDate, endDate } });
      //   return temp;
      // });

      let filterObj = {
        fromUnix: startDate,
        toUnix: endDate,
        dateOption: date.dateOption,
        filterText:
          date?.dateOption === "custom"
            ? `${convertToFilterDate(date.from)} - ${convertToFilterDate(
                date.to
              )}`
            : dateOptionsObj[date?.dateOption],
      };

      dispatch(
        updateExternalFilter({
          screen: getScreenName(screen),
          filterName: "date",
          filterValue: filterObj,
        })
      );
    }
  };

  return (
    <>
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          deleteApiData={multiDeleteApiData}
          screen={screen}
          setShowInfoPopup={setShowInfoPopup}
          setInfoData={setInfoData}
        />
      )}
      {showInfoPopup && (
        <InfoPopup
          showPopup={showInfoPopup}
          setShowPopup={setShowInfoPopup}
          message={infoData.msg}
          icon={infoData.icon}
          listMsg={infoData.listMsg}
        />
      )}
      {showImportPopup && (
        <ImportComponent
          showPopup={showImportPopup}
          setShowPopup={setShowImportPopup}
          screenName={getScreenName(screen)}

          //modalHeading={"Import Service Tasks into Simply Fleet"}
        />
      )}
      <Container style={{ padding: "2px 0" }}>
        <div className="d-flex justify-content-between mx-3 my-0 p-0 gap-3">
          {selectedFlatRows.length <= 0 ? (
            <div className="d-flex justify-content-start align-items-center gap-3 flex-lg-row flex-sm-column">
              {!screen.includes("users") && (
                <SearchInput
                  type="search"
                  placeholder={searchPlaceholder} //{t("filter.search_placeholder")}
                  //onChange={(e) => onChange(e.target.value)}
                  onChange={(e) => {
                    const value = e.target.value;
                    // if (value) {
                    //   setSearchHasValue(true);
                    // } else {
                    //   setSearchHasValue(false);
                    // }
                    setFilterBySearch(value);
                    debouncedSearch(value);
                  }}
                  value={filterBySearch}
                  active={searchHasValue}
                  //value={globalFilter}
                />
              )}

              {/* {dataLength !== rows.length && (
                  <span>Found {rows.length} matches</span>
                )} */}
              {(screen.includes("inspectionscheduling") ||
                screen.includes("vehicles") ||
                screen.includes("faultcodes")) && (
                // <Select
                //   id="group-dropdown"
                //   value={group}
                //   onChange={setGroup}
                //   className="basic-single"
                //   classNamePrefix="select"
                //   isSearchable
                //   name="color"
                //   options={groups}
                //   styles={selectStylesNew}
                //   placeholder={t("group")}
                //   filterOption={createFilter({ ignoreAccents: false })}
                // />
                <TreeSelect
                  id="group-treeselect-dropdown"
                  value={groupId}
                  onChange={(e) => setGroupId(e.value)}
                  options={groups}
                  //className="md:w-20rem w-full"
                  placeholder={t("group")}
                  filter
                  className={
                    groupId && groupId !== "all-groups"
                      ? "treeselect-with-value"
                      : ""
                  }
                ></TreeSelect>
              )}

              {/* <SelectSearch options={vehiclesList} value={vehicle} onChange={setVehicle} name="language" placeholder="Choose your language" search/> */}
              {(screen.includes("servicestask") ||
                screen.includes("expensestask") ||
                screen.includes("inspectionscheduling") ||
                screen.includes("vehicles") ||
                screen.includes("faultcodes")) && (
                <Select
                  id="vehicle-dropdown"
                  value={vehicle}
                  onChange={setVehicle}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable
                  name="color"
                  options={vehicles}
                  styles={selectStylesNew}
                  placeholder={t("vehicle")}
                />
              )}
              {screen.includes("inspectionscheduling") && (
                // <StatusFilter
                //   id="status-filter"
                //   commonProps={commonProps}
                //   data={filterData?.status}
                //   setData={setData}
                // />
                <Select
                  id="status-dropdown"
                  options={statusDataOptions}
                  value={statusArr}
                  onChange={(arr) => {
                    setStatusArr(arr);
                    dispatch(
                      updateExternalFilter({
                        screen: getScreenName(screen),
                        filterName: "status",
                        filterValue: arr,
                      })
                    );
                  }}
                  //className="basic-multi-select"
                  //classNamePrefix="select"
                  //name="color"
                  styles={statusStyles}
                  placeholder={t("status")}
                  isSearchable={false}
                  isMulti={true}
                  //isClearable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  //controlShouldRenderValue={false}
                  filterOption={createFilter({
                    ignoreAccents: false,
                  })}
                />
              )}

              {screen.includes("users") && (
                <>
                  {" "}
                  <SearchInput
                    type="search"
                    placeholder={t("name")} //{t("filter.search_placeholder")}
                    //onChange={(e) => handleNameChange(e.target.value)}
                    //onChange={(e) => setName(e.target.value)}
                    onChange={(e) => {
                      setName(e.target.value);
                      debouncedUserSearch(e.target.value);
                    }}
                    value={name}
                    //active={searchHasValue}
                  />
                  <Select
                    id="user-role-dropdown"
                    options={userRoleOptions}
                    value={userRole}
                    //onChange={setUserRole}
                    onChange={(arr) => {
                      //setUserRole(arr);
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "userRole",
                          filterValue: arr,
                        })
                      );
                    }}
                    styles={statusStyles}
                    placeholder={t("all_roles")}
                    isSearchable={false}
                    isMulti={true}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    //controlShouldRenderValue={false}
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                  />
                  <Select
                    id="user-status-dropdown"
                    value={userStatus}
                    onChange={(val) => {
                      //setUserStatus(val);
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "status",
                          filterValue: val,
                        })
                      );
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable
                    name="color"
                    options={userStatusOptions}
                    styles={selectStylesNew}
                    placeholder={t("status")}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  <SearchInput
                    type="search"
                    value={manager}
                    placeholder={t("manager_name")} //{t("filter.search_placeholder")}
                    //onChange={(e) => handleManagerChange(e.target.value)}
                    onChange={(e) => {
                      setManager(e.target.value);
                      debouncedManagerSearch(e.target.value);
                    }}
                    //active={searchHasValue}
                  />
                </>
              )}

              {/* Filters for Vehicles */}
              {screen.includes("vehicles") && (
                <>
                  {/* <Select
                    id="vehicle-type-dropdown"
                    options={vehicleTypeOptions.current}
                    value={vehicleType}
                    //onChange={setVehicleType}
                    onChange={(arr) => {
                      setVehicleType(arr);
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "type",
                          filterValue: arr,
                        })
                      );
                    }}
                    styles={statusStyles}
                    placeholder="All Types"
                    isSearchable={false}
                    isMulti={true}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    //controlShouldRenderValue={false}
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                  /> */}

                  <Select
                    id="vehicle-status-dropdown"
                    options={vehicleStatusOptions}
                    value={vehicleStatus}
                    //onChange={setVehicleStatus}
                    onChange={(arr) => {
                      setVehicleStatus(arr);
                      dispatch(
                        updateExternalFilter({
                          screen: getScreenName(screen),
                          filterName: "status",
                          filterValue: arr,
                        })
                      );
                    }}
                    styles={statusStyles}
                    placeholder={t("all_statuses")}
                    isSearchable={false}
                    isMulti={true}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    //controlShouldRenderValue={false}
                    filterOption={createFilter({
                      ignoreAccents: false,
                    })}
                  />
                </>
              )}
              {/* Filters for Daily Time Sheet */}
              {screen?.includes("dailytimesheet") && (
                // <div ref={extDateFilterRef}>
                //   <DateContainer
                //     onClick={() => setIsDateFilterOpen((prev) => !prev)}
                //   >
                //     <span>{dateFilterData.filterText}</span>
                //     <i className="fi fi-bs-angle-small-down"></i>
                //   </DateContainer>
                //   {isDateFilterOpen && (
                //     <ExtDateFilter
                //       isDateFilterOpen={isDateFilterOpen}
                //       setIsDateFilterOpen={setIsDateFilterOpen}
                //       submissionsData={dateFilterData}
                //       setSubmissionsData={setDateFilterData}
                //       dailyTSFilters={dailyTSFilters}
                //       setDailyTSFilters={setDailyTSFilters}
                //     />
                //   )}
                // </div>
                <CombinedDateFilter
                  presetDate={externalDatePreset}
                  handleDateApply={handleDateFilterApply}
                  allTimeStartDate={new Date(minDate * 1000)}
                />
              )}

              {/* More Filters */}
              {(screen.includes("inspectionscheduling") ||
                screen.includes("vehicles")) && (
                <>
                  <FilterButton
                    onClick={() => setShowAdvancedFilters(true)}
                    id="advanced-filter-button"
                  >
                    <FilterIcon />
                    {t("more_filters")}
                  </FilterButton>
                  <AdvancedFiltersNew
                    show={showAdvancedFilters}
                    onHide={() => setShowAdvancedFilters(false)}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    dateOption={dateOption}
                    setDateOption={setDateOption}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                    screen={screen}
                    setLocalFilters={setLocalFilters}
                    setInspectionScheduleFilters={setInspectionScheduleFilters}
                    initialData={initialData}
                  />
                </>
              )}
            </div>
          ) : (
            <DeleteRowsContainer>
              <FilterButton onClick={handleDeleteSelected}>
                {t("delete_selected")}
              </FilterButton>
            </DeleteRowsContainer>
          )}

          <RightSideContainer>
            <>
              {(screen.includes("users") || screen.includes("vehicles")) && (
                //screen.includes("dailytimesheet")
                <ColumnReorder
                  allColumns={allColumns}
                  columnOrder={columnOrder}
                  hiddenColumns={hiddenColumns}
                  setColumnOrder={setColumnOrder}
                  setHiddenColumns={setHiddenColumns}
                  screen={screen}
                  pageSize={pageSize}
                />
              )}

              {(screen.includes("servicetasks") ||
                (screen.includes("expensetasks") && roleId != 4) ||
                screen.includes("inspectiontasks") ||
                screen.includes("dailytimesheet") ||
                screen.includes("users") ||
                screen.includes("vehicles") ||
                screen.includes("inspectionforms") ||
                screen.includes("inspectionscheduling")) && (
                <MoreOptions
                  allColumns={allColumns}
                  screen={screen}
                  columnOrder={columnOrder}
                  hiddenColumns={hiddenColumns}
                  rows={rows}
                  showImportPopup={showImportPopup}
                  setShowImportPopup={setShowImportPopup}
                />
              )}
            </>

            {/* {(screen.includes("servicetasks") ||
              screen.includes("expensetasks") ||
              screen.includes("inspectiontasks") ||
              screen.includes("inspectionforms")) && (
              <FilterButton
                style={{ minWidth: "min-content" }}
                onClick={() => setShowImportPopup(true)}
              >
                <i className="fi fi-rr-file-import p-0 m-0"></i>
              </FilterButton>
            )} */}
          </RightSideContainer>
        </div>

        {appliedFilters.length > 0 && (
          <AppliedFiltersContainer>
            {appliedFilters.map((filter) => {
              return (
                <AppliedFilter key={filter.column}>
                  <span>
                    <span style={{ fontSize: "12px" }}>{filter.name}:</span>
                    <span style={{ fontWeight: 500 }}>
                      {" "}
                      {getFilterMessage(filter)}
                    </span>
                  </span>
                  <span
                    onClick={() => handleCloseClick(filter)}
                    style={{ cursor: "pointer" }}
                  >
                    <CloseIcon />
                  </span>
                </AppliedFilter>
              );
            })}
            <FilterButton onClick={handleClearAll}>
              {t("clear_all")}
            </FilterButton>
          </AppliedFiltersContainer>
        )}
        {optionValues?.length > 0 && (
          <div>
            <hr style={{ margin: "8px 0" }} />
          </div>
        )}
        <div className="d-flex mx-4 gap-3">
          {optionValues?.map((item) => (
            <Alert
              color="info"
              neutral
              icon={item.icon}
              key={item.id}
              //className="col-xl-4"
            >
              <span>{item.name}</span>
              <p>{`${item.value} ${item?.units ? item.units : ""}`}</p>
            </Alert>
          ))}
        </div>
      </Container>
    </>
  );
};

const filterGreaterThan = (rows, id, filterValue) =>
  rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

ReactTableFilterClient.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  //setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  placeholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
};

ReactTableFilterClient.defaultProps = {
  setFilterValue: () => {},
  placeholder: "Search...",
};

export default ReactTableFilterClient;

// region STYLES

// const SearchWrap = styled.div`
//   //margin-top: 10px;
//   min-width: 150px;
//   display: flex;
//   align-items: baseline;
//   //color: ${colorAdditional};
// `;

// const SearchInput = styled(Form.Control)`
//   border: 1px solid ${colorLightGray};
//   outline-width: 0;
//   border-radius: 5px;
//   height: 35px;
//   padding: 6px 8px;
//   max-width: 250px;
//   font-size: 12px;
//   margin-right: 20px;
//   color: ${colorTextNew};
//   &:focus {
//     border-color: ${colorBlueHover};
//     box-shadow: 0 0 0.5pt 0.5pt ${colorBlueHover};
//   }
//   //background: ${colorBackground};
// `;

// Added

// const FunctionsWrap = styled.div`
//   display: flex;
//   wrap: no-wrap
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: 10px;
//   color: ${colorAdditional};
// `;

// const AppliedFiltersContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   flex-wrap: wrap;
// `;

// const AppliedFilter = styled.div`
//   border: 1px solid ${colorLightGray};
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 5px;

//   span {
//     color: ${colorTextNew};
//     svg {
//       width: 20px;
//       height: 20px;
//       padding: 0 2px 2px 2px;
//       fill: ${colorGray};
//     }
//   }
// `;

const DeleteButtonContainer = styled.div``;

const DeleteButton = styled(Button)`
  background: white;
  color: ${colorTextNew};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 10px;
  background-color: transparent;

  svg {
    height: 18px;
    width: 16px;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: ${colorHover};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`;

export const ClearSelection = styled.p`
  margin: 10px 10px 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${colorBlueHover};
`;
// export const AddButton = styled(Button)`
//   padding: 10px;
//   min-width: 95px;
//   white-space: nowrap;
// `;

// endregion

export const Option = (props) => {
  if (props.data.__isNew__) {
    return <components.Option {...props}></components.Option>;
  }
  return (
    //<div>
    <components.Option {...props}>
      <div
        //style={{ height: "20px" }}
        className="d-flex align-items-center"
      >
        <label
          onClick={(e) => e.preventDefault()}
          style={{ padding: "0px 5px" }}
        >
          <input
            name={props.value}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
            style={{
              width: "auto",
              position: "relative",
              top: "1px",
              marginRight: "4px",
            }}
            //defaultValue={false}
          />
          {props.label}
        </label>
      </div>
    </components.Option>
    // </div>
  );
};
