import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import styled from "styled-components";
//import { addAdvancedFilter } from "../../../../../features/filter/filterSlice";
import { addAdvancedFilter } from "../../../../../features/advancedFilter/advancedFilterSlice";
import { colorLightGray, colorTextNew } from "../../../../../utils/palette";
import { ErrorText, RadioInput, SaveButton } from "./RangeFilter";
import {
  TextInput,
  InputRadioContainer,
  Label,
  SaveButtonContainer,
} from "./RangeFilter";

const ContainsFilter = ({ commonProps, top, data }) => {
  const {
    filterData,
    setFilterData,
    appliedFilters,
    setAppliedFilters,
    screen,
    setLocalFilters,
  } = commonProps;
  const [inputRequired, setInputRequired] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const [errorMessage, setErrorMessage] = useState("");

  const getPlaceholderText = (value = data.radioValue) => {
    if (value === "is") {
      return t("is");
    } else if (value === "is-not") {
      return t("is_not");
    } else if (value === "contains") {
      return t("contains");
    }
    return t("is");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let isFiltered = data.isFiltered;
    if (name === "input_1") {
      if (value) {
        isFiltered = true;
      } else {
        isFiltered = false;
      }
    }

    let radioName = data.radioName;
    if (name === "radioValue") {
      radioName = getPlaceholderText(value);
    }

    setFilterData((prev) => {
      const newData = {
        ...data,
        [name]: value,
        radioName: radioName,
        isFiltered,
      };
      return { ...prev, [data.column]: newData };
    });
  };

  const handleApply = () => {
    if (!data.input_1) {
      setErrorMessage("Please enter a value!");
      setInputRequired(true);
      setTimeout(() => setInputRequired(false), 3000);
    } else {
      if (data.column === "cardNumber") {
        if (data.input_1 < 0) {
          setErrorMessage("Please enter a value greater than 0!");
          setInputRequired(true);
          setTimeout(() => setInputRequired(false), 3000);
        } else if (data.input_1.toLocaleString().length !== 4) {
          setErrorMessage("Please enter a 4 digit value");
          setInputRequired(true);
          setTimeout(() => setInputRequired(false), 3000);
        } else {
          updateAppliedFilters();
        }
      } else {
        updateAppliedFilters();
      }
    }
  };

  const updateAppliedFilters = () => {
    //dispatch(addAdvancedFilter(data));
    if (!screen.includes("inspectionscheduling")) {
      dispatch(addAdvancedFilter({ ...data, screen }));
    } else {
      setLocalFilters((prev) => {
        const temp = prev.filter((f) => f.type !== "formName");
        temp.push({
          id: "formName",
          type: "formName",
          data: data,
        });
        return temp;
      });
    }
    setAppliedFilters((prev) => {
      const newArray = prev.filter((item) => item.column !== data.column);
      newArray.push(data);
      return newArray;
    });
  };

  return (
    <ContainsFilterContainer top={top} id="text-filter">
      {!data?.hideIsFilter && (
        <InputRadioContainer>
          <RadioInput
            type="radio"
            id="is"
            value="is"
            name="radioValue"
            onChange={handleChange}
            checked={data.radioValue === "is"}
          />
          <Label htmlFor="is">{t("is")}</Label>
        </InputRadioContainer>
      )}
      {!data?.hideIsNotFilter && (
        <InputRadioContainer>
          <RadioInput
            type="radio"
            id="is-not"
            value="is-not"
            name="radioValue"
            checked={data.radioValue === "is-not"}
            onChange={handleChange}
          />
          <Label htmlFor="is-not">{t("is_not")}</Label>
        </InputRadioContainer>
      )}
      {data.column !== "cardNumber" && (
        <InputRadioContainer>
          <RadioInput
            type="radio"
            id="contains"
            value="contains"
            name="radioValue"
            checked={data.radioValue === "contains"}
            onChange={handleChange}
          />
          <Label htmlFor="contains">{t("contains")}</Label>
        </InputRadioContainer>
      )}
      <div>
        <TextInput
          type={data.column === "cardNumber" ? "number" : "text"}
          name="input_1"
          value={data.input_1}
          onChange={handleChange}
          placeholder={
            data.column === "cardNumber"
              ? t("last_4_digits")
              : getPlaceholderText()
          }
        />
      </div>
      {inputRequired && <ErrorText>{errorMessage}</ErrorText>}
      {/* <SaveButtonContainer>
        <SaveButton id="apply-filter-button" variant="primary" onClick={handleApply}>
          {t("filter.apply")}
        </SaveButton>
      </SaveButtonContainer> */}
    </ContainsFilterContainer>
  );
};

export default ContainsFilter;

// styles

export const ContainsFilterContainer = styled.div`
  min-width: 150px;
  padding: 20px 0;
  background: white;
  position: absolute;
  display: flex;
  flex-direction: column;
`;
