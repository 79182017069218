import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { CheckboxInput } from "../ColumnReorder";
import { ItemContainer, ItemLabel, ItemValue } from "./";
import { useSelector } from "react-redux";
//import { fetchServicesTasks } from "../../../../../features/servicesTasks/servicesTasksSlice";
import { useDispatch } from "react-redux";
import { taskBgColor } from "../../../../../utils/palette";
import ImageWithZoom from "../../../form/ImageWithZoom";
import { useTranslation } from "react-i18next";
import { NATEXT, s3Url } from "../../../../constants";
import {
  getDistanceUnit,
  getListingDisplayDate,
  getTranslatedTransactionType,
} from "../../../../helpers";
import { postApi } from "../../../../../api/getUser";

const ExpensesRightPanel = ({ rowData }) => {
  const { currency, distance } = useSelector((state) => state.units);
  const { t } = useTranslation("common");

  const {
    expense_id,
    expense_date,
    odo,
    primary_meter,
    required,
    total_cost,
    expense_task_ids,
    labor_cost,
    parts_cost,
    vendor,
    //service_task_name,
    expense_by,
    transaction_type,
    card_number,
    comments,
    org_id,
    receipt_img_name,
    tax,
    discount,
    uname,
  } = rowData;

  const [expenseTasks, setExpenseTasks] = useState([]);
  //const expenseTaskOptions = useSelector((state) => state.expenseTasks.list);
  const users = useSelector((state) => state.users.list);

  useEffect(() => {
    (async () => {
      let expenseTaskOptions = [];
      let selectedExpenseTasks = [];
      const apiData = {
        querystring: "get_all_expense_tasks",
        expense_veh_id: "All",
      };

      const res = await postApi(apiData, "commonNew");
      console.log("res", res);

      if (res.success) {
        expenseTaskOptions = res?.user_data;
        const expenseTaskIds = JSON.parse(rowData?.expense_task_ids);
        const costData = JSON.parse(rowData?.cost);
        const totalCost = rowData?.total_cost;

        expenseTaskIds?.forEach((taskId) => {
          let temp = {
            ...expenseTaskOptions.find((t) => t.expense_task_id === taskId),
          };
          console.log(costData[taskId], taskId);
          if (+costData[taskId]) {
            temp.cost = +costData[taskId];
          }
          selectedExpenseTasks.push(temp);
        });
      }
      //console.log("selectedExpenseTasks", selectedExpenseTasks);
      //console.log("expenseTaskOptions", expenseTaskOptions);
      setExpenseTasks(selectedExpenseTasks);
    })();
  }, [rowData]);

  let receiptsIdArr = [];
  let receiptsUrlData = [];
  if (receipt_img_name) {
    receiptsIdArr = receipt_img_name.split(":::");
    receiptsUrlData = receiptsIdArr.map((imgName) => ({
      id: imgName,
      url: `${s3Url}/org_${org_id}/expenses/${imgName}`,
    }));
    console.log(receiptsUrlData);
  }
  return (
    <>
      <Row>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("date_tv")}</ItemLabel>
            <ItemValue>{getListingDisplayDate(expense_date)}</ItemValue>
          </ItemContainer>
        </Col>
        <Col md={6}>
          <ItemContainer>
            <ItemLabel>{t("time_tv")}</ItemLabel>
            <ItemValue>
              {new Date(1000 * expense_date).toLocaleTimeString()}
            </ItemValue>
          </ItemContainer>
        </Col>
        {!!odo && !!required && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("odometer")}</ItemLabel>
              <ItemValue>
                {`${odo}
                ${getDistanceUnit(
                  primary_meter === "Hours" ? primary_meter : distance
                )}`}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {vendor && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("vendor")}</ItemLabel>
              <ItemValue>{vendor}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {/* {service_task_name && service_task_name !== "N/A" && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>Service Task</ItemLabel>
              <ItemValue>{service_task_name}</ItemValue>
            </ItemContainer>
          </Col>
        )} */}
      </Row>
      {expenseTasks?.map((task) => {
        return (
          <Row
            key={task.expense_task_id}
            style={{
              background: taskBgColor,
              padding: "4px",
              margin: "0.25rem 0",
              borderRadius: "5px",
            }}
          >
            <p style={{ fontSize: "16px" }}>{task.expense_task_name}</p>

            {/* <Col md={4}>
              <ItemContainer>
                <ItemLabel>Parts</ItemLabel>
                <ItemValue>{task.partsCost}</ItemValue>
              </ItemContainer>
            </Col>

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>Labor</ItemLabel>
                <ItemValue>{task.laborCost}</ItemValue>
              </ItemContainer>
            </Col> */}

            <Col md={4}>
              <ItemContainer>
                <ItemLabel>{`${t("tc_tv")} (${currency})`}</ItemLabel>
                <ItemValue>
                  {task.cost && +task.cost ? +task.cost?.toFixed(2) : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>
          </Row>
        );
      })}
      <Row className="d-flex justify-content-end">
        <Col sm={6}>
          <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "80px" }}
            >
              {t("tax")}:
            </ItemLabel>
            <ItemValue>{`${tax} ${currency}`}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col sm={6}>
          <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("discount")}:
            </ItemLabel>
            <ItemValue>{`${discount} ${currency}`}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col sm={6}>
          <ItemContainer className="d-flex gap-2 align-items-center justify-content-end">
            <ItemLabel
              className="m-0"
              //style={{ minWidth: "90px" }}
            >
              {t("grand_total")}:
            </ItemLabel>
            <ItemValue style={{ fontWeight: 500 }}>
              {`${+total_cost - +discount + +tax} ${currency}`}
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>

      <Row>
        {transaction_type && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("transaction_type")}</ItemLabel>
              <ItemValue>
                {getTranslatedTransactionType(transaction_type, t)}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
        {!!+card_number && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>
                {transaction_type === "Check"
                  ? t("check_number")
                  : t("card_number")}
              </ItemLabel>
              <ItemValue>{card_number}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {expense_by && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("expense_by")}</ItemLabel>
              <ItemValue>{uname}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {comments && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              {/* <textarea
                value={comments}
                disabled
                style={{ minWidth: "90%", margin: "1px", padding: "5px" }}
              ></textarea> */}
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        {receiptsUrlData.length > 0 && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("images")}</ItemLabel>
              <ItemValue>
                <div className="d-flex gap-2 flex-wrap">
                  {receiptsUrlData.map((img) => {
                    return (
                      <ImageWithZoom
                        key={img.id}
                        img={img}
                        //handleDeleteImg={handleDeleteImg}
                        shouldDelete={false}
                      />
                    );
                  })}
                </div>
              </ItemValue>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ExpensesRightPanel;

export const ReceiptImg = styled.img`
  overflow: visible;
  width: 120px;
  height: 90px;
  &:active {
    transform: scale(2) translate(10%, 10%);
    overflow: visible;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  padding: 10px;
  ${(p) => p.active && `background: lightgray`}
`;
