import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Snackbar from "../../../shared/components/Snackbar";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({
  reactTableData,
  valuesArray,
  initialData,
  notInspectedVehiclesLength,
  vehiclesTableData,
  pauses,
  setSnackbarMsg,
}) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);

  const [isEditable, setIsEditable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);

  const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
  const [isDisabledEditable, setIsDisabledEditable] = useState(false);
  const [isDisabledResizable, setIsDisabledResizable] = useState(false);
  const [withDragAndDrop, setWithDragAndDrop] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const { msg } = useSelector((state) => state.snackbar);

  const isSortable = true;
  const withPagination = true;
  const withSearchEngine = true;

  const dispatch = useDispatch();

  const [localFilters, setLocalFilters] = useState([]);
  const location = useLocation();
  const path = location.pathname;
  const rowData = useSelector((state) => state.row.data);

  useEffect(() => {
    if (msg) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 2000);
    } else {
      setShowSnackbar(false);
    }
  }, [msg]);

  useEffect(() => {
    if (
      !path.includes("dailytimesheet") &&
      !path.includes("inspectionscheduling") &&
      !path.includes("users") &&
      !path.includes("servicetasks") &&
      !path.includes("expensetasks") &&
      !path.includes("inspectiontasks") &&
      !path.includes("inspectionforms") &&
      !path.includes("vehicles") &&
      !path.includes("faultcodes")
    ) {
      setData(reactTableData.tableRowsData);
    }
  }, [reactTableData.tableRowsData]);

  const updateDraggableData = (result) => {
    const items = reorder(rows, result.source.index, result.destination.index);
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((item, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return item;
      })
    );
  };

  const tableConfig = {
    isEditable,
    isResizable,
    isSortable,
    withDragAndDrop,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 25, 50, 100],
    placeholder: "Search by keyword...",
  };

  return (
    <>
      <Col md={12} lg={12} style={{ height: "100%" }}>
        <Card className="p-0 m-0">
          <CardBody style={{ background: "transparent" }} className="my-2 py-0">
            {/* <HeaderWrap> */}
            {/* <CardTitleWrap>
              <CardTitle>FillUps</CardTitle>
              <CardSubhead>
                Use table with&nbsp;
                <span className="red-text">table customizer</span>
              </CardSubhead>
            </CardTitleWrap>
            <ReactTableCustomizer
              handleClickIsEditable={handleClickIsEditable}
              handleClickIsResizable={handleClickIsResizable}
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithDragAndDrop={handleClickWithDragAndDrop}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              isEditable={isEditable}
              isResizable={isResizable}
              isSortable={isSortable}
              isDisabledDragAndDrop={isDisabledDragAndDrop}
              isDisabledEditable={isDisabledEditable}
              isDisabledResizable={isDisabledResizable}
              withDragAndDrop={withDragAndDrop}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
              fullCustomizer
            /> */}
            {/* </HeaderWrap> */}
            <ReactTableBase
              key={
                withSearchEngine || isResizable || isEditable
                  ? "modified"
                  : "common"
              }
              columns={reactTableData.tableHeaderData}
              data={rows}
              setData={setData}
              updateEditableData={updateEditableData}
              updateDraggableData={updateDraggableData}
              tableConfig={tableConfig}
              valuesArray={valuesArray}
              initialData={initialData}
              localFilters={localFilters}
              setLocalFilters={setLocalFilters}
              notInspectedVehiclesLength={notInspectedVehiclesLength}
              vehiclesTableData={vehiclesTableData}
              pauses={pauses}
              setSnackbarMsg={setSnackbarMsg}
            />
          </CardBody>
        </Card>
        {showSnackbar && (
          <Snackbar
            showSnackbar={showSnackbar}
            setShowSnackbar={setShowSnackbar}
          />
        )}
      </Col>
    </>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;
  margin-bottom: -20px;

  & > div:first-child {
    margin-right: auto;
  }
`;

// endregion
