import React, { useEffect, useState } from "react";
//import { ItemContainer, ItemLabel, ItemValue } from "../ReactTableRightPanel";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import { getDisplayDate, getUserDocUrl } from "../../../../helpers";
import { Col, Row } from "react-bootstrap";
import {
  colorBg,
  colorLightBlueStatus,
  taskBgColor,
} from "../../../../../utils/palette";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NATEXT, s3Url } from "../../../../constants";
import ZoomModal from "../../../ZoomModal";
import { LinkSpan } from "../../../form/FormElements";
import { useTranslation } from "react-i18next";
import AssignedEntitiesModal from "../AssignedEntitiesModal";
import { postApi } from "../../../../../api/getUser";
import defaultVehicleIcon from "@/shared/img/defaultVehicleIcon.png";
import ImageWithZoom from "../../../form/ImageWithZoom";

const UserManagementRightPanel = ({ rowData }) => {
  const vehicles = useSelector((state) => state.vehicles.list);
  const { t } = useTranslation("common");
  const { roleId } = useSelector((state) => state.user);
  const {
    name,
    email,
    phone,
    home_address,
    manager_name,
    joining_date,
    comments,
    starting_time,
    ending_time,
    hourly_rate,
    working_days,
    role_id,
    license_img,
    org_id,
    user_id,
  } = rowData;

  const daysOfWeekObj = {
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
    7: "Sun",
  };

  const [documents, setDocuments] = useState([]);
  const [showDocsPopup, setShowDocsPopup] = useState(false);

  const [vehiclesAssigned, setVehiclesAssigned] = useState([]);
  const [showAssignedVehiclesPopup, setShowAssignedVehiclesPopup] =
    useState(false);

  useEffect(() => {
    //Fetch docs
    (async () => {
      if (rowData?.additional_documents) {
        const additionalDocsData = JSON.parse(rowData?.additional_documents);
        const allDocsArr = additionalDocsData.All_Documents;
        let temp = [];
        let count = 0;

        for (let doc of allDocsArr) {
          const [[key, value]] = Object.entries(doc);
          console.log(key, value);
          const { items, image, visible } = value;
          let isVisible = visible;

          if (typeof visible === "object") {
            isVisible = visible[0];
          }

          //For users, image format has two formats

          // let updatedDate = "";
          // if (items[0]?.title === "Updated date") {
          //   updatedDate = items[0]?.author;
          // }
          // (async () => {
          const { url, extension } = await getUserDocUrl(
            rowData.org_id,
            rowData.user_id,
            key,
            image
          );
          console.log("url", url);
          temp.push({
            id: Date.now() + count,
            // docName: key,
            // existingDoc: true,
            // imgUpdated: false,
            // fields: updatedDate ? items.slice(1, items.length) : items,
            // updatedDate: updatedDate ? updatedDate : "",
            // isVisible: isVisible,
            // image: {
            //   url: url,
            //   extension: extension,
            //   type: image,
            // },
            url: url,
            extension: extension,
            type: image,
          });

          count += 1;
        }

        setDocuments(temp);
      } else {
        setDocuments([]);
      }
    })();

    (async () => {
      let selectedVehicles = [];
      const res = await postApi(
        {
          user_id: rowData.user_id,
          querystring: "getoperatorvehicle",
        },
        "commonNew"
      );
      const { user_data } = res;
      if (user_data.length > 0) {
        const selectedVehIds = user_data.map((v) => v.veh_id);
        selectedVehicles = vehicles
          .filter((v) => selectedVehIds.includes(v.vehicleId))
          .map((v) => {
            if (selectedVehIds.includes(v.vehicleId)) {
              const { veh_id, veh_img, org_id, label } = v;
              const imageUrl =
                veh_img && veh_img !== NATEXT
                  ? `${s3Url}/org_${org_id}/vehicle_${veh_id}/${veh_img}`
                  : defaultVehicleIcon;

              return { label, imageUrl };
            }
          });
      }
      setVehiclesAssigned(selectedVehicles);
    })();
  }, [rowData]);

  //const { roleId } = useSelector((state) => state.user);

  //const working_days = '1:3:5';

  //const roleId = 4;
  return (
    <>
      {showDocsPopup && (
        <ZoomModal
          showModal={showDocsPopup}
          setShowModal={setShowDocsPopup}
          docs={documents}
        />
      )}
      {showAssignedVehiclesPopup && (
        <AssignedEntitiesModal
          showPopup={showAssignedVehiclesPopup}
          setShowPopup={setShowAssignedVehiclesPopup}
          heading={t("assigned_vehicles")}
          assignedEntities={vehiclesAssigned}
        />
      )}
      <Row>
        {email && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("email")}</ItemLabel>
              <ItemValue>{email}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {phone && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("phone")}</ItemLabel>
              <ItemValue>{phone}</ItemValue>
            </ItemContainer>
          </Col>
        )}
        {manager_name && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("manager")}</ItemLabel>
              <ItemValue>
                {manager_name && manager_name !== "N/A" ? manager_name : NATEXT}
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {role_id === 4 && roleId != 4 && (
          <Row
            style={{ background: taskBgColor, borderRadius: "5px" }}
            className="mx-0 my-3"
          >
            <Col md={12}>
              <ItemContainer>
                <ItemLabel>{t("rate")}</ItemLabel>
                <ItemValue>
                  {hourly_rate && +hourly_rate > 0 ? hourly_rate : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={12}>
              <ItemContainer>
                <ItemLabel>{t("schedule")}</ItemLabel>
                <ItemValue className="d-flex gap-2 pt-1">
                  {[1, 2, 3, 4, 5, 6, 7]?.map((no) => (
                    <>
                      <DayContainer
                        active={working_days
                          ?.split(":")
                          ?.includes(no.toLocaleString())}
                      >
                        {daysOfWeekObj[no]}
                      </DayContainer>
                    </>
                  ))}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={6}>
              <ItemContainer>
                <ItemLabel>{t("start_time")}</ItemLabel>
                <ItemValue>
                  {starting_time && starting_time !== "00:00:00"
                    ? starting_time?.slice(0, 5)
                    : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>

            <Col md={6}>
              <ItemContainer>
                <ItemLabel>{t("end_time")}</ItemLabel>
                <ItemValue>
                  {ending_time && ending_time !== "00:00:00"
                    ? ending_time?.slice(0, 5)
                    : NATEXT}
                </ItemValue>
              </ItemContainer>
            </Col>
          </Row>
        )}

        {!!joining_date && (
          <Col md={6}>
            <ItemContainer>
              <ItemLabel>{t("joining_date")}</ItemLabel>
              <ItemValue>{getDisplayDate(joining_date)}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {home_address && (
          <Col md={12}>
            <ItemContainer>
              <ItemLabel>{t("address")}</ItemLabel>
              <ItemValue>{home_address}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {comments && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("notes_tv")}</ItemLabel>
              <ItemValue>{comments}</ItemValue>
            </ItemContainer>
          </Col>
        )}

        {license_img && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("license")}</ItemLabel>
              <ItemValue>
                <ImageWithZoom
                  img={{
                    url: `${s3Url}/org_${org_id}/user_${user_id}/license/${license_img}`,
                  }}
                  shouldDelete={false}
                />
              </ItemValue>
            </ItemContainer>
          </Col>
        )}

        {documents.length > 0 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("user_documents")}</ItemLabel>
              <LinkSpan onClick={() => setShowDocsPopup(true)}>
                {t("count_documents_added", { count: documents.length })}
              </LinkSpan>
            </ItemContainer>
          </Col>
        )}

        {vehiclesAssigned.length > 0 && (
          <Col sm={12}>
            <ItemContainer>
              <ItemLabel>{t("assigned_vehicles")}</ItemLabel>
              <LinkSpan onClick={() => setShowAssignedVehiclesPopup(true)}>
                {t("count_vehicles_assigned", {
                  count: vehiclesAssigned.length,
                })}
              </LinkSpan>
            </ItemContainer>
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserManagementRightPanel;

export const DayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 8px;
  font-size: 12px;
  height: 24px;
  //margin-bottom: 18px;
  background: white;
  border-radius: 12px;
  ${(p) => p.active && `background: ${colorLightBlueStatus}`}
`;
