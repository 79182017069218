import React, { useCallback, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce, generateTreeArr } from "../../../shared/helpers";
import { Card, CardBody } from "@/shared/components/Card";
import {
  AddFormButton,
  ColumnHeading,
  FilterButton,
} from "../../../shared/components/TableElements";
import { useTranslation } from "react-i18next";
import { Desc } from "../../Reminders/MaintenanceReminders/MaintenanceRemindersForm";
import useClickAway from "../../../shared/custom-hooks/useClickAway";
import styled from "styled-components";
import {
  colorBackground,
  colorBlue,
  colorHover,
  colorTextNew,
} from "../../../utils/palette";
import GroupsFormPopup from "./GroupsFormPopup";
import { DeletePopup } from "../../../shared/components/table/components/DeletePopup";
import { useAuth } from "../../../contexts/AuthContext";
import { getGroups } from "../../../api/getUser";
import { updateGroupsList } from "../../../features/groups/groupsSlice";
import Snackbar from "../../../shared/components/Snackbar";
import { addMsg } from "../../../features/snackbar/snackbarSlice";
import { SearchInput } from "../../../shared/components/table/components/TempFilter";
import ImportComponent from "../../../shared/components/ImportComponent";
import { AlwaysScrollToBottom } from "../../VehicleInspection/InspectionForms/InspectionFormsForm";
import SubscribePopup from "../../../shared/components/SubscribePopup";
import { updateExternalFilter } from "../../../features/storedFilters/storedFiltersSlice";

const GroupsTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const groupsList = useSelector((state) => state.groups.list);

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [groups, setGroups] = useState([]);

  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);
  const [newGroupInfo, setNewGroupInfo] = useState(null);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const groupIdToDelete = useRef("");

  const mode = useRef(null);
  const { reload, setReload, tag } = useAuth();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const { msg } = useSelector((state) => state.snackbar);

  const [globalFilter, setGlobalFilter] = useState("");
  const [showImportPopup, setShowImportPopup] = useState(false);

  const [expandedKeys, setExpandedKeys] = useState({});

  //State to show subscribe popup
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const storedFilters = useSelector((state) => state.storedFilters);

  useEffect(() => {
    const groupsFilters = storedFilters.groups;
    const searchFilter = groupsFilters?.find((f) => f.filterName === "search");
    if (searchFilter && searchFilter?.value) {
      setGlobalFilter(searchFilter?.value);
    }
  }, []);

  useEffect(() => {
    if (globalFilter) {
      expandAll();
    } else {
      setExpandedKeys({});
    }
  }, [globalFilter]);

  //Reload Effect
  useEffect(() => {
    if (reload === "groups") {
      (async () => {
        setReload("");
        //Get Groups
        const groupsList = await getGroups("get_all_groups_list", t);
        dispatch(updateGroupsList(groupsList));
      })();
    }
  }, [reload]);

  useEffect(() => {
    if (groupsList?.length > 0) {
      setGroups(generateTreeArr(groupsList.slice(1, groupsList.length)));
    }
  }, [groupsList]);

  useEffect(() => {
    //console.log(msg);
    if (msg) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
        dispatch(addMsg(""));
      }, 5000);
    } else {
      setShowSnackbar(false);
    }
  }, [msg]);

  const onSearchChange = (value) => {
    dispatch(
      updateExternalFilter({
        screen: "groups",
        filterName: "search",
        filterValue: value,
      })
    );
  };

  const debouncedSearch = useCallback(debounce(onSearchChange), []);

  const ActionTemplate = (group) => {
    const [selectedGroupId, setSelectedGroupId] = useState("");

    const clickAwayRef = useClickAway({
      handleCloseElement: () => setSelectedGroupId(""),
    });

    const handleMoreOptionsClick = (groupId) => {
      // if (selectedGroupId) {
      //   setSelectedGroupId("");
      // } else {
      //   setSelectedGroupId(groupId);
      // }

      setSelectedGroupId((prev) => (prev === groupId ? "" : groupId));

      // if (groupId === selectedGroupId) {
      //   setSelectedGroupId("");
      // } else {
      //   setSelectedGroupId(groupId);
      // }
      //setShowMoreOptions((prev) => !prev);
    };

    const handleEdit = () => {
      mode.current = "from-edit-group";
      setNewGroupInfo(group);
      setShowAddGroupPopup(true);
    };

    const handleAddSubGroup = () => {
      if (tag === "free-user") {
        setShowSubscribePopup(true);
        return;
      }
      mode.current = "from-add-sub-group";
      setNewGroupInfo(group);
      setShowAddGroupPopup(true);
    };

    const handleDelete = () => {
      groupIdToDelete.current = group.group_id;
      setShowDeletePopup(true);
      //mode.current = "from-edit-group";
      //setNewGroupInfo(group);
    };

    return (
      <div
        ref={clickAwayRef}
        className="d-flex align-items-center justify-content-end"
        style={{ position: "relative" }}
      >
        <FilterButton
          style={{ minWidth: 0, padding: 8 }}
          onClick={() => handleMoreOptionsClick(group.group_id)}
        >
          <i
            className="fi fi-rr-menu-dots-vertical"
            style={{ margin: "2px 0 0 0" }}
            //onClick={() => handleMoreOptionsClick(group.group_id)}
          />
        </FilterButton>
        {selectedGroupId === group.group_id && (
          <OptionsMenuWrap>
            <OptionsMenu onClick={handleAddSubGroup}>
              {t("add_sub_group")}
            </OptionsMenu>
            <OptionsMenu onClick={handleEdit}>{t("edit")}</OptionsMenu>
            <OptionsMenu onClick={handleDelete}>{t("delete")}</OptionsMenu>
          </OptionsMenuWrap>
        )}
        {/* <AlwaysScrollToBottom /> */}
      </div>
    );
  };

  const CustomColumn = (g) => {
    return (
      <span className="d-flex flex-column justify-content-center">
        <p>{g.group_name}</p>
        <Desc>{g.description}</Desc>
      </span>
    );
  };

  const handleAddGroup = () => {
    if (tag === "free-user") {
      setShowSubscribePopup(true);
      return;
    }
    mode.current = null;
    setShowAddGroupPopup(true);
  };

  const getHeader = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const clickAwayDropwdownRef = useClickAway({
      handleCloseElement: () => setShowDropdown(false),
    });
    return (
      <>
        {showSubscribePopup && (
          <SubscribePopup
            showPopup={showSubscribePopup}
            setShowPopup={setShowSubscribePopup}
            message={t("subscribe_msg")}
          />
        )}
        <div
          className="d-flex justify-content-between"
          //style={{ width: "-webkit-fill-available" }}
        >
          <SearchInput
            type="search"
            placeholder={t("filter_by_group_name")} //{t("filter.search_placeholder")}
            onChange={(e) => {
              setGlobalFilter(e.target.value);
              debouncedSearch(e.target.value);
            }}
            value={globalFilter}
            style={{ width: "180px" }}
            //onChange={(e) => onChange(e.target.value)}
            //active={searchHasValue}
          />
          {/* <FilterButton
          style={{ minWidth: "min-content" }}
          onClick={() => setShowImportPopup(true)}
        >
          <i className="fi fi-rr-file-import p-0 m-0"></i>
        </FilterButton> */}
          <div ref={clickAwayDropwdownRef}>
            {/* <FilterButton
            style={{ minWidth: "min-content" }}
            onClick={() => setShowDropdown((prev) => !prev)}
          >
            <i className="fi fi-rr-menu-dots-vertical m-0"></i>
          </FilterButton> */}
            <FilterButton
              style={{ minWidth: 0, padding: 8 }}
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <i
                className="fi fi-rr-menu-dots-vertical"
                style={{ margin: "2px 0 0 0" }}
                //onClick={() => handleMoreOptionsClick(group.group_id)}
              />
            </FilterButton>
            {showDropdown && (
              <OptionsMenuWrap
                id="more-options"
                style={{ top: 48, right: 16, fontWeight: 400 }}
              >
                <OptionsMenu
                  id="import-button"
                  onClick={() => {
                    setShowImportPopup(true);
                    setShowDropdown(false);
                  }}
                >
                  {t("import")}
                </OptionsMenu>
              </OptionsMenuWrap>
            )}
          </div>
        </div>
      </>
    );
  };

  let header = getHeader();

  const expandAll = () => {
    let _expandedKeys = {};

    for (let node of groups) {
      //console.log("node", node);
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      // if (node.label.includes(globalFilter)) {
      //   _expandedKeys[node.key] = true;
      // }

      // if (node.parentGroup) {
      //   _expandedKeys[node.parentGroup] = true;
      // } else {
      //   _expandedKeys[node.key] = true;
      // }
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  return (
    <>
      {showDeletePopup && (
        <DeletePopup
          showPopup={showDeletePopup}
          setShowPopup={setShowDeletePopup}
          screen={"/groups"}
          deleteApiData={{
            querystring: "multiple_delete",
            delete_ids: groupIdToDelete.current,
            sub_query_string: "GROUPS",
          }}
        />
      )}
      {showAddGroupPopup && (
        <GroupsFormPopup
          showPopup={showAddGroupPopup}
          setShowPopup={setShowAddGroupPopup}
          newGroupInfo={newGroupInfo}
          setNewGroupInfo={setNewGroupInfo}
          mode={mode.current}
        />
      )}
      {showImportPopup && (
        <ImportComponent
          showPopup={showImportPopup}
          setShowPopup={setShowImportPopup}
          screenName={"groups"}

          //modalHeading={"Import Service Tasks into Simply Fleet"}
        />
      )}
      <Container>
        <Row className="mx-1 d-flex align-items-center justify-content-around">
          <Col>
            <ColumnHeading>{t("groups")}</ColumnHeading>
          </Col>
          <Col className="d-flex justify-content-end pb-1">
            <AddFormButton id="add-group-button" onClick={handleAddGroup}>
              {t("add_group")}
            </AddFormButton>
          </Col>
        </Row>
        <Row className="p-2">
          {/* <DataReactTable reactTableData={reactTableData} /> */}
          <Col>
            <Card className="p-0 m-0">
              <CardBody className="p-0 m-0">
                {/* <FilterButton
                  style={{ minWidth: "min-content" }}
                  onClick={() => setShowImportPopup(true)}
                >
                  <i className="fi fi-rr-file-import p-0 m-0"></i>
                </FilterButton> */}
                <TreeTable
                  //scrollable
                  value={groups}
                  onToggle={(e) => setExpandedKeys(e.value)}
                  //className="d-flex justify-content-between"
                  globalFilter={globalFilter}
                  //onFocusCapture={expandAll}
                  //onFilter={expandAll}
                  expandedKeys={expandedKeys}
                  header={header}
                  //footer={footer}
                  //togglerTemplate={togglerTemplate}
                  //tableStyle={{ minWidth: "50rem" }}
                >
                  {/* <Column field="group_name" header="Name" expander></Column> */}
                  {/* <Column field="description" header="Description"></Column> */}
                  <Column
                    headerStyle={{ display: "none" }}
                    body={CustomColumn}
                    header="Group"
                    expander
                    className="custom-column"
                    field="group_name"
                    //filter={true}
                    filterMatchMode="contains"
                    filterPlaceholder="Filter by group name"
                    //filterElement={<FilterElement />}
                  ></Column>
                  <Column
                    body={ActionTemplate}
                    headerStyle={{ display: "none" }}
                    //filterMatchMode="contains"
                    //field="desc"
                    //filterElement={<div style={{ display: "none" }}></div>}
                  />
                </TreeTable>
              </CardBody>
            </Card>
            {showSnackbar && (
              <Snackbar
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GroupsTable;

//styles

const OptionsMenuWrap = styled.ul`
  z-index: 100;
  min-width: 120px;
  padding: 0;
  position: absolute;
  top: 34px;
  border: 1px solid lightgray;
  background: white;
`;

export const OptionsMenu = styled.li`
  //text-align: center;
  cursor: pointer;
  list-style-type: none;
  //width: 120px;
  height: 40px;
  white-space: nowrap;
  padding: 8px;
  margin-top: 0;
  background: ${colorBackground};
  color: ${colorTextNew};

  &:hover {
    color: ${colorBlue};
  }

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }
  }
`;
