import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { FormContainer } from "../../shared/components/form/FormElements";
import FormHeader from "../../shared/components/form/FormHeader";
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import { NavItem, NavLink, TabsWrap } from "@/shared/components/Tabs";
import TripDetailsForm from "./components/TripDetailsForm";
import styled from "styled-components";
import { scrollbarStyles } from "../../utils/palette";
import SecondaryDataTable from "./components/SecondaryDataTable";
import FillupsFormPopup from "./components/FillupsFormPopup";
import { useRef } from "react";
import { postApi } from "../../api/getUser";
import {
  getDistanceFactor,
  getDistanceUnit,
  getEditUsers,
  getImgString,
  getOdoUnit,
  isObjEmpty,
  odoValidation,
  uploadDocsToS3,
} from "../../shared/helpers";

import getUnixTime from "date-fns/getUnixTime";
import { addMsg } from "../../features/snackbar/snackbarSlice";
import {
  getSecondaryTableColumns,
  //expensesColumns,
  //fillupsColumns,
  //incomeColumns,
  updateExpensesData,
  updateFillupsData,
  updateIncomeData,
  updateTable,
} from ".";
import ErrorPopup from "../../shared/components/ErrorPopup";
import { s3Url } from "../../shared/constants";

const TripsForm = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const { volume, currency, distance, consumption } = useSelector(
    (state) => state.units
  );

  const routeData = location?.state?.data;
  const rowData = routeData?.rowData;
  const screen = location?.state?.screen;
  const type = location?.state?.type;

  //const { reload, toggleReload } = useContext(DeletedContext);

  const [fillupsData, setFillupsData] = useState([]);

  const [expensesData, setExpensesData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);

  const vehiclesList = useSelector((state) => state.vehicles.list);
  const vehicles = vehiclesList.slice(1, vehiclesList.length);
  const users = useSelector((state) => state.users.list);

  const { orgId, userId } = useSelector((state) => state.user);

  const methods = useForm();
  const { setError, clearErrors, setValue } = methods;
  const { errors } = methods.formState;

  const vehicle = methods.watch("vehicle");
  const driver = methods.watch("driver");
  const startingOdo = methods.watch("startingOdo");
  const endingOdo = methods.watch("endingOdo");
  const depDate = methods.watch("depDate");
  const arrDate = methods.watch("arrDate");
  const depLocation = methods.watch("depLocation");
  const arrLocation = methods.watch("arrLocation");
  const comments = methods.watch("comments");

  const [tripDocs, setTripDocs] = useState([]);

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  const [newRecordData, setNewRecordData] = useState({
    type: "",
    vehicleId: "",
    userId: "",
    newRecordId: "",
    screenName: "",
    rowData: "",
  });

  const firstTimeEdit = useRef(false);

  //To toggle error popup while adding/editing form
  const [showErrPopup, setShowErrPopup] = useState(false);

  //Initial Effect
  useEffect(() => {
    if (type !== "edit") {
      setValue("depDate", new Date());
    }
  }, []);

  useEffect(() => {
    if (type !== "edit" && users?.length > 0) {
      setValue(
        "driver",
        users.find((u) => u.user_id === userId)
      );
    }
  }, [users]);

  //Update Fillups, Trips, Income row data
  useEffect(() => {
    (async () => {
      const { type, vehicleId, newRecordId, screenName } = newRecordData;
      if (newRecordId && screenName) {
        if (screenName === "fillups") {
          const apiData = {
            fillup_id: newRecordId,
            veh_id: vehicleId,
            querystring: "getsinglefillup",
          };
          //console.log(singleFillupApiData);
          const res = await postApi(apiData, "commonNew");
          if (res.success) {
            const rowData = res?.user_data[0];
            updateFillupsData(
              rowData,
              type,
              setFillupsData,
              {
                distance,
                consumption,
                currency,
              },
              t
            );
          }
        }

        if (screenName === "income") {
          const apiData = {
            income_id: newRecordId,
            veh_id: vehicleId,
            querystring: "getsingleincome",
            //tripIncomeIds: newRecordId,
          };
          //console.log(singleFillupApiData);
          const res = await postApi(apiData, "commonNew");
          if (res.success) {
            const rowData = res?.user_data[0];
            updateIncomeData(
              rowData,
              type,
              setIncomeData,
              {
                currency,
                distance,
              },
              t
            );
          }
        }

        if (screenName === "expenses") {
          console.log(newRecordData.expenseTaskIds);
          const apiData = {
            expense_id: newRecordId,
            veh_id: vehicleId,
            expense_task_ids: newRecordData.expenseTaskIds,
            querystring: "getsingleexpense",
          };

          const res = await postApi(apiData, "commonNew");
          if (res.success) {
            const rowData = res?.user_data[0];
            updateExpensesData(
              rowData,
              type,
              setExpensesData,
              {
                currency,
                distance,
              },
              t
            );
          }
        }
      }
    })();
  }, [newRecordData]);

  //Effect for edit
  useEffect(() => {
    (async () => {
      if (
        type === "edit" &&
        rowData &&
        vehiclesList?.length > 0 &&
        orgId
        //&&
        //users?.length > 0
      ) {
        firstTimeEdit.current = true;
        console.log(rowData);
        const {
          arr_date,
          arr_loc,
          arr_odo,
          dep_date,
          dep_loc,
          dep_odo,
          row_id,
          trip_by,
          trip_id,
          avg_speed,
          receipt_image_name,
          trip_expenses,
          trip_fillups,
          trip_income,
          trip_distance,
          veh_id,
          trip_time,
          primary_meter,
          comments,
        } = rowData;

        const distanceFactor = getDistanceFactor(primary_meter, distance);
        let depOdo = 0;
        let arrOdo = 0;
        if (+dep_odo) {
          depOdo = +dep_odo / distanceFactor;
          depOdo = Number(depOdo).toFixed(2);
        }
        if (+arr_odo) {
          arrOdo = +arr_odo / distanceFactor;
          arrOdo = Number(arrOdo).toFixed(2);
        }

        setValue(
          "vehicle",
          vehicles?.find((v) => v.veh_id === veh_id)
        );

        //Set user and user options
        (async () => {
          const editUsers = await getEditUsers(veh_id, trip_by);
          if (editUsers?.length > 0) {
            const selectedUser = editUsers?.find(
              (u) => u.user_id === rowData?.trip_by
            );
            setValue("driver", selectedUser);
            setUserOptions(editUsers);
          }
        })();

        // setValue(
        //   "driver",
        //   users.find((u) => u.user_id === trip_by)
        // );
        setValue("depDate", new Date(dep_date * 1000));

        setValue("arrDate", arr_date ? new Date(arr_date * 1000) : null);
        setValue("depLocation", dep_loc);
        setValue("arrLocation", arr_loc);
        setValue("startingOdo", depOdo);
        setValue("endingOdo", arrOdo);
        setValue("comments", comments);

        //Logic to set Docs
        let imgNames = [];
        const editImgName = receipt_image_name;
        if (editImgName) {
          imgNames = editImgName.split(":::");

          const imgs = [];
          imgNames.map((imgName, i) => {
            imgs.push({
              existing: true,
              imgName: imgName,
              id: Date.now() + i,
              url: `${s3Url}/org_${orgId}/trips/${imgName}`,
            });
          });
          //console.log("imgs", imgs);
          setTripDocs(imgs);
        }

        //Logic to update inner tables
        if (trip_fillups) {
          const fillupsApiData = {
            querystring: "gettripfillups",
            tripFillupIds: trip_fillups,
          };
          await updateTable(
            fillupsApiData,
            updateFillupsData,
            setFillupsData,
            {
              currency,
              distance,
              consumption,
            },
            t
          );
        }

        if (trip_income) {
          const incomeApiData = {
            querystring: "gettripIncome",
            tripIncomeIds: trip_income,
          };
          await updateTable(
            incomeApiData,
            updateIncomeData,
            setIncomeData,
            {
              currency,
              distance,
            },
            t
          );
        }

        if (trip_expenses) {
          const expensesApiData = {
            querystring: "gettripExpense",
            tripExpenseIds: trip_expenses,
          };

          await updateTable(
            expensesApiData,
            updateExpensesData,
            setExpensesData,
            { currency, distance },
            t
          );
        }
      }
    })();
  }, [rowData, vehiclesList, orgId]);

  const onSubmit = async () => {
    try {
      if (+endingOdo && +startingOdo >= +endingOdo) {
        setError(
          "endingOdo",
          {
            type: "custom",
            message: "Ending Odo must be greater than Starting Odo",
          },
          { shouldFocus: true }
        );

        return;
      }

      setSaveBtnLoading(true);
      const tripId =
        type === "edit"
          ? rowData.trip_id
          : `${vehicle.vehicleId}_${Date.now()}`;

      const distanceFactor = getDistanceFactor(vehicle.primary_meter, distance);

      //Odo Validation

      //Starting Odo
      const startingOdoConverted = +startingOdo * distanceFactor;
      const startingOdoCheckApiData = {
        odo: startingOdoConverted,
        veh_id: vehicle.vehicleId,
        datetocheck: getUnixTime(depDate),
        querystring: "get_around_dates_v3",
        check_from: "trips",
        check_id: type === "edit" ? rowData.trip_id : "",
      };
      const startingOdoValidationRes = await odoValidation(
        "startingOdo",
        startingOdoCheckApiData,
        setError,
        setSaveBtnLoading,
        t,
        distanceFactor,
        startingOdo
      );

      if (!startingOdoValidationRes) {
        return;
      }

      //Ending Odo
      let endingOdoConverted = 0;
      if (endingOdo) {
        // if (+endingOdo <= startingOdo) {
        //   setError(
        //     "endingOdo",
        //     {
        //       type: "min",
        //       message: "Ending Odo must be greater than Starting Odo",
        //     },
        //     { shouldFocus: true }
        //   );
        //   return;
        // }

        endingOdoConverted = +endingOdo * distanceFactor;
        const endingOdoCheckApiData = {
          odo: endingOdoConverted,
          veh_id: vehicle.vehicleId,
          datetocheck: getUnixTime(arrDate),
          querystring: "get_around_dates_v3",
          check_from: "trips",
          check_id: type === "edit" ? rowData.trip_id : "",
        };
        const endingOdoValidationRes = await odoValidation(
          "endingOdo",
          endingOdoCheckApiData,
          setError,
          setSaveBtnLoading,
          t,
          distanceFactor,
          endingOdo
        );

        if (!endingOdoValidationRes) {
          return;
        }
      }

      const { imgString, imgsToS3 } = getImgString(
        tripDocs,
        tripId,
        userId,
        orgId,
        "trips"
      );

      const apiData = {
        operation: type === "edit" ? "update" : "create",
        querystring: "insert_trip",
        insertTripVehId: vehicle.vehicleId,
        insertTripDepDate: getUnixTime(depDate),
        insertTripDepOdo: startingOdoConverted,
        insertTripDepLoc: depLocation ? depLocation : "",
        insertTripArrDate: arrDate ? getUnixTime(arrDate) : 0,
        insertTripArrOdo: endingOdo ? endingOdoConverted : 0,
        insertTripArrLoc: arrLocation ? arrLocation : "",
        insertTripOdoUnit:
          vehicle.primary_meter === "Hours" ? "Hours" : distance,
        insertTripTripDistance: endingOdo
          ? endingOdoConverted - startingOdoConverted
          : 0,
        insertTripTripTime: arrDate ? getUnixTime(arrDate - depDate) : 0,
        insertTripTripFillups:
          fillupsData.length > 0
            ? fillupsData.map((row) => row.rowId).join(":::")
            : "",
        insertTripTripExpenses:
          expensesData.length > 0
            ? expensesData.map((row) => row.rowId).join(":::")
            : "",
        insertTripTripIncome:
          incomeData.length > 0
            ? incomeData.map((row) => row.rowId).join(":::")
            : "",
        insertTripCurrency: currency ? currency : "",
        insertTripTripBy: driver.user_id,
        insertTripReceiptImageName: imgString,
        insertTripComments: comments ? comments : "",
        insertTripAction: type === "edit" ? 2 : 1,
        insertTripId: tripId,
        insertAvgSpeed: type === "edit" ? rowData.avg_speed : "",
        sync_version: "v2",
      };

      console.log(apiData);

      const snackbarMsg =
        type === "edit"
          ? t("record_edited_msg", { recordName: t("trip") })
          : t("record_added_msg", { recordName: t("trip") });
      const response = await postApi(apiData, "commonNew");

      if (response.success === 1) {
        if (imgsToS3?.length > 0) {
          const docsUploaded = await uploadDocsToS3(imgsToS3);
          if (docsUploaded) {
            setSaveBtnLoading(false);
            dispatch(addMsg(snackbarMsg));
            history.push("/trips", { fromCancel: false });
          }
        } else {
          setSaveBtnLoading(false);
          dispatch(addMsg(snackbarMsg));
          history.push("/trips", { fromCancel: false });
        }
      } else {
        setSaveBtnLoading(false);
        setShowErrPopup(true);
        return;
      }
    } catch (e) {
      setSaveBtnLoading(false);
      setShowErrPopup(true);
      return;
    }
  };
  return (
    <>
      {showErrPopup && (
        <ErrorPopup showPopup={showErrPopup} setShowPopup={setShowErrPopup} />
      )}
      <FormProvider {...methods}>
        <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
          <FormHeader
            handleCancel={() => history.push("/trips", { fromCancel: true })}
            //disableSave={!vehicle}
            isSaveLoading={saveBtnLoading}
            title={type === "edit" ? t("edit_trips") : t("add_trips")}
            //handleCancel={handleCancel}
          />
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardBody
                    style={{
                      padding: 0,
                      //height: `${window.innerHeight - 150}px`,
                      //overflow: "auto",
                    }}
                  >
                    <TabsWrap>
                      <Tabs
                        rowData={rowData}
                        type={type}
                        fillupsData={fillupsData}
                        setFillupsData={setFillupsData}
                        expensesData={expensesData}
                        setExpensesData={setExpensesData}
                        incomeData={incomeData}
                        setIncomeData={setIncomeData}
                        newRecordData={newRecordData}
                        setNewRecordData={setNewRecordData}
                        vehicle={vehicle}
                        driver={driver}
                        tripDocs={tripDocs}
                        setTripDocs={setTripDocs}
                        firstTimeEdit={firstTimeEdit}
                        errors={errors}
                        userOptions={userOptions}
                        setUserOptions={setUserOptions}
                      />
                    </TabsWrap>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </FormContainer>
      </FormProvider>
    </>
  );
};

export default TripsForm;

const Tabs = ({
  rowData,
  type,
  fillupsData,
  setFillupsData,
  expensesData,
  setExpensesData,
  incomeData,
  setIncomeData,
  newRecordData,
  setNewRecordData,
  vehicle,
  driver,
  tripDocs,
  setTripDocs,
  firstTimeEdit,
  errors,
  userOptions,
  setUserOptions,
}) => {
  const { t } = useTranslation("common");
  const [activeKey, setActiveKey] = useState("1");

  const { fillupsColumns, expensesColumns, incomeColumns } =
    getSecondaryTableColumns();

  return (
    <Tab.Container
      defaultActiveKey="1"
      activeKey={activeKey}
      onSelect={(e) => setActiveKey(e)}
    >
      <TabsWrap>
        <Nav className="nav-tabs">
          <NavItem active={activeKey == 1}>
            <NavLink eventKey="1">
              {t("trip_details")}{" "}
              {!isObjEmpty(errors) && (
                <i
                  className="fi fi-rr-triangle-warning"
                  style={{
                    color: "red",
                    position: "relative",
                    top: "2px",
                  }}
                ></i>
              )}
            </NavLink>
          </NavItem>
          {vehicle && (
            <>
              <NavItem active={activeKey == 2}>
                <NavLink eventKey="2">{`${t("trip_fillups")} (${
                  fillupsData.length
                })`}</NavLink>
              </NavItem>
              <NavItem active={activeKey == 3}>
                <NavLink eventKey="3">{`${t("trip_expenses")} (${
                  expensesData.length
                })`}</NavLink>
              </NavItem>
              <NavItem active={activeKey == 4}>
                <NavLink eventKey="4">{`${t("trip_income")} (${
                  incomeData.length
                })`}</NavLink>
              </NavItem>
            </>
          )}
        </Nav>
        <Tab.Content>
          <TabPane
            eventKey="1"
            // style={{
            //   height: `${window.innerHeight - 200}px`,
            //   overflow: "auto",
            // }}
          >
            <TripDetailsForm
              rowData={rowData}
              type={type}
              tripDocs={tripDocs}
              setTripDocs={setTripDocs}
              firstTimeEdit={firstTimeEdit}
              userOptions={userOptions}
              setUserOptions={setUserOptions}
              //userInfo={userInfo}
            />
          </TabPane>
          <TabPane eventKey="2">
            <SecondaryDataTable
              rows={fillupsData}
              setRows={setFillupsData}
              columns={fillupsColumns}
              heading={
                fillupsData.length > 0
                  ? t("items_recorded_during_trip", {
                      screenName: t("fillups"),
                    })
                  : t("no_items_recorded_during_trip", {
                      screenName: t("fillups"),
                    })
              }
              screenName={"fillups"}
              newRecordData={newRecordData}
              vehicle={vehicle}
              driver={driver}
              setNewRecordData={setNewRecordData}
              userOptions={userOptions}
            />
          </TabPane>
          <TabPane eventKey="3">
            <SecondaryDataTable
              rows={expensesData}
              setRows={setExpensesData}
              columns={expensesColumns}
              heading={
                expensesData.length > 0
                  ? t("items_recorded_during_trip", {
                      screenName: t("expenses_title"),
                    })
                  : t("no_items_recorded_during_trip", {
                      screenName: t("expenses_title"),
                    })
              }
              screenName={"expenses"}
              newRecordData={newRecordData}
              vehicle={vehicle}
              driver={driver}
              setNewRecordData={setNewRecordData}
              userOptions={userOptions}
            />
          </TabPane>
          <TabPane eventKey="4">
            <SecondaryDataTable
              rows={incomeData}
              setRows={setIncomeData}
              columns={incomeColumns}
              heading={
                incomeData.length > 0
                  ? t("items_recorded_during_trip", {
                      screenName: t("income"),
                    })
                  : t("no_items_recorded_during_trip", {
                      screenName: t("income"),
                    })
              }
              screenName={"income"}
              newRecordData={newRecordData}
              vehicle={vehicle}
              driver={driver}
              setNewRecordData={setNewRecordData}
              userOptions={userOptions}
            />
          </TabPane>
        </Tab.Content>
      </TabsWrap>
    </Tab.Container>
  );
};

export const TabPane = styled(Tab.Pane)`
  ${scrollbarStyles};
  height: ${window.innerHeight - 192}px;
  overflow: auto;
  //padding: 1rem;
`;
