import React from "react";
import { Col, Row } from "react-bootstrap";
import { ItemContainer, ItemLabel, ItemValue } from ".";
import {
  getListingDisplayDate,
  getTranslatedReasonText,
} from "../../../../helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LinkSpan } from "../../../form/FormElements";

const UsageRightPanel = ({ rowData }) => {
  const { t } = useTranslation("common");
  const { currency } = useSelector((state) => state.units);
  const {
    transaction_date,
    reason,
    old_qty,
    new_qty,
    batch_id,
    batch_available_qty,
    service_task_name,
    veh_name,
    vendor,
    serviced_by,
    technician,
    cost,
    purchase_cost,
    service_id,
    wo_id,
    serviced_by_name,
    technician_name,
  } = rowData;

  const reasonText = getTranslatedReasonText(reason, t);

  const handleView = () => {
    if (reason === 1) {
      if (wo_id) {
        //View WO
        return;
      }

      if (service_id) {
        //View service
        return;
      }
    }
    if (reason !== 1) {
      //View batch
    }
  };

  return (
    <>
      <Row>
        <Col>
          <ItemContainer>
            <ItemLabel>{t("transaction_date")}</ItemLabel>
            <ItemValue>{getListingDisplayDate(transaction_date)}</ItemValue>
          </ItemContainer>
        </Col>
        <Col>
          <ItemContainer>
            <ItemLabel>{t("reason_for_change")}</ItemLabel>
            <ItemValue>{reasonText}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <ItemContainer>
            <ItemLabel>{t("qty_change")}</ItemLabel>
            <ItemValue>{+new_qty - +old_qty}</ItemValue>
          </ItemContainer>
        </Col>
        <Col>
          <ItemContainer>
            <ItemLabel>{t("batch_id")}</ItemLabel>
            <ItemValue>{batch_id}</ItemValue>
          </ItemContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <ItemContainer>
            <ItemLabel>{t("batch_available_qty")}</ItemLabel>
            <ItemValue>{batch_available_qty}</ItemValue>
          </ItemContainer>
        </Col>
        <Col>
          <>
            {reason === 0 && (
              <ItemContainer>
                <ItemLabel>{t("cost_per_unit")}</ItemLabel>
                <ItemValue>{`${cost} ${currency}`}</ItemValue>
              </ItemContainer>
            )}
            {reason === 1 && (
              <ItemContainer>
                <ItemLabel>{t("vehicle")}</ItemLabel>
                <ItemValue>{veh_name}</ItemValue>
              </ItemContainer>
            )}
          </>
        </Col>
      </Row>
      {(reason === 0 || reason === 1) && (
        <Row>
          {reason === 0 && (
            <>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("purchase_cost")}</ItemLabel>
                  <ItemValue>{`${purchase_cost} ${currency}`}</ItemValue>
                </ItemContainer>
              </Col>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("vendor")}</ItemLabel>
                  <ItemValue>{vendor}</ItemValue>
                </ItemContainer>
              </Col>
            </>
          )}

          {reason === 1 && (
            <>
              <Col>
                <ItemContainer>
                  <ItemLabel>{t("service_task_name")}</ItemLabel>
                  <ItemValue>{service_task_name}</ItemValue>
                </ItemContainer>
              </Col>
              <Col>
                <ItemContainer>
                  <ItemLabel>
                    {wo_id ? t("technician") : t("serviced_by")}
                  </ItemLabel>
                  <ItemValue>
                    {wo_id ? technician_name : serviced_by_name}
                  </ItemValue>
                </ItemContainer>
              </Col>
            </>
          )}
        </Row>
      )}
      <Row>
        <Col>
          <ItemContainer>
            <ItemValue>
              {/* {reason === 0 && (
                <LinkSpan onClick={handleViewBatch}>
                  {t("view_batch_details")}
                </LinkSpan>
              )} */}

              <LinkSpan onClick={handleView}>
                {reason === 1 && wo_id
                  ? t("view_wo_details")
                  : reason === 1 && service_id
                  ? t("view_service_details")
                  : t("view_batch_details")}
              </LinkSpan>
            </ItemValue>
          </ItemContainer>
        </Col>
      </Row>
    </>
  );
};

export default UsageRightPanel;
