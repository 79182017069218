import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
  useColumnOrder,
  useRowState,
} from "react-table";
import styled from "styled-components";
import { scrollbarStyles } from "@/utils/palette";
import ReactTableHeader from "./ReactTableHeader";
import BodyReactTable, { PageLoading } from "./ReactTableBody";
import ReactTablePagination from "./ReactTablePagination";
import { Table } from "../../TableElements";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import RowSelectionCheckbox from "./RowSelectionCheckbox";
import { Col, Row, Spinner } from "react-bootstrap";
import { colorBlue, colorLightGray } from "../../../../utils/palette";
import ReactTableFilterClient from "./ReactTableFilterClient";
import { useState } from "react";
import { lighten } from "polished";
import { DeletedContext } from "../../../../containers/App/Router/WrappedRoutes";
import RightPanel from "./RightPanel";
import { useAuth } from "../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const ReactTableConstructorClient = ({
  tableConfig,
  tableOptions,
  tableOptionalHook,
  columnsOrder,
  columnsHidden,
  valuesArray,
  initialData,
  defaultColumns,
  screen,
  data,
  setData,
  localFilters,
  setLocalFilters,
  rightPanelData,
  setRightPanelData,
}) => {
  const { t } = useTranslation("common");

  const {
    isEditable,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize,
    placeholder,
  } = tableConfig;

  const { deletedData } = useSelector((state) => state.filter);
  const { deleted, reload } = useContext(DeletedContext);

  const { noOfRecords } = useSelector((state) => state.columns);
  const colOrder = useSelector((state) => state.columns.order);
  const { loading, setLoading } = useAuth();

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    withDragAndDrop,
    updateDraggableData,
    updateEditableData,
    dataLength,
    selectedFlatRows,
    setRowState,
    setHiddenColumns,
    setColumnOrder,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, columnOrder, hiddenColumns, globalFilter },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useRowState,
    (hooks) => {
      if (
        !screen.includes("users") &&
        !screen.includes("vehicles") &&
        !screen.includes("faultcodes")
      ) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <RowSelectionCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            width: 40,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <RowSelectionCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
    //...tableOptionalHook
  );

  //Effect to handle initial column selection for client side rendered tables
  useEffect(() => {
    // if (allColumns.length > 0 && colOrder?.length > 0) {
    // if (screen.includes("users")) {
    //   setColumnOrder([1, 2, 3, 4, 5]);
    //   setHiddenColumns([6, 7]);
    // }
    if (screen.includes("vehicles") || screen.includes("users")) {
      let columnsToBeFiltered = [];
      if (screen.includes("vehicles")) {
        columnsToBeFiltered = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
      }
      if (screen.includes("users")) {
        columnsToBeFiltered = [2, 3, 4, 5, 6, 7];
      }
      //let columnsToBeFiltered = allColumns.map((col) => +col.id).slice(1);
      let columnsToShow = colOrder?.filter((col) => col != 0);
      let hiddenCols = columnsToBeFiltered.filter(
        (no) => !columnsToShow.includes(no)
      );
      setHiddenColumns(hiddenCols);
      setColumnOrder(columnsToShow);
      //setHiddenColumns([]);
    } else if (screen.includes("dailytimesheet")) {
      setColumnOrder([1, 2, 3]);
      setHiddenColumns([]);
    } else if (screen.includes("inspectionscheduling")) {
      setColumnOrder([1, 2, 3, 4, 5]);
      setHiddenColumns([]);
    }
    // }
  }, [colOrder, screen, data]);

  //Go back to first page whenever a filter is applied
  //(Since user may be in second/third page and needs to come back to first page when a filter is applied)
  useEffect(() => {
    //setRightPanelData({ show: false, content: "" });
    gotoPage(0);
  }, [localFilters, deletedData, reload]);

  const rowData = [...data];

  //Update no of records to show
  useEffect(() => {
    if (noOfRecords) {
      setLoading(false);
      setPageSize(noOfRecords);
    }
  }, [noOfRecords]);

  return (
    <>
      <Row style={{ background: lighten(0.15, "lightgray") }} className="py-2">
        <ReactTableFilterClient
          rows={rows}
          allColumns={allColumns}
          valuesArray={valuesArray}
          initialData={initialData}
          defaultColumns={defaultColumns}
          selectedFlatRows={selectedFlatRows}
          screen={screen}
          dataLength={dataLength}
          data={rowData}
          setData={setData}
          setLocalFilters={setLocalFilters}
          columnOrder={columnOrder}
          hiddenColumns={hiddenColumns}
          setColumnOrder={setColumnOrder}
          setHiddenColumns={setHiddenColumns}
          pageSize={pageSize}
          setRightPanelData={setRightPanelData}
          gotoPage={gotoPage}
        />
      </Row>
      <Row style={{ background: "white", height: "100%" }}>
        <Col
          style={{ height: "100%", padding: 0 }}
          md={rightPanelData.show ? 8 : 12}
        >
          {loading ? (
            <div className="text-center">
              <TableSpinner animation="border" />
            </div>
          ) : (
            <TableWrap
              pagination={withPagination}
              active={rightPanelData.show}
              height={
                valuesArray?.length
                  ? window.innerHeight - 300
                  : window.innerHeight - 240
              }
            >
              <Table
                {...getTableProps()}
                bordered
                id="data-table"
                className={
                  screen.includes("users") ||
                  screen.includes("vehicles") ||
                  screen.includes("faultcodes")
                    ? "table-hover p-left"
                    : ""
                }
              >
                {/* Removed bordered property from Table and added className='resizable-table'*/}
                <ReactTableHeader
                  headerGroups={headerGroups}
                  isSortable={isSortable}
                  isResizable={isResizable}
                />
                <BodyReactTable
                  page={page}
                  getTableBodyProps={getTableBodyProps}
                  prepareRow={prepareRow}
                  updateDraggableData={updateDraggableData}
                  updateEditableData={updateEditableData}
                  isEditable={isEditable}
                  withDragAndDrop={withDragAndDrop}
                  rightPanelData={rightPanelData}
                  setRightPanelData={setRightPanelData}
                  screen={screen}
                />
                {/* {rows.length === 0 && (  //removed pageCount === pageIndex + 1 ||
              <ReactTableFooter footerGroups={footerGroups} />
            )} */}
              </Table>
              {rows.length === 0 && (
                <div style={{ textAlign: "center", fontWeight: 600 }}>
                  {t("no_records")}
                </div>
              )}
            </TableWrap>
          )}
          {withPagination && rows.length > 0 && (
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={dataLength}
              screen={screen}
              rows={rows}
            />
          )}
        </Col>

        {rightPanelData.show && (
          <Col md={4} className="p-0">
            <RightPanel
              screen={screen}
              rightPanelData={rightPanelData}
              setRightPanelData={setRightPanelData}
              height={
                valuesArray?.length
                  ? window.innerHeight - 300
                  : window.innerHeight - 240
              }
            />
          </Col>
        )}
      </Row>
    </>
  );
};

ReactTableConstructorClient.propTypes = {
  tableConfig: PropTypes.shape({
    isEditable: PropTypes.bool,
    isResizable: PropTypes.bool,
    isSortable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    withPagination: PropTypes.bool,
    withSearchEngine: PropTypes.bool,
    manualPageSize: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string,
  }),
  tableOptions: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    data: PropTypes.arrayOf(PropTypes.shape()),
    setFilterValue: PropTypes.func,
    updateDraggableData: PropTypes.func,
    updateEditableData: PropTypes.func,
    defaultColumn: PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.shape({
        Cell: PropTypes.func,
      }).isRequired,
    ]),
    isEditable: PropTypes.bool,
    withDragAndDrop: PropTypes.bool,
    dataLength: PropTypes.number,
  }),
  //tableOptionalHook: PropTypes.arrayOf(PropTypes.func).isRequired,
};

ReactTableConstructorClient.defaultProps = {
  tableConfig: {
    isEditable: false,
    isResizable: false,
    isSortable: false,
    withDragAndDrop: false,
    withPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
  },
  tableOptions: [
    {
      columns: [],
      data: [],
      setFilterValue: () => {},
      updateDraggableData: () => {},
      updateEditableData: () => {},
      defaultColumn: [],
      withDragAndDrop: false,
      dataLength: null,
      disableSortBy: false,
      manualSortBy: false,
      manualGlobalFilter: false,
      manualPagination: false,
    },
  ],
};

export default ReactTableConstructorClient;

// region STYLES

const TableWrap = styled.div`
  //overflow-x: auto;
  overflow: auto;
  height: ${(p) => p.height}px;
  ${scrollbarStyles};

  ${(p) =>
    p.active &&
    `
    margin: 0 5px 0 0;
    border-right: 1px solid ${colorLightGray};
    border-bottom: 1px solid ${colorLightGray};
  `};

  ${(props) =>
    props.pagination
      ? `
    //margin-bottom: 1rem;
    //height: 100%;
  `
      : `
    //height: 100%;
  
    tbody {
      top: 30px;
    }
  `}
`;

const TableSpinner = styled(Spinner)`
  color: ${colorBlue};
`;

// endregion
