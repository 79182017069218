import React, { useEffect, useRef, useState } from "react";
import { TopbarButton, TopbarButtonNewLabel } from "./BasicTopbarComponents";
import { Offcanvas } from "react-bootstrap";
import DOMPurify from "dompurify";
import { post, postApi } from "../../../../api/getUser";
import {
  ActionIconContainer,
  StatusContainer,
} from "../../../../shared/components/TableElements";
import {
  colorBackground,
  colorBackgroundBody,
  colorGreenStatus,
  colorLightGray,
  colorRedNew,
  colorYellowNew,
  scrollbarStyles,
  sidenavColor,
} from "../../../../utils/palette";
import {
  FormItem,
  HintText,
} from "../../../../shared/components/form/FormElements";
import { getListingDisplayDate } from "../../../../shared/helpers";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formInputStyles } from "../../../../shared/constants/styles";
import useDebounce from "../../../../shared/custom-hooks/useDebounce";
import getUnixTime from "date-fns/getUnixTime";

const TopbarAnnouncements = () => {
  const { t } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showNew, setShowNew] = useState(false);

  const [filterText, setFilterText] = useState("");
  const debounceFilterText = useDebounce(filterText, 500);
  const [templateArr, setTemplateArr] = useState([]);
  const allAnnouncements = useRef([]);

  useEffect(() => {
    // if (show) {
    (async () => {
      const selectedLang = localStorage.getItem("language");
      const whatsNewApiData = {
        querystring: "get_whats_new",
        locale: selectedLang || "en",
      };
      const whatsNewRes = await postApi(whatsNewApiData, "commonNew");
      if (whatsNewRes?.success) {
        const { user_data } = whatsNewRes;
        let temp = [];
        if (user_data?.length > 0) {
          user_data.forEach((row) => {
            const { _id, label, description, title, show_date } = row;
            const htmlStr = DOMPurify.sanitize(description, {
              ADD_ATTR: ["target"],
            });
            temp.push({ _id, htmlStr, title, label, show_date });
          });
          const lastSeenUnix = localStorage.getItem("whatsNewLastSeen") || 0;
          const showNewIcon = temp
            .map((obj) => obj.show_date)
            .some((unixDate) => unixDate > lastSeenUnix);
          setShowNew(showNewIcon);
        }
        allAnnouncements.current = temp;
        setTemplateArr(temp);
      }
    })();
    // }
  }, []);

  function extractTextFromHtml(htmlString) {
    // Parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Extract the text content and clean up the extra spaces
    const textContent = doc.body.textContent || "";
    return textContent.replace(/\s+/g, " ").trim().toLocaleLowerCase();
  }

  useEffect(() => {
    if (!debounceFilterText) {
      setTemplateArr(allAnnouncements.current);
    }
    if (debounceFilterText) {
      const filtered = allAnnouncements.current.filter((obj) => {
        const { title, htmlStr } = obj;
        const filterTextLowerCase = debounceFilterText.toLocaleLowerCase();
        const extractedHtmlText = extractTextFromHtml(htmlStr);
        if (
          title?.toLocaleLowerCase().includes(filterTextLowerCase) ||
          extractedHtmlText.includes(filterTextLowerCase)
        ) {
          return obj;
        }
      });
      setTemplateArr(filtered);
    }
  }, [debounceFilterText]);

  const handleClose = () => {
    localStorage.setItem("whatsNewLastSeen", getUnixTime(new Date()));
    setFilterText("");
    setShowSearchInput(false);
    setShow(false);
  };

  const AnnouncementType = ({ label }) => {
    let type = { text: t("new_feature"), bg: colorGreenStatus };

    switch (label) {
      case 1:
        type.text = t("new_feature_caps");
        type.bg = colorGreenStatus;
        break;
      case 2:
        type.text = t("feature_update_caps");
        type.bg = colorYellowNew;
        break;
      case 3:
        type.text = t("bug_fix_caps");
        type.bg = colorYellowNew;
        break;
      case 3:
        type.text = t("announcement_caps");
        type.bg = colorRedNew;
        break;
      default:
        type.text = t("new_feature_caps");
        type.bg = colorGreenStatus;
        break;
    }
    return (
      <StatusContainer
        background={type.bg}
        style={{ fontSize: 10 }}
        className="px-2"
      >
        <p>{type.text}</p>
      </StatusContainer>
    );
  };

  return (
    <>
      <TopbarButton
        //className="d-flex justify-content-center align-items-center"
        onClick={() => {
          setShow(true);
          setShowNew(false);
        }}
      >
        <i className="fi fi-sr-megaphone"></i>
        {showNew && (
          <TopbarButtonNewLabel>
            <span />
          </TopbarButtonNewLabel>
        )}
      </TopbarButton>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header style={{ background: sidenavColor }}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{ color: "white", height: "40px" }}
          >
            {showSearchInput ? (
              <FormItem>
                <input
                  type="text"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  style={{ ...formInputStyles, background: "white" }}
                  placeholder={t("whats_new")}
                />
              </FormItem>
            ) : (
              <h4 style={{ color: "white" }}>{t("whats_new")}</h4>
            )}
            <div className="d-flex gap-3">
              {/* <ActionIconContainer
                onClick={() => setShowSearchInput((prev) => !prev)}
              >
                <i className="fi fi-rr-search" style={{ color: "white" }} />
              </ActionIconContainer> */}
              <ActionIconContainer onClick={handleClose}>
                <i
                  className="fi fi-rr-cross-small"
                  style={{ color: "white" }}
                />
              </ActionIconContainer>
            </div>
          </div>
          {/* <Offcanvas.Title style={{ color: "white" }}>
            Offcanvas
          </Offcanvas.Title> */}
        </Offcanvas.Header>
        <OffcanvasBody
          style={{ background: colorBackgroundBody }}
          className="m-0 p-2"
        >
          <div className="d-flex flex-column gap-2">
            {templateArr.length === 0 && (
              <p className="d-flex justify-content-center align-items-center m-2">
                {t("no_records")}
              </p>
            )}
            {templateArr.map((obj) => (
              <div
                key={obj._id}
                style={{
                  //border: "1px solid black",
                  borderRadius: "5px",
                  boxShadow: `1px 1px 3px ${colorLightGray}`,
                  background: "white",
                }}
                className="d-flex flex-column gap-2 p-2"
              >
                <div className="d-flex gap-2">
                  <AnnouncementType label={obj.label} />
                  <HintText>{getListingDisplayDate(obj.show_date)}</HintText>
                </div>
                <h4 style={{ fontWeight: 500, fontSize: 16 }}>{obj.title}</h4>
                <div
                  dangerouslySetInnerHTML={{ __html: obj.htmlStr }}
                  style={{ fontSize: 12 }}
                />
              </div>
            ))}
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default TopbarAnnouncements;

//styles

export const OffcanvasBody = styled(Offcanvas.Body)`
  ${scrollbarStyles}
`;
