import React from "react";
import styled from "styled-components";
import logo from "../../../shared/img/simply-fleet-logo.png";

const UnderMaintenanceComponent = () => {
  return (
    <MainContainer>
      <img
        src={logo}
        width={240}
        height={100}
        style={{ objectFit: "contain" }}
      />
      <p>Website under maintenance.</p>
      <p>We will be back shortly.</p>
    </MainContainer>
  );
};

export default UnderMaintenanceComponent;

//styles
const MainContainer = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  display: flex;
  background-size: cover;
  background: white;

  p {
    font-size: 22px;
    font-weight: 500;
  }
`;
